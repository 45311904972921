.imagery {
    &-text {
        font-size: rem(12);
        text-align: center;
        margin-bottom: 25px;
        color: $hel-gray;

        @include respond-to(mobile) {
            font-size: rem(8);
        }
    }

    &-wrapper {
        text-align: center;
        margin-bottom: 50px;

        @include respond-to(m_tablet_down) {
            display: none;
        }

        &.expanded {
            .imagery-expandable {
                height: auto;
                transition: all 1s ease-in-out;
            }

            .imagery-button {
                &::before {
                    content: "Show less";
                }
            }
        }

        .video-js {
            width: 100%;
            height: 240px;
            background-color: #fff;
        }

        .vjs-poster {
            background-size: auto;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4%;
    }

    &-item {
        flex: 1 1 48%;
        margin-bottom: 25px;

        &.imagery-video {
            flex-basis: 100%;
        }
    }

    &-image {
        width: 100%;
    }

    &-button {
        padding: 12px 55px;
        font-size: 14px;

        &::before {
            content: "Load more";
            display: block;
        }

        &::after {
            display: none;
        }
    }

    &-expandable {
        height: 0;
        overflow: hidden;
        transition: all 1s ease-in-out;
    }
}

// Thumbnails
.product-image-container {
    .product-thumbnails {
        position: static;
        margin: 0;

        @include respond-to(mobile) {
            margin: 20px 0;
        }
    }

    .carousel-item {
        @include respond-to(mobile) {
            width: 16px !important;
            height: 1px;
            background: $very-light-gray;
            margin-right: 7px;

            &:last-child {
                margin-right: 0;
            }

            &.selected {
                height: 2px;
                background: $black01;
            }
        }
    }

    .thumbnail-link {
        @include respond-to(mobile) {
            display: block;
        }
    }

    .carousel.carousel-vertical {
        .jcarousel-list {
            @include respond-to(mobile) {
                text-align: center;
                transform: none !important;
            }

            @include respond-to(tablet_plus) {
                height: auto;
                display: none;
            }
        }
    
        .jcarousel-nav {
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;
            color: $black01;
            height: auto;
            width: auto;
            margin: 0;
            background-color: transparent;
            
            &::before {
                width: 50px;
                font-size: rem(50);
            }

            &:hover {
                @include respond-to(touch_over) {
                    &::before {
                        color: $hel-gray;
                    }
                }
            }

            &.inactive {
                display: block;
            }
        }

        .jcarousel-next {
            @include icon(arrow-circle-right-large);

            right: 0;
            left: auto;
        }
    
        .jcarousel-prev {
            @include icon(arrow-circle-left-large);

            left: 0;
            right: auto;
        }
    }
}