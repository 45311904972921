.brand-wall-wrapper {
    position: relative;

    .cat-wall-links-wrapper {
        display: block;
        font-size: 0;

        &.brand-wall-tiles {
            margin-top: 65px;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        &.brand-wall-carousel {
            @include respond-to(mobile_strong_over) {
                display: none;
            }
        }
    }

    .slick-dots {
        text-align: center;
        position: static;
    }
}

.brand-wall-carousel-navigation {
    @include respond-to(mobile_strong_over) {
        display: none;
    }

    .prev,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(25);
        margin-top: -30px;
        background: transparent;
        color: $color-primary2;

        .path1,
        .path2 {
            &::before {
                color: inherit;
            }
        }
    }

    .prev {
        left: 0;
        transform: translateY(-50%) scaleX(-1);
    }

    .next {
        right: 0;
    }
}

.btand-wall-tab {
    display: table;
    margin: 0 auto 30px;
    font-size: rem(14);
    text-transform: uppercase;
    color: $color-primary2;
    border-bottom: 1px solid;
    padding-bottom: 20px;

    @include respond-to(mobile_strong) {
        margin: 0 20px 30px;
        display: block;
        text-align: center;
    }
}

.brand-logo-image {
    display: inline-block;
    vertical-align: middle;
    width: 20%;
    margin-bottom: 40px;
    margin-right: 6.66667%;
    font-size: rem(25);
    text-align: center;
    text-transform: uppercase;

    &:nth-child(4n) {
        @include respond-to(mobile_strong_over) {
            margin-right: 0;
        }
    }

    &:nth-child(2n) {
        @include respond-to(mobile_strong) {
            margin-right: 0;
        }
    }

    @include respond-to(mobile_strong) {
        width: 45%;
        margin-right: 10%;
        margin-bottom: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }

    img {
        max-width: 100%;
        display: block;
        margin: auto;
    }
}
