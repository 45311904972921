.js {
    .loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.loader-fixed {
            position: fixed;
            z-index: 99;
        }
    }

    .loader-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 0.7;
    }
    
    .loader-indicator {
        z-index: 100;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 64px;
        height: 64px;
        background: url("../images/loading-small.gif") no-repeat;
    }
}

// Styles lazyloading images

@keyframes placeholderShimmer {
    0% {
        background-position: -475px 0;
    }
    100% {
        background-position: 475px 0; 
    }
}

@mixin lazyload-animation {
    padding-bottom: 100%;
    display: block;
    background-color: $periwinkle;
    background-image: linear-gradient(90deg, rgba(232,227,222,0) 0%, rgba(255,255,255,0.35) 50%, rgba(232,227,222,0) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 15;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    overflow: hidden;
    width: 100%;
}

.lazyloading,
.lazyload {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}