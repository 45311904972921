/* Edit account */
.myaccount-header-banner {
    background: $my-account-header-banner-bg;
    text-align: center;
    padding-bottom: 30px;

    h2 {
        padding: 20px 0;
    }

    p {
        width: 80%;
        display: block;
        margin: 0 auto;
        font-size: rem(14);
    }

    &.payment-header-banner {
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .pt_account_unregistered & {
        padding-top: 30px;
    }
}