.pt_storefront #main {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

.main-hp {
    @include respond-to(mobile) {
        padding-left: 0;
        padding-right: 0;

        .slot .carousel-wrapper {
            padding-bottom: rem(50);
        }
    }

    .global-recommendations,
    .carousel-wrapper {
        @include respond-to(mobile) {
            padding: 0 15px;
        }
    }
}

/* New arrivals */
.hp-new-arrivals {
    .fwb-panel .fwbp-text-table .fwbp-text-cell {
        padding: 2.25rem 8rem;
    }

    .slot {
        margin-bottom: 60px;
    }

    .slick-arrow {
        //background: rgba($black01, 0.6);
        color: $white;

        &:hover {
            color: $white;
        }
    }

    .slick-prev {
        left: 15px;

        @include respond-to(mobile) {
            left: 5px;
        }
    }

    .slick-next {
        right: 15px;

        @include respond-to(mobile) {
            right: 5px;
        }
    }
}

.homepage-slider {
    overflow: hidden;
    position: relative;
    width: 100%;

    .jcarousel-control {
        bottom: 0.5rem;
        position: absolute;
        right: 0.5rem;

        a {
            background: none repeat scroll 0 0 $gainsboro;
            border: 1px solid $very-light-gray;
            display: block;
            float: left;
            height: 24px;
            margin: 0 3px;
            opacity: .5;
            text-indent: -999em;
            width: 24px;

            &.active {
                background-color: $dim-gray;
            }

            &:hover {
                background-color: $dim-gray;
                opacity: .7;
            }
        }
    }
}

.home-bottom-slots {
    margin: auto;
    max-width: 960px;

    .home-bottom-left,
    .home-bottom-center,
    .home-bottom-right {
        clear: none;
        width: 100%;

        @include respond-to(desktop_plus) {
            float: left;
            width: 33.333%;
        }
    }
}

/* Best Sellers */
.hp-best-sellers {
    .slot {
        margin-bottom: 60px;
    }

    .carousel {
        .jcarousel-nav {
            margin-top: 0;
            top: 23%;
        }
    }

    .product-tile {
        height: auto;
    }
}

/* New In & Be Inspired */
// @FIXME
// not sure but this part of code can potentially cause artifacts in many other places (not only "New In & Be Inspired")
// like "slot-panels-container" negative -20px left-margin I found in "header-banner"
.slot-panels {
    .slot,
    &.slot {
        margin-bottom: 60px;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    &-container {
        @extend %clearfix;
        margin-left: -40px;

        @include respond-to(tablet) {
            margin-left: -20px;
        }

        @include respond-to(mobile) {
            margin-left: 0;
        }
    }

    .asset {
        float: left;
        padding-left: 40px;
        width: 50%;

        @include respond-to(tablet) {
            padding-left: 20px;
        }

        @include respond-to(mobile) {
            float: none;
            margin: 0 auto 20px;
            padding-left: 0;
            width: 95%;
        }

        &-link {
            display: block;
        }

        &-image_container {
            display: block;
        }

        &-image {
            display: block;
            max-width: 100%;
            border-radius: 5px;
        }

        img {
            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &-text {
            display: block;
            margin-bottom: 10px;
            min-height: 0;
        }

        &-title {
            display: block;
            font-size: rem(18);
            margin-bottom: 4px;
            max-height: 58px;
            overflow: hidden;

            @include respond-to(mobile) {
                font-size: rem(20);
                max-height: none;
            }
        }

        &-description {
            color: $hel-gray;
            display: block;
            font-size: rem(14);
            overflow: hidden;
        }

        &-button {
            font-size: rem(12);
        }

        &-info_container {
            display: block;
            padding: 24px 0;
            text-align: center;

            @include respond-to(tablet) {
                padding: 18px 0;
            }
        }
    }

    &.text-left {
        .asset-info_container {
            text-align: left;
        }
    }

    &-three-box {
        .asset {
            width: 33.333%;
        }
    }

    &-four-box {
        .asset {
            width: 25%;
        }
    }

    &-three-box,
    &-four-box {
        .asset {
            padding-left: 6px;

            @include respond-to(mobile) {
                padding-left: 0;
                width: 95%;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-title {
                max-height: 50px;
                text-transform: capitalize;
                margin-bottom: 10px;

                @include respond-to(mobile) {
                    max-height: 50px;
                }
            }
        }

        .asset-text {
            min-height: 0;
        }

        .slot-panels-container {
            margin-left: -6px;

            @include respond-to(mobile) {
                margin-left: 0;
            }
        }
    }
}

.global-recommendations {
    margin-bottom: rem(50);
}
