// Brand banner slot
.brand-banner-slot-wrapper {
    @include clip-decorator($croppedTop: true);

    .component-image {
        @include respond-to(tablet_plus) {
            min-height: 400px;
            width: auto;
            max-width: none;
        }
    }
}

.brand-banner-wrapper {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    bottom: 0;
    color: $color-primary3;
    left: 0;
    padding: 10px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    @include respond-to(mobile) {
        padding: 30px;
    }
}

.brand-banner-title {
    font-family: $font-main;
    line-height: 1;
    color: $color-primary3;
    margin-bottom: 1.4%;

    @include respond-to(mobile) {
        font-size: rem(22);
        margin-bottom: 7%;
    }

    @include respond-to(tablet_plus) {
        font-size: rem(32);
    }
}

.brand-banner-description {
    max-width: 420px;
    font-size: rem(14);
    line-height: 20px;
    margin: 0 0 4%;

    @include respond-to(mobile) {
        margin-bottom: 6%;
    }
}
