.bonus-item-promo {
    padding-bottom: 10px;
    visibility: hidden;

    &.active {
        visibility: visible;
    }

    &:nth-child(2n) {
        @include respond-to(mobile_mid) {
            display: none;
        }
    }

    .cart-promo .bonus-item-actions {
        width: 100%;
    }
}

.cart-banner {
    margin: 40px 0 20px;
    text-align: center;
    font-size: rem(14);

    @include respond-to(mobile) {
        margin-bottom: 25px;
    }

    &.stores-restricted {
        color: $black01;
    }

    .button {
        line-height: 1.2;
    }

    &.bonus-edit {
        .button {
            background-color: $color-secondary5;
        }
    }
}

.cart-promo {
    @extend %clearfix;
    display: table;
    width: 100%;
    position: relative;
    color: $night-rider;
    margin: 20px 0;

    @include respond-to(mobile_strong) {
        display: block;
    }

    .bonus-item-promo-name {
        text-transform: uppercase;
        color: $pink;
    }

    .bonus-item-details {
        display: table-cell;
        width: 73%;
        padding-right: 1.5rem;

        @include respond-to(mobile) {
            display: block;
            width: 100%;
        }

        @include respond-to(mobile_strong_over) {
            display: table-cell;
        }
    }

    .bonus-item-actions {
        display: table-cell;
        width: 30%;
        text-align: right;
        vertical-align: middle;

        a {
            @include link_underline($medium-blue);

            display: inline-block;
            text-transform: capitalize;
            white-space: nowrap;
        }

        @include respond-to(mobile) {
            display: block;
            width: 100%;
            padding: 2rem 0 1rem;
        }

        @include respond-to(mobile_strong_over) {
            display: table-cell;
            padding: initial;
        }
    }
}

.cart-title {
    position: relative;
    text-align: center;

    @include respond-to(mobile) {
        font-size: rem(26);
        margin: 40px 0 25px;
    }

    @include respond-to(tablet_plus) {
        font-size: rem(30);
        margin: 40px 0 36px 0;
    }
}

.cart-coupon-code {
    float: left;
    width: 100%;
    text-align: center;

    .coupon-error {
        text-align: left;
    }

    input {
        width: 100%;
        margin: 30px 0 0;
        display: block;

        @include respond-to(mobile) {
            width: 96%;
            margin: 30px 2% 0;
        }

        &.error {
            margin-top: 30px;
        }
    }

    .field-wrapper,
    .form-row {
        margin-bottom: 0;
    }

    .error {
        padding-top: 0.4em;

        @include respond-to(mobile) {
            width: 96%;
            margin: 0 auto;
        }
    }

    button {
        margin-top: 10px;

        @include respond-to(mobile) {
            float: none;
            display: block;
            width: 96%;
            margin: 10px auto 20px;
        }
    }
}

.rowcoupons {
    position: relative;
    background: $light-gray;
    border: 1px solid $medium-blue;
    color: $night-rider;
    margin: 20px 0;
    padding: 10px;
    word-break: break-all;

    @include respond-to(mobile) {
        display: none;
    }

    &-couponcodes-label {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .coupon {
        position: relative;
    }

    .item-total {
        display: block;
        margin-top: 15px;
        font-size: rem(19);
    }

    .item-details {
        font-size: rem(19);
    }

    button {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 20px;
        height: 20px;
        padding: 20px 0;
        background: none;
        color: $night-rider;
        font-size: 0;

        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 100%;
            border-bottom: 1px solid;
            margin-left: 5px;
        }
    }
}

.cart-content-wrapper {
    @extend %clearfix;

    .item-stock-info {
        margin-bottom: rem(6);

        @include respond-to(mobile_stronger) {
            margin-bottom: rem(1);
        }
    }
}

.item-stock-info-wrapper {
    display: none;
}

.cart-empty-content {
    @include respond-to(mobile) {
        -webkit-order: 1;
        order: 1;
    }

    @include respond-to(tablet_plus) {
        float: left;
        width: 65%;

        .cart-banner,
        .cart-items-form {
            width: 100%;
        }
    }

    .cart-banner-slot {
        @include hidden;
    }
}

.cart-footer .main-title {
    display: none;
}

.cart-items-form {
    @extend %clearfix;
    margin: 1rem 0;

    @include respond-to(tablet_plus) {
        width: 100%;
    }

    @include respond-to(mobile) {
        @include flexbox();
        @include flex-vertical();
    }

    .rowbundle-wrapper {
        clear: both;
        padding-top: 20px;
    }

    .rowbundle {
        @extend %clearfix;
        margin-bottom: 10px;
        padding-bottom: 10px;

        .item-image {
            display: block;
            float: left;
            width: 15%;

            @include respond-to(mobile) {
                display: none;
            }
        }

        .image-container {
            @include respond-to(mobile) {
                float: left;
                width: 20%;
            }
        }

        .name {
            @include respond-to(mobile) {
                float: left;
                width: 80%;
            }
        }

        img {
            margin-bottom: 5px;
            max-width: 80px;

            @include respond-to(mobile) {
                max-width: 100%;
            }
        }

        .item-details {
            display: block;
            float: left;
            padding: 0 0 0 20px;
            width: 40%;
        }

        .bundla-brand {
            display: block;
            font-size: rem(16);
        }

        .item-quantity-details {
            float: left;
            padding: 0 2rem 0;
            width: 28%;

            @include respond-to(mobile) {
                float: none;
                padding: 0;
                width: 100%;
            }
        }

        .item-price,
        .item-total {
            display: none;
        }
    }

    .item-list {
        float: left;
        width: 60%;

        @include respond-to(mobile) {
            float: none;
            order: 2;
            width: 100%;
            padding: 0 10px;

            .cart-content-banner {
                display: none;
            }
        }

        > div {
            @extend %clearfix;
        }
    }

    .line-items-body {
        width: 79%;

        @include respond-to(m_tablet_down) {
            width: 100%;
        }

        &::before {
            @include respond-to(m_tablet) {
                content: "";
                display: block;
                width: 133%;
                border-bottom: 1px solid $very-light-gray;
            }
        }
    }

    .line-item-wrapper {
        @include flexbox();
        @include align-items(center);

        border-bottom: 1px solid $color-secondary3;
        margin: 0;
        min-height: 170px;
        padding: 40px 0 40px 10%;
        position: relative;

        @include respond-to(mobile_stronger) {
            padding: 40px 0 40px 5%;
        }

        @include respond-to(mobile_stronger) {
            margin-top: 2rem;
            padding: 40px 0;
        }

        @include respond-to(mobile_mid) {
            @include align-items(start);

            padding: 45px 0 40px;
        }

        &:first-child {
            @include respond-to(mobile_stronger) {
                margin-top: 0;
            }
        }

        &:last-child {
            @include respond-to(mobile) {
                border-bottom: 1px solid $color-secondary2;
                margin-bottom: 2rem;
            }
        }

        &.line-items-row {
            .item-quantity-details {
                padding-top: 2rem;
            }
        }

        &.line-item-bonus {
            .item-price {
                @include respond-to(mobile) {
                    display: none;
                }
            }

            .item-details .bonus-item {
                display: none;

                @include respond-to(mobile) {
                    display: block;
                }

                .value {
                    text-transform: uppercase;
                }
            }

            .item-quantity-details {
                padding-top: 0;
            }
        }

        .promo {
            clear: both;
            width: 100%;
            padding: 1rem;
            margin-bottom: 10px;
            background-color: $white-smoke;
        }

        > span {
            display: block;
            float: left;
            margin: 0;
            padding: 0;

            &.item-total {
                @include flexbox();
                @include align-items(flex-end);
                @include justify-content(center);
                @include flex-direction(column);

                border-bottom: 2px solid $black01;
                bottom: -1px;
                height: 100%;
                left: 108%;
                margin: 0;
                padding: 0;
                position: absolute;
                width: 25%;

                @include respond-to(tablet_ext) {
                    width: 23%;
                }

                @include respond-to(mobile_stronger) {
                    left: 84%;
                    width: 16%;
                }

                @include respond-to(tablet) {
                    width: 23%;    
                }

                @include respond-to(mobile_strong) {
                    display: none;
                }

                &.cart-price-adjusted {
                    border-bottom-color: $red01;

                    &.has-rrp-price {
                        border-bottom-color: #000;

                        .price-adjusted-total {
                            color: #000;
                        }
                    }
                }

                .price-total {
                    font-family: $font-main-bold;

                    @include respond-to(mobile) {
                        font-size: rem(14);
                    }

                    @include respond-to(tablet_plus) {
                        font-size: rem(14);
                    }
                }

                .price-adjusted-total,
                .price-unadjusted {
                    display: block;
                    text-align: right;
                    text-transform: capitalize;

                    @include respond-to(mobile) {
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                .price-adjusted-total {
                    font-family: $font-main-bold;
                    color: $red01;

                    @include respond-to(mobile) {
                        font-size: rem(14);
                        margin-bottom: 4px;
                    }

                    @include respond-to(tablet_plus) {
                        font-size: rem(14);
                    }
                }

                .price-unadjusted {
                    font-size: rem(14);
                    color: $charcoal;
                    text-decoration: line-through;

                    @include respond-to(mobile) {
                        font-family: $font-main-bold;
                    }

                    &.was-price {
                        font-family: $font-main;
                    }

                    &.rrp-price {
                        text-decoration: none;
                        font-family: $font-main;
                    }
                }

                .promo-adjustment {
                    text-align: right;
                    margin-top: 10px;
                    color: initial;
                    font-size: 14px;
                }

                .promo-adjustment-inner {
                    padding-left: 5px;
                    word-break: break-word;

                    > p {
                        font-size: 1.2rem; 
                        
                        @include respond-to(desktop_below) {
                            font-size: 1rem; 
                        }
                    }
                }

                .bonus-item {
                    font-family: $font-main-bold;
                    font-size: rem(14);
                }
            }
        }

        .item-image {
            width: 27%;

            @include respond-to(mobile_strong) {
                width: 44%;
                padding: 0 10px;
            }

            @include respond-to(mobile_stronger) {
                padding-left: 7%;
            }

            @include respond-to(mobile_mid) {
                padding: 0;
            }

            img {
                width: 100%;

                @include respond-to(mobile_strong) {
                    width: 80%;
                }

                @include respond-to(mobile_mid) {
                    width: 94.5%;
                }
            }
        }

        .item-details,
        .item-quantity-details {
            margin: 0;
            padding: 0;
        }

        .item-details {
            @include flexbox();
            @include align-self(flex-start);

            margin-top: 13px;
            padding-left: 7%;
            width: 73%;

            @include respond-to(mobile_mid) {
                margin-top: 0;
            }

            @include respond-to(mobile_stronger) {
                padding-left: 2%;
                width: 58%;
            }

            @include respond-to(mobile_strong) {
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                width: 48%;
                padding-left: 0;
            }

            .product-list-item {
                width: 60%;

                @include respond-to(m_mobile_down) {
                    display: flex;
                    flex-direction: column;
                }

                > div {
                    padding-bottom: 0.4rem;
                }

                .label {
                    text-transform: capitalize;
                }

                .product-custom-delivery-cta {
                    font-size: rem(12);
                    color: $hel-gray;
                    margin-bottom: 6px;

                    @include respond-to(mobile_strong) {
                        order: 6;
                        padding-bottom: 5px;
                    }

                    @include respond-to(mobile) {
                        margin: 7px 0 2px;
                    }
                }

                .product-intl-delivery-unavail,
                .product-intl-delivery-avail {
                    @include respond-to(mobile_strong) {
                        order: 7;
                        padding-bottom: 20px;
                    }
                }

                .cart-price-display {
                    @include respond-to(mobile_stronger) {
                        display: none;
                    }

                    @include respond-to(mobile_strong) {
                        order: 5;
                        padding-bottom: 20px;
                        font-size: rem(14);
                        color: $nobel;
                        display: inline-block;

                        .label {
                            display: none;
                        }

                        .item-stock-info {
                            display: block;
                            position: initial;
                            margin: 1px 0 12px;
                        }
                    }

                    .price-with-adjustments {
                        font-family: $font-main-bold;

                       .price-adjusted-total {
                            color: $red01;
                       }

                       .was-price {
                            font-family: $font-main;
                       }

                       &.has-rrp-price {
                            .price-adjusted-total {
                                color: #000;
                            }
                       }
                    }

                    .price-promotion {
                        @include respond-to(mobile) {
                            display: flex;

                            .price-standard {
                                order: 2;
                                margin-left: 5px;
                                color: $charcoal;
                                text-decoration: line-through;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .promo-adjustment {
                        @include respond-to(mobile) {
                            display: none;
                        }
                    }

                    .price-unadjusted {
                        @include respond-to(mobile) {
                            color: $charcoal;
                            text-decoration: line-through;
                            text-transform: uppercase;
                            margin-right: 5px;
                            display: inline-block;
                            order: 3;

                            &.rrp-price {
                                text-decoration: none;
                                font-family: $font-main;
                            }
                        }
                    }

                    .price-adjusted-total,
                    .price-total {
                        @include respond-to(mobile) {
                            font-family: $font-main-bold;
                            display: inline-block;
                            order: 2;

                            .value {
                                color: $black01;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .label {
                    @include respond-to(mobile) {
                        order: 1;
                    }
                }

                .over18sproduct_i {
                    display: inline-block;
                }

                .quantity {
                    font-size: rem(12);
                    margin-bottom: rem(1);

                    @include respond-to(mobile_strong) {
                        font-size: rem(14);
                        order: 4;
                        margin-bottom: 6px;
                        color: $nobel;
                    }

                    .label,
                    .value {
                        color: $color-auxiliary19;
                    }
                }

                .product-brand {
                    font-family: $font-main-bold;
                    margin-bottom: 2px;
                    padding: 0;
                    font-weight: $normal;
                    color: $nobel;
                    font-size: rem(14);

                    @include respond-to(mobile_strong) {
                        order: 1;
                    }
                }

                .name {
                    font-size: rem(14);
                    color: $nobel;
                    word-break: break-word;
                    word-wrap: break-word;

                    @include respond-to(mobile) {
                        margin-bottom: 27px;
                        padding-bottom: 0;
                    }

                    @include respond-to(tablet_plus) {
                        margin-bottom: 5px;
                        padding-bottom: 1rem;
                    }

                    @include respond-to(mobile_strong) {
                        order: 2;
                        padding-bottom: 15px;
                        margin-bottom: 0;
                        color: $black01;
                    }
                }

                .sku {
                    display: none;
                }

                .attribute {
                    @include respond-to(mobile) {
                        font-size: rem(14);
                    }

                    @include respond-to(tablet_plus) {
                        font-size: rem(12);
                    }

                    @include respond-to(mobile_strong) {
                        order: 3;
                        color: $black01;
                    }

                    .label,
                    .value {
                        color: $hel-gray;

                        @include respond-to(mobile_strong) {
                            color: $black01;
                        }
                    }
                }
            }

            .item-edit-details {
                text-decoration: underline;
                font-size: rem(15);
            }
        }

        .item-quantity-details {
            width: 40%;
            padding: 1rem 0 0;

            @include respond-to(mobile_stronger) {
                width: 65%;
                float: none;
                padding: 0;
            }

            @include respond-to(mobile_strong) {
                width: auto;
                padding: 0;
                display: flex;
                flex-direction: column;
            }

            .item-user-actions {
                @extend %clearfix;
                clear: both;
            }

            .item-availabilitymsg {
                display: block;
                font-size: rem(14);
                line-height: 14px;
                padding-top: 10px;
                color: $red01;
            }

            .hamper-quantity-exceeded input.js-quantity {
                color : $red01;
            }

            span.error,
            .error-quantity {
                bottom: auto !important;
                position: static !important;
                width: auto !important;
                margin-bottom: 0;

                @include respond-to(mobile_strong) {
                    text-align: center;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }

            .error-quantity {
                font-size: rem(12);
                color: $red01;
                margin-top: 0 !important;
                text-align: center;

                @include respond-to(mobile_strong) {
                    order: 3;
                }
            }

            .quantity-block {
                @extend %clearfix;
                position: relative;

                @include respond-to(mobile) {
                    margin-bottom: 13px;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .label {
                    display: none;
                }

                span.error {
                    padding: 0;
                }

                .label-quantity-alt {
                    display: inline;
                }

                .error-quantity {
                    margin-top: 45px;
                    margin-bottom: 0;
                }
            }

            &.without-qty-input {
                padding-top: 0;
                font-family: $font-main-bold;
                font-size: rem(12);
                display: block;

                @include respond-to(mobile_strong_over) {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 34%;
                    text-align: center;
                }

                @include respond-to(mobile_strong) {
                    font-size: rem(14);
                }

                .quantity-block {
                    margin: 0;
                }
            }

            .item-availabilitymsg {
                font-size: rem(16);
                line-height: 1;
                margin-bottom: 20px;
                text-align: left;
            }

            .quantity {
                min-width: 114px;

                @include respond-to(tablet_plus) {
                    width: 114px;
                }

                &.has-availability-msg {
                    display: table;
                    margin: 30px 0;

                    @include respond-to(mobile) {
                        margin-bottom: 0;
                        width: 100%;
                    }

                    .item-user-actions {
                        display: table-footer-group;
                    }

                    .button-update-item {
                        margin-top: 15px;
                    }

                    .item-availabilitymsg {
                        display: table-row-group;
                        font-size: rem(12);
                        text-align: center;
                        color: $red01;
                    }
                }

                &.quantity-disabled {
                    margin: 0;
                }
            }
        }

        @at-root { // overriding styles from _responsive.scss
            #cart-table .item-user-actions {
                @include custom-respond-to($break_mobile, $break_tablet) {
                    width: auto;
                }
            }
        }

        .home-delivery,
        .item-delivery-options,
        .item-price,
        .product-availability-list {
            display: none;
        }

        .item-price {
            @include respond-to(mobile) {
                display: block;
            }
        }

        .button-update-item {
            border-bottom: 1px solid currentColor;
            border-radius: 0;
            letter-spacing: .05em;
            margin: 0 auto;
            padding-bottom: 2px;
            text-decoration: none;
            width: auto;

            @include respond-to(mobile) {
                padding-bottom: 0;
            }
        }

        .button-remove-item,
        .button-remove-bonus-item {
            border: 0;
            border-radius: 0;
            bottom: 0;
            font-size: 0;
            height: 30px;
            width: 30px;
            left: 10px;
            margin: auto;
            position: absolute;
            text-decoration: none;
            top: 0;
            background-color: transparent;

            @include respond-to(mobile_strong) {
                right: -95%;
                top: 15px;
                bottom: auto;
                left: 0%;
            }

            @include respond-to(mobile_stronger) {
                padding: 0;

            }

            &::before,
            &::after {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 2px;
                transition: opacity .3s;
                transform: none;
            }

            @include icomoon-icon($aricons-close-2-path2, $font-size: rem(26)) {
                color: $black01;
                opacity: 1;

                @include respond-to(mobile_strong) {
                    font-size: rem(20);
                }
            }

            @include icomoon-icon($aricons-close-2-path1, $font-size: rem(26), $pseudo: after) {
                color: $color-primary2;
                opacity: 0;

                @include respond-to(mobile_strong) {
                    font-size: rem(20);
                }
            }

            &:hover {
                &::before {
                    color: $black01;
                    opacity: 0;
                }

                &::after {
                    color: $black01;
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }

    // Selected gift bonus & Gift with purchase
    .bonus-product-title {
        display: block;
        position: absolute;
        top: 15px;
        left: 0;
        font-size: rem(16);
        line-height: rem(18);
        color: $nobel;
        font-weight: $bold;
        padding-bottom: 15px;
        width: 100%;
        border-bottom: 1px solid $mist;
        text-transform: none;
    }

    .bonus-product + .bonus-product {
        .bonus-product-title {
            display: none;
        }
    }

    .bonus-product,
    .line-item-bonus {
        position: relative;
        padding-bottom: 50px;

        @include respond-to(mobile_strong) {
            margin-top: 0;
        }

        .item-details {
            .product-list-item {
                @include respond-to(mobile_strong_over) {
                    width: 70%;
                }

                .name {
                    font-weight: unset;

                    @include respond-to(mobile_strong) {
                        padding-bottom: 5px;
                    }
                }

                .quantity {
                    display: none;
                }

                .product-custom-delivery-cta {
                    margin-top: 30px;

                    @include respond-to(mobile_strong) {
                        margin-top: 0;
                    }
                }

                .attribute {
                    font-size: rem(12);

                    @include respond-to(mobile_strong) {
                        font-size: rem(14);
                    }
                }

                .cart-price-display {
                    font-family: $font-main-bold;

                    @include respond-to(mobile_strong) {
                        margin-top: 13px;
                    }
                }
            }
        }

        .item-quantity-details,
        .wishlist-link {
            display: none;
        }

        .item-image {
            padding-top: 35px;
        }

        .item-details {
            padding-top: 35px;
            flex-wrap: wrap;
            position: relative;

            @include respond-to(mobile_strong) {
                flex-direction: column;
            }
        }

        .bonusproducts {
            a {
                color: $charcoal;
                font-size: rem(14);
                color: $nobel;
                text-decoration: underline;

                @include respond-to(mobile_strong) {
                    font-size: rem(12);
                    display: block;
                    margin-top: 20px;
                }
            }
        }
    }

    .product_actions-wishlist_link {
        @include shared-wishlist;

        position: absolute;
        bottom: 20px;
        left: 25px;
    }

    // show 'available' msg on cart
    .product-intl-delivery-avail {
        display: block;
    }
}

// hide international delivery item availability messages by default
.product-intl-delivery-avail,
.product-intl-delivery-unavail {
    display: none;
    font-size: rem(12);
    margin-bottom: 12px;
    color: $nobel;

    @include respond-to(mobile_strong) {
        margin-bottom: 0;
    }
}

// international customer
.cg-international {
    .cart-items-form {

        // hide 'avail' and standard delivery lead time msgs
        .product-intl-delivery-avail {
            display: none;
        }

        // show 'unavail' msg
        .product-intl-delivery-unavail {
            display: block;
        }
    }
}

.international-delivery-warning,
.cart-international-delivery-warning {
    background-color: $light-gray;
    font-size: rem(17);
    margin: -30px 0 45px;
    padding: 12px 15px 12px 45px;
    position: relative;

    @include respond-to(mobile) {
        font-size: rem(18);
        margin: 30px 0 10px;
        padding-left: 50px;
    }

    &-icon,
    &-icon.fa {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);

        &::before {
            font-size: rem(20);
        }
    }
}

.international-delivery-warning {
    margin: 0 0 10px;

    .is-not-authenticated-customer & {
        @include respond-to(m_desktop) {
            width: 94%;
        }
    }
}

.hide-form-part {
    .select-saved-addresses {
        margin: 0;
    }

    .form-row-field-state,
    .js-billing-fields-target,
    .form-row-field-address1,
    .form-row-field-address2,
    .form-row-field-city,
    .form-row-field-postal,
    .label-inline {
        // only visually hide elements to let them still be considered by client-side validator
        display: block;
        height: 0;
        overflow: hidden;
        margin: 0;
    }
}

 // ------ CHANEL SECTION ONLY -------- //

.cart-row {
    &.brand-chanel,
    .brand-chanel {
        .product-name-collection {
            display: block;
            text-transform: uppercase;
        }
        .name {
            text-transform: uppercase;

            a:hover {
                color: $color-secondary4;
            }
        }
    }
}

 // ------ CHANEL SECTION END -------- //

.pt_cart {
    .cart-empty-content {
        display: block !important;
    }

    .error-form {
        background-color: $white-smoke2;
        border-color: $red01;
        display: flex;
        align-items: center;
        margin: 0 auto 70px;
        padding: 10px;
        text-transform: none;
        text-align: center;
        font-size: rem(14);

        @include respond-to(m_desktop) {
            width: 745px;
        }

        @include respond-to(m_mobile_down) {
            align-items: flex-start;
        }

        @include respond-to(mobile) {
            font-size: rem(12);
            margin-bottom: 90px;
            padding: 15px 20px 15px 10px;
            text-align: left;
        }

        .fa.pull-left {
            margin: 3px 15px 0 0;
            float: none;
            font-size: rem(28);
        }
    }

    .cart-item-delivery-unavailable {
        font-size: rem(12);
        color: $red01;

        @include respond-to(mobile_strong) {
            order: 1;
            margin-bottom: 50px;
        }

        @include respond-to(mobile_stronger) {
            margin-left: 15px;
        }
    }
}

.item-stock-info-wrapper {
    @include respond-to(mobile_strong_over) {
        display: block;
    }
}

.bonus-product-dialog {
    min-width: 850px;
    max-width: 850px;

    @include respond-to(tablet_ext) {
        min-width: 98%;
        max-width: 98%;
    }

    @include respond-to(mobile) {
        min-width: 92%;
        max-width: 92%;
    }

    &.ui-dialog {
        .ui-dialog-title:after {
            border-bottom: 0;
        }

        .ui-dialog-titlebar {
            position: absolute;
            top: 15px;
            right: 15px;

            @include respond-to(mobile_mid) {
                top: 0;
                right: 0;
            }
        }

        .dialog-content {
            padding: 0 15px 20px;

            @include respond-to(mobile_mid) {
                padding: 0 0 20px;
            }
        }

        .ui-dialog-titlebar-close {
            height: 33px;

            &::before {
                font-size: rem(26);
            }

            .ui-icon-closethick {
                @include respond-to(mobile_mid) {
                    width: 20px;
                    height: 20px;
                    top: 2px;
                }
            }
        }
    }

    .choice-of-bonus-products {
        .title {
            font-size: rem(29);
            line-height: rem(37);
            font-family: $font-main-bold;
            padding: 0;

            @include respond-to(mobile_mid) {
                margin-top: 55px;
            }

            &::after {
                content: none;
            }
        }

        .subtitle {
            margin: 20px 0 50px;
            text-align: center;
            font-size: rem(14);
            font-family: $font-main-bold;
            line-height: rem(17);
            text-transform: none;

            @include respond-to(mobile_mid) {
                padding: 0 10px;
            }
        }

        .bonus-maxcount-error {
            margin-bottom: 30px;
            text-align: center;
            font-size: rem(14);
            font-family: $font-main-bold;
            line-height: rem(17);
        }

        .choice-of-bonus-products {
            color: $medium-blue;
            font-size: 14px;
            margin-bottom: 30px;
        }

        &-grid {
            margin: 0 auto;
            font-size: 0;
            text-align: center;
        }

        .bonus-product-tile {
            width: 20.2%;
            min-width: 165px;
            margin-right: 25px;
            margin-bottom: 45px;

            @include respond-to(mobile_mid) {
                margin-right: 13px;
                min-width: 150px;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .product-price {
                display: none;
            }

            .product-name {
                color: $hel-gray;
            }

            .product-variations {
                text-align: left;

                .selected-value {
                    width: 100%;
                }

                .attribute .value {
                    position: relative;
                }

                .toggle.expanded > ul {
                    left: 0;
                    width: 100%;
                    margin-left: 0;
                }
            }

            .product-compare {
                bottom: 0;

                label {
                    font-size: 0;
                    text-align: center;
                    overflow: hidden;
                    width: 100%;
                    height: 18px;
                    display: block;

                    &::before {
                        content: "";
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0;
                        margin-right: 0;
                        color: $very-light-grey;
                        font-size: 23px;
                        width: 18px;
                        height: 18px;
                        border: 1px solid $storm-grey;
                    }

                    &::after {
                        display: none;
                    }
                }

                input[type="checkbox"]:checked {
                    + label {
                        &::before {
                            content: '';
                        }

                        .check {
                            &::before {
                                content: '';
                                position: absolute;
                                left: 47%;
                                top: 14px;
                                width: 8px;
                                height: 2px;
                                background: $medium-blue;
                                -webkit-transform: rotate(50deg);
                                transform: rotate(50deg) translateX(-50%);
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                left: 50%;
                                top: 4px;
                                width: 13px;
                                height: 2px;
                                background: $medium-blue;
                                -webkit-transform: rotate(-50deg);
                                transform: rotate(-50deg) translateX(-50%);
                            }
                        }
                    }
                }
            }
        }

        &-grid {
            .product-variations {
                position: initial;

                .attribute.color {
                    .value {
                        .swatches.color {
                            li {
                                margin: 0;
                            }
                        }
                    }
                }

                .attribute.size {
                    .selected-value {
                        .swatch-image {
                            display: none;
                        }

                        span {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .choice-of-bonus-products-actions {
        display: flex;
        flex-direction: column;
        width: 42.2%;
        margin: 0 auto 90px;

        .button-fancy-medium {
            font-size: 14px;
            line-height: 48px;
            padding: 0 22px;

            &:first-child {
                background: $medium-blue;
                margin-bottom: 23px;

                @include respond-to(mobile_mid) {
                    margin-bottom: 0;
                }

                &:disabled {
                    background-color: $very-light-grey;
                }
            }
        }

        @include respond-to(mobile_mid) {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 40px;
        }
    }

    .cart-promo {
        @include respond-to(mobile_mid) {
            margin: 0;
        }
    }

    .select-more-msg {
        position: absolute;
        bottom: 77px;
        right: 50%;
        transform: translateX(50%);
        display: block;

        @include respond-to(mobile_mid) {
            right: 0;
            bottom: 27px;
            transform: translateX(-5%);
        }
    }
}

.choice-of-bonus-products {
    .title {
        position: relative;
        display: block;
        width: 100%;
        margin-top: 35px;
        text-align: center;
        font-size: rem(32);
        font-weight: 300;

        &:after {
            content: '';
            position: absolute;
            border-bottom: 1px solid $nobel;
            bottom: -2px;
            width: 110px;
            left: 50%;
            margin-left: -55px;
        }
    }

    .subtitle {
        margin: 18px 0 7px;
        text-align: center;
        font-size: rem(21);
        text-transform: uppercase;
    }

    .message {
        text-align: center;
    }

    &-grid {
        margin-top: 50px;
        font-size: 0;
        text-align: center;
        @include flexbox();
        @include flex-wrap();
        @include justify-content(center);

        &:after {
            @extend %clearfix;
        }

        .bonus-product-tile {
            width: 24%;
            display: inline-block;
            vertical-align: top;
            font-size: rem(14);

            @include respond-to(mobile) {
                width: 49%;
            }

            .product-tile {
                padding-bottom: 25px;

                img {
                    width: 100%;

                    @include respond-to(mobile_mid) {
                        width: 90%;
                    }
                }
            }
        }

        .whishlist_instore-actions {
            display: none;
        }

        .product-name {
            margin-top: 10px;
        }

        .product-compare {
            position: absolute;
            bottom: 80px;
            left: 0;
            right: 0;

            label {
                position: relative;
            }

            .field-wrapper {
                float: none;
            }
        }

        .product-variations {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;
            margin: 0;

            &.variation-product {
                .label {
                    display: inline-block;
                    text-transform: capitalize;
                }
            }

            .swatches li {
                display: inline-block;
                float: none;
            }

            .label,
            .swatches .selected-value,
            .swatchanchor span {
                display: none;
            }

            .swatchanchor {
                border: 0;
            }

            .swatchanchor {
                display: block;
                width: 26px;
                margin-right: 5px;

                img {
                    height: 24px;
                    width: 24px;
                    max-width: 100%;
                    margin-top: 0;

                    &:hover {
                        border: 2px solid $lightest-gray;
                    }
                }

                &.selected img {
                    border: 2px solid $lightest-gray;
                }
            }

            .color li a {
                width: 28px;
                height: 26px;
            }

            .selected img, img:hover {
                border: 2px solid $lightest-gray;
            }

            .color img {
                border: 0;
            }

            .attribute .value {
                width: 100%;
            }
        }
    }

    &-actions {
        text-align: right;
        margin: 20px;

        @include respond-to(mobile_tablet) {
            button + button {
                margin-top: 20px;
            }
        }
    }

    .bonus-maxcount-error {
        text-align: center;
    }
}

.is-cart-empty {
    @extend %clearfix;
    margin-top: 4rem;

    .cart-action-continue-shopping {
        float: none;
        text-align: center;
        margin-bottom: 4rem;
    }

    .product-listing {
        @extend %clearfix;
        background-color: $white-smoke;
        padding: 1rem 0;

        h2 {
            margin-left: 1.3rem;
        }

        .search-result-items {
            margin-top: 1em;
        }
    }

    .cart-empty-content {
        .h1 {
            clear: both;
            margin: 5rem 0 2rem 0;
            text-align: center;

            @include respond-to(tablet_plus) {
                margin: 2.5rem 0;
                text-align: left;
                text-transform: none;
            }
        }
    }
}

.promo-adjustment {
    color: $sangria;

    @include respond-to(mobile) {
        flex-basis: 100%;
        order: 2;
    }
    img {
        display: none;
    }
}

ul.product-availability-list {
    margin: 0;
    padding: 0;
    width: 100%; //for IE
    width: max-content;

    li {
        list-style: none outside none;
    }

    .on-order,
    .not-available {
        color: $sangria;
        font-size: 0.9em;
    }
}

#password-reset {
    white-space: nowrap;
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }

    .not-user {
        margin: .83em 0;
    }
}

.arnotts-global-section {
    .bonus-product-tile {
        .product-options,
        .product-variations {
            .toggle.expanded {
                & > ul.size,
                .swatches.size.size {
                    margin-left: 0;
                }
            }
        }
    }
}

.paypal-cart-buttons-container {
    margin-top: 20px;

    .paypal-cart-button {
        width: 100%;
    }
}

.gift-message {
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    max-width: 59%;
    font-size: rem(14);
    margin-bottom: 10px;

    @include respond-to(mobile_strong) {
        max-width: 100%;
        font-size: rem(12);
        order: 3;
    }

    @include respond-to(mobile_stronger) {
        font-size: rem(13);
    }
}

.cart-order-totals {
    .staff-coupon_title {
        display: none;
    }

    .cart-coupon_code {
        margin: 20px 0;
    
        .field-wrapper {
            margin-bottom: 25px;
        }
    
        .add-coupon {
            width: 100%;
        }
    
        .remove {
            @include link_underline($medium-blue);
    
            font-family: $font-main;
            font-size: rem(14);
            margin-bottom: 10px;
            display: inline-block;
        }

        .coupon-error {
            padding: 5px;
        }
    }
}