// Product set
.is-product-set {
    &.is-sku-only .price-sales__now-text {
        display: inline-block;
    }

    .sku-details {
        .product-name-brand {
            text-transform: uppercase;
            font-size: rem(20);
            margin: 0 0 5px;
            font-family: $font-main-bold;
        }

        .product-price .product-standard-price {
            @include respond-to(mobile) {
                font-size: rem(14);
            }
        }

        .product-name-title {
            font-family: $font-main;
            font-size: rem(16);
        }
    }

    .product-set {
        .pos-rel {
            position: relative;
        }

        .size-guide-link {
            margin-bottom: 20px;
            text-align: right;
            text-decoration: underline;
            text-transform: none;

            &__mobile {
                display: none;

                @include respond-to(mobile) {
                    display: block;
                }
            }

            &__desktop {
                @include respond-to(mobile) {
                    display: none;
                }
            }
        }

        .product-primary-image { // Changes order of elements on mobile
            @include respond-to(mobile) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
            }

            .product-thumbnails {
                @include respond-to(mobile) {
                    order: 2;
                }
            }

            .main-image {
                @include respond-to(mobile) {
                    order: 1;
                }
            }
        }

        .product-price {
            font-size: rem(22);
            line-height: 1;
            text-transform: capitalize;

            .price-sales,
            .salesprice {
                color: $red01;
                display: block;

                ~ .price-standard {
                    color: $night-rider;
                    font-size: rem(15);
                    display: inline-block;

                    &.rrp-price {
                        font-size: rem(10);
                    }
                }
            }
        }

        .product-set-list {
            .product-set-details {
                margin-bottom: 0;

                .product-name-brand {
                    font-size: rem(18);
                }

                .item-name {
                    font-size: rem(16);
                    font-family: $font-main;
                }

                .price-sales,
                .product-sales-price.now-sell-price,
                .product-pricing .product-sales-price.now-sell-price {
                    font-size: rem(20);
                }

                .product-pricing .product-sales-price,
                .product-price  > span:only-of-type {
                    font-size: rem(18);
                }

                .product-outofstock .oos-label {
                    font-size: rem(14);
                }
            }

            .product-set-item {
                padding-left: 145px;
                padding-right: 5px;

                @include respond-to(tablet) {
                    padding-left: 90px;
                }
            }

            .product-set-image {
                @include respond-to(tablet) {
                    margin-left: -90px;
                    width: 90px;
                }
            }

            .product-set-sku-item {
                &:last-child .product-set-sku-details {
                    border-bottom: 1px solid $lighter-gray;
                }

                .product-set-sku-details {
                    border-top: 1px solid $lighter-gray;
                    padding: 2px 0;
                }

                .product-set-error {
                    text-align: right;
                    width: 100%;
                    padding: 0;
                    display: block;

                    span,
                    span.error {
                        display: inline;
                        margin: 0px;
                        padding: 0px;
                        line-height: 22px;
                        font-size: rem(14);

                        @include respond-to(tablet) {
                           font-size: rem(15);
                        }
                    }
                }

                .product-set-inner-cont {
                    @include flexbox();

                    align-items: center;
                    justify-content: space-between;
                    background: $white;
                    min-height: 60px;

                    &::after {
                        content: '';
                        min-height: inherit;
                        font-size: 0;
                    }

                    @include respond-to(mobile_mid) {
                        flex-wrap: wrap;
                        position: relative;
                    }

                    &.item-selected {
                        background: $white-smoke;
                    }

                    .item-name {
                        flex-basis: 45%;
                        font-size: rem(14);

                        @include respond-to(tablet) {
                            flex-basis: 40%
                        }

                        @include respond-to(mobile) {
                            flex-basis: 50%;
                            text-align: left;
                        }

                        @include respond-to(mobile_mid) {
                            flex-basis: calc(100% - 160px);
                        }

                        @include respond-to(mobile_min) {
                            flex-basis: 65%;
                        }
                    }

                    .product-price,
                    input.error {
                        margin-bottom: 0;
                        min-width: 0;
                    }

                    .product-price {
                        flex-basis: 25%;
                        text-align: center;

                        @include respond-to(tablet) {
                            flex-basis: 30%
                        }

                        @include respond-to(mobile) {
                            flex-basis: 20%
                        }

                        @include respond-to(mobile_mid) {
                            flex-basis: calc(100% - 160px);
                            text-align: left;
                        }

                        @include respond-to(mobile_min) {
                            flex-basis: 65%;
                        }

                        .price-standard {
                            &::after {
                                display: none;
                            }
                        }

                        .price-sales,
                        .salesprice,
                        .now-sell-price,
                        .price-standard {
                            font-size: rem(14);

                            &.rrp-price {
                                font-size: rem(10);
                            }
                        }

                        .price-standard.was-price,
                        .product-standard-price {
                            font-size: rem(10);
                        }
                    }

                    .item-out-of-stock {
                        flex-basis: 160px;
                        font-family: $font-main-bold;
                        font-size: rem(14);
                        text-align: center;
                    }

                    .item-out-of-stock,
                    .quantity_selector {
                        @include respond-to(mobile_mid) {
                            flex-basis: 31%;
                            width: 31%;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translate(0,-50%);
                        }
                    }

                    .quantity_selector {
                        width: 160px;
                    }
                }
            }
        }

        .product-variations {
            @extend %clearfix;
        }

        .view-larger-image {
            font-size: rem(14);
        }
    }

    .dialog-quickview & {
        .product-col-1 {
            width: 60%;
        }

        .product-col-2 {
            width: 40%;
        }
    }

    .add-all-skus-to-cart-error {
        color: $red01;
        margin: -20px 0 40px;
    }
    
    .product-name-brand {
        font-size: rem(20);
        font-family: $font-main-bold;
        text-transform: uppercase;

        @include respond-to(mobile) {
            font-size: rem(18);
        }
    }

    .product-price {
        .product-standard-price {
            @include respond-to(mobile) {
                font-size: rem(14);
            }
        }
    }
    
    .product-set {
        .product-price {
            @include shared-product-price;
            .product-standard-price {
                color: $color-auxiliary15;
                text-decoration: line-through;

                &.rrp-price {
                    text-decoration: none;
                }
            }

            .price-sales,
            .salesprice {
                ~ .price-standard {
                    color: $color-auxiliary15;
                    text-decoration: line-through;

                    &.rrp-price {
                        text-decoration: none;
                    }
                }
            }
        }

        .product-set-list {
            .product-set-sku-item {
                .product-set-inner-cont {
                    .item-out-of-stock {
                        .input-text {
                            height: 30px;
                            width: 40px;
                            border-color: $color-auxiliary39;

                            &.quantity-not-available {
                                color: $red01;
                            }
                        }
                    }
                }
            }
        }
    }

    .product-col-2.product-set {
        .qty-title {
            width: 160px;

            @include respond-to(mobile) {
                display: block;
            }
        }
    }
}

.product-set-item {
    .error-quantity {
        text-align: left;
        margin-top: -15px;
    }
}
