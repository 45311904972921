// Sale now slot
.hp-sale-now {
    padding: 0;

    @include respond-to(mobile) {
        padding: 20px 0;
    }
}

.sale-now-wrapper.hidden-mobile {
    @include border-tape($border-color: currentColor);

    .sale-now-inner-wrapper {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);

        padding: 4px 22px;
    }
}

.sale-now-wrapper.hidden-tablet.hidden-desktop {
    padding: 20px;
    text-align: center;

    .sale-now-title {
        @include border-tape($border-color: currentColor);

        margin-bottom: 12px;
    }
    .sale-now-title-inner {
        line-height: 32px;
        height: 30px;
    }
    .sale-now-description {
        margin-bottom: 15px;
    }
}

.sale-now-title {
    color: currentColor;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    
    @include respond-to(mobile) {
        font-size: rem(16);
    }

    @include respond-to(tablet_plus) {
        font-size: rem(18);
    }
}

.sale-now-description {
    font-size: rem(14);
    line-height: 1.1;
    color: $black01;
    margin: 0;
}

.sale-now-link {
    font-family: $font-main-bold;
}
