// Buttons
body button, // increase the priority of styles over js 3-d party
.button,
.buttonstyle,
input[type="button"] {
    @include ripple-effect;

    border-radius: 50px;

    &.coloured {
        background: $black01;
        
        @include respond-to(touch_over) { // To avoid color changes on devices on click
            &:hover {
                background: $color-primary2;
            }
        }

        &[disabled],
        &[disabled]:hover {
            background-color: $color-secondary5;
            color: $color-primary3;
            cursor: not-allowed;

            @include respond-to(touch_over) {
                background-color: $color-secondary5;

                &:hover {
                    background-color: $color-secondary5;
                }
            }

            &::after {
                display: none;
            }
        }
    }

    &.btn-blue,
    &.apply {
        @extend .button.coloured;
    }

    &[disabled],
    &[disabled]:hover,
    &.add-to-cart-disabled,
    &.add-to-cart-disabled:hover,
    &.inactive {
        background-color: $color-secondary5;
        color: $color-primary3;
        cursor: not-allowed;

        @include respond-to(touch_over) {
            background-color: $color-secondary5;

            &:hover {
                background-color: $color-secondary5;
            }
        }

        &::after {
            display: none;
        }
    }
}

button.outlined,
.button.outlined,
.button.cancel,
.button-cancel,
.cancel-shipping-address,
.reset-password-content .button {
    @include button-type(1);

    padding: 14px 22px;
    min-width: 0;
}

.button-type1,
.button-type2,
.button-type3,
.button-type4,
.button-type5 {
    @include button-type;
    @include ripple-effect;
}

.button-type1 {
    @include button-type(1);
}

.button-type2 {
    @include button-type(2);
}

.button-type3 {
    @include button-type(3);
}

.button-type4 {
    @include button-type(4);
}

.button-type5 {
    @include button-type(5);
}

.button-type1m {
    @include respond-to(mobile) {
        @include button-type(1);
    }
}

.button-type2m {
    @include respond-to(mobile) {
        @include button-type(2);
    }
}

.button-type3m {
    @include respond-to(mobile) {
        @include button-type(3);
    }
}

.button-type4m {
    @include respond-to(mobile) {
        @include button-type(4);
    }
}

.button-type5m {
    @include respond-to(mobile) {
        @include button-type(5);
    }
}

.buttons-mobile-full-width {
    .button-type1,
    .button-type2,
    .button-type3,
    .button-type4,
    .button-type5 {
        @include respond-to(mobile) {
            display: block;
            width: 100%;
        }
    }
}

//wrapper of mobile-half-width button should have font-size: 0
.buttons-mobile-half-width {
    @include respond-to(mobile) {
        font-size: 0;
    }
    
    .button-type1,
    .button-type2,
    .button-type3,
    .button-type4,
    .button-type5 {
        @include respond-to(mobile) {
            font-size: rem(12.5);
            display: inline-block;
            min-width: 0;
            width: calc(50% - #{$buttons-space-between} / 2);
        }

        &:nth-child(odd) {
            @include respond-to(mobile) {
                margin-right: $buttons-space-between;
            }
        }
    }
}

// CTAs
.cta-type1,
.cta-type2,
.cta-type3,
.cta-type4,
.cta-type5,
.cta-type6 {
    display: inline-block;
    font-size: rem(14);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    cursor: pointer;
    background-color: transparent; // reset for buttons
    border: none; // reset for buttons
    transition: color 300ms ease-out;

    &:after {
        content: $aricons-small-arrow-right;
        font-family: $font-icons;
        font-size: rem(8);
        padding-left: 15px;
    }

    &:hover {
        color: $black01;
        background-color: transparent;
    }

    &:disabled,
    &.disabled {
        color: rgba($black01, .3);
        background-color: transparent;
        cursor: default;

        &:after {
            color: rgba($black01, .3);
        }
    }
}

.cta-type7 { // type-7 - simple lowercase link 
    display: inline-block;
    font-size: rem(18);
    line-height: 1;
    text-transform: none;
    letter-spacing: .02em;
    cursor: pointer;
    background-color: transparent; // reset for buttons
    border: 0; // reset for buttons
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    transition: color 300ms ease-out;
    color: $black01;

    &:hover {
        color: $color-primary2;
        background-color: transparent;
    }

    &:disabled,
    &.disabled {
        color: rgba($black01, .3);
        background-color: transparent;
        cursor: default;
    }
}

.cta-type1 {
    color: $color-primary2;

    &:after {
        color: $black01;
        transition: color 300ms ease-out;
    }

    &:hover {
        color: $color-primary2;

        &:after {
            color: inherit;
        }
    }
}

.cta-type2 {
    color: $red01;
}

.cta-type3 {
    color: $color-auxiliary8;
}

.cta-type4 {
    color: $color-auxiliary9;
}

.cta-type5,
.cta-type6 {
    position: relative;
    color: $black01;
    padding-bottom: 2px;
    text-decoration: none;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid transparent;
        transition: .3s ease-out;
    }

    &:hover {
        color: $color-primary2;

        &:after {
            border-bottom-color: $color-primary2;
        }
    }
}

.cta-type6 {
    &:after {
        border-bottom-color: currentColor;
        transition: none;
    }

    &:hover {
        &:after {
            border-bottom-color: currentColor;
        }
    }
}

.cta-type6.cta-dark { // primary(.6) -> black
    color: $link-color-dark;

    &:hover {
        color: $color-auxiliary2;
    }
}

.cta-type6.cta-light { // white(.6) -> white
    color: $link-color-light;

    &:hover {
        color: $color-primary3;
    }
}

.cta-type6.cta-white { // white -> white(.6)
    color: $color-primary3;

    &:hover {
        color: $link-color-light;
    }
}

.cta-type6.cta-inverse { // white(.6) -> black
    color: $link-color-light;

    &:hover {
        color: $color-auxiliary2;
    }
}

button.large,
.button-fancy-large {
    font-size: rem(16);
    padding-bottom: 14px;
    padding-top: 14px;

    &.coloured[disabled],
    &.coloured[disabled]:hover {
        background-color: $color-secondary5;
    }
}

button,
.button {
    &.add-to-cart-disabled,
    &.add-to-cart,
    &.add-all-to-cart {
        @include icon(bag);

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(14);
        text-transform: capitalize;

        &::before {
            font-size: rem(20);
            margin-right: 12px;
        }
    }

    &.add-to-cart,
    &.add-all-to-cart {
        background-color: $color-primary2;

        @include respond-to(touch_over) { // To avoid color changes on devices on click
            &:hover {
                background-color: $black01;
            }
        }

        &.add-to-cart-disabled, // for cases when add-to-cart & add-to-cart-disabled use together
        &.add-to-cart-disabled:hover,
        &[disabled] {
            background-color: $color-secondary5;
            color: $color-primary3;
            cursor: not-allowed;

            &::after {
                display: none;
            }
        }
    }

    &.add-to-cart-disabled,
    &[disabled] {
        @include respond-to(touch_over) {
            &:hover {
                background-color: $color-secondary5;
            }
        }
    }

    &.js-add-to-cart-active {
        @include respond-to(mobile) { // this is to enable size selector pop-up on mobile
            &::before {
                content: "js-size-selector-popup";
                display: none;
            }
        }
    }
}

button.applepay-button { // Common styles for Apple Pay button
    display: block;
    -webkit-appearance: -apple-pay-button !important; // Override external styles 
    height: 50px;
    border-radius: 50px !important;

    @include respond-to(mobile) {
        height: 44px;
    }

    &:hover {
        padding: 20px !important;
    }
    
    @supports not (-webkit-appearance: -apple-pay-button) {
        display: none;
    }

    .cart-actions & { // Styles for Apple Pay on Cart 
        -apple-pay-button-type: check-out;
        margin: 20px 0 0 0 !important;
    }

    .pdp-main & { // Styles for Apple Pay on PDP 
        -apple-pay-button-type: buy;
        width: 100%;
    }

    .dialog-quickview & {
        display: none;
    }
}
