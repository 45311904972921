.icon-instore {
    &::before {
        font-size: rem(12);
    }
}

.instore {
    &-title {
        margin: 30px 0 5px 0;
    }

    &-adderess {
        margin: 0 0 10px;
    }

    &-badge {
        color: $white;
        font-size: rem(14);
        text-transform: uppercase;
        font-weight: 700;
        padding: 5px 12px;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
        display: inline-block;
        border-radius: 4px;
    }

    .badge-red {
        background-color: $red01;
    }
    
    .badge-yellow {
        background-color: $yellow;
    }
    
    .badge-green {
        background-color: $green;
    }

    .badge-light-grey {
        background-color: $very-light-grey;
    }

    &-details {
        padding: 10px;
        margin-top: 10px;
        background-color: $light-gray02;
        display: flex;
        justify-content: space-between;

        @include respond-to(m_mobile_down) {
            flex-direction: column;
        }
    }

    .left {
        float: left;
        margin: 0 10px 2px 0;
    }

    .right {
        clear: right;
        margin: 0 0 2px 0;
    }

    .dotted {
        display: none;
    }

    &-hours,
    &-contacts {
        word-break: break-all;
        font-size: rem(14);

        @include respond-to(m_tablet) {
            width: 49%;
        }
    }

    &-hours {
        @include respond-to(m_mobile_down) {
            margin-bottom: 30px;
        }
    }

    .hours-title,
    .contacts-title {
        margin-bottom: 5px;
    }
}