.is-product-bundle {
    .product-name-brand {
        font-size: rem(22);
        text-transform: uppercase;
        max-width: 78%;
        margin: 0;

        @include respond-to(mobile) {
            max-width: 100%;
        }
    }

    .product-name-title {
        font-size: rem(18);
        letter-spacing: 0;
        margin-bottom: 10px;
        max-width: 78%;

        @include respond-to(mobile) {
            max-width: 100%;
        }
    }

    .socialsharing {
        .toggle__text {
            display: none;
        }
    }

    .product-set .product-price {
        max-width: 78%;
        font-size: rem(16);

        @include respond-to(mobile) {
            max-width: 100%;
        }
    }

    .product-col-2 {
        width: 45%;
        padding: 0 5% 0 0;

        @include respond-to(mobile) {
            width: 100%;
            padding: 0 18px;
        }
    }
}

.product-bundle-list {
    h2 {
        font-size: rem(36);
        letter-spacing: -0.02em;
        line-height: 40px;
        text-transform: none;

        @include respond-to(mobile) {
            font-size: rem(26);
        }

        span:before {
            display: none;
        }
    }

    .product-bundle-details {
        .product-name-brand {
            font-size: rem(18);
            max-width: 100%;
        }

        .item-name {
            font-size: rem(16);
        }
    }

    .product-bundle-show-more {
        border-color: $sections-body-border;
        color: $color-primary2;
        font-size: rem(15);
        margin-top: 0;
        margin-bottom: 70px;

        @include respond-to(mobile) {
            margin-bottom: 50px;
        }

        &:hover {
            background-color: $color-primary2;
            border-color: $color-primary2;
        }
    }
}
