.article-wrapper {
    @include full-page-content($scrollbar-fix: true);
}

.article-text-small-width {
    max-width: 500px;
    width: calc(100% - 30px);
    margin: 0 auto;
}

.article-text-middle-width {
    max-width: 816px;
    margin: 0 auto;
}

.article-header {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    padding-top: 40px;
    

    &:after {
        content: '';
        position: absolute;
        background: linear-gradient($color-auxiliary38, $color-primary3);
        left: 0;
        right: 0;
        top: -100px;
        bottom: 0;
        z-index: -1;
    }
}

.article-title {
    margin-bottom: 40px;
}

.article-header-image {
    display: block;
    width: 100%;
}

.article-youtube-video {
    margin-top: 40px;
    margin-bottom: 40px;

    iframe {
        max-width: 100%;
    }
}

.article-carousel-image {
    width: 100%;
}

.article-carousel {
    margin-top: 40px;
    margin-bottom: 40px;
}

.article-carousel .slick-dots {
    text-align: center;
}

.author-picture {
    max-width: 85px;
    float: left;
}

.author-picture {
    img {
        max-width: 100%;
    }
}

.quote-wrapper {
    width: calc(100% - 30px);
    margin-top: 40px;
    margin-bottom: 40px;
}

.quote {
    padding-left: 120px;
}

.quote-text {
    font-size: rem(28);

    @include respond-to(mobile) {
        font-size: rem(18);
    }
}

.author-details {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: rem(20);

    @include respond-to(mobile) {
        font-size: rem(16);
    }

    .name,
    .position {
        &::after {
            content: ' - ';
        }
    }
}