fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

input {
    outline: none;
}

.js .simple-submit {
    display: none;
}

legend {
    font-size: rem($legend-font-size);
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;

    span {
        font-size: rem($legend-span-font-size);
        position: relative;
        text-transform: none;
        padding: 0 .5em;

        em {
            color: $body-base-color;
        }
    }
}

label {
    display: block;
    font-size: rem($label-font-size);
    cursor: pointer;

    .form-row & {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .form-horizontal & {
        float: left;
        width: 100%;
        line-height: 1.5;

        @include respond-to(tablet_plus) {
            text-align: right;
            width: 25%;
        }
    }

    .label-inline & {
        display: inline;
        float: none;
        font-size: rem($label-font-size);
        margin-top: 0;
        text-align: left;
        width: auto;
        line-height: normal;
    }

    .label-above & {
        margin-bottom: .3rem;
        width: auto;
    }

    .label-empty & {
        visibility: hidden;

        @include respond-to(mobile) {
            visibility: visible;
        }
    }
}

.form-label-text {
    float: left;
    font-size: rem(11);
    padding: 0.75em 0 0;
    text-align: right;
    width: 25%;
}

.form-row {
    @extend %clearfix;
    margin: .5rem 0 1rem;
        
    &.required label:after {
        content: '*';
        color: $required-legend-color;
    }

    &.js-dublincode .form-field-tooltip {
        margin-left: 27%;
        padding-top: 0;
        width: auto;
        color: $light-pink;
        font-size: rem(15);
        
        @include respond-to(mobile) {
            margin: 0;
        }
    }
}

.registration-form .form-row.js-dublincode .form-field-tooltip {
    margin-left: 18%;
}

.form-row-button {
    clear: left;

    .form-horizontal & {
        @include respond-to(tablet_plus) {
            margin-left: 27%;
            width: 62%;
        }
    }

    .checkout-shipping.form-horizontal &,
    .checkout-billing.form-horizontal & {
        width: 73%;
        margin-top: 3rem;
        text-align: right;

        @include respond-to(mobile) {
            width: auto;
        }

        button {
            background-color: $btn-secondary-bg;

            &:hover {
                background: $btn-secondary-hover-bg;
            }

            &.disabled,
            &[disabled] {
                background-color: $btn-disabled-bg;
                color: $btn-disabled-color;
                cursor: default;
                opacity: 0.7;

                &:hover {
                    background-color: $btn-disabled-bg;
                }
            }
        }
    }
}

.form-row-alert {
    clear: left;

    .form-horizontal & {
        @include respond-to(tablet_plus) {
            margin-left: 27%;
            width: 62%;
        }
    }

    .checkout-shipping.form-horizontal &,
    .checkout-billing.form-horizontal & {
        width: 73%;
        
        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.field-wrapper {
    box-sizing: border-box;
    margin-bottom: .5rem;
    position: relative;
    width: 100%;

    .form-horizontal & {
        float: left;
        width: 75%;

        @include respond-to(mobile) {
            float: none;
            width: auto;
        }

        @include respond-to(tablet_plus) {
            padding-left: 2%;
        }
    }

    .label-inline & {
        float: left;
        padding-left: 0;
        width: auto;
    }

    .label-above & {
        float: none;
        padding-left: 0;
        width: auto;
    }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
    background-color: $white;
    border-radius: 0;
    border: $field-border-width solid $field-border-color;
    box-sizing: border-box;
    color: $field-text-color;
    display: block;
    font-size: rem(16);
    margin: 0;
    outline: none;
    padding: 11px;
    transition: all 0.15s linear;
    width: 100%;

    @include placeholder {
        color: $field-placeholder-text-color;
    }

    &:hover {
        background-color: $field-hover-bg;
        border-color: $field-hover-border-color;
        color: $field-hover-text-color;
    }

    &:focus {
        background-color: $field-focus-bg;
        border-color: $field-focus-border-color;
        color: $field-focus-text-color;
    }

    &:disabled {
        background-color: $field-disabled-bg;
        border-color: $field-disabled-border-color;
        color: $field-disabled-text-color;
        cursor: default;
    }
}

input[type=number] {
    -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.password {
    padding-right: 55px;
}

select {
    &:disabled {
        background-color: $select-disabled-bg;
        border-color: $select-disabled-border-color;
        cursor: default;
    }

    &::-ms-expand {
        display: none;
    }

    &.error {
        border: 1px solid $red01;
    }
}

input[type="checkbox"],
input[type="radio"] {
    margin: 3px 7px 0 0;
}

.form-caption {
    clear: left;
    width: auto;

    .form-horizontal & {
        @include respond-to(tablet_plus) {
            margin-left: 27%;
        }
    }

    .label-above & {
        margin-left: 0;
    }

    .form-indent & {
        margin-left: 0;
    }
}

.form-indent {
    @include respond-to(tablet_plus) {
        margin-left: 27%;
    }
}

.error-message {
    border: 2px solid $error-message-border-color2;
    background-color: $error-message-bg2;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;
}

input.error,
input.error:focus {
    background-color: $field-error-bg;
    border: 1px solid $field-error-border-color;
    color: $field-error-text-color;

    &[type="checkbox"] {
        border-bottom: 1px solid $field-error-border-color;
    }
}

span.error,
div.error,
.send-to-friend-wishlist label.error {
    background-color: $error-message-bg;
    border: 1px solid $error-message-border-color;
    border-top: none;
    color: $error-message-text-color;
    display: block;
    font-size: rem(12);
    padding: 5px 20px;
}

.error-form {
    background-color: $error-message-bg;
    border: 1px solid $error-message-border-color;
    color: $error-message-text-color;
    font-size: rem(14);
    margin: 5px 0;
    padding: 10px 8px;
    text-transform: uppercase;
}

.form-inline {
    form {
        padding: 0 0 2em;
        width: 98%;
    }

    label {
        padding: 0 0 0.75em;
        text-transform: uppercase;
        width: auto;
    }

    input[type="text"], select {
        margin-left: 0;
        width: 95%;
    }

    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.row-inline {
    display: inline;

    label {
        width: auto;
    }
}

// Required Indicator and Text
.dialog-required {
    display: inline;
    position: relative;
    padding: 0 .5em;

    em {
        color: $required-dialog-legend-color;
        font-size: rem(6.5);
        font-style: normal;
        text-transform: uppercase;
    }
}

.required-indicator {
    display: none;
    color: $required-dialog-legend-color;
    font-size: rem(10);
    padding: 0;

    &:before {
        content: '\2022';
    }
}

.form-field-tooltip {
    float: left;
    margin-left: 3%;
    padding-top: .75em;
    width: 20%;
}

.clearable {
    background: $white url(../images/icon_remove_2.gif) no-repeat right -10px center;
    border: 1px solid $dim-gray;
    padding: 3px 18px 3px 4px;
    border-radius: 3px;
    transition: background 0.4s;

    &.x  {
        background-position: right 5px center;
    }

    &.onX{
        cursor: pointer;
    }

    &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
}