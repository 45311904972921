.beauty_ingredients {
    margin-left: auto;
    margin-right: auto;
    max-width: 1260px;
    padding-left: 15px;
    padding-right: 15px;

    &-header {
        text-align: center;
        padding: 80px 0 40px;
    }

    &-title {
        margin-bottom: 20px;
        font-style: italic;
    }

    &-filtering {
        font-size: 0;
        font-family: $font-main-black;
        line-height: 1.5;
    }

    &-text {
        font-size: rem(16);
    }

    &-letter {
        margin-right: 20px;
        font-size: rem(26);
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $black01;
        }

        &.disabled {
            color: $dim-gray;
            cursor: default;

            &:hover {
                color: $dim-gray;
            }
        }
    }

    &-active_letter {
        text-align: center;
        font-size: 26px;
        font-family: $font-main-black;
        margin-bottom: 50px;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 43%;
            height: 1px;
            background-color: $black01;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }
    }

    &-name {
        text-align: center;
        text-transform: uppercase;
        font-family: $font-main-black;
        letter-spacing: 1px;
        margin-bottom: 20px;
        width: 100%;
    }

    &-anchor {
        cursor: default;

        &:hover {
            color: $black01;
        }
    }

    &-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 50px;

        @include respond-to(m_tablet_down) {
            flex-direction: column;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-image_wrapper {
        position: relative;

        @include respond-to(m_desktop) {
            width: 30%;
        }

        @include respond-to(m_tablet_down) {
            margin: 0 auto 30px;
        }
    }

    &-image {
        width: 100%;
        display: block;
    }

    &-button {
        @extend .button;

        position: static;
        margin: 20px auto 0;
        display: block;
        width: 90%;

        @include respond-to(mobile_stronger) {
            max-width: 575px;
        }
    }

    &-description {
        @include respond-to(m_desktop) {
            width: 65%;
        }
    }

    &-subtitle,
    h4 {
        margin-bottom: 20px;
        text-transform: none;
    }

    &-read_more,
    &-link {
        @include link_underline($medium-blue);
    }

    &-link {
        @include respond-to(desktop_below) {
            text-decoration: underline;
            text-underline-offset: 3px;
            text-decoration-color: $medium-blue;

            &::after {
                display: none;
            }
        }
    }

    &-read_more {
        display: inline-block;

        .expanded & {
            display: none;
        }
    }

    &-additional {
        padding: 0;
        height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;

        .expanded & {
            padding: 20px 0;
            height: auto;
            transition: all 0.5s ease-in-out;
        }
    }

    &-dialog {
        max-width: 70%;

        @include respond-to(m_tablet_down) {
            min-width: 80%;
            max-width: 90%;
        }

        .dialog-content {
            display: flex;
            flex-wrap: wrap;

            @include respond-to(m_desktop) {
                justify-content: space-between;
            }

            @include respond-to(m_tablet_down) {
                flex-direction: column;
            }
        }

        .beauty_ingredients-image_wrapper {
            @include respond-to(m_desktop) {
                width: 45%;
            }
        }

        .beauty_ingredients-description {
            @include respond-to(m_desktop) {
                width: 50%;
            }
        }

        .beauty_ingredients-name {
            margin-bottom: 20px;
        }

        .beauty_ingredients-button {
            display: none;
        }

        .ui-dialog-titlebar .ui-dialog-title {
            display: none;
        }
    }
}