.editorial-component {
    margin-bottom: 40px;
}

.editorial-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    padding-left: 20px;
    padding-right: 20px;

    @include respond-to(mobile_strong) {
        @include full-page-content;
    }

    .component-image {
        display: block;
        max-width: 100%;
    }
}

.editorial-top-block,
.editorial-bottom-block {
    @include respond-to(mobile_strong_over) {
        @include flexbox;
    }
}

.editorial-bottom-block {
    @include respond-to(mobile_strong) {
        margin-top: 10px;
    }

    @include respond-to(mobile_strong_over) {
        margin-top: 20px;
    }
}

.editorial-text-wrapper,
.editorial-description-wrapper {
    @include respond-to(mobile_strong_over) {
        @include flex-basis(40%);
        width: 40%;
    }
}

.editorial-description-wrapper {
    @include respond-to(mobile_strong) {
        margin-top: 30px;
        text-align: center;
    }

    @include respond-to(mobile_strong_over) {
        @include order(1);
        padding-top: 10%;
        padding-right: 80px;
    }
}

.editorial-image-secondary {
    @include respond-to(mobile_strong) {
        display: none;
    }
}

.editorial-video-wrapper,
.editorial-image-wrapper {
    @include respond-to(mobile_strong_over) {
        @include flex-basis(60%);
        width: calc(60% - 20px);
        margin-left: 20px;
    }
}

.editorial-text-wrapper {
    @include respond-to(mobile_strong) {
        text-align: center;
    }

    @include respond-to(mobile_strong_over) {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-direction(column);
    }

    .editorial-cat-name {
        font-family: $font-main-bold;
        text-transform: uppercase;

        @include respond-to(mobile) {
            font-size: rem(12);
        }
    
        @include respond-to(tablet_plus) {
            font-size: rem(14);
        }
    }

    .editorial-title {
        font-family: $font-main;

        @include respond-to(mobile) {
            font-size: rem(22);
        }
    
        @include respond-to(tablet_plus) {
            font-size: rem(32);
            text-transform: uppercase;
        }
    }
}

.editorial-video-wrapper {
    .vjs-tech {
        &.video-js {
            @include respond-to(mobile_strong_over) {
                padding-top: 81.25%;
            }

            @include respond-to(mobile_strong) {
                padding-top: 56.25%;
            }
        }

        video {
            background-color: $black01;
        }
    }

    .vjs-poster {
        background-size: cover;
    }
}

.editorial-image-wrapper {
    @include respond-to(mobile_strong) {
        text-align: center;
    }

    @include respond-to(mobile_strong_over) {
        @include flexbox;
        @include order(2);
    }
}

.editorial-image-auxiliary,
.editorial-image-auxiliary2 {
    @include respond-to(mobile_strong) {
        display: inline-block;
        vertical-align: top;
    }
}

.editorial-image-auxiliary {
    @include respond-to(mobile_strong_over) {
        width: 53.125%;
        margin-right: 20px;
    }

    @include respond-to(mobile_strong) {
        margin-right: 10px;
        max-width: 55%;
    }
}

.editorial-image-auxiliary2 {
    @include respond-to(mobile_strong_over) {
        width: 31.25%;
    }

    @include respond-to(mobile_strong) {
        max-width: 25%;
    }
}

.editorial-description-mobile {
    @include respond-to(mobile_strong_over) {
        display: none;
    }
}

.editorial-description-desktop {
    margin-bottom: 50px;

    @include respond-to(mobile_strong) {
        display: none;
    }
}

.editorial-description {
    font-size: rem(14);
    line-height: 20px;
}