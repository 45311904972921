.mini-cart {
    position: relative;
    order: 2;

    @include respond-to(mobile_strong_over) {
        z-index: 20;
    }

    .mini-cart-name {
        a {
            color: $nobel;
        }
    }

    .sticky-wrapper.is-sticky & { // section with high specifisity to reset _core_ext styles
        .mini-cart-total .mini-cart-link .minicart-quantity {
            color: $black01;
        }
    }
}

.mini-cart-link {
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    -webkit-tap-highlight-color:rgba(0,0,0,0);

    @include respond-to(mobile_strong_over) {
        width: 70px;
        line-height: $header-inner-wrapper-height;
        position: relative;
    }

    @include respond-to(mobile_stronger) {
        width: $header-inner-wrapper-height-tablet;
        line-height: $header-inner-wrapper-height-tablet;
    }

    &.mini-cart-empty .minicart-quantity {
        display: none;
    }

    .bticons-bag {
        @extend .icon-bag;

        font-size: rem(20);

        @include respond-to(mobile_strong) {
            margin-left: 2px;
        }

        @include respond-to(mobile_strong_over) {
            display: inline;
            padding-left: rem(5);
            vertical-align: text-top;
        }

        &::before {
            color: $black01;
        }
    }

    .minicart-quantity {
        line-height: rem(16);
    }

    &:hover {
        @include respond-to(mobile_strong_over) {

            .mini-cart-total & .minicart-quantity {
                color: $black01;
            }
        }
    }

    .is-sticky & {
        @include respond-to(mobile_strong_over) {
            line-height: $header-inner-wrapper-sticky-height;
        }
    }
}

.mini-cart-content {
    .product-custom-delivery-cta {
        display: none;
    }

    .mini-cart-price__prefix {
        display: none;    
    }

    .rrp-price {
        .mini-cart-price__prefix {
            display: inline-block;
        }    
    }

    .has-rrp-price.mini-cart-pricing {
        .mini-cart-price {
            color: #000;
        }
    }
}

.no-mobile .mini-cart-content {
    top: 100%;
    border-color: $color-secondary2;

    &:before {
        right: 29px;
        border-width: 5px;
        top: -10px;

       @include respond-to(mobile_stronger) {
            right: 22px;
       }
    }

    &:after {
        right: 28px;
        top: -12px;
        border-width: 6px;
        border-bottom-color: $color-secondary2;

       @include respond-to(mobile_stronger) {
            right: 21px;
       }
    }

    &.mini-cart-empty p {
        margin: 17px 30px;
        font-size: rem(16);
    }
}

.mini-cart-header {
    font-size: rem(16);
    text-transform: none;
    padding: 20px;
    margin: 0 20px;
    border-bottom-color: $color-secondary3;
}

.mini-cart-products {
    border-bottom: 1px solid $color-secondary3;
    margin: 0 20px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-clip: content-box;
        background: $color-secondary3;
        border: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-primary2;
        border: 0;

        &:hover {
            background: $minicart-scrollbar-color;
        }
    }

    &::-webkit-scrollbar-button {
        padding: 5px 0;
    }
}

.mini-cart-product {
    @include shared-mini-cart-product;
}

.mini-cart-inner {
    > .mini-cart-totals {
        @include shared-mini-cart-totals;
    }
}

.mini-cart-ctas {
    margin: 35px 0 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .mini-cart-cta {
        width: 50%;
    }

    .button {
        width: 100%;
        padding: 15px 0;
    }

    .mini-cart-link-cart {
        font-family: $font-main-bold;
        font-size: rem(14);
        text-transform: none;
        letter-spacing: .05em;
        background-color: transparent; // reset for buttons
        border: 0; // reset for buttons
        text-decoration: underline;
        color: $link-color-dark;
        width: auto;
        text-align: left;

        &:hover {
            color: $color-primary2;
            background-color: transparent;
        }
    }

    .mini-cart-link-checkout {
        @extend .button.coloured;

        font-size: rem(14);
        font-family: $font-main-bold;
        border: 0;

        @include icomoon-icon($aricons-lock, $font-size: rem(13)) {
            padding-right: 10px;
        }

        &:hover {
            border: 0;
        }

        &.disabled {
            background-color: $color-auxiliary28;
            cursor: not-allowed;

            &::after {
                display: none; // Сancel ripple-effect for disabled button
            }
        }
    }
}

.mini-cart-slot {
    margin: 0 20px 20px;
}

.error {
    &.mini-cart-error {
        background: $color-auxiliary25;
        margin: 15px 20px 20px;
        border: 0;
    }
}

.no-desktop {
    .mini-cart-header {
        margin: 0;
        font-size: rem(14);
        text-transform: uppercase;
        padding: 15px 20px;
    }

    .mini-cart-products {
        margin: 0 20px 0 0;
        border-bottom: 0;

        @include respond-to(mobile_mid) {
            max-height: 310px; // ~ 3 products on mobile_mid
        }

        @include respond-to(mobile_strong) {
            max-height: 300px;
        }

        @include respond-to(mobile_stronger) { // Portrait tablet
            max-height: 670px;
        }

        @include custom-respond-to(993px, 1024px) { // Landscape tablet
            max-height: 430px;
        }
    }

    .mini-cart-inner {
        > .mini-cart-totals {
            padding: 0 15px;
            margin: 0;
            border-top: 1px solid $color-secondary3;

            > div {
                margin: 20px 0 0;

                &:last-child {
                    margin-top: 30px;
                }
            }
        }
    }

    .mini-cart-subtitle,
    .mini-cart-name {
        width: 100%;
    }

    .mini-cart-product {
        margin: 0 10px;

        .mini-cart-pricing {
            padding: 0;
            margin-bottom: 5px;
            text-align: left;
        }
    }

    .mini-cart-data {
        width: 65%;

        .mini-cart-col-1,
        .mini-cart-col-2 {
            width: 100%;
            padding: 0;
        }
    }

    .mini-cart-ctas {
        .mini-cart-cta {
            width: 100%;

            &:first-child {
                text-align: center;
                order: 2;
            }
        }

        .mini-cart-link-cart {
            margin-top: 15px;
            text-transform: uppercase;
            text-decoration: none;
        }
    }

    .mini-cart-image {
        width: 35%;
    }

    .close-minicart {
        height: $header-height-mobile;
        transform: none;
        right: 0;
        transition: left 500ms ease;
    }
}
