.js {
    #suggestions {
        color: $nobel;
        cursor: pointer;
        padding: 0;
        display: none;
        position: absolute;
        z-index: 8184;
        
        .suggestion-row {
            clear: both;
            width: 20rem;
            padding: 3px 5px;
            overflow: hidden;
            white-space: nowrap;
            font-size: rem(12);
            background-color: $white;
            border-left: 1px solid $charcoal;
            border-right: 1px solid $charcoal;

            &:first-child {
                border-top: 1px solid $charcoal;
            }

            &:last-child {
                border-bottom: 1px solid $charcoal;
            }

            &:hover {
                background-color: $white-smoke;
            }

            .suggestionterm {
                display: inline-block;
                float: left;
                overflow: hidden;
                width: 100%;
            }

            .hits {
                display: none;
                float: right;
                width: 5rem;
                line-height: 1.8em;
                text-align: right;
            }
        }
    }
}
