.collections-component {
    @include shared-three-border-tape;

    margin: 50px auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1440px;

    @include respond-to(mobile) {
        margin: 20px 0;
    }

    .component-image {
        margin-bottom: 50px;
    }

    & &-title {
        @include respond-to(mobile_strong) {
            display: block;
        }
    }
}

.collections-component-asset-title {
    @include respond-to(mobile) {
        text-transform: uppercase;
    }

    @include respond-to(tablet_plus) {
        font-size: rem(18);
    }
}

.collections-component-asset-description {
    margin-bottom: 44px;

    @include respond-to(tablet_plus) {
        font-size: rem(14);
    }
}

.collections-component-asset-link {
    margin: 0;
}

.collections-components {
    &-list {
        @include flexbox();
        @include justify-content(space-between);

        margin: 0;
        padding: 8% 5.7% 0;

        @include respond-to(mobile) {
            padding: 5%;
        }

        &-wrapper {
            padding: 0 11%;

            &.mobile-col-1 {
                &::before,
                &::after {
                    @include respond-to(mobile_strong) {
                        height: calc(100% - 35px);
                    }
                }

                .collections-components-list {
                    @include respond-to(mobile) {
                        display: block;
                    }

                    &-item {
                        @include respond-to(mobile) {
                            display: block;
                            margin-bottom: 40px;
                            width: 100%;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        .component-image {
                            @include respond-to(mobile) {
                                margin-bottom: 17px;
                            }
                        }

                        .collections-component-asset-title {
                            @include respond-to(mobile) {
                                font-size: rem(18);
                            }
                        }

                        .collections-component-asset-description {
                            @include respond-to(mobile) {
                                font-size: rem(14);
                                margin: 14px 0 20px;
                            }
                        }
                    }
                }
            }

            &.mobile-col-2 {
                &::before,
                &::after {
                    @include respond-to(mobile_strong) {
                        height: calc(100% - 65px);
                    }
                }

                .collections-components-list {
                    @include respond-to(mobile) {
                        @include flex-wrap();
                    }

                    &-item {
                        @include respond-to(mobile) {
                            margin-bottom: 30px;
                            width: 45%;
                        }

                        .component-image {
                            @include respond-to(mobile) {
                                margin-bottom: 12px;
                            }
                        }

                        .collections-component-asset-description {
                            @include respond-to(mobile) {
                                font-size: rem(12);
                                margin: 6px 0 12px;
                            }
                        }

                        .collections-component-asset-link {
                            @include respond-to(mobile) {
                                min-width: 0;
                                width: 100%;
                            }
                        }
                    }
                }

                .collections-component-asset-title {
                    @include respond-to(mobile) {
                        font-size: rem(14);
                        line-height: 1.2;
                    }
                }

                .collections-component-asset-description {
                    @include respond-to(mobile) {
                        font-size: rem(15);
                    }
                }
            }
        }

        &-item {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(space-between);

            text-align: center;
            width: 22%;
        }
    }
}
