.search-suggestion-wrapper {
    @extend %clearfix;
    background-color: $white;
    border: 1px solid $very-light-gray;
    position: absolute;
    top: 5.5rem;
    left: 3%;
    width: 94%;
    z-index: 5;

    @include respond-to(mobile_strong) {
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 1.4rem;
            border-color: transparent transparent $white transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -2.8rem;
            left: 50%;
            margin-left: -0.7rem;
            z-index: 3;
        }
        
        .is-sticky & {
            position: fixed;
            top: 12rem;
            max-height: 78%;
            overflow: auto;
            
            &:before {
                position: fixed;
                top: 9.4rem;
            }
        }
    }
    
    @include respond-to(mobile_strong_over) {
        top: 2.9rem;
        left: auto;
        right: 0;
        width: 500px;
        max-width: none;

        .is-sticky & {
            position: fixed;
            width: 52rem;
            margin-left: -26rem;
            left: 50%;
            top: auto;
            max-height: 80%;
            overflow: auto;
        }
    }

    .phrase-suggestions,
    .product-suggestions {
        width: 49.5%;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .product-suggestions {
        float: right;
        border-left: 1px solid $ghost;
        margin-left: -1px;
        box-sizing: content-box;
    }

    .phrase-suggestions {
        float: left;
        padding: 0 20px;

        .full & {
            border-right: none;
        }
    }

    .you-look-for {
        display: none;
    }

    .hitgroup {
        color: $black01;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .header {
            font-size: rem(14);
            line-height: 19px;
            text-transform: uppercase;
            padding: 1rem 0 0.1rem 0;
        }

        .hit {
            display: block;
            font-size: rem(14);
            line-height: 22px;
            letter-spacing: 1px;
            color: $hel-gray;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: $hel-gray;
                line-height: 22px;

                &:hover {
                    color: $dim-gray;
                }
            }

            &:hover {
                color: $dim-gray;
            }
        }
    }
}

.product-suggestion {
    @extend %clearfix;
    margin-bottom: 10px;
    padding: 0 20px;

    &:last-child {
        margin-bottom: 0;
    }

    .product-link {
        display: block;
        overflow: hidden;
    }

    .product-image {
        float: left;
        text-align: center;
        width: 35%;

        img {
            max-width: 100%;
        }
    }

    .product-details {
        float: left;
        padding-left: 20px;
        width: 60%;

        .product-brand {
            font-size: rem(14);
            line-height: 19px;
            text-transform: uppercase;
            padding: 1rem 0 0.1rem 0;
        }

        .product-name,
        .product-price,
        .product-sales-price {
            display: block;
            font-size: rem(14);
            line-height: 18px;
            letter-spacing: 1px;
        }

        .product-price,
        .product-sales-price {
            line-height: 22px;
        }

        .now-sell-price {
            color: $red01;
            display: inline-block;
        }

        .product-standard-price{
            text-decoration: line-through;
            color: $charcoal;
        }
    }
}

.search-phrase {
    color: $black01;
    margin: 20px 15px 0;
    border-bottom: 1px solid $ghost;
    
    @include respond-to(mobile_strong) {
        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 1.4rem;
            border-color: transparent transparent $tutu transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -2.9rem;
            left: 50%;
            margin-left: -0.7rem;
            z-index: 2;
        }
        
        .is-sticky & {
            &:after {
                position: fixed;
                top: 9.3rem;
            }
        }
    }

    > span,
    a {
        display: block;
        clear: both;
        font-size: rem(14);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    > span {
        font-size: rem(14);
        letter-spacing: 1px;
        margin-bottom: 2px;
    }
}
