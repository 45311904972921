.plp-header-carousel {
    .slot-assets-list-item {
        max-height: none;

        @include respond-to(mobile) {
            float: left;
        }

        // fix before carousel initialization
        // https://jira.ontrq.com/browse/BRTREF-2217
        &:nth-child(n+2) {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    }
}

.plp-header-asset {
    position: relative;
    overflow: hidden;

    @include respond-to(tablet_plus) {
        min-height: 500px;
    }

    &:not(.plp-header-asset-video) {
        @include clip-decorator;
    }
    
    .brandmark-bar {
        background: $black01;
        position: absolute;
        z-index: $z-min;
        left: 0;
        width: 100%;
        height: 65px;
        text-align: left;

        @include respond-to(mobile) {
            text-align: center;    
        }

        @include respond-to(mobile_mid) {
            height: 35px;
        }

        @include respond-to(tablet_plus) {
            @include clip-decorator;

            padding-top: 15px;
        }

        .brandmark-bar-logo-wrapper {
            max-width: $plp-header-max-width-medium;
            height: 100%;

            &::before {    /* create a full-height inline block pseudo=element */
                content: '';
                display: inline-block;
                vertical-align: middle;  /* vertical alignment of the inline element */
                height: 100%;
            }

            img {
                max-width: 200px;
                max-height: 40px; // leave at least 5px top and bottom paddings in 50px height area
                vertical-align: middle;
                display: inline-block;
                width: auto;

                @include respond-to(mobile_mid) {
                    max-width: 170px;
                    max-height: 25px; // leave at least 5px top and bottom paddings in 50px height area
                }
            }
        }

        ~ .promo-content-wrapper { // compensate .brandmark-bar existance
            @include respond-to(mobile_strong_over) {
                padding-top: 50px;
            }

            .promo-breadcrumbs-wrapper {
                top: 70px;
            }
        }
    }

    .plp-promo-brandmark {
        margin-bottom: 35px;
        max-width: 300px;

        @include respond-to(mobile_mid) {
            max-width: 200px;
        }

        @include respond-to(mobile) {
            margin: 15px auto;
        }

        img {
            max-height: 60px;
            width: auto;

            @include respond-to(mobile) {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .promo-content {
        @include flexbox;
        @include align-items(flex-start);
        @include flex-vertical;
        @include justify-content(center);

        text-align: left;
        width: 50%;
        height: 100%;
        position: relative;
        z-index: 0;

        @include respond-to(mobile) {
            padding: 20px 0 30px;
            position: static;
        }

        @include respond-to(tablet_plus) { // avoid overlapping with breadcrumbs
            padding-top: 30px;
        }

        &.text-color-light {
            color: $color-primary3;
        }

        &.text-color-dark {
            color: $black01;
        }

        .color-bg-overlay {
            position: absolute;
            width: 100vw;
            height: 100%;
            top: 0;
            right: 0;
            background: transparent; // adjustable // can be setup by JSON in template (inlined color style)
            z-index: -1;

            @include respond-to(tablet_plus) {
                opacity: .9;
            }
        }

        .plp-promo-title {
            font-family: $font-main;
            color: inherit;
            margin-bottom: 25px;
            line-height: 1;
            letter-spacing: .02em;

            @include respond-to(mobile) {
                width: 100%;
                font-size: rem(22);
                padding-left: 35px;
                padding-right: 35px;
                margin-bottom: 0;
            }

            @include respond-to(tablet_plus) {
                font-size: rem(32);
            }
        }

        .plp-promo-description {
            line-height: 20px;
            margin-bottom: 30px;
            padding-left: 15px;
            width: 100%;

            @include respond-to(mobile) {
                font-size: rem(14);
                margin-bottom: 20px;
                padding: 0 15px;
                margin-top: 25px;
            }

            @include respond-to(tablet_plus) {
                font-size: rem(16);
            }
        }

        .navigation-wrapper {
            width: 100%;

            @include respond-to(tablet_plus) {
                padding-left: 15px;
            }
        }

        .plp-promo-ctas {
            @include flexbox;
            @include justify-content(space-between);

            margin-bottom: 0;

            .plp-promo-cta-button {
                width: 32%;

                &:only-child {
                    @include respond-to(mobile) {
                        margin: auto;
                    }
                }
            }

            &.mobile-buttons-fullwidth .plp-promo-cta-button {
                @include respond-to(mobile_strong) {
                    width: 100%;
                }
            }
        }
    }

    .promo-content-wrapper {
        max-width: $plp-header-max-width-medium; // should be more narrow than site-width

        @include respond-to(mobile) {
            @include clip-decorator;

            position: relative;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .promo-breadcrumbs-wrapper {
        @include respond-to(mobile_strong) {
            display: none;
        }

        @include respond-to(mobile_strong_over) {
            display: block;
        }
    
        @include respond-to(mobile) {
            padding-top: 30px;
            text-align: left;
        }
    
        @include respond-to(tablet_plus) {
            left: 15px;
            padding-top: 0;
            position: absolute;
            top: 30px;
            z-index: 1;
        }

        &.theme-dark .breadcrumb {
            @include text-link-style($link-color-dark);
        }

        &.theme-light .breadcrumb {
            @include text-link-style($link-color-light);
        }

        .breadcrumb {
            padding: 0;
        }
    }

    .plp-promo-category {
        font-size: 14px;
        color: inherit;
        text-transform: uppercase;
        letter-spacing: .02em;
        margin-bottom: 35px;

        @include respond-to(mobile) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .carousel-navigation {
        display: none;
        padding-left: 0;
        margin-top: 35px;

        .plp-header-carousel & {
            display: block;

            i {
                @include respond-to(tablet_plus) {
                    font-size: rem(9);
                }
            }
        }

        .prev,
        .next {
            position: static;
            margin-top: 0;
            padding: 0;
            color: inherit;
        }

        .prev {
            margin-right: 15px;
        }
    }

    .carousel-navigation-mobile {
        width: 100%;
        position: relative;

        .prev,
        .next {
            display: none;
            margin-top: 0;
            padding: 0;
            color: inherit;
            top: 50% !important;
            transform: translateY(-50%);
            bottom: auto;

            .plp-header-carousel & {
                @include respond-to(mobile) {
                    display: block;
                }
            }
        }

        .prev {
            left: 0;
            transform: translateY(-50%) scaleX(-1);
        }

        .next {
            right: 0;
        }
    }

    &.content-right {
        .promo-content {
            margin-right: 0;
            margin-left: auto;

            @include respond-to(tablet_plus) {
                padding-left: 9%;
            }

            .color-bg-overlay {
                right: auto;
                left: 0;
            }
        }
    }

    .component-image {
        @include respond-to(tablet_plus) {
            min-height: 500px;
            width: auto;
        }
    }

    &.plp-header-type1,
    &.plp-header-type2-carousel,
    &.plp-header-type3,
    &.plp-header-type11 {
        .component-image {
            @include respond-to(tablet_plus) { // make fullwidth bg image "bg cover"-style
                min-height: 500px;
                left: 50%;
                max-width: none;
                min-width: 100%;
                transform: translateX(-50%);
                position: relative;
            }
        }
    }

    &.plp-header-type5,
    &.plp-header-type6-carousel,
    &.plp-header-type12,
    &.plp-header-type13 {
        .promo-image-wrapper {
            @extend .site-width;

            padding: 0;

            @include respond-to(tablet_plus) {
                max-width: $plp-header-max-width-widest;
            }
        }

        .component-image {
            @include respond-to(tablet_plus) {
                margin-left: auto;
                margin-right: 0;
            }

            @include respond-to(mobile_to_desktop) {
                min-height: 0;
            }
        }

        .promo-content-wrapper {
            .promo-content {
                @include respond-to(mobile) {
                    padding-bottom: 40px;
                }

                @include respond-to(tablet_ext) {
                    width: 36%;
                    padding-right: 20px;
                }
            }
        }

        &.content-right {
            .promo-content-wrapper {
                .promo-content {
                    @include respond-to(tablet_ext) {
                        padding-right: 0;
                        padding-left: 20px;
                    }
                }
            }

            .component-image {
                @include respond-to(tablet_plus) {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }

        .color-bg-overlay {
            opacity: 1;
        }

        .content-slot & {
            @include respond-to(mobile) {
                background: none;
            }
        }
    }

    &.plp-header-type4 {
        .promo-image-wrapper {
            @extend .site-width;

            padding: 0;

            @include respond-to(mobile_strong_over) {
                max-width: $plp-header-max-width-widest;
            }
        }

        .component-image {
            min-width: 0;

            @include respond-to(mobile_strong_over) {
                margin-left: auto;
                margin-right: 0;
            }

            @media screen and (min-width: $break_mobile_strong) and (max-width: $break_desktop) {
                min-height: 0;
            }
        }

        .promo-breadcrumbs-wrapper {
            padding-top: 30px;
            text-align: left;

            @include respond-to(mobile_strong_over) {
                display: block;
                padding-top: 0;
                left: 15px;
                padding-top: 0;
                position: absolute;
                top: 30px;
                z-index: 1;
            }
        }

        .promo-content-wrapper {
            max-width: $plp-header-max-width-medium; // should be more narrow than site-width
            position: absolute;

            @include respond-to(mobile_strong) {
                @include clip-decorator;

                position: relative;
                padding-left: 15px;
                padding-right: 15px;
            }

            .promo-content {
                width: 33%;

                @include respond-to(mobile_strong) {
                    padding-bottom: 10px;
                    text-align: center;
                    width: 100%;
                }

                @include respond-to(mobile_strong_over) {
                    position: relative;
                    text-align: left;
                    padding-top: 0;
                    padding-right: 30px;
                }

                @media screen and (min-width: $break_mobile_strong) and (max-width: $break_touch) {
                    width: 36%;
                    padding-right: 20px;
                }

                .color-bg-overlay {
                    opacity: 1;
                }

                .plp-promo-title {
                    margin-bottom: 25px;
                    padding-left: 0;
                    padding-right: 0;
                    width: auto;

                    @include respond-to(mobile_strong) {
                        width: 100%;
                        padding-left: 35px;
                        padding-right: 35px;
                        margin-bottom: 0;
                    }
                }

                .plp-promo-description {
                    margin-bottom: 30px;
                    padding: 0 0 0 15px;
                    margin-top: 1em; // default

                    @include respond-to(mobile_strong) {
                        margin-bottom: 20px;
                        padding: 0 15px;
                        margin-top: 25px;
                    }
                }

                .navigation-wrapper {
                    padding-left: 0;

                    @include respond-to(mobile_strong_over) {
                        padding-left: 15px;
                    }
                }

                .plp-promo-ctas {
                    li {
                        width: 46%;
                        padding: 0;

                        @include respond-to(mobile_strong) {
                            width: 50%;
                            padding: 0 10px;
                        }
                    }

                    .cta-type5,
                    .cta-type6 {
                        position: relative;
                        color: $black01;
                        padding-bottom: 2px;
                        text-decoration: none;
                    
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border-bottom: 1px solid transparent;
                            transition: .3s ease-out;
                        }
                    
                        &:hover {
                            color: $color-primary2;
                    
                            &:after {
                                border-bottom-color: $color-primary2;
                            }
                        }
                    }

                    .cta-type6 {
                        &:after {
                            border-bottom-color: currentColor;
                            transition: none;
                        }

                        &:hover {
                            &:after {
                                border-bottom-color: currentColor;
                            }
                        }
                    }

                    .cta-type6.cta-dark { // primary(.6) -> black
                        color: $link-color-dark;

                        &:hover {
                            color: $color-auxiliary2;
                        }
                    }

                    .cta-type6.cta-light { // white(.6) -> white
                        color: $link-color-light;

                        &:hover {
                            color: $color-primary3;
                        }
                    }

                    .cta-type6.cta-white { // white -> white(.6)
                        color: $color-primary3;

                        &:hover {
                            color: $link-color-light;
                        }
                    }

                    .cta-type6.cta-inverse { // white(.6) -> black
                        color: $link-color-light;

                        &:hover {
                            color: $color-auxiliary2;
                        }
                    }

                    .plp-promo-cta-button a {
                        font-family: $font-main-bold;
                        text-decoration: underline;
                        text-transform: capitalize;
                        color: $link-color-dark;

                        @include respond-to(mobile_strong) {
                            color: inherit;
                        }

                        @include respond-to(mobile) {
                            font-size: rem(14);
                        }

                        @include respond-to(tablet_plus) {
                            font-size: rem(12);
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .plp-promo-cta-button:only-child {
                        margin: 0;
                        
                        @include respond-to(mobile_strong) {
                            margin: auto;
                        }
                    }
                }
            }
        }

        .promo-breadcrumbs-wrapper {
            padding-top: 0;
            text-align: start;

            @include respond-to(mobile_strong) {
                padding-top: 30px;
                text-align: left;
            }
        }

        &.content-right {
            .promo-content-wrapper {
                .promo-content {
                    padding-left: 0;

                    @media screen and (min-width: $break_mobile_strong) and (max-width: $break_touch) {
                        padding-right: 0;
                        padding-left: 20px;
                    }
                }
            }

            .component-image {
                @include respond-to(mobile_strong_over) {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }

        .content-slot & {
            @include respond-to(mobile_strong) {
                background: none;
            }
        }
    }

    // these are applied for types that have no overlay colour - so on mobile they will be on white bg - so make black text colour
    &.transparent-bg {
        .promo-content {
            @include respond-to(mobile) {
                color: $black01;
            }
        }

        .component-image {
            @include respond-to(mobile) {
                @include clip-decorator;
            }
        }
    }


    // ************* COMMON STYLES
    &.plp-header-type1,
    &.plp-header-type2-carousel,
    &.plp-header-type3 {
        &.colored-bg {
            .promo-content {
                @include respond-to(tablet_plus) {
                    min-width: 350px;
                    width: 30%;
                    padding-right: 5%;
                }
            }

            .plp-promo-category {
                @include respond-to(mobile) {
                    color: inherit;
                }
            }

            &.content-right {
                .promo-content {
                    padding-right: 0;

                    @include respond-to(tablet_plus) {
                        padding-left: 6%;
                    }
                }
            }
        }
    }

    // ************* COMMON STYLES
    &.plp-header-type1,
    &.plp-header-type2-carousel {

        .promo-content {
            width: 33%;

            .plp-promo-ctas {
                li {
                    width: 100%;

                    a {
                        border-color: inherit;
                    }
                }
            }
        }

        &.content-right {
            .promo-content {
                padding-left: 0;
            }
        }

        .plp-header-carousel & {
            .plp-promo-ctas {
                @include respond-to(tablet_plus) {
                    float: left;
                }
            }

            .carousel-navigation {
                @include respond-to(tablet_plus) {
                    float: left;
                    margin: 10px 0 0 50px;
                }
            }
        }
    }

    &.plp-header-type3,
    &.plp-header-type5,
    &.plp-header-type5-carousel {
        .promo-content {
            width: 33%;

            @include respond-to(mobile) {
                padding-bottom: 10px;
            }

            .plp-promo-ctas {
                li {
                    width: 46%;

                    @include respond-to(mobile) {
                        width: 50%;
                        padding: 0 10px;
                    }
                }
            }
        }

        .plp-promo-cta-button a {
            font-family: $font-main-bold;
            text-decoration: underline;
            text-transform: capitalize;

            @include respond-to(mobile) {
                color: inherit;
            }

            &::after {
                display: none;
            }
        }

        &.content-right {
            .promo-content {
                padding-left: 0;
            }
        }
    }

    // apply some modifications to buttons in PLP headers
    .button-type5 {
        min-width: 100%;
        font-size: rem(12);
        margin: 0;

        @include respond-to(tablet_plus) {
            padding: 8px;
            background-color: rgba($black01, .5);
            border-color: rgba($color-primary3, .5);
        }

        &:hover {
            @include respond-to(tablet_plus) {
                background-color: rgba($black01, 1);
                border-color: rgba($color-primary3, .3);
            }
        }
    }

    &.plp-header-type10 {
        .component-image {
            @include respond-to(mobile_strong_over) { // make fullwidth bg image "bg cover"-style
                min-height: 500px;
                left: 50%;
                max-width: none;
                min-width: 100%;
                transform: translateX(-50%);
                position: relative;
                min-height: 500px;
                width: auto;
            }
        }

        .promo-breadcrumbs-wrapper {
            padding-top: 30px;
            text-align: left;

            @include respond-to(mobile_strong_over) {
                display: block;
                padding-top: 0;
                left: 15px;
                padding-top: 0;
                position: absolute;
                top: 30px;
                z-index: 1;
            }
        }

        .plp-promo-brandmark {
            max-width: 300px;

            @include respond-to(mobile_strong) {
                margin: 15px auto;
            }

            @include respond-to(mobile_strong_over) {
                margin: 0 0 35px;
            }

            img {
                max-height: 60px;
                width: auto;

                @include respond-to(mobile_strong) {
                    display: block;
                    margin: 0 auto;
                }

                @include respond-to(mobile_strong_over) {
                    margin: 0;
                }
            }
        }

        .promo-content-wrapper {
            max-width: $plp-header-max-width-medium; // should be more narrow than site-width
            position: absolute;

            @include respond-to(mobile_strong) {
                @include clip-decorator;

                position: relative;
                padding-left: 15px;
                padding-right: 15px;
            }
            .promo-content {
                padding: 20px 0 30px;
                position: static;

                @include respond-to(mobile_strong_over) {
                    padding: 0;
                    position: absolute;
                    text-align: left;
                    width: 50%;
                }

                &.text-color-light {
                    color: $color-primary3;
                }

                &.text-color-dark {
                    color: $black01;
                }
            }
        }

        .promo-content {
            .plp-promo-title {
                color: inherit;
                margin-bottom: 25px;
                line-height: 1;
                letter-spacing: .02em;

                @include respond-to(mobile_strong) {
                    width: 100%;
                    padding-left: 35px;
                    padding-right: 35px;
                    margin-bottom: 0;
                }

                @include respond-to(mobile_strong_over) {
                    margin-bottom: 25px;
                    padding: 0;
                }
            }

            .plp-promo-description {
                line-height: 20px;
                margin-bottom: 30px;
                padding-left: 15px;
                width: 100%;

                @include respond-to(mobile_strong) {
                    margin-bottom: 20px;
                    padding: 0 15px;
                    margin-top: 25px;
                }

                @include respond-to(mobile_strong_over) {
                    margin-bottom: 30px;
                    padding-left: 15px;
                }
            }
        }

        // apply some modifications to buttons in PLP headers
        .button-type5 {
            min-width: 100%;
            margin: 0;

            @include respond-to(mobile_strong) { // do not join these two mediaqueries as mediaquery _inside_ button-type(2) mixin will overwrite settings below
                min-width: 100%;
                padding: 11px;
                margin: 0;
            }

            @include respond-to(mobile_strong_over) {
                font-size: rem(12);
                padding: 8px;
                background-color: rgba($black01, .5);
                border-color: rgba($color-primary3, .5);
            }

            &:hover {
                @include respond-to(mobile_strong_over) {
                    background-color: rgba($black01, 1);
                    border-color: rgba($color-primary3, .3);
                }
            }
        }

        &.content-left .promo-content {
            @include respond-to(mobile_strong_over) {
                padding-right: 7%;
            }
        }

        &.transparent-bg {
            .promo-content {
                &,
                &.text-color-light,
                &.text-color-dark {
                    @include respond-to(mobile_strong) {
                        color: $black01;
                    }
                }
            }

            .component-image {
                @include respond-to(mobile_strong) {
                    @include clip-decorator;
                }
            }
            .button-type5 {
                @include respond-to(mobile_strong) {
                    background: transparent;
                    color: $black01;
                    border-color: $black01;

                    &:hover {
                        background: rgba($black01, 0.5);
                        color: $color-primary3;
                    }
                }
            }
        }

        .plp-promo-description {
            @include respond-to(mobile_strong_over) {
                width: 75%;
            }
        }

        .navigation-wrapper {
            @include respond-to(mobile_strong_over) {
                padding-left: 10px;
            }
        }

        .plp-promo-ctas {
            @include flex-wrap;

            .plp-promo-cta-button {
                padding: 5px;
                width: 50%;

                @include respond-to(mobile_strong) {
                    padding: 2px;
                }

                @include respond-to(desktop_over) {
                    width: 33.333%;
                    width: calc(100% / 3);
                }

                &:only-child {
                    @include respond-to(mobile_strong) {
                        margin: auto;
                    }
                }

                .button-dark {
                    background: transparent;
                    color: $black01;
                    border-color: $black01;

                    &:hover {
                        background: rgba($black01, 0.5);
                        color: $color-primary3;
                    }
                }
            }
        }
    }

    &.plp-header-type11,
    &.plp-header-type12,
    &.plp-header-type13 {
        &.content-left .promo-content {
            @include respond-to(tablet_plus) {
                padding-right: 7%;
            }
        }

        &.transparent-bg {
            .button-type5 {
                @include respond-to(mobile) {
                    background: transparent;
                    color: $black01;
                    border-color: $black01;

                    &:hover {
                        background: rgba($black01, 0.5);
                        color: $color-primary3;
                    }
                }
            }
        }

        .plp-promo-description {
            @include respond-to(tablet_plus) {
                width: 75%;
            }
        }

        .navigation-wrapper {
            @include respond-to(tablet_plus) {
                padding-left: 10px;
            }
        }

        .plp-promo-ctas {
            @include flex-wrap;

            .plp-promo-cta-button {
                padding: 5px;
                width: 50%;

                @include respond-to(mobile_strong) {
                    padding: 2px;
                }

                @include respond-to(desktop_over) {
                    width: 33.333%;
                    width: calc(100% / 3);
                }

                &:only-child {
                    @include respond-to(mobile) {
                        margin: auto;
                    }
                }

                .button-dark {
                    background: transparent;
                    color: $black01;
                    border-color: $black01;

                    &:hover {
                        background: rgba($black01, 0.5);
                        color: $color-primary3;
                    }
                }
            }
        }
    }

    &.plp-header-type11,
    &.plp-header-type12,
    &.plp-header-type13 {

        .button-type5 {
            @include respond-to(mobile) {
                padding: 11px;
                background-color: rgba($black01, .5);
                border-color: rgba($color-primary3, .5);
            }

            &:hover {
                @include respond-to(mobile) {
                    background-color: rgba($black01, 1);
                    border-color: rgba($color-primary3, .3);
                }
            }
        }
    }

    // styles for special primary image behaviour type
    &.plp-header-type12,
    &.plp-header-type13 {
        &.content-left .promo-image-wrapper {
            @include respond-to(tablet_plus) {
                margin-left: 50%;
            }
        }

        &.content-right .promo-image-wrapper {
            @include respond-to(tablet_plus) {
                margin-right: 50%;
            }
        }

        .component-image {
            @include respond-to(tablet_plus) { // behave image in "cover"-style
                max-width: none;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .promo-content-wrapper .promo-content {
            @include respond-to(tablet_ext) {
                width: 50%; // override width change in prev cases
            }
        }

        &.content-left .promo-content {
            @include respond-to(tablet_ext) {
                padding-right: 20px; // override width change in prev cases
            }
        }
    }

    &.plp-header-type14 {
        @include respond-to(mobile) {
            @include clip-decorator;
        }

        @include respond-to(tablet_plus) {
            min-height: 236px;
        }

        .promo-content {
            width: 60%;
            margin: 0 auto;
            padding: 0;
            color: $black01;
            text-align: center;

            @include respond-to(mobile) {
                padding: 15px 0;
                text-align: left !important;
            }

            .plp-promo-title {
                max-width: none;
                width: 100%;
                font-family: $font-main-bold;
                text-transform: capitalize;

                @include respond-to(mobile) {
                    font-size: rem(18);
                    padding: 0;
                }

                @include respond-to(tablet_plus) {
                    margin-bottom: 0;
                }
            }

            .plp-promo-description {
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                padding: 0;

                @include respond-to(mobile) {
                    font-size: rem(12);
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                @include respond-to(tablet_plus) {
                    font-size: rem(14);
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }

            .plp-promo-category {
                width: 100%;

                @include respond-to(mobile) {
                    font-size: rem(12);
                    margin-bottom: 10px;
                }

                @include respond-to(tablet_plus) {
                    font-size: rem(14);
                    margin-bottom: 20px;
                }
            }
        }

        .carousel-navigation {
            margin-top: 10px;
        }
    }

    .promo-content-wrapper {
        width: 100%;

        .promo-content {
            @include respond-to(mobile) {
                width: 100%;
                text-align: center;
            }
        }
    }

    &.plp-header-asset-video {
        @include clip-path(none);

        &::before,
        &::after {
            content: '';
            border: 5px solid;
            position: absolute;
            bottom: 0;
            z-index: 1;
        }

        &::before {
            left: 0;
            border-color: transparent transparent $color-primary3 $color-primary3;
        }

        &::after {
            right: 0;
            border-color: transparent $color-primary3 $color-primary3 transparent;
        }

        .promo-video-wrapper {
            max-width: 1440px;
            margin: auto;
        }

        .video-js-data-container {
            @include respond-to(mobile_strong_over) {
                position: relative;
                z-index: $z-min;
                height: 100%;
                width: 56.25%;
                margin-left: auto;
                min-height: 500px;
            }

            .video-js {
                @include respond-to(mobile_strong_over) {
                    min-height: 500px;
                    padding-top: 56.25%;
                }

                .vjs-tech {
                    background-color: $black01;
                }

                .vjs-poster {
                    background-size: cover;
                }
            }
        }

        .promo-content-wrapper {
            max-width: $plp-header-max-width-medium; // should be more narrow than site-width
            position: absolute;

            @include respond-to(mobile_strong) {
                @include clip-decorator;

                position: relative;
                padding-left: 15px;
                padding-right: 15px;
            }

            .promo-content {
                @include respond-to(mobile_strong) {
                    width: 100%;
                    text-align: center;
                    padding-bottom: 40px;
                }
                @include respond-to(mobile_strong_over) {
                    width: 33%;
                    padding: 0;
                    position: relative;
                    text-align: left;
                }
            }
        }

        .promo-breadcrumbs-wrapper {
            @include respond-to(mobile_strong) {
                display: none;
            }

            @include respond-to(mobile_strong_over) {
                display: block;
            }

            @include respond-to(mobile_strong) {
                padding-top: 30px;
                text-align: left;
            }

            @include respond-to(mobile_strong_over) {
                left: 15px;
                padding-top: 0;
                position: absolute;
                top: 30px;
                z-index: 1;
            }

            &.theme-dark .breadcrumb {
                @include text-link-style($link-color-dark);
            }

            &.theme-light .breadcrumb {
                @include text-link-style($link-color-light);
            }

            .breadcrumb {
                padding: 0;
            }
        }
    }
}
