#secondary.secondary-content.refinements {
    max-width: 200px;

    @include respond-to(mobile) {
        max-width: 100%;
    }

    .category-banner {
        @include respond-to(mobile) {
            text-align: center;
        }
    }
}

#secondary.secondary-content.refinements + .primary-content {
    @include respond-to(m_tablet_down) {
        width: 100%;
    }
}

.category-banner img {
    max-width: 100%;
    width: 100%;
    margin: 20px 0;
}

.noresults-refinement-category {
    @include respond-to(mobile) {
        display: none;
    }
}

.secondary-navigation.content-nav {
    background: $light-gray;
    border: 1px solid $very-light-gray;
    padding: 0 10px;

    > .toggle-nav {
        border-top: none;

        &::after {
            right: 0px;
            top: 27px;
        }
    }

    @include respond-to(mobile) {
        background: none;
        border: none;
        padding: 0;

        > .toggle-nav {
            border: 1px solid $very-light-gray;
            margin-bottom: 12px;
            padding: 12px 16px;
            font-size: rem(16);
            background: $light-gray;
            text-transform: none;

            &:hover {
                color: $night-rider;
            }

            &::after {
                right: 15px;
                top: 50%;
            }
        }

        li {
            a {
                position: relative;
                display: block;
                border-top: 1px solid $very-light-gray;
                padding: 12px 16px;
                margin-bottom: -0.58em; // this will overlap !important derective for li
            }
        }
    }
}

#category-level-1 {
    li {
        color: $night-rider;
        display: block;

        .refinement-link {
            background: none repeat scroll 0 0 transparent;
        }

        &.expandable {
            .active {
                padding: 0 0 0 6px;
                font-size: rem(13);
            }
        }
    }
}

#category-level-2 {
    a {
        &.active {
            color: $night-rider;
        }

        &.refinement-link {
            margin: 0 0 0 .4em;
            padding: 0;
        }
    }
}

#category-level-2 {
    padding: .5em .1em 0 .7em;
}

#category-level-3 {
    padding: .3em 0 0;

    a {
        &.refinement-link {
            font-style: normal;
            margin: 0 0 0 .9em;
            padding: 0;
        }
    }
}

.breadcrumb-refined-by-box {
    margin-bottom: 50px;

    @include respond-to(mobile) {
        margin: 30px 0 10px;
    }
}

.breadcrumb-refined-by {
    display: block;
    font-size: rem(18);
    text-transform: uppercase;
    padding-bottom: 16px;
}

.breadcrumb-refined-clear-all {
    float: right;
    font-size: rem(14);
    text-decoration: underline;
    color: $dim-gray;
    cursor: pointer;

    @include respond-to(mobile) {
        font-size: rem(15);
        text-transform: uppercase;
        margin-top: 3px;
    }
}

.breadcrumb-refinement-value {
    display: block;
    font-size: rem(14);

    @include respond-to(mobile) {
        display: inline-block;
        border: 1px solid $gainsboro;
        font-size: rem(15);
        padding: 5px 10px;
        margin: 0 10px 10px 0;
        vertical-align: middle;
    }

    .bticons-close:before {
        color: $black01;
        font-size: rem(10);
    }
}

.breadcrumb-relax {
    background: none;
    font-style: normal;
    font-size: rem(9);
    padding: 0 6px 0 0;
}

.breadcrumb-result-text a,
.breadcrumb .searchphraselink {
    background: none;
    padding: 0;
}

.refinement-header {
    display: block;
    font-size: rem(16);
    text-transform: capitalize;
    font-family: $font-main-bold;
    color: $nobel;

    + .refinement-header {
        font-size: rem(18);
        margin-top: 22px;
    }
}

.refinement-header-expand {
    float: right;
    font-size: rem(14);
    text-decoration: underline;
    color: $dim-gray;
    cursor: pointer;
    display: none;

    @include respond-to(mobile) {
        font-size: rem(15);
        text-transform: uppercase;
    }
}

.show-mobile-refinements,
.close {
    display: none;
}

.category-refinement {
    margin-top: 20px;
    border-bottom: 0;

    @include respond-to(m_tablet_down) {
        padding: 0 10px;
    }

    h3.toggle {
        display: none;
    }

    li {
        padding: 0;
        margin-bottom: 6px;
    }

    .category-level-1 {
        .expandable.fa-angle-right:before {
            display: none;
        }

        > li {
            display: block;
            border: 1px solid $lightest-gray;
            font-size: rem(15);
            line-height: 24px;

            .category-link {
                padding: 10px;
                display: inline-block;
                width: 100%;
                position: relative;

                &.toggle::after {
                    border-bottom: 0;
                    font-size: rem(23);
                    margin-right: 10px;
                    top: 10px;
                    content: '+';
                }

                &.toggle.expanded::after {
                    border-bottom: 1px solid;
                    top: 20px;
                    content: '';
                }
            }

            .category-level-2 {
                .toggle::after {
                    top: 0;
                }

                .toggle.expanded::after {
                    top: 15px;
                }
            }

            .category-level-3,
            .category-level-4 {
                padding-left: 10px;
                margin-bottom: 0px;
            }

            &:before {
                display: none;
            }

            &:hover {
                background: $light-gray;
                color: $black01;
            }
        }
    }

    .category-level-2 {
        li {
            .category-link {
                padding: 5px 10px 5px 20px;
            }
        }
    }
}

@include respond-to(m_tablet_down) {
    .refinements-overlay {
        box-shadow: rgba(0, 0, 0, 0.74902) -50px 0px;
    }

    .search-refinements-container {
        width: 85%;
        max-width: 300px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        background: $white;
        z-index: 10000;
        transform: translateX(-100%);
        transition: transform 0.6s;

        .close {
            width: 70px;
            height: 70px;
            position: absolute;
            top: 0;
            right: -70px;
            font-size: rem(14);
            font-weight: bold;
            line-height: 70px;
            text-align: center;
            color: $white;
            background: $nobel;

            &:after {
                content: '';
                width: 1500px;
                height: 70px;
                position: absolute;
                top: 0;
                right: -1500px;
                background: $nobel;
            }
        }
    }

    .search-refinements-active {
        transform: translateX(0);

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 100%;
            top: 70px;
            width: 1500px;
            height: 100%;
            background: rgba($black01, .3);
        }

        .close {
            display: block;
        }
    }

    .show-mobile-refinements {
        width: 50%;
        height: 58px;
        position: relative;
        float: left;
        display: inline-block;
        padding: 0 35px 0 15px;
        font-size: rem(12);
        font-family: $font-main-bold;
        line-height: 58px;
        text-transform: uppercase;
        color: $hel-gray;
        border-right: 1px solid $lighter-gray;
        cursor: pointer;
        outline: none;

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 1px);
            right: 20px;
            display: inline-block;
            padding: 3px;
            border: solid $black01;
            border-width: 0 2px 2px 0;
            transform: rotate(-45deg) translateX(50%);
        }
    }
}

.is-sticky .search-refinements-container {
    padding-top: 40px;

    @include respond-to(mobile) {
        padding-top: 0;
    }
}

.refinement-view-type {
    float: right;
    text-align: right;
    padding: 22px 2px 0 0;
    font-size: rem(14);
    text-decoration: underline;
    color: $dim-gray;
    cursor: pointer;

    @include respond-to(mobile) {
        display: none;
    }

    &:hover {
        color: $nobel;
        text-decoration: none;
    }

    .collapse-all {
        display: none;
    }

    &.show-collapse {
        .collapse-all {
            display: block;
        }

        .show-all {
            display: none;
        }
    }
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: .58em 0;
        padding: 0;

        li {
            list-style: none outside none !important;
            margin: 0 0 15px;
        }
    }

    .toggle {
        border: 0 none;
        display: block;
        font-size: 1em;
        margin: .83em 0 .58em;
        text-transform: uppercase;

        span {
            background: none repeat scroll 0 0 transparent;
        }
    }

    .expanded {
        background-position: left -96px;
    }
}
