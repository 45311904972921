.payment-list__link {
    @extend button.coloured;

    padding-top: 14px;
    padding-bottom: 14px;

    @include respond-to(mobile) {
        min-width: 0;
    }
}

.payment-list {
    border-top: 1px solid $very-light-gray;

    &__link {
        margin: 10px 0;
        color: $white;
    }

    .payment-box {
        font-size: rem(12);
    }

    .cc-owner {
        font-family: $font-main-bold;
    }
}

.payment__title {
    font-size: rem(18);
    margin-bottom: 10px;
}