.amp-header-style-1 {
    @include flexbox;
    align-items: center;
    background-color: $ghost;
    background-size: auto, cover;
    background-position: top left, center top;
    background-repeat: repeat, no-repeat;

    &__wrapper {
        margin: 0 auto;
        padding: 24px;
        text-align: center;
        width: 66.666%;

        @include respond-to(mobile_tablet) {
            width: auto;
        }
    }

    &__subtitle {
        font-family: $font-second;
        margin-bottom: 20px;

        @include respond-to(mobile_tablet) {
            font-size: rem(13);
        }
    }

    &__header-box {
        display: inline-block;
        margin-bottom: 8px;
        margin-top: 3px;

        @include respond-to(mobile_tablet) {
            display: block;
        }
    }

    &__header-box-wrapper {
        border-bottom: 1px solid;
        border-top: 1px solid;
        padding: 6px 24px;

        @include respond-to(mobile_tablet) {
            padding: 6px 0;
        }
    }

    &__top-header {
        font-size: rem(27);
        font-family: $font-second;

        @include respond-to(mobile_tablet) {
            font-size: rem(18);
        }
    }

    &__header {
        font-size: rem(46);
        line-height: 55px;
        text-transform: uppercase;

        @include respond-to(mobile_tablet) {
            font-size: rem(30);
            line-height: 36px;
        }
    }

    &__description {
        letter-spacing: 1px;
        line-height: 25px;
        margin-bottom: 20px;

        @include respond-to(mobile_tablet) {
            font-size: rem(13);
            line-height: 20px;
        }
    }

    &__cta {
        margin-bottom: 20px;
    }

    &__button {
        background-color: transparent !important;
        margin-bottom: 20px;

        @include respond-to(mobile_tablet) {
            width: auto;
        }

        &_icon {
            font-size: rem(10);
        }
    }

    &__text-center {
        text-align: center;
    }
}

.amp-header-style-2 {
    padding: 24px 0;

    &__wrapper {
        padding: 24px;

        @include respond-to(mobile) {
            padding: 0;
        }
    }

    &__image {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: 100px;
    }

    &__inner_wrapper {
        margin: 0 auto;
        padding: 0 5px;
        text-align: center;
        width: 85%;

        @include respond-to(mobile_tablet) {
            width: auto;
        }
    }

    &__header {
        display: inline-block;
        font-size: rem(46);
        line-height: 55px;
        padding: 0 24px 6px 24px;
        border-bottom: 1px solid;
        margin: 3px 0 8px 0;

        .accent {
            color: $contessa;
        }

        .smaller {
            font-size: rem(32);
            line-height: 38px;

            @include respond-to(mobile_tablet) {
                line-height: 32px;
            }
        }

        .tiny {
            font-size: rem(18);
            text-transform: none;
            font-family: $font-second;
            line-height: 1;
            color: $toast;
        }

        .block {
            display: block;
        }

        @include respond-to(mobile_tablet) {
            font-size: rem(30);
            line-height: 36px;
            display: block;
        }
    }

    &__top-header {
        font-size: rem(23);
        line-height: 27px;
        margin: 3px 0 8px 0;
    }

    &__description {
        letter-spacing: 1px;
        line-height: 25px;
        margin-bottom: 20px;

        @include respond-to(mobile_tablet) {
            font-size: rem(13);
            line-height: 20px;
        }
    }
}

.amp-vertical-slider-slide {
    &__item {
        list-style: none;

        &:hover {
            .is--bottom-position {
                display: block;

                @include respond-to(tablet_plus) {
                    position: absolute;
                }
            }
        }
    }

    &__bg-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 1;
        z-index: -1;
    }

    &__content {
        z-index: 1;

        &.is--bottom-position {
            position: relative;
            top: auto;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            padding: 20px;

            @include respond-to(tablet_plus) {
                display: none;
            }
        }

        &.fwbp-text {
            background: none;
            color: $white;
        }
    }
}

.amp-caption-panel {
    &__item {
        list-style: none;
    }

    &__link {
        display: inline-block;
    }

    &__image {
        display: block;
    }

    &__img {
        max-width: 100%;
        vertical-align: top;
    }

    &__caption {
        display: block;
        font-size: rem(16);
        font-family: $font-second;
        line-height: 24px;
        padding: 10px 30px 0;
        text-align: center;
        margin: 0 auto;
    }
}

.amp-generic-slick-slider {
    display: none;  // this will prevent all slides in structure reveal (blink of slides) - until slider is fully loaded

    div {
        max-width: none; // without this slider brakes itself
    }

    img:focus,
    div:focus {
        outline: 0;
    }

    /* Slider specific */
    &.slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    &.slick-slider .slick-track,
    &.slick-slider .slick-list {
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &.slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
        display: none;

        [dir="rtl"] & {
            float: right;
        }

        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }
    }

    &.slick-initialized .slick-slide {
        display: block;
    }

    &.slick-loading .slick-slide {
        visibility: hidden;
    }

    &.slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    /* END Slider specific */

    &.navigation_automatic .slick-dots {
        pointer-events: none;
    }

    .slick-dots { // if illustration block contains carousel - grab these default styles
        display: block; // by default: "none"
        position: absolute;
        background: rgba($black01, 0.3);
        padding: 11px 7px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0;
        margin: 0;
        z-index: 1;

        li {
            display: inline-block;
        }

        li:first-child button {
            margin-left: 0;
        }

        .slick-active button {
            background: $white;
        }

        button {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: $charcoal;
            margin-left: 15px;
            font-size: 0;
            padding: 0;

            &:hover {
                background: $white;
            }
        }
    }
}

.amp-slider-module {
    display: none;  // this will prevent all slides in structure reveal (blink of slides) - until slider is fully loaded

    div,
    img {
        max-width: none; // without this slider brakes itself on smaller screens
    }

    .slick-shadow-overlay {
        position: absolute;
        top: 0;
        width: 30%;
        height: 100%;
        transition: .65s ease;
        background: rgba($black01, 0.7);

        &.slick-overlay-left {
            left: 0;
              margin-right: -1px;
        }

        &.slick-overlay-right {
            right: 0;
            margin-left: -1px;
        }
    }

    .slick-slide img,
    .slick-shadow-overlay {
        max-width: none;
        height: 300px;

        @media (min-width: 768px) {
            height: 400px;
        }

        @media (min-width: 992px) {
            height: 540px;
        }

        @media (min-width: 1260px) {
            height: 620px;
        }
    }

    .slick-arrow {
        top: 150px;

        @media (min-width: 768px) {
            top: 200px;
        }

        @media (min-width: 992px) {
            top: 270px;
        }

        @media (min-width: 1260px) {
            top: 310px;
        }
    }

    .slick-arrow {
        margin-top: -27px;
        height: 45px;
        width: 45px;
        position: absolute;
        background: $white;
        font-size: 0;
        font-family: $font-icons;
        border: 0;
        z-index: 1;

        &:hover::before {
            color: $dim-gray;
        }

        &::before {
            font-size: rem(13);
            color: $black01;
            margin-left: -2px;
            transition: .3s;
        }

        &.slick-next {
            right: 0;

            &::before {
                content: "\e915";
            }
        }

        &.slick-prev {
            left: 0;

            &::before {
                content: "\e90c";
            }
        }
    }

    .amp-caption-panel__caption {
        color: transparent;
        transition: color .3s;
    }

    .slick-current .amp-caption-panel__caption {
        color: currentColor;
    }
}

.amp-quote-panel {
    &__wrapper {
        margin: 0 auto;
        padding: 0 15px;
        text-align: center;
        width: 83.33333%;

        @include respond-to(mobile_tablet) {
            width: auto;
        }
    }

    &__blockquote {
        padding: 20px;
        margin: 0;

        p {
            font-size: rem(20);
            line-height: 30px;
            margin: 0 0 20px;
        }

        small {
            font-size: rem(16);
            line-height: 25px;
        }
    }
}

.amp-quote-panel-new {
    margin: 30px 0;
    padding: 30px 15px;

    @include respond-to(mobile_strong_over) {
        padding: 30px;
    }

    &__wrapper {
        max-width: 750px;
        margin: 0 auto;
        background-color: inherit;
    }

    &__image {
        margin: 0 auto 20px auto;
        display: block;
        width: 100px;

        @include respond-to(mobile_strong_over) {
            margin: 0;
            float: left;
        }
    }

    &__blockquote {
        padding: 30px 0;
        border-top: 1px solid $black01;
        border-bottom: 1px solid $black01;
        margin: 0;
        position: relative;
        text-align: center;
        background-color: inherit;

        @include respond-to(mobile_strong_over) {
            text-align: left;
            padding: 30px;
        }

        svg {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -12px;
            margin: 0 auto;
            height: 24px;
            width: 60px;
            background-color: inherit;
        }

        svg:first-child {
            bottom: auto;
            top: -12px;
        }

        p {
            margin: 0;

            &:first-letter {
                font-family: Georgia;

                @include respond-to(mobile_strong_over) {
                    float: left;
                    font-size: rem(90);
                    line-height: 50px;
                    padding: 14px 10px 10px 0;
                }
            }
        }

        img + p {
            @include respond-to(mobile_strong_over) {
                margin-left: 125px;
            }
        }
    }
}

.amp-slider-reveal {
    .twentytwenty-handle {
        height: 50px;
        width: 50px;
        margin-left: -29px;
        margin-top: -29px;
        background: $white;

        span {
            position: absolute;
            top: 50%;
            left: auto;
            right: auto;
            width: auto;
            height: auto;
            border: none;
            margin: -9px 0 0;
            font-size: rem(18);
            @include font-icomoon;
            font-weight: bold;
        }

        .twentytwenty-right-arrow {
            right: 10px;

            &::before {
                content: "\e915";
            }
        }

        .twentytwenty-left-arrow {
            left: 10px;

            &::before {
                content: "\e90c";
            }
        }
    }
}

.amp-generic-two-panel-mode {
    .row {
        width: auto;
        @include flexbox;
        @include align-items(center);

        @include respond-to(mobile_tablet) {
            @include flex-vertical;
        }
    }

    .columns {
        padding: 0 15px;
        float: none;

        @include respond-to(mobile_tablet) {
            width: 100%;
        }
    }

    &.position_rhs {
        @include respond-to(desktop_plus) {
            .amp-generic__illustration {
                @include order(1);
            }
        }
    }

    .amp-generic__illustration {
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
        }

        &.columns {
            padding: 0;
        }
    }

    .amp-generic__text {
        text-align: center;

        .row {
            padding: 0 24px;
        }

        .columns {
            margin: 0 auto;
            padding: 0 39px;

            @include respond-to(mobile_tablet) {
                padding: 20px 0;
            }
        }
    }
}

.amp-generic,
.amp-generic-two-panel-mode {

    .amp-generic__header {
        font-size: rem(44);
        margin-bottom: 8px;
        border-bottom: 1px solid $black01;
        padding: 6px 0;

        @include respond-to(mobile_tablet) {
            font-size: rem(30);
            line-height: 36px;
        }
    }

    .amp-generic__topheader {
        display: block;
        font-size: rem(18);
        line-height: 1;

        @include respond-to(mobile_tablet) {
            font-size: rem(12);
        }
    }

    .amp-generic__description {
        font-size: rem(16);
        line-height: 1.6;
        font-family: $font-second;
        letter-spacing: 1px;

        @include respond-to(mobile_tablet) {
            font-size: rem(13);
        }
        
        &.amp-long-text-panel__description {
            font-family: $font-main;
        }
    }

    .amp-generic__cta {
        margin-top: 20px;
        transition: .3s;
        border-color: $charcoal;

        &:hover {
            color: $charcoal;
        }

        &.outline:hover {
            background: rgba(255,255,255,0.3);
            color: $charcoal;
        }

        .amp-generic__cta_icon {
            font-size: rem(10);
        }
    }
}

.amp-long-text-panel {
    .row {
        @include flexbox();
    }

    &.position_rhs .row {
        flex-direction: row-reverse;

        @include respond-to(mobile) {
            @include flex-vertical();
        }
    }

    .columns {
        float: left;

        @include respond-to(mobile_tablet) {
            width: 100%;
        }
    }

    .amp-generic__text .columns {
        float: none;
    }

    .amp-generic__description {
        font-size: rem(14);

        @include respond-to(mobile_tablet) {
            font-size: rem(13);
        }
    }

    .amp-generic__header {
        border-bottom: 0;
    }
    .amp-generic__subheader {
        border-bottom: 1px solid $black01;
    }
}

.amp-long-text-panel {
    &__imgwrapper {
        position: relative;

        @media screen and (max-width: 450px) {
            overflow: auto;
            height: auto;

            img {
                position: static;
            }
        }
    }

    &__image {
        text-align: center;

        img {
            display: block;
        }

        .position_rhs & {
            float: right;
        }

        &.columns {
            padding: 0;
        }
    }
}

.amp-image-panel-with-carousel {
    &__imgwrapper {
        position: relative;

        @include respond-to(mobile_tablet) {
            height: 600px;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }

        @include respond-to(mobile_strong) {
            height: 400px;
        }

        @media screen and (max-width: 450px) {
            overflow: auto;
            height: auto;

            img {
                position: static;
            }
        }
    }

    &__image {
        text-align: center;

        img {
            display: block;
        }

        .position_rhs & {
            float: right;
        }

        &.columns {
            padding: 0;
        }
    }
}

.amp-image-panel-with-carousel {
    @include respond-to(mobile_tablet) {
        .amp-generic__illustration {
            @include order(1);
        }

        .carousel {
            margin-bottom: 60px;
        }
    }

    .carousel {
        .product-image {
            height: auto;
            max-height: 270px;
        }
    }
}

.amp-carousel-panel {
    margin-bottom: 20px;
}

.amp-slider-panel {
    &__carousel { // specific style\behaviuor for carousel in Slider Panel
        &.columns {
            @include respond-to(mobile_tablet) {
                margin: 30px auto 0;

                .amp-image-slide__img {
                    width: 100%;
                }
            }

            &.large-9 {
                @include respond-to(mobile_stronger) {
                    width: 70%; // used, otherwise between 768px and 992px carousel will become really huge and bad looking
                }
            }
        }
    }
    
    .amp-generic__description {
        font-family: $font-main;
    }
}

.amp-pullout-panel {
    @extend %clearfix;
    padding: 20px;

    @include respond-to(mobile_tablet) {
        padding: 20px 0;
    }

    &__text {
        width: 75%;

        @include respond-to(mobile_tablet) {
            width: auto;
        }
    }

    &__image {
        border-radius: 50%;
        float: left;
        max-width: 100%;
        width: 14%;

        @include respond-to(mobile_tablet) {
            width: 25%;
        }

        + .amp-pullout-panel__text {
            float: left;
            padding-left: 30px;
            width: 50%;

            @include respond-to(mobile_tablet) {
                width: 75%;
            }
        }
    }

    &__description {
        letter-spacing: 1px;
        margin-top: 10px;
    }
}

.amp_simple-content {
    text-align: center;

    &__img-container {
        margin-bottom: 30px;
    }

    &__img {
        margin: 0 auto 10px;
        max-width: 800px;
        width: 100%;
    }

    &__img-caption {
        font-family: $font-second;
    }

    &__title {

    }

    &__description {
        max-width: 700px;
        margin: 0 auto 20px;
        padding: 0 30px;
    }

    .amp-generic__cta {
        width: auto;
    }
}

.amp-single-product-panel {
    .amp-generic__description {
        font-size: rem(14);
        margin-bottom: 20px;
        font-family: $font-main;

        @include respond-to(mobile_tablet) {
            font-size: rem(13);
        }

        @include respond-to(mobile_strong) {
            & + .amp-single-product-panel__img {
                display: none;
            }
        }
    }

    .amp-generic__illustration {
        &.columns {
            padding: 20px;
        }
    }

    &__imgwrapper {
        margin-bottom: 20px;
    }

    .amp-generic__cta {
        border-color: transparent;

        &:hover {
            color: $white;
        }
    }

    .amp-generic__topheader {
        font-size: rem(32);
        margin: 0 0 8px;
        border-bottom: 1px solid currentColor;
        padding: 6px 0;
        line-height: 38px;

        @include respond-to(mobile_tablet) {
            font-size: rem(30);
            line-height: 36px;
        }
    }

    .amp-generic__header {
        display: block;
        font-size: rem(20);
        line-height: 24px;
        padding: 0;
        margin: 3px 0 8px 0;
        border: 0;

        @include respond-to(mobile_tablet) {
            font-size: rem(15);
            line-height: 18px;
        }
    }

    .product-title {
        font-size: rem(18);
    }

    .price-switcher {
        display: block;
    }

    .product-price {
        display: block;
        line-height: 26px;

        .price-sales + .price-standard {
            margin-left: 5px;
            color: $charcoal;
            font-size: 80%;
            text-transform: uppercase;
        }
    }
}

.amp-lower-content-panel-feature {
    &__image-wrapper {
        margin-bottom: 15px;
    }

    &__image {
        max-width: 100%;
        vertical-align: top;
    }

    &__description {
        margin: 4px 0 0;
    }

    &__link {
        display: inline-block;
        margin-top: 20px;
    }

    &__video-wrapper {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        .jwplayer {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
        }
    }
}


.amp-menu-block {
    margin-bottom: 10px;

    &__link {
        text-transform: uppercase;
        text-decoration: underline;
        letter-spacing: 1px;
    }

    &__img {
        max-width: 100%;
    }
}

.amp-text-overlay-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 24px;
}

.amp-shoppable-category-banner-slide {
    position: relative;

    &__img {
        display: block;
        max-width: 100%;
    }
}

.amp-shoppable-category-banner {
    background: $white-smoke;
    margin-bottom: 24px;

    &.full-width {
        @include out-of-wrapper;
    }

    .hide-content-mobile,
    &.hide-content-mobile {
        @include respond-to(mobile) {
            .amp-generic__description,
            .amp-generic__header {
                display: none;
            }
        }
    }

    .hide-additional-content-mobile,
    &.hide-additional-content-mobile {
        @include respond-to(mobile) {
            .amp-shoppable-category-banner__carousel {
                display: none;
            }
        }
    }

    .clb-cta-two-column,
    &.clb-cta-two-column {
        .amp-generic__cta {
            @include text-truncate;
            float: left;
            width: 49.5%;

            &:nth-child(even) {
                float: right;
            }
        }
    }

    .clb-cta-narrow,
    &.clb-cta-narrow {
        .amp-generic__cta {
            padding: 6px 22px;
            font-size: rem(13);
        }
    }

    .row {
        margin: 0;
        width: auto;
        @include flexbox;
        @include align-items(center);

        @include respond-to(mobile_strong) {
            @include flex-vertical;

            .columns {
                width: 100%;
                float: none;
            }
        }
    }

    .columns {
        padding: 0 15px;
    }

    .amp-generic__header {
        font-size: rem(36);
        margin-bottom: 12px;
        border: none;
        padding: 0;
        text-transform: none;
    }

    .amp-generic__text {
        text-align: center;

        @include respond-to(mobile) {
            @include order(2);
        }

        .columns {
            margin: 0 auto;
            padding: 24px 0;
            width: auto;
        }
    }

    .amp-generic__description {
        font-style: normal;
        letter-spacing: 0;
        margin-bottom: 20px;
        font-family: $font-main;

        @include respond-to(mobile_tablet) {
            font-size: rem(16);
        }
    }

    .amp-text-overlay-wrapper {
        padding: 24px 40px;
    }

    &__carousel {
        padding: 30px 0;

        @include respond-to(mobile_strong) {
            padding: 0 0 24px;
        }

        .slick-arrow {
            background: rgba($black01, 0.6);
            padding: 11px 15px;
            color: $white;
            width: auto;

            &::before {
                font-size: rem(14);
            }
        }
    }

    &__CTAs {
        width: 70%;
        margin: 0 auto;

        .amp-generic__cta {
            display: block;
            font-size: rem(16);
            text-transform: none;
            padding: 6px 5px;
            width: 100%;
            margin: 0 0 2px;

            &:hover {
                color: $white;
            }
        }
    }
}

.amp-who-will-be {
    .carousel-list {
        @include respond-to(tablet_plus) {
            font-size: 0;
        }
    }

    .carousel {
        .carousel-item {
            float: none;
            vertical-align: top;
            font-size: 0;

            &:nth-of-type(4n+1) {
                clear: none;
            }

            &:hover {
                background-color: transparent;
            }

            @include respond-to(tablet_plus) {
                float: left;
            }
        }

        .jcarousel-nav {
            padding: 11px 15px;
            margin-top: -18px;
            font-size: 0;

            &::before {
                font-size: rem(14);
            }
        }
    }

    @include respond-to(mobile_strong) {
        .who-will-be-text {
            position: static;
            opacity: 1;
            transform: none;
            width: 100%;
        }

        .carousel-list .carousel-item {
            &::before {
                content: none;
            }

            .who-will-be-img {
                transition: none;
            }

            &:hover {
                .who-will-be-text,
                .who-will-be-img {
                    transform: none;
                }
            }
        }
    }
}


.amp-lowerpanel {
    &__sidebar {
        float: left;
        width: 25%;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }

        .grid-item--width2,
        .grid-item {
            width: 100%;

            @include respond-to(mobile) {
                text-align: center;
            }
        }
    }

    &__heroimage {
        float: right;
        width: 75%;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }

        .grid-item--width2,
        .grid-item {
            width: 100%;

            @include respond-to(mobile) {
                text-align: center;
            }
        }
    }

    &__products {
        @extend %clearfix;

        .product-tile {
            width: 100%;
        }

        .carousel-item {
            width: 37.5%;
            float: left;
            text-align: center;

            @include respond-to(mobile) {
                width: 80%;
                float: none;
                margin: 0 auto;
            }
        }

        .product-swatches {
            display: none;
        }
    }
}


.fixed-by-fullpage-js {
    position: fixed;
    width: 100%;

    &.top-banner {
        top: 28px;
    }

    &.header-banner {
        top: 0;
        z-index: 1;
    }
}

.amp-vertical-slider {
    @include full-page-content();

    .fwb-panel {
        background-position: top center;
    }

    .amp-carousel-panel {
        margin: 0 60px;

        @include respond-to(mobile_strong) {
            margin: 0 0 20px 0;
        }
    }
}

#fp-nav {
    background: $white;

    &::before,
    &::after {
        position: absolute;
        width: 28px;
        height: 28px;
        background: $white;
        border-radius: 50%;
        content: '';
        left:0;
    }

    &::before {
        top: -14px;
    }

    &::after {
        bottom: -14px;
    }
}

.amp-video-panel {
    &.standard {
        margin: 0 auto; //center it in standard view
        padding-bottom: 20px;

        @include respond-to(tablet_plus) {
            padding-right: 30px;
        }
    }

    &.use-as-background video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        transform: translateX(-50%) translateY(-50%);
        transition: 1s opacity;
    }

    .video-js.vjs-fluid {
        padding-top: 56.25%; // fix priority of this style in Youtube mode
    }

    .video-js {
        font-size: rem(10);
        margin: 0 auto; //center it (usually needed if  fluid=false and we have video smaller than container)
    }

    .video-js .vjs-tech {
        background-color: $lighter-gray;
    }

    .vjs-default-skin .vjs-big-play-button {
        font-size: 3em;
        line-height: 3em;
        height: 3em;
        width: 3em;
        border: 0;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        margin: 0;
    }

    .video-js:hover .vjs-big-play-button {
        background-color: $lighter-gray;
        color: $nero2;
    }

    .video-js .vjs-control-bar,
    .video-js .vjs-big-play-button,
    .video-js .vjs-menu-button .vjs-menu-content {
        background-color: $nero2;
        background-color: rgba($black01, 0.2);
        color: $white;
    }

    .video-js .vjs-slider {
        background-color: $ghost;
    }

    .video-js .vjs-volume-level,
    .video-js .vjs-play-progress,
    .video-js .vjs-slider-bar {
        background: $white;
        height: 3px !important;
    }

    .video-js .vjs-progress-control:hover .vjs-progress-holder {
        font-size: 1em;
    }

    .video-js .vjs-progress-control:hover .vjs-mouse-display:after,
    .video-js .vjs-progress-control:hover .vjs-play-progress:after {
        display: none;
    }

    .video-js .vjs-play-progress:before {
        font-size: 1.2em;
    }

    .video-js .vjs-progress-control .vjs-mouse-display {
        width: 4px;
        height: 100%;
        background-color: rgb(255,0,0);
    }

    .video-js .vjs-load-progress {
        background: ligthen($nero2, 25%);
        background: rgba(34,34,34, 0.5);
    }

    .video-js .vjs-load-progress div {
        background: ligthen($hel-gray2, 50%);
        background: rgba(101, 101, 101, 0.75);
    }

    .video-js .vjs-control:focus:before,
    .video-js .vjs-control:hover:before,
    .video-js .vjs-control:focus {
        text-shadow: none;
    }

    .vjs-paused button.vjs-big-play-button {
        display: block;
    }

    .vjs-control::after {
        display: none;
    }
}

.amp-personal-shop__ {
    &tab-wrap {
        @include flexbox();
        @include flex-wrap();
        -webkit-transition: 0.3s box-shadow ease;
        transition: 0.3s box-shadow ease;
        position: relative;
        max-width: 100%;
        min-height: 500px;
        list-style: none;
        margin: 0 0 50px 0;
        padding: 0;
        overflow: hidden;

        @include respond-to(tablet) {
            min-height: 430px;
        }

        @include respond-to(mobile) {
            min-height: auto;
        }
    }

    &tab {
        @extend %clearfix;
        position: absolute;
        left: 0;
        height: 500px;
        z-index: 1;
        list-style-type: none;
        text-align: center;
        width: 33.33333333%;
        padding: 40px 15px 80px;
        margin: 0;
        background-color: $white-smoke;

        &:nth-of-type(2) {
            left: 33.33333333%;
        }

        &:nth-of-type(3) {
            left: 66.66666666%;
        }

        @include respond-to(tablet) {
            height: 430px;
        }

        @include respond-to(mobile) {
            position: relative;
            width: 100%;
            height: auto;

            &:nth-of-type(2),
            &:nth-of-type(3) {
                left: 0;
            }
        }

        figure {
            margin: 0;
        }

        img {
            max-width: 100%;
        }

        h2 {
            margin-top: 5px;
            line-height: 1;
        }

        small {
            display: inline-block;

            @include respond-to(mobile) {
                margin-bottom: 30px;
            }
        }
    }

    &cta {
        display: none;

        & + label {
            cursor: pointer;
            display: block;
            position: absolute;
            z-index: 2;
            top: 420px;
            text-decoration: none;
            color: $black01;
            background-color: $white;
            padding: 0 15px;
            border: 1px solid $black01;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 50px;
            line-height: 48px;
            box-sizing: border-box;
            -webkit-transition: 0.3s color ease, 0.3s background-color ease;
            transition: 0.3s color ease, 0.3s background-color ease;
            -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);

            &:hover {
                background-color: $white-smoke;
            }

            @include respond-to(tablet) {
                top: 350px;
            }

            @include respond-to(mobile) {
                position: relative;
                top: auto;
                bottom: 80px;
                margin-bottom: -50px;
            }
        }

        &:nth-of-type(1) + label {
            left: 16.666666%;

            @include respond-to(mobile) {
                left: 50%;
            }
        }

        &:nth-of-type(2) + label {
            left: 50%;
        }

        &:nth-of-type(3) + label {
            right: 16.666666%;
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);

            @include respond-to(mobile) {
                right: auto;
                left: 50%;
                -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
            }
        }

        &:checked + label {
            background-color: $black01;
            color: $white;
            cursor: default;

            &:hover {
                background-color: $black01;
                color: $white-smoke;
            }
        }
    }

    &tab-content {
        -webkit-transition: 0.8s top ease, 0.8s height ease, 0.8s transform ease;
        transition: 0.8s top ease, 0.8s height ease, 0.8s transform ease;
        padding: 0;
        background-color: $white;
        position: absolute;
        width: 300%;
        z-index: -1;
        left: 0;
        top: 100%;
        height: 0;
        overflow: hidden;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    &cta:checked:nth-of-type(1) ~ &tab-content:nth-of-type(1),
    &cta:checked:nth-of-type(2) ~ &tab-content:nth-of-type(2),
    &cta:checked:nth-of-type(3) ~ &tab-content:nth-of-type(3) {
        position: relative;
        margin-top: 500px;
        z-index: 0;
        height: auto;
        padding: 50px 25px 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);

        @include respond-to(tablet) {
            margin-top: 430px;
        }

        @include respond-to(mobile) {
            margin-top: 0;
            padding-bottom: 30px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 16.66666666%;
            width: 0;
            height: 0;
            margin-left: -30px;
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            border-top: 30px solid $white-smoke;

            @include respond-to(mobile) {
                left: 50%;
            }
        }
    }

    &cta:checked:nth-of-type(2) ~ &tab-content:nth-of-type(2) {
        &:after {
            left: 50%;
        }
    }

    &cta:checked:nth-of-type(3) ~ &tab-content:nth-of-type(3) {
        &:after {
            left: 83.33333333%;

            @include respond-to(mobile) {
                left: 50%;
            }
        }
    }

    &tab-col {
        float: right;
        width: 48%;
        margin-bottom: 30px;
        text-align: left;

        &:first-child {
            float: left;
        }
    }

    &contact-info {
        clear: both;
        padding: 15px 20px;
        background-color: $white-smoke;
    }

    &tab-close {
        position: absolute;
        z-index: 10;
        height: 32px;
        width: 32px;
        right: 20px;
        top: 20px;
        font-family: $font-icons;
        color: $black01;
        font-size: rem(18);
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        -webkit-transition: 0.3s color ease;
        transition: 0.3s color ease;

        &:hover {
            color: $charcoal;
        }

        &:after {
            content: "\e901";
        }
    }
}

.amp-phone-slider-slide {
    position: relative;
    text-align: center;
    padding: 0 15px 264px;
    overflow: hidden;

    .phone-slide__header {
        font-size: rem(16);
        letter-spacing: 0;
        margin: 0;
        padding: 0 0 20px 0;

        span {
            position: relative;
            display: inline-block;

            &::before {
                position: absolute;
                margin: 0 auto;
                bottom: -5px;
                left: 0;
                right: 0;
                width: 50%;
                border-bottom: 1px solid;
                content: '';
            }
        }
    }

    .phone-slide__description {
        line-height: 26px;
        font-size: rem(16);
        padding-bottom: 80px;
        background: url(../images/amplience/vertical-dotted-line.png) no-repeat;
        background-position: left 50% bottom 20px;
    }
    
    .phone-slide__img {
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(100%);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(100%);
    }
    
    100% {
        transform: translateY(0);
    }
}

.inView.slideInUp {
    animation-name: slideInUp;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-duration: 1s;
}

.amp-phone-slider {
    padding: 50px 20px 0 20px;
    text-align: center;
    font-size: 0;
    @extend %clearfix;

    .amp-phone-slider-slide {
        display: inline-block;
        width: 25%;

        @include respond-to(mobile_tablet) {
            width: 50%;
            margin-bottom: 30px;
        }

        @include respond-to(mobile_strong) {
            width: auto;
        }
    }
}

.slick-slide {
    -webkit-transform: translate3d(0,0,0); // will fix flickering on Samsung\Android (mostly in landscape mode)
}