.is-wishlist-default-available {
    .wishlist-menu-vip,
    .wishlist-menu-handpicked {
        display: none;
    }

    &.is-wishlist-handpicked-available {
        .wishlist-menu-handpicked {
            display: block;
        }
    }

    &.is-wishlist-vip-available {
        .wishlist-menu-vip {
            display: block;
        }
    }
}

.wishlist-header {
    @include out-of-wrapper();
    background-color: $puce;
    color: $white;
    margin-bottom: 65px;
    padding-bottom: 25px;
    padding-top: 25px;
    text-align: center;

    @include respond-to(mobile) {
        margin-bottom: 25px;
        padding-bottom: 40px;
        padding-top: 35px;
    }

    &-container {
        margin: 0 auto;
        max-width: 480px;

        @include respond-to(mobile) {
            margin: 0;
            max-width: none;
        }

        &_subtitle {
            font-family: $font-second;
            font-size: rem(26);

            @include respond-to(mobile) {
                margin-bottom: 11px;
            }
        }

        &_title {
            color: $white;
            font-family: $font-third;
            font-size: rem(76);
            line-height: 1;
            margin-bottom: 8px;
            text-transform: uppercase;

            @include respond-to(mobile) {
                font-size: rem(48);
                margin-bottom: 17px;
            }
        }

        &_description {
            letter-spacing: 1px;
            line-height: 1.6;
            margin: 0;
            opacity: 0.9;
        }
    }
}

.wishlist-search-phrase {
    text-decoration: underline;
}

.mywishlist-flayer {
    @extend %clearfix;
    margin-bottom: 35px;
    padding: 30px;
    text-align: center;

    @include respond-to(mobile) {
        margin-bottom: 0;
        padding: 15px;
    }
}

.mywishlist-flayer_text {
    display: inline-block;
    max-width: 46%;

    @include respond-to(mobile) {
        display: block;
        max-width: none;
    }

    + .mywishlist-flayer_visual {
        display: inline-block;
        max-width: 46%;
        padding-left: 65px;
    }
}

.mywishlist-flayer_title {
    @include respond-to(mobile) {
        font-size: rem(35);
        margin-bottom: 12px;
    }

    @include respond-to(tablet_plus) {
        font-size: rem(44);
        margin-bottom: 14px;
    }

    span {
        text-decoration: underline;
    }
}

.mywishlist-flayer_title-vip {
    color: $new-orleans;
    margin-bottom: 0;

    @include respond-to(mobile) {
        margin-bottom: 0;
    }
}

.mywishlist-flayer_visual {
    @include respond-to(mobile) {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 9px;
    }
}

.mywishlist-flayer_description {
    font-size: rem(14);
    line-height: 1.6;
    margin-bottom: 22px;

    @include respond-to(mobile) {
        margin-bottom: 16px;
    }
}

.mywishlist-flayer_author-position {
    display: inline-block;
    text-transform: uppercase;
    margin-top: 10px;
    text-decoration: underline;
}

.mywishlist-flayer_subtitle {
    font-size: rem(24);
    margin-bottom: 25px;
    text-transform: uppercase;
}

.mywishlist-flayer_categories-title {
    font-size: rem(20);
    text-transform: uppercase;
}

.mywishlist-flayer_links {
    .mywishlist-flayer_item {
        margin-top: 10px;
    }
}

.product-name-collection {
    display: none;
}

.wishlist-products {
    margin-top: 20px;
    margin-bottom: 40px;

    &-item {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $very-light-gray;

        @include respond-to(tablet_plus) {
            margin-top: 0;
            padding-top: 45px;
            position: relative;
        }

        &:last-child {
            border-bottom: 1px solid $very-light-gray;
        }
    }

    &-form {
        @include respond-to(tablet_plus) {
            display: table;
            width: 100%;
        }

        .in-stock-msg,
        .low-stock-msg {
            font-size: rem(14);
            margin-bottom: 18px;

            &::before {
                margin-right: 8px;
            }

            @include respond-to(mobile) {
                margin: 0;
            }
        }
    }

    .product-sales-price {
        + .product-standard-price {
            color: $charcoal;
            font-size: rem(12);
            text-transform: uppercase;
            white-space: nowrap;
            text-decoration: line-through;

            &.rrp-price {
                text-decoration: none;
            }
        }
    }

    .wishlist-added,
    .wishlist-remove-item {
        color: $hel-gray;

        @include respond-to(mobile) {
            margin: 15px 0;
        }

        @include respond-to(tablet_plus) {
            text-align: right;
            margin-bottom: 25px;
        }

        &-date {
            margin-bottom: 2px;
        }
    }

    .product-tile-wrapper {
        height: auto;
        overflow: visible;
    }

    .product-tile {
        height: auto;
        text-align: left;
        overflow: visible;
        max-width: none;

        @include respond-to(tablet_plus) {
            position: static;
            display: table;
            width: 100%;
            padding-bottom: 45px;
        }

        .promo-flag {
            position: absolute;
            left: 15%;
            top: 50px;
            margin-left: 10px;

            @include respond-to(mobile) {
                display: inline-block;
                position: static;
                margin: 0;
            }
        }

        .product-image {
            height: auto;

            @include respond-to(tablet_plus) {
                margin-bottom: 0;

                .promo-flag {
                    right: auto;
                    left: 15%;
                    margin-left: 10px;
                    top: -10px;

                    &::after {
                        content: none;
                    }
                }
            }

            @include respond-to(mobile) {
                text-align: center;
            }

            img {
                margin-top: 0;
                max-width: 135px;
    
                @include respond-to(tablet_plus) {
                    margin-top: 10px;
                }
                @include respond-to(mobile) {
                    padding-top: 50px;
                }
            }
        }

        .product-name {
            text-transform: none;
            max-height: none;
            overflow: visible;

            &-brand {
                display: block;
                font-size: rem(20);
                text-transform: uppercase;
            }

            &-title {
                font-family: $font-main;
                font-size: rem(16);
                line-height: rem(16);
            }
        }

        .product-variations {
            ul {
                margin-bottom: 0;
            }

            .attribute {
                margin: 5px 0;

                &:last-child {
                    @include respond-to(mobile) {
                        margin: 5px 0 10px;
                    }
                }

                .label,
                h3 {
                    font-family: $font-main;
                }

                .value {
                    @include respond-to(mobile) {
                        margin: 0;
                        width: 100%;
                    }
                }
            }

            .inventory {
                margin: 5px 0;

                @include respond-to(mobile) {
                    margin: 10px 0 15px;
                }

                &-label {
                    @include respond-to(mobile) {
                        line-height: 1.6;
                        padding-bottom: 3px;
                    }
                }
            }
        }
    }

    .wishlist-added-available {
        color: $dark-gray;
    }

    .promo-flag-wishlist + .product-col-2 {
        @include respond-to(tablet_plus) {
            padding-top: 25px;
        }

        @include respond-to(mobile) {
            padding-top: 10px;
        }
    }

    .promo-flag-wishlist-above {
        @include hidden;
    }

    .product-variations {
        @include respond-to(mobile) {
            text-align: left;
        }

        .toggle.expanded > ul {
            @include respond-to(mobile) {
                z-index: 10;
                width: 100% !important;
                box-shadow: inset 0px 0px 0px $white; // to fix gap inside variations dropdown
            }
        }

        &.toggle-content {
            .selected-value,
            .toggle.expanded > ul {
                @include respond-to(tablet_plus) {
                    width: 100%;
                }
            }
            .product-add-to-cart {
                margin-top: 33px;
            }
        }

        .label {
            @include respond-to(mobile) {
                text-align: left;
            }
        }
    }

    .product-col-1 {
        @include respond-to(tablet_plus) {
            display: table-cell;
            vertical-align: top;
            width: 15%;
        }
    }

    .product-col-2 {
        @include respond-to(tablet_plus) {
            display: table-cell;
            vertical-align: top;
            padding-left: 10px;
            padding-right: 10px;
            width: 40%;
        }

        .now-sell-price {
            color: $red01;

            + .product-standard-price {
                font-size: rem(16);
                color: $color-secondary2;
                text-decoration: line-through;
                text-transform: capitalize;
                display: block;
                margin: 5px 0 0 39px;

                &.rrp-price {
                    text-decoration: none;
                }
            }
        }
    }

    .product-col-3 {
        @include respond-to(tablet_plus) {
            display: table-cell;
            vertical-align: top;
            width: 45%;
        }

        &_left {
            @include respond-to(tablet_plus) {
                display: table-cell;
                padding-right: 15px;
                width: 172px;
                vertical-align: bottom;
            }

            .not-available-msg {
                display: none;
            }
        }

        &_right {
            @include respond-to(tablet_plus) {
                display: table-cell;
                vertical-align: bottom;
            }

            .product-variations {
                .attribute {
                    ul {
                        @include respond-to(mobile) {
                            float: none;
                        }
                    }
                }

                .toggle.expanded {
                    .swatches.size.size,
                    > ul.size {
                        @include respond-to(mobile) {
                            top: calc(100% - 1px);
                            z-index: 3;
                        }
                    }
                }
            }

            .inventory {
                @include respond-to(mobile) {
                    margin: 30px 0 !important;
                }
            }

            .error-quantity {
                display: block;
                margin: 0 0 25px;

                @include respond-to(mobile) {
                    margin: 0 0 15px;
                }
            }
        }

        .add-to-cart {
            font-size: rem(16);
            min-width: 215px;
            white-space: nowrap;

            @include respond-to(mobile) {
                padding: 13px;
            }
        }
    }

    // ------ CHANEL SECTION ONLY -------- //

    .product-detail-chanel {
        button.coloured {
            &:hover {
                background: $color-secondary4;
            }
        }

        button:not(.add-to-cart) {
            color: $black01;

            &:hover {
                background: $black01;
                color: $white;
                border-color: transparent;
            }
        }

        .add-to-cart {
            background-color: $black01;
        }
    }

    .brand-chanel {
        .product-name-collection {
            display: block;
        }
    }

    // ------ CHANEL SECTION END -------- //
}

.wishlist-products-form {
    button:not(.add-to-cart) {
        @include button-type(1);

        min-width: 215px;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: rem(16);

        @include respond-to(mobile) {
            margin: 0;
        }
    }
}

.list-share {
    @extend %clearfix;
    border-top: 1px solid darken($white-smoke, 10%);
    padding: 2em;

    .share-option {
        box-sizing: border-box;
        display: block;
        margin-bottom: .5rem;
        float: left;
    }

    .share-options {
        text-align: center;
        margin-bottom: .5rem;
        font-size: 1.7em;
        float: left;
        margin-left: 1rem;
        margin-top: 0.3rem;

        .share-title {
            display: inline;
        }
    }

    .list-title {
        float: left;
    }

    .share-link {
        cursor: pointer;
    }

    .share-link-content {
        display: none;

        &.active {
            display: block;
            float: left;
        }
    }
}

.event-info {
    margin: 1em;
}

.wishlist-search-result {
    margin-bottom: 0;

    .view {
        text-align: right;
    }
}

.wishlist-search,
.registry-search {
    font-size: 0;

    .form-row {
        clear: none;
        display: inline-block;
        padding: 0 10px 0 0;
        vertical-align: top;
        width: 28%;

        @include respond-to(mobile) {
            display: block;
            width: auto;
            padding: 0;
        }
    }

    .form-row-button {
        padding-right: 0;
        width: 16%;

        @include respond-to(mobile) {
            width: auto;
        }
    }

    .field-wrapper {
        margin-bottom: 0;
    }
}

.wishlist-search {
    .form-row-button {
        label {
            display: none;
        }

        .button {
            padding-top: 14px;
            padding-bottom: 14px;
            min-width: 0;
        }
    }
}

.wishlist-sharing-box {
    @extend %clearfix;
    background-color: $ghost;
    margin-bottom: 35px;
    padding: 35px 25px;

    @include respond-to(mobile) {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    &_sharing {
        float: left;
        width: 55%;
        padding: 0 15px;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            padding: 0;
        }

        .notify-customer-button {
            margin-bottom: 20px;

            @include respond-to(mobile) {
                margin-bottom: 30px;
            }
        }
    }

    &_shipping {
        float: right;
        width: 45%;
        padding: 0 25px;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            padding: 0;
        }

        .input-select {
            margin-top: 4px;

            @include respond-to(mobile) {
                margin-top: 0;
            }
        }

        .wishlist-cta & {
          width: 100%;
          text-align: center;
          padding: 0 25px;
        }
    }

    &_title {
        font-size: rem(16);
        margin-bottom: 25px;

        @include respond-to(mobile) {
            margin-bottom: 14px;
        }
    }

    .wishlist-share-button,
    a.sendtofriend-button {
        font-size: rem(16);
        letter-spacing: 0;
        padding: 15px 10px;
        margin-bottom: 20px;

        &:hover {
            background: $medium-blue;
        }

        @include respond-to(mobile_to_desktop) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include respond-to(tablet_plus) {
            max-width: 49.5%;
            min-width: 45%;
        }

        @include respond-to(mobile) {
            margin-bottom: 30px;
            padding: 13px;
        }

        @include custom-respond-to(0, 1150px) {
            max-width: 100%;
            width: 100%;
            float: none;
        }
    }

    .wishlist-share-button {
        @include respond-to(tablet_plus) {
            float: left;
        }
    }

    .sendtofriend-button {
        @include respond-to(tablet_plus) {
            float: right;
        }
    }

    .list-share-description {
        margin-bottom: 16px;
        clear: both;

        @include respond-to(mobile) {
            line-height: 1.7;
            margin-bottom: 9px;
        }
    }

    .list-share-link {
        @include respond-to(mobile) {
            margin-bottom: 50px;
            word-break: break-all;
        }
    }
}

.pt_wish-list {
    @extend %clearfix;

    // fix for WL wrapper [BTP2-530]
    .is-sticky + #main {

        @include respond-to(touch_over) {
            margin-top: 60px;
        }
    }

    &_inner {
        @include respond-to(mobile) {
            @include flexbox();
            @include flex-vertical();
        }
    }

    .primary-content {
        float: right;
        width: 74%;

        @include respond-to(mobile) {
            float: none;
            width: auto;
            @include order(2);
        }
    }

    #secondary {
        float: left;
        width: 21%;

        @include respond-to(mobile) {
            float: none;
            margin: 20px 0 30px;
            width: auto;
            @include order(1);
            &:before {
                content: "js-tablet-nav";
                display: none;
            }
        }
    }

    .list-table-header {
        background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $white-smoke;
        overflow: hidden;
        padding: 2em;

        p {
            color: $nobel;
            float: right;
            margin: 0;
            overflow: hidden;
            width: 40%;
        }
    }

    .primary-content .list-table-header h2 {
        float: left;
    }

    .breadcrumb {
        position: relative;
        z-index: 1;

        @include respond-to(desktop_plus) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .breadcrumb + .pt_wish-list_inner.customer-is-not-authenticated {
        @include respond-to(desktop_plus) {
            margin-top: -20px;
        }

        @include respond-to(mobile_stronger) {
            margin-top: -10px;
        }

        .my-account-title-wishlist-result {
            @include respond-to(desktop_plus) {
                margin-top: 60px;
            }

            @include respond-to(mobile_stronger) {
                margin-top: 45px;
            }
        }
    }

    .h1.my-account-title {
        @include full-page-content($scrollbar-fix: true);

        background: linear-gradient($color-auxiliary38, $color-primary3);
        text-align: center;

        @include respond-to(desktop_plus) {
            padding-bottom: 50px;
            padding-top: 115px;
        }

        @include respond-to(mobile_stronger) {
            padding-top: 70px;
        }

        @include respond-to(mobile) {
            margin-bottom: 60px;
        }

        @include respond-to(mobile_strong) {
            padding-top: 30px;
        }
    }

    .checkoutlogin-col-wrapper {
        .login-box {
            &.login-account {
                @include shared-checkout-sidebar-decoration;

                @include respond-to(mobile_strong) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .wishlist-tab {
        @include flexbox();
        font-family: $font-main-bold;
        font-size: rem(18);
        text-transform: capitalize;
        text-align: center;
        border: 1px solid $very-light-gray;
        border-width: 1px 0;

        a {
            color: $very-light-gray;
        }

        .pseudo-tab {
            width: 33.333%;
            padding: 15px 5px;
            position: relative;

            @include respond-to(mobile_strong) {
                width: auto;
            }

            &.is-active {
                &:after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background: $white;
                    border: 1px solid $very-light-gray;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 50%;
                    bottom: -5px;
                }
            }
        }
    }
}

.pt_wish-list_inner.customer-is-not-authenticated {
    @include shared-login-page-layout;

    .nav {
        display: none;
    }
}

.wishlist-search-form {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 24px;
    clear: both;
    min-height: 400px;

    p {
        font-size: rem(14);
        margin-top: 0;
    }

    .my-account-title {
        border-bottom: 1px solid $black01;
        padding-bottom: 20px;
    }

    .pt_wish-list & {
        .login-box-content {
            margin: 0;
            padding: 0;
            width: 100%;

            .form-row-button {
                margin: .5rem 0 1rem;
            }
        }
    }

    .my-account-subtitle {
        padding-top: 0;
    }

    .wishlist-search {
        .button {
            @extend button.coloured;

            line-height: 14px;
            padding: 14px;
        }
    }
}

.send-to-friend-wishlist {
    text-align: left;

    .input-textarea {
        resize: vertical;
    }
    
    .js-form-row-field-friendemailconfirm.error {
        background-color: transparent;
        border: none;
        color: inherit;
        padding: 0;
        text-transform: none;

        .field-wrapper {
            margin-bottom: 0;
        }

        .error-message {
            background-color: $white-smoke2;
            border: 1px solid $red01;
            border-top: none;
            color: $red01;
            padding: 10px 8px;
            text-transform: uppercase;
            width: 100%;
        }
    }
}

.dialog-sendtofriend-wishlist {
    .ui-dialog-buttonpane {
        display: block !important;

        .ui-button-text {
            display: block !important;
        }
    }

    .dialog-content {
        text-align: center;
    }

    body & .ui-dialog-title {
        text-transform: uppercase;

        @include respond-to(mobile_strong) {
            padding-left: calc(15% - 5px);
            padding-right: calc(15% - 5px);
            font-size: rem(24);
        }
    }

    body & .ui-dialog-titlebar-close {
        top: 18px;
        right: 25px;

        @include respond-to(mobile) {
            top: 15px;
        }

        @include respond-to(mobile_strong) {
            top: 12px;
            right: 20px;
        }
    }
}

.wishlist-cta {
    overflow: hidden;
    margin: 15px 0;
}