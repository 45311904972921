.menu-utility-user {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    order: 1;
    width: 30%;

    @include respond-to(tablet_regular) {
        width: 40%;
        justify-content: flex-start;
        padding-left: 30px;
    }

    @include respond-to(mobile_strong) {
        height: 102px;
        flex-direction: column;
        border-bottom: 2px solid $color-auxiliary10;
    }

    .primary-logo + & {
        display: none;

        @include respond-to(mobile_strong_over) {
            display: flex;
        }
    }

    .navigation-inner-wrapper & {
        @include respond-to(mobile_strong) {
            width: 100%;
        }
    }

    .utility-store {
        > i {
            font-size: rem(15);
        }

        .content-asset {
            float: left;

            .toggle {
                float: left;
                padding-left: 0.6rem;
                font-size: rem(11);
                line-height: 1.8rem;
                text-transform: uppercase;
            }
        }

        &:hover > i {
            color: $dim-gray;
        }
    }

    li {
        font-family: $font-main-bold;

        i {
            float: left;
            font-size: rem(15);
            padding: 0;
            transition: color .5s ease;
        }

        > i {
            color: $nobel;
        }

        @include respond-to(mobile_strong_over) {
            position: relative;

            &:first-child {
                border: none;
            }
        }

        a {
            text-decoration: none;
            color: $nobel;

            &:active {
                color: $dim-gray;
            }

            @include respond-to(mobile_strong_over) {
                color: $nobel;
                transition: color .5s ease;

                &:hover {
                    color: $dim-gray;
                    text-decoration: none;
                }

                i {
                    display: block;
                    float: left;
                    font-size: rem(14);
                    padding: 0;
                }

                span {
                    float: left;
                    padding-left: 0.6rem;
                    font-size: rem(11);
                }
            }
        }

        &.stores a i {
            font-size: rem(16);
            margin-top: -0.1rem;
        }
    }

    .int-country-selector {
        cursor: pointer;
        padding-left: 0;
        margin-right: 25px;

        @include respond-to(tablet_regular) {
            margin-right: 15px;
        }

        @include respond-to(mobile_strong) {
            margin: 0;
            width: 50%;
            height: 50px;
            display: flex;
            align-items: center;
            padding-left: 30px;
            border-bottom: 1px solid $color-auxiliary10;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 10px;
                height: 30px;
                border-right: 1px solid $color-auxiliary10;
            }
        }

        &-locale {
            font-size: rem(12);
            letter-spacing: 0;
            line-height: 1.8rem;
            pointer-events: none;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: block;
                width: 17px;
                height: 17px;
                margin-right: 10px;
                background: url("../images/icons/globe.svg") left / contain no-repeat;
            }
        }
    }

    .user-info {
        @include respond-to(mobile_strong) {
            padding: 0;
            width: 50%;
            height: 100px;
        }

        > a {
            @include respond-to(mobile_strong) {
                height: 50px;
                display: flex;
                align-items: center;
            }

            &:after {
                content: "Login";
                float: left;
                padding-left: 0.6rem;
                font-size: rem(11);
                line-height: 1.8rem;
                letter-spacing: 0.1rem;
                text-transform: uppercase;
            }

            &.user-account {
                @include respond-to(tablet_regular) {
                    align-items: center;
                }

                &:after {
                    content: "Account";
                }
            }

            i {
                font-size: rem(15);
            }

            span {
                display: none;
                text-align: left;
                word-break: break-word;
                max-height: 50px;
                overflow: hidden;

                @include respond-to(mobile_stronger) { // 768px - 992px
                    max-width: 75px;
                }

                @include respond-to(desktop_plus) { // >= 993px
                    max-width: 175px;
                }

                @include respond-to(m_desktop) { // >= 1024px
                    max-width: 240px;
                }

                @include respond-to(desktop_over) { // >= 1261px
                    max-width: 350px;
                }
            }
        }

        @include respond-to(mobile_strong_over) {
            position: relative;
            transition: color .5s ease;

            a {
                &:after {
                    display: none;
                }

                span {
                    display: block;
                }
            }
        }

        &.active {
            .user-login {
                color: $citrus;
            }

            .user-panel {
                opacity: 1;
                visibility: visible;
            }
        }

        .user-panel {
            box-sizing: border-box; //See https://code.google.com/p/chromium/issues/detail?can=2&q=max-content&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified&id=522191&thanks=522191&ts=1439925077
            text-align: left;
            transition: all .3s ease;
            position: absolute;
            top: 5rem;
            left: 0.8rem;
            width: 95%;
            border: 1px solid $charcoal;
            background-color: $white;
            visibility: hidden;

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 6px;
                border-color: transparent transparent $color-primary3 transparent;
                border-bottom-style: solid;
                position: absolute;
                top: -1.2rem;
                left: -webkit-calc(50% + 1rem);
                left: calc(50% + 1rem);
                z-index: 3;
            }

            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border: inset 7px;
                border-color: transparent transparent $charcoal transparent;
                border-bottom-style: solid;
                position: absolute;
                top: -1.4rem;
                left: -webkit-calc(50% + 0.9rem);
                left: calc(50% + 0.9rem);
                z-index: 2;
            }

            @include respond-to(mobile_strong_over) {
                top: 3rem;
                left: 2rem;
                width: 200px;
                visibility: hidden;

                &::before {
                    left: 1rem;
                }

                &::after {
                    left: 0.9rem;
                }
            }

            a {
                display: block;
                float: left;
                clear: both;
                width: auto;
                padding: 0.8rem;
                font-size: rem(11);
                letter-spacing: 0.1rem;
                color: $mid-gray;
                background: transparent;
            }

            h3 {
                display: none;
                margin: 0;
                padding-bottom: .7em;
                text-transform: uppercase;

                @include respond-to(mobile_strong_over) {
                    display: block;
                }
            }

            .user-links {
                @extend %clearfix;
                text-align: center;
                font-family: $font-main;
            }
        }
    }

    .top-banner & {
        text-transform: capitalize;

        @include respond-to(mobile_stronger) {
            height: 56px;
        }

        .user-info {
            @include respond-to(mobile_strong) {
                position: relative;
                z-index: $mobile-menu-z-index;
            }

            .user-account {
                @include respond-to(mobile_strong) {
                    display: none;
                }
            }

            a > span {
                white-space: nowrap;
                padding-top: 3px;
                max-height: 40px;
                overflow: hidden;
                font-family: $font-main-bold;

                @include respond-to(mobile_strong_over) { // >= 768px
                    white-space: normal;
                }
            }
        }

        .bticons-user {
            font-size: rem(20);
            white-space: nowrap; // EDGE fix

            &::before {
                display: none;
            }

            span { // reset style
                display: inline;
                padding: 0;
                float: none;
                text-transform: none;
                letter-spacing: 0;
                font-size: inherit;
            }

            .path1 {
                color: $black01;

                &::before {
                    font-family: "icomoon-arnotts";
                    color: inherit;
                }
            }

            .path2 {
                color: transparent;

                &::before {
                    font-family: "icomoon-arnotts";
                    color: inherit;
                    position: absolute;
                }
            }
        }

        .user-info a:hover,
        .user-info.active a {
            .bticons-user {
                .path1 {
                    color: transparent;
                }

                .path2 {
                    color: $black01;
                }
            }
        }

        .user-account { // user logged in
            .bticons-user {
                .path1 {
                    color: transparent;
                }

                .path2 {
                    color: $black01;
                }
            }
        }
    }

    .utility-store {
        display: none;
    }

    .user-info {
        a {
            @include flexbox;

            &:hover,
            &:hover > span {
                color: $header-links-hover-color;
            }

            > span {
                color: $black01;
                font-size: rem(12);
                padding: 0 0 0 7px;
            }

            &:first-child {
                @include respond-to(mobile_strong) {
                    padding-left: 20px;
                    position: relative;
                    border-bottom: 1px solid $color-auxiliary10;
                }
            }
        }

        .user-panel { // tip for log out
            border-color: $color-secondary2;
            width: 175px;
            padding: 20px;

            &:before { // corner for tip
                left: auto;
                right: 52px;
                border-width: 5px;
                top: -10px;
            }

            &:after { // corner for tip
                left: auto;
                right: 51px;
                top: -12px;
                border-width: 6px;
                border-bottom-color: $color-secondary2;
            }

            @include respond-to(mobile_strong) {
                visibility: visible;
                border: 0;
                width: 100%;
                background: transparent;
                transition: none;
                padding: 0;
                position: static;

                &::before,
                &::after {
                    display: none;
                }
            }

            @include respond-to(mobile_strong_over) {
                top: 130%;
                left: auto;
            }

            .user-links {
                @include respond-to(mobile_strong) {
                    text-align: left;
                }

                a {
                    @include respond-to(mobile_strong) {
                        height: 50px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        font-family: $font-main-bold;

                        + a { // border for authorized user
                            position: relative;
                            border-radius: 0;
                            overflow: visible;

                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 10px;
                                height: 30px;
                                border-left: 1px solid $color-auxiliary10;
                            }
                        }
                    }

                    &:first-child {
                        @include respond-to(mobile_strong) {
                            @include icomoon-icon($aricons-user-path1, $font-size: rem(20)) {
                                margin-right: 10px;
                            }
                        }

                        &:hover::before {
                            @include respond-to(mobile_strong) {
                                content: $aricons-user-path2;
                            }
                        }
                    }
                }
            }

            a {
                text-transform: capitalize;
                font-family: $font-main;
                color: $black01;

                @include respond-to(mobile_strong) {
                    white-space: nowrap;
                    font-size: rem(12);
                    padding: 0;
                }

                @include respond-to(mobile_strong_over) {
                    padding: 0;
                    font-size: rem(12);
                    line-height: 32px;

                    @include icomoon-icon($aricons-tiny-arrow-right, $font-size: rem(7), $line-height: 18px) {
                        margin-right: 7px;
                    }
                }

                &:hover {
                    color: $header-links-hover-color;
                }
            }
        }
    }
}

li.header-services {
    font-size: rem(12);
    padding: 0;
    margin: 3px 0 0 25px;

    @include respond-to(tablet_regular) {
        margin-left: 15px;
    }

    @include respond-to(mobile_strong) {
        margin: 0;
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 30px;
    }

    .menu-category & {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    .content-asset {
        @include respond-to(mobile_strong) {
            width: 100%
        }
    }

    .header-services-link {
        @include icon(bell);

        position: relative;
        padding-left: 25px;

        &:hover {
            color: $black01;
        }

        &::before {
            font-size: rem(14);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        @include respond-to(mobile_strong) {
            width: 100%;
            display: block;
        }
    }
}
