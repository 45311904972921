.header-banner {
    display: block;
    text-align: center;
    position: relative;
    z-index: 5;

    .slot {
        color: $header-promo-banner-text-color;
        background-color: $header-promo-banner-bg;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        .slot-panels-container {
            margin-left: 0;
        }
    }

    .asset {
        display: inline-block;
        font-size: rem(10);
        line-height: 1;
        letter-spacing: 0.1rem;
        padding: 0 2rem;
        margin: 0.8rem 0;
        border-left: 1px solid $header-promo-banner-divider-color;

        &:first-child {
            border: none;
        }

        @include respond-to(mobile_strong) {
            display: none;

            &.mobile-primary {
                display: block;
                margin: 0 auto;
                padding: 0.8rem 2rem;
                border: none;
            }
        }
    }

    a {
        color: $header-promo-banner-link-color;
    }

    .main-title {
        display: none;
    }

    .header-promotion {
        padding: 0.5rem 0;
        background-color: $header-promo-banner-bg;
        color: $header-promo-banner-text-color;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-transform: uppercase;
    }
}

.js-close-category-menu {
    position: fixed;
    z-index: -1;
    opacity: 0;
    height: 6rem;
    left: 0;
    width: 100%;
    transform: translateX(0);

    @include respond-to(mobile_strong) {
        .menu-active & {
            display: block;
            position: fixed;
            z-index: 9999;
            opacity: 1;
            transform: translateX($header-nav-width-mobile);
            transition: transform 500ms ease;

            &:after {
                content: "\e901";
                position: absolute;
                z-index: 2;
                height: 6rem;
                top: 0;
                left: 0;
                right: 0;
                padding: 1.8rem;
                font-family: $font-icons !important;
                speak: none;
                font-size: rem(18);
                font-style: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: $header-nav-button-close-color;
                background-color: $header-nav-button-close-bg;
                cursor: pointer;

                &hover {
                    color: $header-nav-button-close-hover-color;
                }
            }

            span {
                display: none;
            }
        }
    }
}

.menu-active .is-sticky {
    .js-close-category-menu:after,
    .header-wishlist {
        display: none;
    }
}

.mini-cart-active .is-sticky {
    .primary-logo {
        display: none;
    }

    .close-minicart {
        right: 0;
        height: 60px;

        &:after {
            display: none;
        }
    }
}

.header-search-icon {
    display: none;
}

.header-search {
    display: block;
    position: relative;
    color: $header-search-text-color;
    width: 100%;
    padding: 0.8rem;
    background-color: $header-search-bg;
    border: 1px solid $header-search-border-color;
    border-left-width: 0;
    border-right-width: 0;

    @include respond-to(mobile_strong_over) {
        float: right;
        padding: 0;
        width: 20rem;
        z-index: 1;
        background-color: $header-search-bg-desktop;
        border: none;
    }

    .is-sticky & {
        top: auto;
    }

    form {
        @extend %clearfix;
        margin-bottom: 1px;
        position: relative;

        @include respond-to(mobile_strong) {
            height: 2.9rem;
        }
    }

    input[type="text"] {
        background-color: $header-search-field-bg;
        border: none;
        float: right;
        margin: 0;
        padding: 0.6rem 3rem 0.6rem 0.6rem;
        height: auto;
        font-size: rem(11);
        line-height: 1.6rem;
        width: $header-search-field-width-mobile;
        border: $header-search-field-border-width solid $header-search-field-border-color;
        border-radius: $header-search-field-border-radius;

        @include placeholder {
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            border-radius: $header-search-field-border-radius;
        }

        @include respond-to(mobile_strong_over) {
            background-color: $header-search-field-bg-desktop;
            width: $header-search-field-width;
            border: $header-search-field-border-width-desktop solid $header-search-field-border-color-desktop;
            border-radius: $header-search-field-border-radius-desktop;

            &:focus {
                width: $header-search-field-width-desktop;
            }

            @include placeholder {
                color: $nobel;
                border-radius: $header-search-field-border-radius-desktop;
            }
        }

        @include respond-to(mobile_stronger) {
            &:focus {
                width: $header-search-field-width-tablet;
            }
        }

        @include respond-to(mobile_strong) {
            position: absolute;
        }
    }

    button {
        position: absolute;
        z-index: 1;
        top: 0.6rem;
        right: 1.4rem;
        background-color: transparent;
        color: $header-search-button-color;
        padding: 0;
        width: auto;

        @include respond-to(mobile_strong_over) {
            right: 0.6rem;
        }

        &:hover {
            color: $header-search-button--hover-color;
        }
    }
}

.top-banner .header-search input[type="text"] {
    transition: width .4s ease-in-out;

    &:not(:focus) {
        text-transform: uppercase;
        letter-spacing: .1rem;

        @include respond-to(mobile) {
            color: $tutu;
        }
    }
}

.header-store {
    .toggle-content {
        display: none;
        position: absolute;
        top: 5rem;
        left: 0.8rem;
        width: 95%;
        border: 1px solid $charcoal;
        background-color: $white;

        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 6px;
            border-color: transparent transparent white transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -1.2rem;
            left: 1rem;
            z-index: 3;
        }

        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 7px;
            border-color: transparent transparent $charcoal transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -1.4rem;
            left: 0.9rem;
            z-index: 2;
        }

        @include respond-to(mobile_strong_over) {
            top: 3rem;
            left: 2rem;
            width: 200px;
        }

        &.expanded {
            display: block;
        }

        a {
            display: block;
            float: left;
            clear: both;
            padding: 0.8rem;
            font-size: rem(11);
            letter-spacing: 0.1rem;
            color: $mid-gray;

            @include respond-to(mobile_strong_over) {
                text-transform: uppercase;
            }
        }
    }
}

#header-wrapper .sticky-wrapper .containter-cart-wish-search.expanded,
#header-wrapper.sticky-wrapper .containter-cart-wish-search.expanded  {
    width: 100%;
}


.header-search.toggle-content {
    @include respond-to(mobile_strong_over) {
        display: block;
    }
}

.sticky-wrapper {
    height: auto !important;

    .header-search.toggle-content {
        display: block;
    }

    &.is-sticky {
        height: 143px !important;

        @include respond-to(touch) {
            height: 113px !important;
        }

        @include respond-to(mobile_strong) {
            height: 96px !important;
        }


        .header-search {
            position: absolute;
            top: 35px;
            float: none;
            width: 100%;
            padding: 0.8rem;
            background-color: $white-smoke;
            border: 1px solid $very-light-gray;
            border-left-width: 0;
            border-right-width: 0;

            @include respond-to(mobile_strong) {
                position: relative;
                top: 10px;
            }

            @include respond-to(mobile_stronger) {
                top: 70px;
            }

            &.toggle-content {
                display: none;

                @include respond-to(mobile_strong) {
                    display: block;
                }

                &.expanded {
                    display: block;
                }
            }

            form {
                position: relative;
                width: 42%;
                margin: 0 auto;

                @include respond-to(mobile_strong) {
                    width: auto;
                }
            }

            input[type="text"] {
                float: none;
                width: 100%;
                border: 1px solid $tutu;
                border-radius: 0.4rem;

                &::-webkit-input-placeholder {
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    border-radius: 0.4rem;
                }

                &::-moz-placeholder {
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    border-radius: 0.4rem;
                }

                &:-moz-placeholder {
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    border-radius: 0.4rem;
                }
            }
        }

        .top-banner {
            background-color: $nobel;
            z-index: $z-header-sticky;
            border-bottom: 1px solid $very-light-gray;

            @include respond-to(touch) {
                top: 0;
            }

            @include respond-to(m_mobile_down) {
                position: fixed !important;
                bottom: auto !important;
            }

            // iPad fix block
            @include respond-to(mobile_strong_over) {
                position: fixed;
                width: 100%;
                top: 0;
            }

            // end of iPad fix block

            @include respond-to(desktop_over) {
                height: auto;
            }

            &.is-sticky {
                position: fixed;
                top: 0;
            }

            .primary-logo {
                position: relative;
                font-size: rem(19);
                color: $white;
                transform: none;

                @include respond-to(mobile_min) {
                    font-size: rem(17);
                }

                @include respond-to(mobile_stronger) {
                    display: block;
                    font-size: rem(27);
                    text-align: center;
                }

                a {
                    color: $white;
                }
            }
        }

        .containter-cart-wish-search {
            @include respond-to(mobile_stronger) {
                position: absolute;
                top: 5px;
                width: 100%;
            }
        }

        .header-search-icon {
            position: relative;
            display: none;
            float: right;
            margin: 1.3rem 0 0 0rem;
            color: $white;

            @include respond-to(mobile_strong_over) {
                margin-top: 0.9rem;
                display: block;
                z-index: 3;
            }
        }

        .header-wishlist {
            a {
                color: $white;
            }
        }

        .mini-cart {
            .mini-cart-total,
            .mini-cart-total .mini-cart-link .minicart-quantity {
                color: $white;
            }
        }
    }
}

.is-sticky {
    right: 0 !important;
    margin: 0 !important;
}

/* Header cookies banner */
.header-cookies {
    background: $cirkon;
    position: relative;
    z-index: 9998;

    &-inner {
        max-width: 1180px;
        margin: 0 auto;
        padding: 30px;
        display: none;
        @include align-items(center);
        @extend %clearfix;

        &.visible {
            @include flexbox();
        }

        @include respond-to(mobile_tablet) {
            @include flex-vertical();
        }

        @include respond-to(mobile) {
            padding-bottom: 15px;
        }
    }

    &__left {
        float: left;
        width: 50%;

        @include respond-to(mobile_tablet) {
            width: 90%;
            text-align: center;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        h3 {
            font-size: rem(17);
        }

        p {
            margin: 8px 0 0 0;
            line-height: 18px;
            font-size: rem(14);
        }
    }

    &__right {
        display: flex;
        align-items: center;
        float: right;
        width: 50%;
        padding-left: 7%;

        @include respond-to(mobile_tablet) {
            width: 80%;
            padding-left: 0;
            text-align: center;
            margin-top: 30px;
        }

        @include respond-to(mobile) {
            flex-direction: column;
        }

        .button {
            flex: 1 0 auto;

            @include respond-to(tablet) {
                width: 250px;
            }

            @include respond-to(mobile) {
                width: 90%;
                margin-bottom: 10px;
            }
        }

        .link {
            margin-left: 10px;
            text-decoration: underline;
            color: $hel-gray2;

            @include respond-to(mobile) {
                margin-bottom: 10px;
            }
        }
    }
}

.ios8.no-desktop,
.ios7.no-desktop {
    #wrapper {
        position: relative;
        left: 0;
        transition: left 500ms ease; // initialize animation

        // CART SPECIFIC
        &.mini-cart-active {
            transform: none;
            left: -$minicart-mobile-width;
        }

        &:before,
        #main,
        .top-banner,
        .footer,
        .header-banner {
            transition: none;
        }

        .mini-cart-content{
            top: 0;
            left: auto;
            right: -$minicart-mobile-width;
            transition: right 500ms ease; // initialize animation
        }

        .js-close-minicart,
        .js-close-category-menu {
            display: block;
            right: 0;
            top: -2000px; // hide it but now with display:none to preserve animation
            transition: right 500ms ease; // initialize animation
        }

        &:before {
            display: block;
            transform: none;
            width: 100%;
            left: auto;
            right: 0;
            transition: right 500ms ease; // initialize animation
        }

        @include respond-to(mobile_strong_over) {
            .footer-links {
                position: fixed;
                width: 100%;
                left: auto;
                transition: right 500ms ease; // initialize animation
            }
        }

        &.mini-cart-active {
            .containter-cart-wish-search {
                z-index: 0;
            }

            #main,
            .top-banner,
            .footer,
            .header-banner {
                transform: none;
            }

            .mini-cart-content {
                right: 0;
            }

            .is-sticky .top-banner {
                height: 60px;

                .primary-logo {
                    display: none;
                }
            }

            .js-close-minicart {
                right: $minicart-mobile-width;
                top: 0;
                transform: none;
            }

            &:before  {
                right: $minicart-mobile-width;
                top: 60px;
            }

            @include respond-to(mobile_strong_over) {
                .footer-links {
                    right: $minicart-mobile-width;
                }
            }
        }

        .js-close-category-menu {
            position: fixed;
        }

        // MENU SPECIFIC
        @include respond-to(mobile) {
            .js-close-category-menu {
                width: 100%;
                left: auto;
            }

            #navigation {
                left: auto;
                top: 0;
            }

            &.menu-active {
                left: $header-nav-width-mobile;

                #main,
                .top-banner,
                .footer,
                .header-banner {
                    transform: none; // reset transform3d - we use left\right here
                }

                &::before {
                    right: -$header-nav-width-mobile;
                }

                .js-close-category-menu {
                    right: -$header-nav-width-mobile;
                    top: 0;
                }

                .is-sticky .js-close-category-menu {
                    &:before {
                        display: block;
                        content: "\e901";
                        width: 100%;
                        height: 6rem;
                        position: absolute;
                        top: 0;
                        padding: 1.8rem;
                        z-index: 9999;
                        font-size: rem(18);
                        color: $white;
                        background-color: $nobel;
                        cursor: pointer;
                        @include font-icomoon;
                    }
                }
            }
        }
    }
}

/* Workaround for https://jira.ontrq.com/browse/BTHSUP-452 on Brown Thomas.
   Plugin jquery.sticky.js makes wrong height calculation for header ('.js-header')
   on iPad and this adds wrong inline classes to header block (like "bottom: 0px;")
   which occurs expanding header to unexpected height on iPad on scrolling.
   This fix overlaps plugin's styles to make header workable on iPad.
 */
.ios.no-desktop .is-sticky .top-banner.is-tablet {
    position: fixed !important;
    top: 0 !important;
    bottom: auto !important;

    @include respond-to(print) {
        position: static !important;
    }
}

.noscroll .sticky-wrapper.is-sticky .top-banner { // tablet fix for noscroll (overwrite js)
    @include respond-to(mobile_strong_over) {
        position: static !important;
    }
}
