.mini-cart-total {
    position: relative;
    
    .mini-cart-link {
        color: $nobel;
        text-transform: uppercase;
        transition: color 500ms ease;
    }
    
    i {
        display: inline-block;
        font-size: rem(16);
        vertical-align: sub;
    }
    
    .minicart-quantity {
        color: $nobel;
        font-size: rem(11);
        letter-spacing: 0.1rem;
    }
}

.is-sticky {
    .mini-cart-link,
    .mini-cart-total .mini-cart-empty {
        color: $white;
    }
}

.mini-cart-total {
    .mini-cart-empty {
        color: $nobel;
    }
}

.mini-cart-content {
    background-color: $white;
    display: none;
    position: absolute;
    right: 0;
    width: $minicart-width;
    z-index: 1;

    .no-mobile & {
        top: 3rem;
        border: 1px solid $charcoal;

        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 0.6rem;
            border-color: transparent transparent $white transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -1.2rem;
            right: 1rem;
            z-index: 89;
        }

        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border: inset 0.7rem;
            border-color: transparent transparent $charcoal transparent;
            border-bottom-style: solid;
            position: absolute;
            top: -1.4rem;
            right: 0.9rem;
            z-index: 88;
        }
    }

    &.mini-cart-empty {
        width: auto;

        p {
            margin: 16px 20px;
            white-space: nowrap;
        }
    }
}

.mini-cart-header {
    font-size: rem(17.6);
    padding: 1.2rem 0;
    margin: 0 1.5rem;
    border-bottom: 1px solid $nobel;
    text-transform: uppercase;
}

.mini-cart-products {
    padding: 0;
    margin: 0 1.5rem;
    overflow: auto;

    @include respond-to(touch_over) {
        max-height: 285px;
    }
}

.mini-cart-product {
    border-bottom: 1px solid $very-light-gray;
    overflow: hidden;
    padding: 0.8rem 0;
    position: relative;
    font-size: rem(14);

    &:last-of-type {
        border: none;
    }

    @include respond-to(touch_over) {
        min-height: 13.5rem;
    }

    &.collapsed {
        .mini-cart-name,
        .mini-cart-attributes,
        .mini-cart-pricing,
        .mini-cart-offer,
        .product-availability-list {
            width: 90%;
        }

        .mini-cart-image {
            display: none;
        }
    }
}

.mini-cart-image {
    float: left;
    width: 30%;
    padding: 0 1.5rem;

    img {
        max-width: 100%;
    }
}

.mini-cart-data {
    padding: 0 0 15px;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .mini-cart-attributes,
    .mini-cart-qty {
        flex-basis: 100%;
    }

    .no-desktop .mini-cart-content & {
        padding: 0 0 0 1rem;
    }
}

.mini-cart-subtitle {
    width: 90%;
    text-transform: uppercase;
}
.mini-cart-name {
    width: 90%;
    margin-bottom: 1.2rem;
}

.mini-cart-attributes,
.mini-cart-product .product-availability-list {
    font-size: rem(12.6);
}

.mini-cart-attributes {
    clear: both;
}

.mini-cart-pricing {
    clear: right;
    font-size: rem(12);
    padding-right: 1.5rem;
    font-family: $font-main-bold;
    font-weight: 400;
}

.mini-cart-offer {
    clear: both;
    float: left;
    width: 100%;

    > a {
        @extend %clearfix;
        display: block;
        background-color: $white-smoke;
        padding: 0.8rem;
        text-align: center;

        .mini-cart-so-title {
            text-transform: uppercase;
        }

        .mini-cart-so-details-link {
            font-size: 80%;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
}

.mini-cart-totals {
    text-align: center;
}

.mini-cart-subtotals {
    @extend %clearfix;
    border-top: 1px solid $nobel;
    border-bottom: 1px solid $nobel;
    padding: 1.5rem;
    margin: 0 1.5rem;
    font-size: rem(14);

    .label {
        display: block;
        float: left;
    }

    .value {
        display: block;
        float: right;
    }
}

.mini-cart-slot {
    background-color: $white-smoke;
    font-size: rem(14);
    text-align: center;
    margin: 0 1.5rem 1.5rem 1.5rem;

    .slot-panels-container {
        margin: 0;
        padding: 1.2rem 1.5rem;
    }
}


/** minicart jCarousel **/

.minicart-carousel {

    /** Carousel **/
    .jcarousel {
        margin: 0;

        .mini-cart-product {
            width: 100% !important;
        }
    }

    /** Carousel Controls **/

    .jcarousel-prev,
    .jcarousel-next {
        position: absolute;
        width: 4.5rem;

        &.inactive {
            opacity: .5;
            cursor: default;
        }

        &:hover {
            span {
                display: block;
            }
        }
    }

    /** Carousel Pagination **/
    .jcarousel-pagination {
        display: none;
    }
}

.no-desktop {
    .is-sticky {
        .mini-cart-active & .mini-cart-content {
            &:before {
                display: block;
                content: "";
                width: 800px;
                height: 6rem;
                position: absolute;
                top: 0;
                left: -800px;
                padding: 1.8rem;
                z-index: 9999;
                font-size: rem(18);
                color: $white;
                background-color: $nobel;
                cursor: pointer;
                @include font-icomoon;
            }

            &:after {
                content: "\e901";
                position: absolute;
                color: $white;
                left: -36px;
                display: block;
                top: 18px;
                width: 20px;
                height: 20px;
                z-index: 10000;
                font-size: rem(18);
                @include font-icomoon;
            }
        }
    }

    .mini-cart-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .mini-cart-content {
        width: $minicart-mobile-width;

        @include respond-to(mobile) {
            height: auto !important;
        }

        &.mini-cart-empty {
            > p {
                text-align: center;
            }
        }

        &:before {
            display: none;
        }

        .carousel.carousel-vertical .jcarousel-list {
            height: auto;
        }

        .mini-cart-pricing {
            padding: 0.5rem 0 1rem;
            width: auto;
        }
    }

    .mini-cart-image {
        padding: 0;
    }

    .mini-cart-data {
        width: 70%;
    }

    .mini-cart-pricing {
        text-align: right;
        width: 70%;
        padding: 0.5rem 2rem 1rem 0;
    }
}

.js-close-minicart {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;

}

.mini-cart-promos {
    clear: both;
    text-align: center;
    padding-top: 10px;

    .mini-cart-promo {
        padding: 5px;
        margin-bottom: 10px;
        background: $second-light-gray;
    }

    .promotion-title {
        display: inline;
        text-transform: uppercase;
    }

    .promotion-details {
        text-decoration: underline;
    }
}

.error.mini-cart-error {
    border: 1px solid $red01;
    margin: 0 15px 20px;
    padding-left: 20px;
}