#secondary {
    &.secondary-content {
        &.refinements {
            width: 23%;
            max-width: 100%;

            @include respond-to(m_tablet_down) {
                width: 100%;
            }
        }
    }
}

.search-result-options__controls {
    @extend %clearfix;

    float: left;

    @include respond-to(m_tablet_down) {
        width: 76%;
        position: relative;
        border-right: 1px solid $lighter-gray;
    }
}

.refinement-header-products-count {
    display: none;
}

.refinement-header-box {
    + .refinement {
        h3 {
            margin-top: 0;

            &::before {
                content: '';
                background-color: inherit;
                position: absolute;
                height: 10px;
                bottom: 100%;
                left: 0;
                right: 0;
            }
        }
    }

    .refinement-header-category-name,
    .refined-clear-all__icon,
    .refinement-header--desktop {
        display: none;
    }

    &__inner {
        border-bottom: 1px solid $color-primary2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        @include respond-to(m_tablet_down) {
            padding: 24px 15px;
        }
    }

    .refined-clear-all__button {
        color: $hel-gray;
    }
}

.refinements {
    h3.toggle::after {
        content: '';
        position: absolute;
        border: solid $nobel;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(-135deg) translateX(-50%);

        @include respond-to(m_desktop) {
            right: 23px;
            top: calc(50% - 3px);
        }
    }

    .hidden-refinement {
        h3.toggle::after {
            @include respond-to(m_tablet_down) {
                top: calc(50% - 4px);
                right: 23px;
                transform: rotate(-45deg) translateX(-50%);
            }

            @include respond-to(m_desktop) {
                top: calc(50% - 2px);
                right: 20px;
                transform: rotate(45deg) translateX(-50%);
            }
        }

        .clear-refinement,
        .refinement-view-type {
            display: none;
        }
    }

    .refinements__list-item {
        margin: 0 15px;
        padding: 7px 0;
    }

    .refinements__item {
        &.hidden-refinement {
            .refinements__content {
                display: none;
            }
        }
    }

    &-no-result {
        padding-bottom: 0;

        #category-level-1 li.expandable a.refinement-link {
            padding-left: 0;
        }

        h3 {
            border-top: 1px solid $gainsboro;

            &:first-child {
                border-top: 0;
            }
        }

        .toggle.expanded:after {
            content: '+';
            border-bottom: 0;
            margin-top: -15px;
        }
    }

    .category-banner img {
        max-width: 175px;
        width: 100%;
        margin: 20px 0;;
    }
}

.refinement {
    @extend %clearfix;
    position: relative;
    border-bottom: 1px solid $gainsboro;

    &-item {
        display: block;
        position: relative;
        padding-left: 35px;
        font-size: rem(12);
        color: $nero2;
        text-transform: capitalize;

        &::before {
            width: 16px;
            height: 16px;
            position: absolute;
            content: '';
            top: 1px;
            left: 0;
            border: 2px solid $black01;
            border-radius: 3px;
            z-index: 1;
        }

        &:hover {
            color: $black01;
        }

        .match {
            color: $black01;
        }

        .unselectable & { // Filters Not Available for Selection
            color: $color-secondary2;
            cursor: default;

            &:hover {
                color: $color-secondary2;
            }

            &::before {
                border-color: $color-secondary2;
            }
        }
    }

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;

        &.category-refinement {
            display: block;
        }
    }

    h3 {
        position: relative;
        font-size: rem(14);
        color: $nero2;
        padding: 15px;
        transition: color .2s ease-in-out;

        @include respond-to(m_tablet_down) {
            color: $nero2;
        }

        @include respond-to(m_desktop) {
            font-family: $font-main;
        }
    }

    &.filtered-refinement-item {
        h3 {
            @include respond-to(m_desktop) {
                font-family: $font-main-bold;
                color: $black01;
            }
        }
    }

    ul {
        list-style: none outside none;
        margin: 0;
        padding-bottom: 10px;

        li {
            i {
                color: $gainsboro;
                padding-right: 6px;
            }
        }

        &.display-refinement-color-swatches {
            li a {
                &.plain-refinement-color-swatch:before {
                    position: absolute;
                    content: '';
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: $checkbox-size;
                    height: $checkbox-size;
                    border: 1px solid $checkbox-border-color;
                    vertical-align: middle;
                }

                .refinement-swatch,
                img {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: $checkbox-size;
                    height: $checkbox-size;
                    border: 1px solid $checkbox-border-color;
                    vertical-align: middle;
                }
            }

            .selected .refinement-item {
                &:before {
                    content: '';
                    position: absolute;
                    left: 5px;
                    margin-top: 3px;
                    width: 6px;
                    height: 10px;
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    box-shadow: 2px 2px 0 $mid-gray;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    background-color: transparent;
                    z-index: 1;
                }

                &.plain-refinement-color-swatch:before {
                    margin-top: 2px;
                }
            }
        }
    }

    .clear-refinement {
        display: none;
        text-decoration: underline;
        font-size: rem(14);
        padding-top: 22px;

        &:hover {
            text-decoration: none;

            a {
                color: $nobel;
            }
        }

        a {
            color: $dim-gray;
        }
    }

    .refinement-top-level {
        padding: 1em;
    }
}

.selected {
    .refinement-item {
        font-family: $font-main-bold;

        &::before {
            background: $black01;
        }

        &::after {
            content: '';
            width: 6px;
            height: 11px;
            position: absolute;
            top: 2px;
            left: 5px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            z-index: 1;
        }
    }
}

.pricerange-inputs {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        width: 20px;
        top: 50%;
        height: 1px;
        background: $sections-header-border;
        transform: translate(-50%, -50%);
    }

    .pricerange-input-min,
    .pricerange-input-max {
        position: relative;
        width: calc(50% - 15px);
    }

    .pricerange-input-min {
        float: left;
    }

    .pricerange-input-max {
        float: right;
    }

    .currency-symbol {
        position: absolute;
        top: 50%;
        left: 9px;
        font-size: rem(14);
        line-height: 42px;
        color: $dim-gray;
        transform: translateY(-50%);
    }

    input[type="number"] {
        padding-left: 20px;
        padding-right: 10px;
        font-size: rem(14);
        color: $dim-gray;
        border-color: $sections-header-border;
    }
}

// Price section (range slider)
.search-refinement-price-range {
    .ui-widget.ui-slider-horizontal {
        height: 3px;

        @include respond-to(desktop_plus) {
            margin: 0 10px;
        }
    }

    .ui-slider-handle {
        background: radial-gradient(circle at center center, $color-primary2 49%, $white 50%);
        border: 1px solid #bcbcbc;
        padding: 3px;
        border-radius: 50%;
        position: relative;
        width: 25px !important;
        height: 25px !important;
        top: -12.5px !important;
        display: inline-block;
        margin-left: -10px;

        @include respond-to(touch_over) {
            background: radial-gradient(circle at center center, $color-primary2 49%, $white 55%);

            .chanel-section & {
                background: radial-gradient(circle at center center, $black01 49%, $white 55%);
            }
        }

        &:last-child {
            margin-left: -30px;
        }

        .chanel-section & {
            background: radial-gradient(circle at center center, $black01 49%, $white 55%);
        }
    }

    .ui-slider {
        background-color: #bcbcbc;
    }

    .ui-slider-range {
        background-color: $color-primary2;

        .chanel-section & {
            background-color: $black01;
        }
    }
}

// Refinements control buttons
.refinements-control-buttons {
    width: 100%;
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    border-top: 1px solid $gainsboro;
    z-index: 3;

    @include respond-to(m_tablet_down) {
        display: block;
    }

    ~ .loader {
        @include respond-to(mobile_top) {
            z-index: 4;
        }
    }

    .button {
        padding-left: 0;
        padding-right: 0;
        font-size: rem(14);

        &.outlined {
            min-width: 0;
            margin: 0;
        }
    }

    .refinements-clearall-button {
        color: $black01;

        &:hover,
        &:active {
            background-color: transparent;
            border-color: $color-secondary2;
            color: $black01;
        }
    }
}

.search-refinements-inner {
    @include respond-to(m_tablet_down) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: calc(100% - 95px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.search-refinement {
    @include respond-to(m_tablet_down) {
        border-bottom: 1px solid $lighter-gray;
    }

    .refined-clear-all--horizontal {
        display: none;
    }
}

.search-refinements-container {
    &__close {
        @include respond-to(m_tablet_down) {
            width: 1500px;
            height: 100%;
            position: fixed;
            z-index: 1;
        }
    }

    .loader .loader-indicator {
        @include respond-to(mobile) {
            display: none !important;
        }
    }

    .refinement {
        transition: max-height .3s ease;
        max-height: 25000px; // to avoid cropping long list
        overflow: hidden;

        ul {
            transition: max-height .3s ease, margin-top .3s ease, margin-bottom .3s ease;
        }

        &.hidden-refinement {
            max-height: 100px;

            ul {
                max-height: 0;
                margin-top: 0;
                margin-bottom: 0;
                overflow: hidden;
                padding-bottom: 0;
            }
        }
    }
}

.refined-clear-all {
    display: inline-block;
    font-size: rem(12);

    .refined-clear-all__icon {
        display: none;
    }
}

.refinement-header-return-link {
    display: flex;
    align-items: center;
    font-size: rem(12);
    color: $hel-gray;
    padding: 10px;
    border-bottom: 1px solid $color-auxiliary28;

    .ref-level-0 & {
        display: none;
    }

    .bticons-left {
        @include icon(arrow-left);

        margin-right: 5px;
    }
}

.selected-refinements {
    @include respond-to(m_desktop) {
        display: none;
    }

    &__items {
        font-family: $font-main;

        @include respond-to(m_tablet_down) {
            font-size: rem(12);
            line-height: normal;
            color: $nero2;
            margin-top: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &_empty {
            color: $hel-gray;
        }
    }
}

.search-refinement-item-tools {
    &-wrapper {
        display: inline-block;
        vertical-align: middle;

        @include respond-to(m_tablet_down) {
            width: 72%;
        }
    }

    &-clean {
        font-size: rem(11);
        font-family: $font-main;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);

        @include respond-to(desktop_plus) {
            display: none;
        }

        .toggle & {
            display: none;
        }
    }

    &-name {
        @include respond-to(m_tablet_down) {
            text-transform: uppercase;
        }
    }

    &-counter {
        margin-left: 5px;

        &.search-refinement-item-tools-selected-pricerange {
            font-size: rem(14);

            @include respond-to(m_tablet_down) {
                font-size: rem(0);
            }
        }
    }
}

.refinement,
.refinements__item {
    @extend %clearfix;

    position: relative;

    @include respond-to(m_tablet_down) {
        border-bottom: 1px solid $lighter-gray;
    }

    @include respond-to(desktop_plus) {
        &:last-child {
            border-bottom: none;
        }
    }

    &:not(.hidden-refinement) {
        .search-brand-refinements-container,
        .search-refinement-price-range {
            max-height: 300px;
            opacity: 1;
            visibility: visible;
        }

        .search-brand-refinements-container {
            margin: 15px 15px 26px;
        }

        .search-refinement-price-range {
            margin-bottom: 26px;
        }
    }

    .search-brand-refinements-container,
    .search-refinement-price-range {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
    }

    .search-brand-refinements-container {
        position: relative;
        overflow: hidden;

        &::after {
            @include inline-svg-mono($brand-search, $black01);

            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            top: 10px;
            right: 18px;
        }
    }

    .search-brand-refinements-nomatch {
        display: block;
        font-size: rem(12);
        padding: 0 15px;
    }

    .search-brand-refinements-filter {
        padding: 10px 35px 10px 17px;
        font-family: $font-main-bold;
        font-size: rem(10);
        text-transform: uppercase;
        border-color: $ghost;
        border-radius: 18px;
        background-color: $light-gray;

        @include placeholder {
            color: $black01;
        }
    }

    .search-brand-refinements-close {
        cursor: pointer;
        position: absolute;
        right: 18px;
        top: 50%;
        font-size: rem(14);
        padding: 2px;
        background: $light-gray;
        transform: translateY(-50%);
        z-index: 1;
    }

    .search-brand-refinements-filter:hover + .search-brand-refinements-close {
        background: $white;
    }

    .search-brand-refinements-filter:focus + .search-brand-refinements-close {
        background: $field-focus-bg;
    }

    .scrollable-refinement {
        max-height: 197px;
        margin: 15px 0;
        padding-bottom: 0;
        overflow: auto;

        @include respond-to(m_tablet_down) {
            max-height: none;
            margin: 15px 0 0;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: $requirements-scrollbar-track-color;
            border: 1px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background: $requirements-scrollbar-color;
            border: 1px solid $requirements-scrollbar-color;

            .chanel-section & {
                background: $black01;
                border-color: $black01;
            }
        }
    }

    .scrollable-refinement {
        &::-webkit-scrollbar-track {
            background: $color-secondary3;
            border: 1px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-primary2;
            border: 1px solid rgba($color-primary2, 0.7);

            .chanel-section & {
                background: $black01;
                border-color: $black01;
            }
        }
    }

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;

        &.category-refinement {
            display: block;
        }
    }

    h3 {
        position: relative;
        font-size: rem(14);
        color: $nero2;
        transition: color .2s ease-in-out;

        @include respond-to(m_tablet_down) {
            padding: 11px 15px;
        }

        @include respond-to(m_desktop) {
            font-family: $font-main;
            padding: 15px;
        }
    }

    &.filtered-refinement-item {
        h3 {
            @include respond-to(m_desktop) {
                font-family: $font-main-bold;
                color: $black01;
            }
        }
    }

    ul {
        list-style: none outside none;
        margin: 0;
        padding-bottom: 10px;

        li {
            i {
                color: $gainsboro;
                padding-right: 6px;
            }
        }

        &.display-refinement-color-swatches {
            li a {
                &.plain-refinement-color-swatch:before {
                    position: absolute;
                    content: '';
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: $checkbox-size;
                    height: $checkbox-size;
                    border: 1px solid $checkbox-border-color;
                    vertical-align: middle;
                }

                .refinement-swatch,
                img {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: $checkbox-size;
                    height: $checkbox-size;
                    border: 1px solid $checkbox-border-color;
                    vertical-align: middle;
                }
            }

            .selected .refinement-item {
                &:before {
                    content: '';
                    position: absolute;
                    left: 5px;
                    margin-top: 3px;
                    width: 6px;
                    height: 10px;
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    box-shadow: 2px 2px 0 $mid-gray;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    background-color: transparent;
                    z-index: 1;
                }

                &.plain-refinement-color-swatch:before {
                    margin-top: 2px;
                }
            }
        }
    }

    .clear-refinement {
        display: none;
        text-decoration: underline;
        font-size: rem(14);
        padding-top: 22px;

        &:hover {
            text-decoration: none;

            a {
                color: $nobel;
            }
        }

        a {
            color: $dim-gray;
        }
    }

    .refinement-top-level {
        padding: 1em;
    }
}

// Vertical refinements
.vertical-wrapper {
    .search-refinement {
        @include respond-to(m_desktop) {
            display: none;
        }
    }

    .search-refinements-container {
        @include respond-to(m_desktop) {
            height: auto !important;
            padding-top: 30px;
        }
    }

    .refinement,
    .refinements__item {
        @include respond-to(m_tablet_down) {
            height: 100%;
        }
    }
}

// Horizontal refinements
.horizontal-wrapper {
    @include respond-to(m_desktop) {
        .search-refinement-item-tools-counter {
            display: none;
        }

        .js-search-refinements-container::before {
            content: "horizontal"; // this is a marker for JS to let it know that horizontal refinements rendered
            display: none;
        }
    }

    .search-refinements-inner {
        @include respond-to(m_tablet_down) {
            display: flex;
            flex-direction: column;

            .refinement-header-box {
                order: 1;
            }

            .search-refinement {
                order: 2;
            }

            .refinements__wrapper {
                order: 3;
            }
        }
    }

    .sticky-refinements {
        @include respond-to(m_desktop) {
            position: fixed;
            top: 117px; // height of sticky header
            left: 0;
            right: 0;
            padding: 0 15px;
            background-color: $white;
            z-index: 10;
        }

        .refinements__wrapper,
        .search-refinement {
            @include respond-to(m_desktop) {
                max-width: 1230px;
            }
        }

        .refinements__wrapper {
            @include respond-to(m_desktop) {
                margin: 0 auto;
            }
        }

        .search-refinement {
            @include respond-to(m_desktop) {
                margin: 0 auto 20px;
            }
        }
    }

    #secondary.secondary-content.refinements {
        @include respond-to(m_desktop) {
            width: 100%;
            max-width: 1230px;
        }
    }

    .primary-content {
        @include respond-to(m_desktop) {
            width: 100%
        }
    }

    .refinements__wrapper {
        @extend %clearfix;

        @include respond-to(m_desktop) {
            position: relative;
        }

        &::before {
            @include respond-to(m_desktop) {
                content: '';
                width: 100vw;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-top: 1px solid $lighter-gray;
                border-bottom: 1px solid $lighter-gray;
            }
        }
    }

    .ref-level-1 { // Add height ONLY when refinement is openeded
        .refinements__item {
            @include respond-to(m_tablet_down) {
                height: 100%;
            }
        }
    }

    .refinements__item {
        @include respond-to(m_desktop) {
            position: relative;
            width: 16.66666%;
            float: left;
        }

        &:nth-child(6n) {
            .refinements__content {
                @include respond-to(m_desktop) {
                    right: 0;
                }
            }
        }

        &:nth-child(6n+1) {
            h3 {
                @include respond-to(m_desktop) {
                    border-left: 1px solid $lighter-gray;
                }
            }
        }

        .scrollable-refinement {
            @include respond-to(m_desktop) {
                margin-top: 0;
            }
        }
     }

    h3.toggle {
        @include respond-to(m_desktop) {
            height: 60px;
            font-size: rem(12);
            color: $hel-gray;
            text-transform: uppercase;
            padding: 21px 30px 19px;
            border-left: 1px solid transparent;
            border-top: 1px solid transparent;
            border-right: 1px solid $lighter-gray;
            border-bottom: 1px solid $lighter-gray;
        }

        &.expanded {
            @include respond-to(m_desktop) {
                color: $black01;
                border: 1px solid $dim-gray;
                border-bottom: 1px solid transparent;
            }

            &::before {
                @include respond-to(m_desktop) {
                    content: '';
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 55px;
                    border: 3px solid $white;
                    z-index: 10;
                }
            }
        }
    }

    .refinements__content {
        @include respond-to(m_desktop) {
            width: 200%;
            position: absolute;
            top: 59px;
            padding: 20px 15px 25px;
            background-color: $white;
            border: 1px solid $dim-gray;
            z-index: 6;
        }
    }

    .search-refinement {
        @include respond-to(m_desktop) {
            padding: 30px 0 0;
        }
    }

    .refined-clear-all {
        @include respond-to(m_desktop) {
            margin-left: 10px;
            padding: 10px 12px 10px 10px;
            color: $hel-gray;
        }
    }

    .refinement-header-box,
    .refinement-header-return-link,
    .refinement-header-category-name,
    .refinement-header,
    .refinement-bottom,
    .refinement-header--desktop,
    .refined-clear-all--vertical-and-mobile {
        @include respond-to(m_desktop) {
            display: none;
        }
    }

    .search-refinement {
        .refined-clear-all--horizontal {
            @include respond-to(m_desktop) {
                display: block;
            }
        }
    }
}

// Opened refinement on devices
.ref-level-1 {
    @include respond-to(m_tablet_down) {
        h3 {
            border-bottom: 1px solid $color-primary2;
        }

        .refinement-header-box__inner,
        .search-refinement,
        .selected-refinements__items,
        .search-refinement-item-tools-counter {
            display: none;
        }

        .refinement,
        .refinements__item {
            border-bottom: 0;
        }

        .search-refinement-price-range {
            padding: 30px 15px;
        }

        .search-brand-refinements-container ~ .scrollable-refinement {
            height: calc(100% - 60px); // height of sibling elements + margins
        }

        .scrollable-refinement {
            height: 100%;
        }

        .refinements__wrapper {
            height: calc(100% - 37px); // height of sibling elements + margins
        }

        .refinements__content {
            height: calc(100% - 65px); // height of sibling elements + margins
        }
    }
}

.divisions-ribbon {
    text-align: center;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

    &-wrapper {
        position: relative;
    }

    @include respond-to(tablet_plus) {
        padding: 25px 0 15px;
    }

    @include respond-to(m_tablet_down) {
        padding: 20px 0px;
        width: 105%;
        white-space: nowrap;
        overflow-x: auto;
    }

    .division-item {
        margin-right: 10px;
        padding: 10px 12px 10px 15px;
        font-size: rem(10);
        font-family: $font-main-bold;
        text-transform: uppercase;
        border: 1px solid $lighter-gray;
        border-radius: 20px;
        display: inline-block;
        vertical-align: middle;

        @include respond-to(tablet_plus) {
            margin-bottom: 10px;
        }

        &:hover {
            border-color: $dim-gray;
            color: $black01;
        }

        &.selected {
            background-color: $black01;
            color: $white;
        }
    }

    &.show-all .show-more {
        display: none;
    }

    .show-more {
        @include link_underline($medium-blue);

        @include respond-to(tablet_plus) {
            display: inline-block;
            vertical-align: super;
        }

        @include respond-to(m_tablet_down) {
            display: none;
        }
    }

    &:not(.show-all) {
        .division-item:nth-of-type(n + 5) { // "More" link - shown on desktop only if there are more than 4 divisions
            @include respond-to(tablet_plus) {
                display: none;
            }
        }
    }

    .left-chevron,
    .right-chevron {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 20px;
    }

    .right-chevron {
        @include icon(simple-arrow-right);

        right: -20px;
        background: linear-gradient(to right, rgba($white,0) 0%,rgba($white,1) 100%);
    }

    .left-chevron {
        @include icon(simple-arrow-left);

        left: -20px;
        background: linear-gradient(to left, rgba($white,0) 0%,rgba($white,1) 100%);
    }

    &.scrollable {
        .left-chevron,
        .right-chevron {
            display: block;
        }

        &.at-begin {
            .left-chevron {
                display: none;
            }
        }

        &.at-end {
            .right-chevron {
                display: none;
            }
        }
    }
}

// No results popup
.no-refinements-hits {
    text-align: center;
}
