body {
    .ui-dialog {
        border: none;

        button {
            overflow: hidden; // hide transform: scale for ripple effect on buttons
        }

        .ui-dialog-titlebar-close {
            &::before {
                content: $aricons-close;
                font-size: rem(26);
            }

            &:hover {
                &::before {
                    color: $color-primary2;
                    transition: color .3s;
                }
            }
        }

        .ui-dialog-buttonset {
            button {
                @extend button.coloured;
            }
        }

        .choice-of-bonus-products-actions {
            .add-to-cart-bonus {
                @extend button.coloured;
            }

            button:not(.add-to-cart-bonus) {
                @include button-type(1);
            }

            button.button-fancy-medium {
                min-width: 200px;
            }
        }
    }
}
