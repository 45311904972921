.bottom-banner-container {
    overflow: hidden;

    .bottom-banner-cell {
        float: left;
        overflow: hidden;
        text-align: center;
    }

    .banner-cell-1 {
        width: 33%;
    }

    .banner-cell-2 {
        width: 34%;
    }

    .banner-cell-3 {
        width: 33%;
    }
}

.column-left-sidebar {
    @extend %clearfix;
}

.category-banner-name {
    display: none;

    .column-left-sidebar & {
        display: block;

        h1 {
            font-size: rem(18);
            margin-bottom: 8px;
        }
    }
}

.cat-landing-slotbanner {
    .chanel-banner {
        @include respond-to(mobile) {
            margin-top: 15px;
        }
    }
}

.product-brand-slotbanner,
.cat-landing-slotbanner {
    clear: both;

    > img {
        width: 100%;
        margin-bottom: 30px;

        @include respond-to(mobile) {
            margin-bottom: 15px;
        }
    }

    div,
    a {
        display: block;
        border: 1px solid $very-light-gray;
        padding: 40px;
        margin-bottom: 30px;

        @include respond-to(mobile) {
            margin-bottom: 15px;
        }

        img {
            display: block;
            max-width: 240px;
            margin: 0 auto;
        }

        &.chanel-banner {
            padding: 0;
            font-family: $sans-serif;

            img {
                width: 100%;
                max-width: 100%;
            }

            @include respond-to(mobile) {
                background: $black01;

                img {
                    max-width: 200px;
                    margin: 0 auto;
                }
            }
        }
    }

    &.clp-top-branding {
        @include out-of-wrapper;
        position: relative;

        img {
            display: block;
            width: 100%;
        }

        a {
            display: block;
            position: absolute;
            z-index: 1;
            width: 300px;
            margin-left: -150px;
            left: 50%;
            bottom: 50px;
            border: none;
            padding: 10px 0;
            color: $white;
            font-size: rem(18);
            text-align: center;
            text-transform: uppercase;

            &:after {
                content: "\e902";
                position: absolute;
                top: 35px;
                left: 50%;
                margin-left: -15px;
                height: 16px;
                width: 30px;
                font-size: rem(20);
                font-family: $font-icons !important;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            &.clp-anchor {
                visibility: hidden;
                bottom: 140px;
                left: 0;
                margin: 0;
                padding: 0;

                &:after {
                    content:"";
                }
            }
        }
    }

    &.full-page-content {
        margin: 0;
        padding: 0;
        @include full-page-content;
    }
}

.primary-clp {
    .column-left-sidebar & {
        float: right;
        width: 78%;

        @include respond-to(mobile) {
            float: none;
            width: 100%;
        }
    }

    .slot,
    .slot.slot-panels {
        margin-bottom: 65px;

        @include respond-to(mobile) {
            margin-bottom: 15px;
        }

        &.html-slot-container {
            &.cat-landing-slotbanner {
                div {
                    padding: 40px 0;
                    border: 1px solid $very-light-gray;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 200px;
                }

                > img {
                    max-width: 100%;
                }
            }
        }

        &.slot-carousel {
            text-align: center;

            > .button {
                margin-top: 30px;
            }
        }

        &.cat-blocks-carousel {
            padding: 30px 20px 50px 20px;
            background-color: $white-smoke;

            .carousel {
                .jcarousel-nav {
                    background: none;
                }

                .jcarousel-prev {
                    left: -11px;
                }

                .jcarousel-next {
                    right: -11px;
                }

                &.carousel-collection .carousel-item {
                    @include respond-to(mobile) {
                        padding: 0 25px;
                    }
                }
            }

            .carousel-item {
                padding: 0 15px;

                .category-image {
                    display: block;
                    width: 100%;
                }

                h2 {
                    display: block;
                    font-size: rem(20);
                    padding: 20px 20px 10px 20px;
                    background-color: $white;
                }

                .category-description {
                    padding: 0 20px;
                    color: $hel-gray;
                    background-color: $white;
                    word-wrap: break-word;
                    overflow: auto;
                }

                .category-shop-link {
                    background-color: $white;

                    a {
                        @include link_underline($medium-blue);

                        display: inline-block;
                        margin: 20px;
                        font-size: rem(13);
                        text-transform: uppercase;
                    }
                }
            }

            &.cat-landing-single {
                padding: 0;
                background-color: transparent;

                .carousel {
                    .jcarousel-nav {
                        background: rgba(255, 255, 255, 0.8);
                        margin: 0;
                        padding: 14px 18px;
                        top: auto;
                        bottom: 5px;
                        font-size: rem(12);
                        color: $black01;

                        &:hover {
                            color: $dim-gray;
                        }

                        @include respond-to(mobile) {
                            bottom: 50%;
                            margin-bottom: -20px;
                        }
                    }

                    .jcarousel-prev {
                        left: 50%;
                        margin-left: -45px;

                        @include respond-to(mobile) {
                            left: 0;
                            margin-left: 0;
                        }
                    }

                    .jcarousel-next {
                        right: 50%;
                        margin-right: -45px;

                        @include respond-to(mobile) {
                            right: 0;
                            margin-right: 0;
                        }
                    }
                }

                .carousel-item {
                    padding: 0;
                    position: relative;

                    h2,
                    .category-description {
                        display: none;
                    }

                    .category-shop-link {
                        background-color: transparent;

                        a {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: block;
                            border: none;
                            margin: 0;
                            color: transparent;
                        }
                    }
                }
            }
        }

        &.chanel-carousel {
            padding: 0;
            background-color: transparent;

            .carousel {
                .jcarousel-nav {
                    margin: 0;
                    top: auto;
                    bottom: 50%;

                    @include respond-to(mobile) {
                        bottom: 50%;
                        margin-bottom: -20px;
                    }
                }

                .carousel-item {
                    padding: 0;
                    position: relative;

                    h2 {
                        font-size: rem(16);
                        text-align: center;
                    }

                    .category-description {
                        display: none;
                    }

                    .category-shop-link {
                        background-color: transparent;

                        a {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: block;
                            border: none;
                            margin: 0;
                            color: transparent;
                        }
                    }
                }
            }
        }
    }

    .slot-panels-container {
        @include respond-to(mobile) {
            @include flexbox();
            @include flex-vertical();
        }

        .first-asset {
            float: left;
            width: 50%;
            padding-right: 10px;

            @include respond-to(mobile) {
                float: none;
                width: 100%;
                padding: 0 0 24px 0;
                margin-top: 20px;
                @include order(2);
            }

            .category-name,
            .category-description {
                display: block;
                text-align: center;
                margin-bottom: 20px;
                line-height: 1.6;
            }

            .category-name {
                font-size: rem(36);
                margin-bottom: 10px;
                line-height: 1;
            }

            .category-online-subcategories {
                @extend %clearfix;
                width: 70%;
                margin: 0 auto;

                > a {
                    display: block;
                    width: 100%;
                    margin-bottom: 2px;
                    padding: 6px 5px;
                    font-size: rem(13);
                    line-height: 18px;
                    text-transform: none;
                    letter-spacing: normal;
                }
            }
        }

        .clp-secondary-assets {
            float: right;
            width: 49%;
            margin-left: 1%;

            @include respond-to(mobile) {
                float: none;
                width: 100%;
                margin-left: 0;
                @include order(1);
            }

            .carousel-list {
                position: relative;
                width: 20000em;

                @include respond-to(touch) {
                    max-width: 20000em;
                }
            }

            .carousel-item {
                position: relative;
                float: left;
            }

            img {
                width: 100%;
            }
        }
    }

    .clb {
        .slot-panels-container {
            @include out-of-wrapper;
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: $white-smoke;

            .column-left-sidebar & {
                margin: 0 0 65px 0;
                padding: 20px 10px;
                box-sizing: border-box;

                @include respond-to(mobile) {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .clb-cta-two-column {
        .slot-panels-container {
            align-items: center;
            display: flex;

            .first-asset {
                float: none;

                .category-description {
                    margin: 0 auto 20px;
                    width: 100%;
                }

                .category-online-subcategories {
                    width: 90%;

                    @include respond-to(mobile) {
                        width: 100%;
                    }

                    > a {
                        @include text-truncate;
                        float: left;
                        width: 49.5%;

                        &:nth-child(even) {
                            float: right;
                        }

                        @include respond-to(mobile) {
                            width: 100%;
                            float: none;
                        }
                    }
                }
            }

            .clp-secondary-assets {
                float: none;
            }
        }
    }

    .clb-cta-narrow {
        .slot-panels-container {
            .first-asset {
                .category-online-subcategories {
                    > a {
                        padding: 6px 22px;
                        font-size: rem(13);
                    }
                }
            }
        }
    }

    .featured-tabs.ui-tabs {
        margin-bottom: 65px;
        text-align: center;

        @include respond-to(mobile) {
            margin-bottom: 15px;
        }

        .carousel + .button{
            margin-top: 30px;
        }

        body & {
            .ui-tabs-nav {
                height: auto;
                border-bottom: 1px solid $very-light-gray;
                text-align: center;

                li {
                    display: inline-block;
                    position: relative;
                    float: none;
                    margin: 0;
                    padding: 0;
                    border: none;

                    .ui-tabs-anchor {
                        display: block;
                        float: none;
                        padding: 12px 8px;
                        text-transform: uppercase;
                    }

                    &.ui-state-active {
                        border: none;

                        &:before,
                        &:after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-color: transparent;
                            border-bottom: 0;
                            z-index: 10;
                        }

                        &:after {
                            bottom: -10px;
                            left: 50%;
                            margin-left: -10px;
                            border-top-color: $white;
                            border-width: 10px;
                        }

                        &:before {
                            bottom: -11px;
                            left: 50%;
                            margin-left: -11px;
                            border-top-color: $very-light-gray;
                            border-width: 11px;
                        }
                    }
                }
            }
        }
    }

    .hide-content-mobile .first-asset {
        margin-top: 50px;

        .category-name,
        .category-description {
                @include respond-to(mobile) {
                display: none;
            }
        }
    }

    .hide-additional-content-mobile .clp-secondary-assets {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .slick-prev {
        left: 15px;

        @include respond-to(mobile) {
            left: 5px;
        }
    }

    .slick-next {
        right: 15px;

        @include respond-to(mobile) {
            right: 5px;
        }
    }
}

.chanel-carousel {
    .product-tile {
        .product-actions,
        .product-name,
        .product-pricing {
            display: none;
        }

        img {
            max-height: 94%;
        }
    }
}

.chanel-section .primary-clp .slot.cat-blocks-carousel {
    padding: 0;
    background: transparent;

    .carousel-list {

        .category-image {
            display: block;
            margin: 0 auto;
        }

        h2,
        .category-shop-link {
            text-align: center;
        }

        h2 {
            font-size: rem(14);
        }

        .category-description,
        .category-shop-link {
            display: none;
        }
    }
}

.accordion-toggle-title {
    display: block;
    margin: 20px 0;
    padding: 15px;
    border: 1px solid $very-light-gray;
    background : $second-lightest-gray;

    &:after {
        font-family: $font-icons !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e912";
        float: right;
        font-size: rem(13);
        line-height: 21px;
    }

    &.expanded:after {
        content: "\e90f";
    }
}

.category-sidebar-navigation {

    .category-level-null,
    .featured-brands-level-null,
    .category-level-1 {
        margin-bottom: 0;

        > li {

            > .category-link,
            > .featured-brands-link {
                position: relative;
                display: block;
                border-top: 1px solid $very-light-gray;
                padding: 12px 16px;

                &:hover {
                    background: $light-gray;
                    color: $night-rider;
                }

                &.toggle {

                    &:after {
                        content: "\e912";
                        position: absolute;
                        right: 15px;
                        display: block;
                        font-family: $font-icons;
                        font-size: rem(12);
                        top: 50%;
                        margin-top: -10px;
                    }

                    &.expanded {

                        &:after {
                            right: 15px;
                            content: '';
                            width: 11px;
                            height: 2px;
                            border-bottom: 1px solid;
                            margin-top: -3px;
                        }

                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }

            > .category-browse {
                @include respond-to(mobile) {
                    display: block;
                    background: $light-gray;
                    border: 1px solid $very-light-gray;
                    margin-bottom: 12px;
                }

                @include respond-to(tablet_plus) {
                    display: none;
                }
            }

            > .category-base {
                @include respond-to(tablet_plus) {
                    display: block;
                }
            }
        }
    }

    .featured-brands-level-null,
    .category-level-1 {
        ul {
            a {
                display: block;
                padding: 0 16px 7px 16px;
                &:hover {
                    color: $smokegrey;
                }
            }

            li:last-child {
                a {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .category-level-3 li > a{
        padding: 0 16px 4px 26px;
        font-size: rem(14);
    }
}

.who-will-be {
    &.carousel {
        @include respond-to(tablet_plus) {
            ul {
                position: static;
                width: auto;
            }

            .carousel-item {
                list-style-type: none;
                width: 20% !important;
            }

            .jcarousel-nav {
                display: none;
            }
        }
    }

    #secondary + #primary &.carousel {
        @include respond-to(tablet_plus) {
            .carousel-item {
                width: 25% !important;
            }
        }
    }

    &-link {
        display: block;
        font-size: 0;
    }

    &-img {
        .desktop & {
            position: relative;
            max-width: 100%;
            margin: 0;
            transition: opacity 0.35s, transform 0.35s;
            transform: scale(1);
        }
    }

    &-text {
        display: block;
        padding: 20px;

        .desktop & {
            position: absolute;
            z-index: 3;
            padding: 5px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: $white;
            opacity: 0;
            bottom: auto;
            padding: 20px;
            top: 50%;
            transition: opacity 0.35s, transform 0.35s;
            transform: scale(0.8) translateY(-50%);
            width: 80%;
        }
    }

    &-title {
        display: block;
        margin: 3px 0 8px;
        font-size: rem(14);
        line-height: 17px;
        text-transform: uppercase;
    }

    &-description {
        display: block;
        font-size: rem(12);
        line-height: 19px;
        margin: 0;
    }

    .carousel-item {
        padding: 0;
        text-align: center;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transform-style: preserve-3d;

        .desktop & {
            &:nth-of-type(4n+1) {
                clear: both;
            }

            &:before {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($black01, 0.6);
                content: '';
                transform: scale(1.1);
                opacity: 0;
                transition: opacity 0.35s, transform 0.35s;
                cursor: pointer;
            }

            &:hover {
                background-color: $nobel;

                &:before {
                    opacity: 0.9;
                    transform: scale(1);
                }

                .who-will-be-img {
                    opacity: 1;
                    transform: scale(1.12);
                }

                .who-will-be-text {
                    opacity: 1;
                    transform: scale(1) translateY(-50%);
                }
            }
        }
    }
}

/* Wide Content Blocks */
.slot-wideblocks {
    &.slot {
        margin-bottom: 65px;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    .asset {
        width: 100%;
        margin-bottom: 30px;

        @include respond-to(tablet) {
        }

        @include respond-to(mobile) {
        }

        &-image_container {
            @include flex(1);
            @include order(1);
            margin: 0 15px 0 0;

            @include respond-to(mobile) {
                margin: 0;
            }
        }

        &-image {
            display: block;
            max-width: 100%;
        }

        &-info_container {
            @include flexbox();
            @include flex(1);
            @include order(2);
            @include justify-content(center);
            @include flex-vertical();
            padding: 24px 0;
            margin: 0 0 0 15px;
            text-align: center;
            background-color: $second-lightest-gray;

            @include respond-to(tablet) {
                padding: 18px 0;
            }

            @include respond-to(mobile) {
                margin: 0;
            }
        }

        &-title {
            padding: 0 24px;
            font-size: rem(22);
            margin-bottom: 4px;
            max-height: 58px;
            overflow: hidden;
            text-transform: uppercase;

            @include respond-to(mobile) {
                padding: 0 10px;
                font-size: rem(20);
                max-height: none;
            }
        }

        &-description {
            color: $hel-gray;
            padding: 0 24px;
            font-size: rem(16);
            max-height: 42px;
            overflow: hidden;

            @include respond-to(mobile) {
                padding: 0 10px;
                font-size: rem(14);
                max-height: none;
            }
        }

        &-link {
            @include flexbox();
            @include align-items(stretch);

            @include respond-to(mobile) {
                -moz-box-orient: vertical;
                -webkit-box-orient: vertical;
                -webkit-flex-flow: column;
                -ms-flex-direction: column;
                flex-flow: column;
            }

            &.reversed {
                .asset-image_container {
                    @include order(2);
                    margin: 0 0 0 15px;
                }

                .asset-info_container {
                    @include order(1);
                    margin: 0 15px 0 0;
                }

                @include respond-to(mobile) {
                    .asset-image_container {
                        @include order(1);
                        margin: 0;
                    }

                    .asset-info_container {
                        @include order(2);
                        margin: 0;
                    }
                }
            }
        }
    }

    &-three-box,
    &-four-box {
        .asset {
            padding-left: 6px;
            width: 33.33333333%;

            @include respond-to(mobile) {
                padding-left: 0;
                width: auto;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-text {
                min-height: 96px;

                @include respond-to(mobile) {
                    min-height: 0;
                }
            }

            &-title {
                font-size: rem(19);
                max-height: 50px;

                @include respond-to(mobile) {
                    max-height: 50px;
                }
            }
        }

        .slot-panels-container {
            margin-left: -6px;

            @include respond-to(mobile) {
                margin-left: 0;
            }
        }
    }

    &-four-box {
        .asset {
            width: 25%;

            @include respond-to(mobile) {
                width: auto;
            }
        }
    }
}

.clp-isotope {
    background-color: $white-smoke;
    padding-top: 35px;

    .grid-item {
        box-sizing: border-box;
        font-size: 0;
        padding: 0 15px 35px;
        width: 25%;

        @include respond-to(mobile) {
            width: auto;
        }

        img {
            display: block;
            max-width: 100%;
        }

        h3 {
            font-size: rem(19);
            line-height: normal;
            margin-top: 15px;
        }

        p {
            color: $hel-gray;
            font-size: rem(16);
            line-height: 1.2;
            margin: 2px 0 0;
        }

        .link {
            border-bottom: 1px solid;
            color: $hel-gray;
            display: inline-block;
            font-size: rem(13);
            letter-spacing: 1px;
            margin-top: 20px;
            text-transform: uppercase;
        }

        .videoWrapper {
            height: 0;
            padding-bottom: 56.25%;
            position: relative;
            width: 100%;

            iframe {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        &--width2 {
            width: 75%;

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &--width3 {
            width: 33.33333333%;

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &--width4 {
            width: 66.66666667%;
            padding-bottom: 15px;

            @include respond-to(mobile) {
                width: 100%;
            }
        }

        &.no-right-padding {
            padding-right: 0;
        }

        &.onethird-left {
            padding-right: 8px;
        }

        &.onethird-right {
            padding-left: 8px;
        }
    }

    .product-tile {
        background-color: $white;
        display: inline-block;
        padding-bottom: 30px;
        width: 50%;

        @include respond-to(mobile) {
            display: block;
            width: auto;
        }

        img {
            margin-right: auto;
            margin-left: auto;
        }

        .product-actions {
            display: none;
        }
    }
}

/* Chanel content panel */
.chanel-content-block {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    @include respond-to(mobile) {
        position: relative;
        overflow: hidden;
    }

    .chanel-left {
        position: absolute;
        top: 0;
        width: 100%;

        img {
            @include respond-to(touch) {
                max-width: none;
            }
        }
    }

    .chanel-right {
        position: relative;
        width: 57.5%;
        float: right;
        margin: 25px 20px;
        padding: 24px;
        z-index: 1;
        background: $white;
        opacity: 0.9;

        @include respond-to(mobile_strong) {
            float: none;
            position: relative;
            left: 0;
            right: 0;
            margin: 32px auto;
            width: 95%;
            padding: 24px 8px;
            text-align: center;
        }

        @include respond-to(mobile_mid) {
            width: 90%;
            padding: 15px 8px;
        }

        @include respond-to(mobile_min) {
            margin: 15px auto;
            padding: 15px 8px;
        }

        h3 {
            margin-bottom: 5px;
            font-size: rem(35);
            text-transform: uppercase;
            font-family: Arial;
            letter-spacing: 2px;

            @include respond-to(mobile) {
                font-size: rem(29);
            }
        }

        p {
            font-size: rem(14);
            font-family: Arial;
            line-height: 18px;
            margin: 0 0 10px 0;
            color: $nobel;

            @include respond-to(mobile) {
                font-size: rem(12);
                line-height: 13px;
                margin-bottom: 8px;
            }
        }
    }
}

// can be RE-used as component's style - to collapse tabs, etc into custom dropdown select
.collapse-to-dropdown-wrapper {
    > label,
    .selected-value {
        display: none;
    }

    @include respond-to(mobile) {
        > label {
            display: inline-block;
            margin-right: 10px;
        }
    }

    .collapse-to-dropdown {
        position: relative;
        display: block;
        min-width: 220px;

        @include respond-to(mobile) {
            display: block;
        }

        .selected-value {
            display: none;
        }

        .collapse-to-dropdown__item {
            @include respond-to(mobile) {
                padding: 8px 0;
            }
        }

        @include respond-to(mobile) {
            > ul {
                display: none;
            }

            $item_height: 36px;
            $items_to_show: 7;

            &.expanded > ul,
            &.expanded > ul.ui-tabs-nav {
                display: block;
                position: absolute;
                max-height: $item_height*$items_to_show + 1px;
                overflow: auto;
                width: 100%;
                top: 100%;
                left: 0;
                background: $white;
                z-index: 3; // < 3 can be overlapped by underneath carousel nav arrows
                border: 1px solid $tutu;
                border-top: none;

                a,
                a.ui-tabs-anchor {
                    padding: 0 15px;
                    margin: 0;
                    height: $item_height;
                    font-size: rem(16);
                    line-height: $item_height;
                    vertical-align: middle;
                    width: 100%;
                    text-transform: none;
                }

                li {
                    display: block;
                    width: 100%;
                    text-align: left;
                    background: $white;
                    -webkit-transition: background .3s ease-in-out;
                    transition: background .3s ease-in-out;
                    color: $charcoal;
                    margin: 0;

                    &::before,
                    &::after {
                        content: none !important;
                    }

                    &:hover {
                        background: $tutu;
                    }
                }
            }

            &.expanded {
                @include respond-to(mobile) {
                    .selected-value {
                        border-bottom: 0;

                        &:after {
                            content: "\e90f";
                        }
                    }

                    ul > li {
                        padding: 8px 0;
                    }
                }
            }

            .selected-value {
                display: inline-block;
                position: relative;
                border: 1px solid $tutu;
                padding: 0 50px 0 15px;
                color: $nobel;
                text-transform: uppercase;
                cursor: pointer;
                font-size: rem(16);
                width: 55%;
                line-height: 50px;

                @include respond-to(mobile_tablet) {
                    text-align: left;
                }

                @include respond-to(mobile) {
                    width: 100%;
                }

                &:after {
                    font-family: $font-icons;
                    content: "\e912";
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    margin-top: -16px;
                    width: 30px;
                    text-align: left;
                    font-size: rem(14);
                    padding: 5px;
                    height: 70%;
                    line-height: 24px;
                }

                span {
                    vertical-align: middle;
                }
            }
        }
    }
}
