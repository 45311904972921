.search-suggestion {
    @extend %clearfix;

    position: absolute;
    top: 100%;
    background: $color-primary3;

    @include respond-to(mobile_strong) {
        width: calc(100% - 20px);
        top: 70px;
        left: 10px;
        border: 1px solid $color-secondary3;
    }

    @include respond-to(mobile_strong_over) {
        width: 440px;
        left: -27px;
        padding: 0 0 10px 10px;
        background-clip: content-box;
        -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 10px 100%, 0 calc(100% - 10px));
        clip-path: polygon(0% 0, 100% 0, 100% 100%, 10px 100%, 0 calc(100% - 10px));
    }

    @include respond-to(mobile_stronger) {
        top: 45px;
    }

    @include respond-to(desktop_plus) {
        top: 68px;
    }

    .is-sticky & {
        @include respond-to(desktop_plus) {
            top: 46px;
        }
    }

    &:before {
        @include respond-to(mobile_strong) {
            content: "";
            width: auto;
            height: auto;
            position: absolute;
            top: -40px;
            left: 50%;
            margin-left: -20px;
            border-width: 20px;
            border-style: solid;
            border-color: transparent transparent $color-primary3;
            opacity: 1;
        }

        @include respond-to(mobile_strong_over) {
            content: '';
            width: 10px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 1px;
            background: $color-primary3;
            opacity: 0.7;
            z-index: 1;
        }
    }

    &:after {
        @include respond-to(mobile_strong_over) {
            content: '';
            width: 100%;
            height: 10px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: $color-primary3;
            opacity: 0.8;
        }
    }
}

.search-suggestion-inner {
    max-height: 70vh;
    overflow: auto;
    padding: 23px;

    @include respond-to(mobile_strong) {
        height: calc(100vh - 250px);
    }
}

.search-suggestion__phrase {
    font-size: rem(12);
    line-height: 1;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-secondary4;
}

.search-suggestion__phrase-link {
    text-transform: capitalize;
    font-family: $font-main-bold;
}

.search-suggestion__item {
    margin-top: 30px;

    &:first-child {
        margin: 0;
    }
}

.search-suggestion__item-title {
    font-size: rem(14);
    text-transform: uppercase;
    border-bottom: 1px solid $color-auxiliary40;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @include respond-to(mobile) {
        font-size: rem(12);
    }
}

.search-suggestion__item-hit {
    display: block;
    font-size: rem(12);
    margin-bottom: 15px;

    &-category {
        font-family: $font-main-bold;
    }
}

.search-suggestion__item--products {
    border-top: 1px solid $color-auxiliary40;
    padding-top: 15px;
}

.search-suggestion__item--popular {
    text-transform: capitalize;
}

.search-suggestion__item-hit-link {
    display: flex;
}

.search-suggestion__item-img {
    display: inline-block;
    width: 108px;
    height: 77px;
    vertical-align: top;
    margin-right: 10px;
}

.search-suggestion__item-text {
    width: 50%;
    font-size: rem(16);
}