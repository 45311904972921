// Slider "WHAT'S ON IN STORE"
.hp-carousel {
    margin-bottom: -15px;
}

.carousel-title {
    font-family: $font-main;
    text-align: center;
    line-height: 1;
    margin-bottom: 75px;

    @include respond-to(mobile_strong) {
        margin-bottom: 15px;
    }

    @include respond-to(mobile) {
        font-size: rem(22);
    }

    @include respond-to(tablet_plus) {
        font-size: rem(32);
    }
}

.whats-on-in-store-carousel {
    @include respond-to(mobile_strong) {
        text-align: center;
    }

    &::before {
        @include clip-decorator($z-index: 1);

        background: $color-auxiliary11;
        bottom: 0;
        content: '';
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        right: 0;
    }

    .slick-arrow {
        bottom: auto;
        font-size: 0;
        top: 50%;

        @include respond-to(mobile_strong) {
            bottom: 38%;
            font-size: 2rem;
            top: auto;
        }
    }

    // fix before carousel initialization
    // https://jira.ontrq.com/browse/BRTREF-2217
    .slide:nth-child(n+2) {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .slick-dots {
        @include respond-to(mobile_strong) {
            background: $color-auxiliary11;
        }
    }

    .slide-main-img {
        @include respond-to(touch) {
            width: 100%;
        }
    }

    img {
        width: 100%;
        max-width: 100%;
    }
}

.aricons-dots {
    display: inline-block;
    font-size: rem(9);

    .path1,
    .path3 {
        &::before {
            color: transparent;
        }
    }
}

.slick-dots {
    @include respond-to(mobile_strong) {
        bottom: 5px;
        left: 0;
        margin: 0;
        position: absolute;
        right: 0;
    }

    li {
        display: inline-block;
        line-height: 1;
        padding: 14px;

        &:hover {
            @include respond-to(mobile_strong_over) {
                .path1,
                .path2 {
                    &::before {
                        color: transparent;
                    }
                }
                .path3 {
                    &::before {
                        color: $color-primary2;
                    }
                }
            }
        }
        &:active {
            @include respond-to(mobile_strong) {
                .path1,
                .path2 {
                    &::before {
                        color: transparent;
                    }
                }
                .path3 {
                    &::before {
                        color: $color-primary2;
                    }
                }
            }
        }
    }
    button {
        background-color: transparent;
        color: $color-secondary2;
        font-size: 0;
        padding: 0;

        &::after {
            @include respond-to(mobile_strong) {
                content: '';
                display: block;
            }
        }
    }
}

.slick-active {
    .path1 {
        &::before {
            color: $color-secondary2;
        }
    }
    .path2 {
        &::before {
            color: transparent;
        }
    }
}

.slide {
    @include respond-to(mobile_strong) {
        position: relative;
    }

    &:focus {
        outline: none;
    }
}

.slide-discover-link {
    .aricons-small-arrow-right {
        font-size: rem(9);
        margin-left: 15px;
    }
}

.slide-content-wrapper {
    position: relative;

    &::after {
        @include clip-decorator($z-index: 7);

        background: $color-primary3;
        bottom: 0;
        content: '';
        display: block;
        height: 10px;
        left: 0;
        position: absolute;
        right: 0;

        @include respond-to(mobile_strong) {
            display: none;
        }
    }
}

.slide-content-width {
    margin: 0 auto;
    max-width: 1140px;
    position: relative;
    width: 96%;

    @include respond-to(mobile_strong) {
        position: static;
        width: 80%;
    }
}

.slide-part1 {
    position: relative;
    width: 79%;

    @include respond-to(mobile_strong) {
        width: 100%;
    }
}

.text-box {
    display: inline-block;
    min-height: 252px;
    padding: 42px 20px 20px;
    width: 35%;

    @include respond-to(touch) {
        padding: 26px 20px 20px;
        width: 45%;
    }
    @include respond-to(mobile_strong) {
        margin-bottom: 27px;
        min-height: 0;
        padding: 0;
        width: 100%;
    }
}

.slide-title {
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: 25px;

    @include respond-to(mobile_strong) {
        text-transform: uppercase;
    }

    @include respond-to(mobile) {
        font-size: rem(14);
    }

    @include respond-to(tablet_plus) {
        font-size: rem(18);
    }
}

.slide-description {
    font-size: rem(14);
    margin: 0 0 20px;

    @include respond-to(mobile_strong) {
        margin-bottom: 13px;
    }
}

.slide-discover-link {
    font-family: $font-main-bold;
    color: $black01;
}

.slide-main-img {
    &-wrapper {
        float: right;
        max-width: 480px;
        position: relative;
        width: 54%;
        z-index: 8;

        @include respond-to(mobile_strong) {
            float: none;
            margin: 0 auto;
            position: relative;
            width: 86%;
            z-index: 2;
        }

        .component-image {
            margin: 0 auto 37px;

            @include respond-to(mobile_strong) {
                margin-bottom: 10px;
            }
        }
    }

    &-title {
        text-align: center;
        text-transform: uppercase;

        @include respond-to(mobile) {
            font-size: rem(14);
        }

        @include respond-to(tablet_plus) {
            font-size: rem(16);
        }
    }
}

.slide-part2 {
    @extend %clearfix;

    background: $color-auxiliary11;
    margin-top: -10px;
    min-height: 620px;
    padding: 47px 0;

    @include respond-to(mobile_strong) {
        bottom: 0;
        min-height: 0;
        position: absolute;
        top: 63%;
        width: 100%;

        &::before {
            @include clip-decorator($z-index: 1);

            background: $color-primary3;
            top: -1px;
            content: '';
            display: block;
            height: 11px;
            left: 0;
            position: absolute;
            right: 0;
        }
    }
}

.carousel-control,
.next-el {
    bottom: -280px;
    position: absolute;
}

.carousel-control {
    left: 0;
    max-width: 342px;
    width: 30%;

    @include respond-to(touch) {
        max-width: 245px;
        width: 100%;
    }
    @include respond-to(mobile_strong) {
        display: none;
    }
}

.carousel-pagination {
    @include respond-to(mobile_strong) {
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }
}

.carousel-pagination,
.next-el-image-clipper {
    margin-bottom: 56px;
}

.carousel-pagination,
.carousel-navigation {
    @include respond-to(mobile_strong) {
        left: 0;
        position: absolute;
        width: 100%;
    }
}

.whats-on-in-store-carousel {
    .pagination-list {
        font-size: rem(14);
        text-align: left;
        margin-bottom: 40px;
    }
    .pagination-el,
    .prev,
    .next {
        cursor: pointer;
    }
}

.pagination-el {
    display: inline-block;
    margin-left: 20px;

    &.pagination-el-active {
        position: relative;

        &::after {
            background: $color-auxiliary12;
            content: '';
            cursor: default;
            display: block;
            height: 27px;
            left: 50%;
            position: absolute;
            top: 28px;
            width: 1px;
        }
    }
}

.pagination-img-clipper,
.next-el-image-clipper {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.pagination-img-clipper {
    height: 142px;
}

.pagination-img,
.next-el-image-wrapper {
    @include border-tape($border-color: $color-primary3, $direction: 'in');
}

.pagination-img {
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10px 100%, 0 calc(100% - 10px));
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10px 100%, 0 calc(100% - 10px));

    @include respond-to(mobile_strong) {
        display: none;
    }

    &::after {
        display: none;
    }

    .border-type-inner {
        &::before {
            display: none;
        }
    }
}

.next-el-image-wrapper {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);

    &::before {
        display: none;
    }

    .border-type-inner {
        &::before {
            display: none;
        }
    }
}

.carousel-navigation {
    font-size: 0;
    padding-left: 15px;

    .prev,
    .next {
        cursor: pointer;
        display: inline-block;
        line-height: 20px;
        height: 20px;
        width: 20px;
        text-align: center;

        &:hover {
            .aricons-small-arrow-left,
            .aricons-small-arrow-right {
                color: $color-primary2;
            }
        }
    }
    .prev {
        margin-right: 32px;
    }
    .aricons-small-arrow-left,
    .aricons-small-arrow-right {
        font-size: rem(10);
        transition: color .3s;
        vertical-align: middle;
    }
}

.next-el {
    right: 0;
    max-width: 126px;
    width: 11%;

    .next {
        height: 20px;
        display: inline-block;
        font-family: $font-main-bold;
        font-size: rem(14);
        line-height: 20px;
        text-transform: uppercase;
        transition: color .3s;

        &:hover {
            color: $color-primary2;
        }
    }

    @include respond-to(touch) {
        width: 18%;
    }
    @include respond-to(mobile_strong) {
        display: none;
    }
}

.next-el-image-clipper {
    cursor: pointer;
    height: 192px;
}

.next-el-image {
    width: 100%;
}
