
//function for convertation pixels to rems
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}


/* Mixin to put content our of wrapper */
@mixin out-of-wrapper {
    margin-left: -100%;
    margin-right: -100%;
    padding-left: 100%;
    padding-right: 100%;
    box-sizing: content-box;

    .menu-active &,
    .mini-cart-active & {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin full-page-content {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin full-page-border {
    &::after {
        content: "";
        display: block;
        border: 1px solid $lighter-gray;
        margin-left: -100%;
        margin-right: -100%;
        clear: both;
    }

    &::after {
        @include respond-to(mobile_strong_over) {
            margin-top: 96px;
        }
    
        @include respond-to(mobile_strong) {
            margin-top: 30px;
        }
    }
}


/* Mixin for using icons in pseudo-elements */
@mixin font-icomoon {
    font-family: $font-icons;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Mixin to cut off the rest of one-line text with dots */
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Flexbox Mixins */
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-basis($values) {
    -webkit-flex-basis: $values; /* Safari 6.1+ */
            flex-basis: $values;
}

@mixin flex($values) {
    -webkit-flex: $values;
        -ms-flex: $values;
            flex: $values;
}

@mixin order($val) {
    -ms-flex-order: $val;
     -webkit-order: $val;
             order: $val;
}

@mixin align-items($val) {
    -webkit-align-items: $val;
         -ms-flex-align: $val;
            align-items: $val;
}

@mixin justify-content($val) {
    -webkit-justify-content: $val;
        -ms-justify-content: $val;
            justify-content: $val;
}

@mixin align-content($val) {
    -webkit-align-content: $val;
        -ms-align-content: $val;
            align-content: $val;
}

@mixin align-self($val) {
    -webkit-align-self: $val;
        -ms-align-self: $val;
            align-self: $val;
}

@mixin flex-vertical() {
    -webkit-flex-flow: column;
    -ms-flex-direction: column;
    flex-flow: column;
}

@mixin flex-wrap() {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

@mixin flex-horizontal() {
    -webkit-flex-flow: row wrap;
    -ms-flex-direction: row;
    flex-flow: row wrap;
}

@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin position($position: absolute, $left: auto, $right: auto, $top: auto, $bottom: auto) {
    position: $position;
    left: $left;
    right: $right;
    bottom: $bottom;
    top: $top;
}

// mixin to change placeholder style (font\color etc)
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

@mixin link_underline ($border-color: $black01) {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        border: 0.5px solid $border-color;
        visibility: visible;
        transition: all .3s ease-in-out 0s;
        transform-origin: 0%;
    }

    &:hover {
        cursor: pointer;

        &:after {
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0% 50%;
        }
    }
}

//----------------------------------------------------------------------
// Arnotts mixins
//----------------------------------------------------------------------
// Mixin used to cut bottom corners of block (decoration)
// Parameters :
// -------------------------------------------------------------------------
//  $corners  - number of corners (default = 2)
// -------------------------------------------------------------------------
//  $z-index  - z-index for element (default = undefined)
// -------------------------------------------------------------------------
//  $size     - size of the axis (default = 10px)
// -------------------------------------------------------------------------
// Examples :
// -------------------------------------------------------------------------
// if you wish to have 2 corners (which is default) cutted without z-index:
// @include clip-decorator;
// -------------------------------------------------------------------------
// 2 corners and defined z-index:
// @include clip-decorator($z-index: 999);
// -------------------------------------------------------------------------
// 4 corners, without z-index:
// @include clip-decorator(4);
// -------------------------------------------------------------------------
// 4 corners and defined z-index:
// @include clip-decorator(4, 999);

@mixin clip-decorator($corners: 2, $z-index: undefined, $size: 10px, $position: relative, $croppedTop: false) {
    @if $corners == 4 {
        -webkit-clip-path: polygon(0% #{$size}, #{$size} 0%, calc(100% - #{$size}) 0%, 100% #{$size}, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, #{$size} 100%, 0% calc(100% - #{$size}));
                clip-path: polygon(0% #{$size}, #{$size} 0%, calc(100% - #{$size}) 0%, 100% #{$size}, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, #{$size} 100%, 0% calc(100% - #{$size}));

    } @else if $corners == 2 {
        @if $croppedTop {
            margin-top: -#{$size};
            -webkit-clip-path: polygon(0% 0%, #{$size} #{$size}, calc(100% - #{$size}) #{$size}, 100% 0%, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, #{$size} 100%, 0 calc(100% - #{$size}));
                    clip-path: polygon(0% 0%, #{$size} #{$size}, calc(100% - #{$size}) #{$size}, 100% 0%, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, #{$size} 100%, 0 calc(100% - #{$size}));
        } @else {
            -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, #{$size} 100%, 0 calc(100% - #{$size}));
                    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, #{$size} 100%, 0 calc(100% - #{$size}));
        }
    }

    @if $z-index != undefined {
        position: $position;
        z-index: $z-index;
    }
}

@mixin clip-path($param) {
    -webkit-clip-path: $param;
            clip-path: $param;
}


// Mixin for creating advanced full-width content elemens
// (banners etc. which are already inside of max-width wrapper i.e. #main)
// Parameters :
// -------------------------------------------------------------------------
//  $scrollbar-fix    - if true, it will enable width fix (applied only for desktops) that excludes width of scroller bar
//                      (Especially useful in combination with @mixin clip-decorator as it will properly show cut corners)
// -------------------------------------------------------------------------
//  $scrollbar-width  - width of scroller bar (default = 16px)
// -------------------------------------------------------------------------

@mixin full-page-content($scrollbar-fix: false, $scrollbar-width: 16px) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    @if $scrollbar-fix == true {
        .desktop & {
            width: calc(100vw - #{$scrollbar-width});
            margin-left: calc(-50vw + #{$scrollbar-width / 2});
        }

        .desktop.safari & {
            width: calc(100vw - 14px);
            margin-left: calc(-50vw + #{14px / 2});
        }
    }
}


// Mixin for creating complex borders
// Parameters :
// -------------------------------------------------------------------------
//  $border-color    - color of the type (default = inherit)
// -------------------------------------------------------------------------
//  $width           - widths of the type (default = 10px)
// -------------------------------------------------------------------------
//  $direction        - direction of the type [out of / in the block] (default = out)
// -------------------------------------------------------------------------
//  $opacity         - list of opacities of the type on sides
//                     (top/bottom, left/right)
//                     (top, left/right, bottom)
// -------------------------------------------------------------------------
//  HTML structure should be:
//      <div class="outer-wrapper">
//          <div class="border-type-inner"></div>
//      </div>
// .outer-wrapper - here can be any class
// .border-type-inner - please use this specific class for inner wrapper
// Please do not use "border-type-inner" for styling!

@mixin border-tape($border-color: inherit, $width: 10px, $direction: 'out', $opacity: .8 .7, $bgcolor: transparent) {
    @include clip-decorator($corners: 4, $size: $width);

    background-color: $bgcolor;
    position: relative;

    &::before,
    &::after {
        background: $border-color;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: $width;
        z-index: 1;
    }

    &::before {left:  0;}
    &::after  {right: 0;}

    .border-type-inner {
        background: inherit;
        width: 100%;

        &::before,
        &::after {
            background: $border-color;
            content: '';
            height: $width;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::before {top:    0;}
        &::after  {bottom: 0;}
    }

    @if length($opacity) == 1 {
        &::before,
        &::after {opacity: $opacity;}

        .border-type-inner {
            &::before,
            &::after {opacity: $opacity;}
        }
    }

    @if length($opacity) == 2 {
        &::before,
        &::after {opacity: nth($opacity, 2);}

        .border-type-inner {
            &::before,
            &::after {opacity: nth($opacity, 1);}
        }
    }

    @if length($opacity) == 3 {
        &::before,
        &::after {opacity: nth($opacity, 2);}

        .border-type-inner {
            &::before {opacity: nth($opacity, 1);}
            &::after  {opacity: nth($opacity, 3);}
        }
    }

    @if length($opacity) == 4 {
        &::before {opacity: nth($opacity, 4);}
        &::after  {opacity: nth($opacity, 2);}

        .border-type-inner {
            &::before {opacity: nth($opacity, 1);}
            &::after  {opacity: nth($opacity, 3);}
        }
    }

    @if ($direction == 'out') {
        background-clip: content-box;
        padding: $width - .5px; // to prevent gap between content and border

        .border-type-inner {
            position: relative;
            z-index: 10;

            &::before,
            &::after {
                left: -$width;
                width: calc(100% + #{$width} * 2 - 1px); // 1px compensates gap prevention rule
            }
            &::before {top:    -$width;}
            &::after  {bottom: -$width;}
        }
    }
}


// Mixin for toggle icons
// Parameters :
// -------------------------------------------------------------------------
//  $expanded-class  - class name used for parent indicationg expanded state
// -------------------------------------------------------------------------
//  $icon-class-plus - class name used for icons in collapsed state
// -------------------------------------------------------------------------
//  $icon-class-plus - class name used for icons in expanded state
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
//  HTML structure should be:
//      <li class="wrapper expanded">
//          ...caption of item....
//          <i class="aricons-plus"></i>
//          <i class="aricons-minus"></i>
//      </li>

@mixin icon-toggler($expanded-class: expanded, $icon-class-plus: aricons-plus, $icon-class-minus: aricons-minus) {
    &.#{$expanded-class} {
        .#{$icon-class-plus} {
            display: none;
        }

        .#{$icon-class-minus} {
            display: inline-block;
        }
    }

    .#{$icon-class-minus} {
        display: none;
    }
}


// Mixin for animated underline on link hover
// Parameters :
// -------------------------------------------------------------------------
//  $pseudo          - attach to ::before or ::after
// -------------------------------------------------------------------------
//  $inner-span      - is there SPAN with text inside current link ot not
// -------------------------------------------------------------------------
//  $color           - color of the underline element

@mixin underline-on-hover($pseudo: before, $inner-span: true, $color: $black01) {
    $selector: '';

    @include respond-to(touch_over) {
        .no-mobile & {
            @if ($inner-span == true) {
                $selector: ' > span';

                #{$selector} {
                    position: relative;
                    display: inline-block; // IE 11 100%-width underline jump fix
                }
            } @else {
                position: relative;
                display: inline-block; // IE 11 100%-width underline jump fix
            }

            &#{$selector}::#{$pseudo} {
                position: absolute;
                left: 0;
                background-color: $color;
                bottom: 0;
                height: 1px;
                width: 0;
                transition: width .3s;

                content: '';
            }

            &:hover#{$selector}::#{$pseudo} {
                width: 100%;
            }
        }
    }
}


// Mixin for links
// Parameters :
// -------------------------------------------------------------------------
//  $pseudo          - attach to ::before or ::after
// -------------------------------------------------------------------------
//  $inner-span      - is there SPAN with text inside current link ot not
// -------------------------------------------------------------------------
//  $color           - color of link (on hover state). In not hover state it will use $color with 0.6 opacity

@mixin text-link-style($color: $black01) {
    color: rgba($color, .6);
    transition: color .3s !important; // necessary evil (core_ext)

    &:hover {
        color: $color;
    }
}

// Mixin ripple effect for buttons
@mixin ripple-effect {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: radial-gradient(circle, #fff 10%, transparent 10%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform .5s, opacity 1s;
    }

    &:active:after {
        transform: scale(0, 0);
        opacity: .3;
        transition: 0s;
    }
}

// Mixin for buttons
// Parameters :
// -------------------------------------------------------------------------
//  $type          - type of button
// -------------------------------------------------------------------------

@mixin button-type($type: undefined) {
    font-family: $font-main-bold;
    font-size: rem(14);
    padding: 11px;
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
    line-height: 1;
    cursor: pointer;
    letter-spacing: .05em;
    min-width: $buttons-min-width-desktop;
    transition: background-color 300ms ease-out,
                border-color 300ms ease-out,
                color 300ms ease-out;

    @include respond-to(mobile) {
        min-width: $buttons-min-width-mobile;
        padding: 13px;
        margin: $buttons-space-between auto;
    }

    &:disabled,
    &.disabled {
        cursor: default;
        user-select: none;
    }

    @if ($type == 1 or $type == 2) {
        &:disabled,
        &.disabled {
            color: $color-primary3;
            background-color: rgba($black01, .15);
            border-color: rgba($black01, .15);

            @include respond-to(touch_over) {
                &:hover {
                    color: $color-primary3;
                    background-color: rgba($black01, .15);
                    border-color: rgba($black01, .15);
                }
            }
        }
    }

    @if ($type == 1) {
        background-color: transparent;
        border-color: $color-secondary2;
        color: $black01;

        @include respond-to(touch_over) {
            &:hover {
                border-color: $color-primary2;
                color: $black01;
            }
        }
    }

    @if ($type == 2) {
        background-color: transparent;
        border-color: $color-secondary1;
        color: $black01;

        @include respond-to(touch_over) {
            &:hover {
                background-color: $black01;
                border-color: $black01;
                color: $color-primary3;
            }
        }
    }

    @if ($type == 3) {
        background-color: transparent;
        border-color: $color-secondary2;
        color: $color-primary3;

        @include respond-to(touch_over) {
            &:hover {
                background-color: rgba($black01, .5);
                border-color: $color-secondary2;
                color: $color-primary3;
            }
        }

        &:disabled,
        &.disabled {
            color: rgba($color-primary3, .1);
            background-color: rgba($black01, .5);
            border-color: rgba($color-primary3, .6);

            @include respond-to(touch_over) {
                &:hover {
                    color: rgba($color-primary3, .1);
                    background-color: rgba($black01, .5);
                    border-color: rgba($color-primary3, .6);
                }
            }
        }
    }

    @if ($type == 4) {
        background-color: rgba($color-primary3, .3);
        border-color: $black01;
        color: $black01;

        @include respond-to(touch_over) {
            &:hover {
                background-color: rgba($color-primary3, 1);
                border-color: $black01;
                color: $black01;
            }
        }

        &:disabled,
        &.disabled {
            color: rgba($black01, .3);
            background-color: rgba($color-primary3, .3);
            border-color: rgba($black01, .5);

            @include respond-to(touch_over) {
                &:hover {
                    color: rgba($black01, .3);
                    background-color: rgba($color-primary3, .3);
                    border-color: rgba($black01, .5);
                }
            }
        }
    }

    @if ($type == 5) {
        background-color: rgba($black01, .3);
        border-color: $color-primary3;
        color: $color-primary3;

        @include respond-to(touch_over) {
            &:hover {
                background-color: rgba($black01, 1);
                border-color: rgba($color-primary3, .5);
                color: $color-primary3;
            }
        }

        &:disabled,
        &.disabled {
            color: rgba($color-primary3, .15);
            background-color: rgba($black01, .5);
            border-color: rgba($color-secondary3, .3);

            @include respond-to(touch_over) {
                &:hover {
                    color: rgba($color-primary3, .15);
                    background-color: rgba($black01, .5);
                    border-color: rgba($color-secondary3, .3);
                }
            }
        }
    }
}


// Mixin for icomoon icons use without any i or span tags
// Parameters :
// -------------------------------------------------------------------------
//  $content          - utf symbol code i.e. "\e960" (see _external_fonts.scss)
// -------------------------------------------------------------------------
//  $font-size        - glyph size
// -------------------------------------------------------------------------
//  $line-height      - glyph line-height
// -------------------------------------------------------------------------
//  $pseudo          - attach to ::before or ::after

// NOTE: content can be included {....}

// sample:

// @include icomoon-icon("\e960", $font-size: rem(7), $line-height: 18px) {
//     margin-right: 7px;
// }

@mixin icomoon-icon($content, $font-size: inherit, $line-height: inherit, $pseudo: before) {
    &::#{$pseudo} {
        // COPY from _external_fonts.scss
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon-arnotts' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;

        font-size: $font-size;
        line-height: $line-height;
        letter-spacing: 0 !important;

        content: $content;

        @content; // include anything else if needed
    }
}

// Mixin used to create custom media-queries
// Parameters :
// -------------------------------------------------------------------------
//  $min-width  - min-width value (default = undefined)
// -------------------------------------------------------------------------
//  $max-width  - max-width value (default = undefined)
// -------------------------------------------------------------------------
// Examples :
// -------------------------------------------------------------------------
// if you wish to have just min-width:
// .selector { @include custom-respond-to(320px) { your styles } }
// -------------------------------------------------------------------------
// if you wish to have just max-width:
// .selector { @include custom-respond-to($max-width: 640px) { your styles } }
// -------------------------------------------------------------------------
// if you wish to have min-width and max-width:
// .selector { @include custom-respond-to(768px, 1024px) { your styles } }

@mixin custom-respond-to($min-width: undefined, $max-width: undefined) {
    @if $min-width != undefined and $max-width != undefined {
        @media screen and (min-width: $min-width) and (max-width: $max-width) {
            @content;
        }
    } @else if $min-width != undefined {
        @media screen and (min-width: $min-width) {
            @content;
        }
    } @else if $max-width != undefined {
        @media screen and (max-width: $max-width) {
            @content;
        }
    }
}



@mixin check($type: 'checkbox', $verticalCentered: true) {
    position: absolute;
    z-index: -1;
    opacity: 0;

    + span.error {
        position: absolute;
        top: 25px;

        + label {
            margin-bottom: 40px;
        }
    }

    ~ label {
        display: block;
        position: relative;
        padding-left: 30px;
        cursor: pointer !important;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            z-index: 0;
            border: 1px solid $color-secondary2;
            top: 0 !important;
            margin: 0 15px 0 0;
            opacity: 1 !important;

            @if ($type == 'radio') {
                border-radius: 50%;
            }

            @if ($verticalCentered == true) {
                margin-top: auto;
                margin-bottom: auto;
                bottom: 0;
            }
        }

        &::after {
            display: none;
        }
    }

    &:checked ~ label {
        &::after {
            display: block;
            background-color: $color-primary2;
            transform: scale(0.5);
        }
    }
}

@mixin dublin_tooltip($font_color: $hel-gray, $margin_left: 21px, $margin_left_mobile: 15px) {
    .form-row.form-row-field-dublincode {
        .field-wrapper {
            margin-bottom: 0;

            .input-select {
                & + .error {
                    position: relative;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }

        .form-field-tooltip {
            font-size: rem(14);
            color: $font_color;
            margin: 10px 0px 15px $margin_left;

            @include respond-to(mobile_strong) {
                margin: 3px 0 -5px $margin_left_mobile;
            }
        }
    }
}

@mixin appearance($type: none, $moz_type: none) {
    -webkit-appearance: $type;
    -moz-appearance: $type;
    -ms-progress-appearance: $type;
    appearance: $type;

    @if $moz_type != none {
        -moz-appearance: $moz_type;
    } @else {
        -moz-appearance: none;
    }
}

@mixin flexbox-inline(){
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
}

@mixin flex-wrap-new($type) {
    -webkit-flex-wrap: $type;
    -moz-flex-wrap: $type;
    -ms-flex-wrap: $type;
    flex-wrap: $type;
}

/**
    Applies for all Internet Explorer and Edge versions
**/
@mixin browsersIeAndEdge() {
    /* all IE versions <= 11 */
    @media screen and (-ms-high-contrast: none) {
        @content;
    }

    /* all edge versions */
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

@mixin hidden {
    display: none !important;
    visibility: hidden;
}
