.product-bundle__asset {
    width: 100%;
    float: left;
}

.product-bundle-list {
    float: left;
    width: 100%;
    text-align: center;

    h2 {
        text-transform: uppercase;
        text-align: center; 
        margin-bottom: 35px;
        padding: 0;
        letter-spacing: 1px;

        span {
            display: inline-block;
            position: relative;
            
            &:before {
                content:'';
                width: 50%;
                display: block;
                border-bottom: 1px solid;
                position: absolute;
                height: 0px;
                margin: 0 auto;
                top: 100%;
                left: 0;
                right: 0;
            }
        }
    }
    
    &__item-list {
        display: flex;
        justify-content: center;
        align-items: stretch;
        align-content: flex-start;
        flex-wrap: wrap;
    }

    .product-bundle-item {
        max-width: calc(100% / 6);
        flex-basis: calc(100% / 6);
        margin-bottom: 50px;
        padding: 0 6px;

        @include respond-to(mobile) {
            max-width: 50%;
            flex-basis: 50%;
        }

        a {
            font-size: rem(16);
            text-transform: uppercase;
        }

        .item-name {
            font-size: rem(15);
            text-transform: none;
        }
    }

    .product-bundle-image {
        @include lazyload-animation;

        text-align: center;
        position: relative;

        .product-thumbnail {
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    .product-bundle-show-more {
        font-family: $font-main;
        margin-top: 30px;
        margin-bottom: 50px;
        display: inline-block;
        padding: 0 22px;
        letter-spacing: 1px;
        line-height: 45px;
        text-align: center;
        color:$nobel;
        text-transform: uppercase;
        font-size: rem(15);
        border:1px solid $nobel;
        cursor: pointer;
        transition: .3s;

        &:active,
        &:hover {
            background: $nobel;
            color: $white;
        }
       
    }

    .product-bundle-details {
        padding: 0 10px;
        margin-bottom: 10px;

        .product-name-brand {
            display: block;
            font-size: rem(18);
            padding-top: 3px;
            text-transform: uppercase;
            line-height: 1.1;
            color:$nobel;
        }

        .item-name {
            font-size: rem(14);
            line-height: 1.4;
            margin: 6px 0;
            color:$nobel;
            display: block;
        }
    }  
}