// Form Components Arnotts

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &.error {
        border-color: $field-error-border-color;
        color: $field-error-text-color;

        &:focus {
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }

        @include placeholder {
            color: $field-placeholder-text-color !important;
        }
    }
}

input {
    &.password[type="password"],
    &.password[type="text"],
    &.input-text[type="password"] {
        padding-right: 60px; 

        @include respond-to(m_tablet_down) {
            padding-right: 45px;
        }
    }

    &:disabled {
        opacity: 1;
    }
}

span.error {
    border: 0;
}

.field-wrapper {
    .show-password,
    .hide-password {
        @include icon(eye);

        font-size: 0;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 10;
        width: 45px;
        height: 45px;

        &::before {
            font-size: rem(13);
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
        }
    }

    .show-password {
        &::before {
            color: $color-auxiliary19;
        }
    }

    .hide-password {
        &::before{
            color: $black01;
        }
    }
}

select {
    @include appearance();

    width: 100%;
    height: 46px;
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGhJREFUeNpiZGBgMABiZiD+CMR3GBBAEYiFYJxeIP4PxJ+BWA4qJgrEb6Hic0ACPEB8GyqwBapoKZT/BNk0J6ggCM9HYgcyoAFkSRBex4AFgIx9DlUAco80Aw4QAlWUxkAAVKALAAQYAFloG0xRpMIfAAAAAElFTkSuQmCC') right no-repeat;
    background-position: right 18px top 18px;
    background-color: $color-primary3;
    font-size: rem(16);
    text-transform: none;
    line-height: normal;
    color: $black01;
    padding: 11px 35px 11px 20px;
    border: $field-border-width solid $field-border-color;
    border-radius: 0;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: $color-primary3;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAMAAAAcEyWHAAAAP1BMVEUAAAAAT9wABuYALuEAJeIAUtsAVtoACOYADuUAKuEANOAAPN8AQd4ARt0ARt0ATNwAUtsAU9sAVNsAVdsAAOkHgGt8AAAAFHRSTlMAW/3b50Aj/PrfzLijjIhzODMoF04PN+0AAAA7SURBVAjXLchJDgAhDMRAJwzMvvf/30pA1MWSMctANmOTHj5X4l80sWoucEtJugjR2E1x+Ut36mDYCRVYuAHzZauqIwAAAABJRU5ErkJggg==');
    }

    &:hover {
        border-color: $field-border-color;
    }

    &.error {
        border: $field-border-width solid $field-error-border-color;
    }

    option {
        font-style: normal !important; // override js-inlied style
    }
}

.input-radio {
    @include check(radio);
    opacity: 1;
}

.input-checkbox {
    @include check(checkbox);
}

.row-element-type-input,
.row-element-type-select {
    label {
        display: none;
    }

    input[type = 'checkbox'],
    input[type = 'radio'] {
        + label {
            display: block;
        }
    }
}

.form-horizontal {
    .field-wrapper {
        padding-left: 0;
        width: 100%;
    }
}

.registration-form {
    .form-fieldset-loyalty {
        margin-top: 20px;
        font-size: rem(14);

        .field-wrapper {
            label {
                font-size: rem(14);
                line-height: 1;
            }

            .input-radio {
                // Prevent duplicate radio buttons on wonder account registration
                display: none !important;
            }
        }
    }

    .form-fieldset-subtitle {
        margin-bottom: 20px;
        line-height: 2;
    }

    .form-fieldset-inline {
        .error-form{
            text-transform: none;
            font-size: rem(18);
        }
        .form-row.label-inline {
            display: inline-block;
            margin-right: 15px;

            @include respond-to(mobile_min) {
                margin-right: 0;
                width: 24%;
            }
        }

        .field-wrapper {
            float: none;
        }
    }
}


.registration,
.pt_account {
    .registration-form {
        @include dublin_tooltip;
    }
}

.registration {
    .registration-form {
        .form-row.form-row-field-dublincode {
            .form-field-tooltip {
                margin-bottom: 0;

                @include respond-to(mobile_stronger) {
                    margin-bottom: 15px;
                }

                @include respond-to(mobile_strong) {
                    margin-bottom: -5px;
                }
            }
        }

        .form-fieldset-loyalty {
            .field-wrapper {
                @include respond-to(mobile) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.edit-adress-form {
    @include dublin_tooltip($margin_left_mobile: 15px);
}

.cart-coupon_code { // During redesign this styles should become global
    .field-wrapper {
        font-family: $font-main;
    
        input[type="text"] {
            padding: 12px 40px 0 12px;
            height: 50px;
            font-size: rem(14);

            @include respond-to(mobile_strong) {
                font-size: rem(13);
            }

            &:disabled {
                color: $black01;
                -webkit-text-fill-color: $black01;
            }

            &.valid,
            &.invalid {
                background-repeat: no-repeat;
                background-size: 20px;
                background-position: top 15px right 17px;
                border-bottom: 2px solid;
                background-color: $white;
            }

            &:focus {
                outline: none;
                border-bottom: 2px solid $medium-blue;
            }
    
            &.valid {
                background-image: url("../images/icons/valid_green.svg") !important;
                border-bottom-color: $green01;
    
                &:-webkit-autofill {
                    box-shadow: inset 0 0 0 30px transparent !important; // Fix for IOS
                    -webkit-animation-name: autofill-valid;
                    -webkit-animation-fill-mode: both;
                }
            }
    
            &.invalid {
                background-image: url("../images/icons/invalid.svg") !important;
                border-bottom-color: $red01;
    
                &:-webkit-autofill {
                    box-shadow: inset 0 0 0 30px transparent !important; // Fix for IOS
                    -webkit-animation-name: autofill-invalid;
                    -webkit-animation-fill-mode: both;
                }
            }
        }
    
        input[type="text"] {
            &.invalid,
            &.error {
                border-bottom: 2px solid $red01;
            }
        }
    
        input[type="text"] {
            ~ label {
                pointer-events: none;
                color: $black01;
                font-size: rem(14);
                font-family: $font-main;
                position: absolute;
                display: block;
                top: 0;
                left: 12px;
                line-height: 50px;
                transition: all 0.3s ease-out;
                white-space: nowrap;
    
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50px;
                    left: 0;
                    white-space: nowrap;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
        
                &.active {
                    font-size: rem(10);
                    line-height: 25px;
                }
            }
    
            &.error,
            &.invalid,
            &.valid,
            &:focus {
                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                    color: $hel-gray;
                }
            }
    
            &:-webkit-autofill { //this prefix should be written separately for correctly work
                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                    color: $hel-gray;
                }
            }
    
            &:-moz-autofill { //this prefix should be written separately for correctly work
                ~ label {
                    font-size: rem(10);
                    line-height: 25px;
                    color: $hel-gray;
                }
            }
    
        }
    }
}
