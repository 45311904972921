.ui-tooltip.ui-widget-content {
    background: $color-primary2;
    border-radius: 0;
    box-shadow: 2px 3px 3px 0 $color-auxiliary4;
    left: auto !important;
    
    &.tooltip-pt_product-details {
        .btn-close {
            &::before {
                content: $aricons-close-fine;
            }
        }

        &.is-touch {
            padding-right: 30px;

            .btn-close {
                right: 0;
                top: 0;
            }
        }
    }
}
