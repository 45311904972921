.dialog-quickview {
    .ui-dialog-content {
        overflow: visible;// to reset scroll on product set in quickview. Please check there before remove

        body & {
            padding-top: 35px;
        }
    }

    .pdp-main {
        margin-top: 0;
    }

    .product-col-1 {
        @include respond-to(touch) {
            width: 48%;
            float: left;
        }
    }

    .product-col-2 {
        @include respond-to(touch) {
            width: 52%;
        }
    }

    .promo-flag {
        @include respond-to(mobile_strong_over) {
            text-align: left;
        }
    }

    .promo-flag-above {
        @include respond-to(touch) {
            @include hidden;
        }
    }

    .product-name {
        padding: 0;
    }

    .product-variations {
        .attribute {
            margin-bottom: 25px;
        }

        .swatches {
            .selectable {
                a {
                    height: 30px;
                }
            }
        }
    }

    .price_review-wrapper {
        @include respond-to(mobile_stronger) {
            align-items: flex-start;
        }
    }

    .product-price {
        margin: 0 0 15px;
    }

    .product-variations {
        .label {
            font-size: rem(14);
            text-transform: uppercase;
            margin-bottom: 10px;

            &.va-size {
                margin-bottom: 4px;
            }
        }

        .swatches .selectable {
            margin-right: 10px;
        }
    }

    .whishlist_instore-actions {
        margin-bottom: 50px;
    }
    
    .quickview-actions {
        .product_actions-instore_link,
        .product_actions-wishlist_link {
            @include product_actions-button;
        }

        .product_actions-wishlist_link {
            @include shared-wishlist;
        }
    }

    .quick-view-description {
        height: 90px;
        overflow: hidden;
        position: relative;

        @include respond-to(mobile_tablet) {
            display: none;
        }

        &::after {
            @include position(absolute, 0, 0, 0, 0);

            background: linear-gradient(to bottom, rgba($color-primary3,0) 0%,rgba($color-primary3,0.37) 37%,rgba($color-primary3,1) 100%); // Fix for Safari
            content: '';
            pointer-events: none; // to make text still selectable
        }

        ul {
            font-size: rem(16);
            list-style: square;
            padding: 0 2em;
        }
    }

    .quick-view-description-link {
        color: $color-primary2;
        font-size: rem(16);
        letter-spacing: 0;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }

        &:hover {
            color: $dim-gray;
        }
    }

    .quickview-prev,
    .quickview-next {
        color: $color-primary2;
        font-size: rem(14);
        transition: color 0.3s ease-in-out;

        &:hover {
            @include respond-to(touch_over) {
                color: $color-secondary2;
            }
        }

        &:disabled {
            color: $color-secondary2;
            background-color: transparent;

            &:hover {
                color: $color-secondary2;
                background-color: transparent;

                @include respond-to(touch_over) {
                    color: $color-secondary2;
                }
            }
        }
    }

    .quickview-prev {
        .bticons-left {
            &::before {
                content: $aricons-small-arrow-left;
                font-size: rem(10);
            }
        }
    }

    .quickview-next {
        .bticons-right {
            &::before {
                content: $aricons-small-arrow-right;
                font-size: rem(10);
            }
        }
    }

    .is-product-set {
        .product-name-title {
            font-size: 1.8rem;
            letter-spacing: 0;
            text-align: left;

            &:before {
                display: none;
            }
        }

        .product-set {
            .hidden-mobile {
                display: block!important;// to show product name on tablet in quickview
            }

            a + .product-add-to-cart {
                margin-top: 10px;
            }
        }

        .pdpForm {
            width: 100%;
        }

        .quickview-prev,
        .quickview-next {
            margin-top: 0;
        }

        .product-set-list {
            -webkit-overflow-scrolling: touch;
        }
    }

    .product-col-1 .main-video.product-image {
        @include respond-to(mobile_tablet) {
            margin: 0 auto;
        }
    }

    .item-stock-info {
        display: none;
    }
}

body .ui-dialog.dialog-quickview[aria-describedby="QuickViewDialog"] .ui-dialog-titlebar-close{
    @include respond-to(mobile_strong){
        bottom: 0;
        height: 60px;
        padding-bottom: 20px;
        width: 60px;
    }
}
