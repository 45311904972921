.cross-sell-dialog {
   @include respond-to(m_tablet) {
      min-width: 700px; 
   }
   
   &.ui-dialog {
      @include respond-to(mobile_strong) {
         max-width: 100%;
      }
   }

   @include respond-to(tablet_ext) {
      max-width: 83%;
   }

   .ui-dialog-content {
      @include respond-to(m_mobile_down) {
         margin-top: 0;
      }
   }
   
   .ui-widget-header {
      padding: 15px 25px 10px !important;

      .ui-dialog-title {
         @include icon(checked);

         font-size: rem(22);
         text-transform: none;
         text-align: left;
         font-family: $font-main;
         padding: 0 0 0 35px;
         position: relative;

         &::before {
            color: $white;
            position: absolute;
            z-index: 1;
            font-size: rem(14);
            left: 3px;
            top: 50%;
            transform: translateY(-50%);
         }

         &::after {
            content: "";
            height: 20px;
            width: 20px;
            background: $black01;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
         }
      }
   
      .ui-dialog-titlebar-close {
         @include icon(close-icon); 

         top: 50%;
         width: 20px;
         height: 20px;
         transform: translateY(-50%);
         right: 22px;

         @include respond-to(mobile_strong) {
            width: 25px;
            height: 25px;
            right: 19px;
         }

         &::before {
            line-height: 20px;
            font-size: rem(14);

            @include respond-to(mobile_strong) {
               font-size: rem(10);
               line-height: 30px;
            }
         }

         .ui-icon-closethick {
            width: 20px;
            height: 20px;

            @include respond-to(mobile_strong) {
               width: 25px;
               height: 25px;
            }
         }
      }
   }
   
   .cross-sell-content {
      border-top: 1px solid $nobel;
      padding-top: 20px;

      @include respond-to(touch_over) {
         max-width: 650px;
      }

      .cart-products {
         display: flex;
         flex-wrap: wrap;
         max-width: 100%;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 20px;

         .cart-product {
            width: 48%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            min-height: 112px;

            @include respond-to(mobile_strong) {
               width: 100%;
               margin-bottom: 30px;
            }

            &-image {
               width: 85px;

               img {
                  width: 100%;
               }
            }

            &-data {
               width: 57%;
               font-family: $font-main;
               font-size: rem(14);

               .brand {
                  font-size: rem(13);
                  font-family: $font-main-bold;
                  margin-bottom: 9px;
                  text-transform: uppercase;
               }

              .name {
                  margin-bottom: 13px;
               }

               .attribute {
                  text-transform: capitalize;
              }
            }
         }
      }

      .product-recommendations-carousel {
         margin: 25px 0 0 0;

         .button-dual_functional {
            display: block;
            width: 90%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
         }

         .bticons-zoom {
            @extend button.coloured;
            @extend .button-type3;
      
            display: block;
            border: 0;
            min-width: auto;
            width: 100%;
   
            &::before {
               content: attr(data-alt-title);
               font-family: $font-main-bold;
            }
      
            @include respond-to(m_tablet_down) {
               min-width: auto;
            }
         }

         .carousel {
            .jcarousel-nav {
               padding: 12px 0;
               top: 50%;
            }

            .jcarousel-prev {
               transform: translateY(-50%) rotate(180deg);
            }

            .jcarousel-next {
               transform: translateY(-50%);
            }

            &-item {
               min-width: 160px;
            }

            .product-image {
               margin: 0 0 30px;
               width: 160px;
            }

            &-list {
               .carousel-item {
                  .product-description-link {
                     .product-brand {
                        font-size: rem(13);
                     }
                  }
               }
            }
         }
      }
   }

   .cart-subtotal {
      font-size: rem(14);
      font-family: $font-main-bold;
      margin-bottom: 18px;
   }

   .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 25px;
      border-bottom: 1px solid $nobel;

      &-button {
         background-color: $medium-blue;
         color: $white;
         margin-right: 22px;

         @include respond-to(mobile) {
            margin: 0 22px 0 0;
         }

         @include respond-to(mobile_strong) {
            margin: 0 0 20px 0;
         }

         &-type2 {
            @include respond-to(mobile) {
               width: auto;
               margin: 0;
            }
         }
      }

      .button-type1 {
         width: 30.5%;

         @include respond-to(desktop) {
            max-width: 200px;
         }

         @include respond-to(mobile_strong) {
            width: 100%;
         }
      }
   }

   .cross-sell-recommendations {
      .main-title {
         font-size: rem(18);
         font-family: $font-main;
         margin: 0 0 25px 0;
      }
   }
}