$footer-height           : 55px;
$footer-titles-padding   : 20px; // padding of toggle
$footer-paddings-mobile  : 30px;

.footer {
    position: relative;

    &-links {
        background-color: $footer-bottom-bg;
        bottom: 0;
        color: $footer-bottom-color;
        left: 0;
        min-height: 50px;
        position: fixed;
        right: 0;
        z-index: $footer-z-index;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 2%;

        @include respond-to(m_tablet_down) {
            position: static;
        }

        .no-desktop .mini-cart-active & {
            position: static;
        }

        a {
            color: $footer-bottom-links-color;

            &:hover {
                color: $footer-bottom-links-hover-color;
            }
        }
    }

    &-container {
        margin: 0 auto 0;
        max-width: 1260px;
        display: flex;
        flex-wrap: wrap;

        @include respond-to(m_mobile_down) {
            flex-direction: column;
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }

        @include respond-to(mobile_strong_over) {
            @include order(2);
        }

        @include custom-respond-to($break_mobile_strong, 1023px) {
            width: 30%;
            flex-direction: column;
        }

        @include respond-to(m_desktop) {
            width: 83%;
            justify-content: space-between;
        }

        @include respond-to(desktop_over) {
            width: 75%;
        }
    }

    &-position {
        max-height: 50px;
        overflow: hidden;
        padding-top: 13px;
        transition: 0.75s;

        @include respond-to(m_desktop) {
            @include icon-toggler;
            max-height: $footer-height;
            padding-top: $footer-titles-padding;

            &.expanded {
                max-height: 500px;

                dt:after {
                    content: "\e90f";
                }

                dd {
                    display: block;
                    visibility: visible;
                }
            }
        }

        @include respond-to(m_tablet_down) {
            max-height: none;
            overflow: visible;
        }
    }

    &-column {
        .content-asset {
            @include respond-to(m_tablet_down) {
                border-bottom: none;
                margin: 0;
                text-align: left;
            }
        }
    }

    dl {
        margin: 0;
    }

    dt,
    .copyright-title {
        font-size: rem(14);

        @include respond-to(m_desktop) {
            margin-bottom: $footer-titles-padding;
            line-height: 1;
        }
    }

    dt {
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;

        @include respond-to(m_tablet_down) {
            @include icon-toggler;

            margin: 10px 0 20px;

            &.expanded {
                
                & + dd {
                    display: block;
                }

                &:after {
                    content: "\e90f";
                }
            }
        }

        &:hover {
            color: $footer-bottom-links-hover-color;
        }

        &:after {
            display: none;
        }

        &.expanded {
            @include respond-to(m_tablet_down) {
                color: $footer-bottom-links-hover-color;
            }
        }

        span {
            vertical-align: middle;
            line-height: 0;
            font-size: rem(12);

            @include respond-to(desktop) {
                font-size: rem(10);
            }
        }

        .aricons-plus,
        .aricons-minus {
            font-size: rem(6);
            padding-left: 2px;

            @include respond-to(m_tablet_down) {
                padding-left: 10px;
            }
        }
    }

    dd {
        font-size: rem(12);
        margin: 0 0 13px;

        @include respond-to(desktop) {
            font-size: rem(10);
        }

        @include respond-to(m_desktop) {
            visibility: hidden;
        }

        @include respond-to(m_tablet_down) {
            margin-bottom: 50px;
            text-transform: uppercase;
            display: none;
        }

        li {
            @include respond-to(m_tablet_down) {
                margin: 30px 0;
            }
        }
    }

    body:not([data-locale="en_IE"]) & { // Hide for international locales
        .gift-card {
            display: none;
        }
    }
}

.opening-hours-wrapper {
    width: 165px;

    @include respond-to(desktop) {
        width: 140px;
    }

    .opening-hours {
        overflow: hidden;
        text-align: left;
    }

    dd {
        @include respond-to(m_tablet_down) {
            text-transform: none;
        }

        li {
            @include respond-to(m_tablet_down) {
                margin: 15px 0;
            }
        }
    }

    .expanded {
        @include respond-to(m_tablet_down) {
            margin-bottom: 0;
        }
    }

    .days {
        float: left;
    }

    .hours {
        float: right;
    }
}

.footer-copyright {
    @include respond-to(m_desktop) {
        padding-top: $footer-titles-padding - 2px;
        width: 15%;
        max-width: 210px;
        position: relative;
    }

    @include respond-to(mobile_strong_over) {
        @include order(1);
    }

    @include respond-to(m_tablet_down) {
        padding: 0 30px 30px;
        width: 100%;
    }

    @include custom-respond-to($break_mobile_strong, 1023px) {
        width: 45%;
        padding: 30px;
    }
}

.copyright-title {
    color: $color-primary3;
}

.copyright-text {
    @include respond-to(m_desktop) {
        position: absolute;
        top: $footer-height;
    }
}

.copyright-text-paragraph {
    margin-top: 0;
    font-size: rem(12);
    line-height: normal;

    @include respond-to(desktop) {
        font-size: rem(10);
    }

    @include custom-respond-to($break_mobile_strong, 1023px) {
        font-size: rem(15);
    }

    span {
        display: block;
    }
}

.footer-top {
    @include respond-to(mobile_strong_over) {
        margin-bottom: 55px;
    }
}

.footer-email-section {
    max-height: 600px;
    position: relative;
    overflow: hidden;

    @include respond-to(mobile_strong) {
        padding-top: 100%;
    }

    @include respond-to(mobile) {
        min-height: 660px;
    }

    @include respond-to(tablet_plus) {
        min-height: 582px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 20%;
        background-image: linear-gradient(rgba($color-primary3, 0.8), transparent);
    }
}

.footer-email-content-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @include respond-to(touch) {
        -moz-box-orient: vertical;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -webkit-box-direction: normal;
         flex-direction: column;
    }
}

.footer-email-bg {
    display: block;
    min-height: 340px;
    min-width: 100%;
    position: relative;
    left: 50%;
    max-width: none;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);

    @include respond-to(mobile) {
        position: absolute;
        top: 50%;
        min-height: 100%;
        transform: translateX(-50%) translatey(-50%);
        -webkit-transform: translateX(-50%) translatey(-50%);
    }
}

.footer-email-content {
    @include border-tape($bgcolor:$color-primary3);
    width: 90%;
    max-width: 520px;
    top: 50%;
    z-index: $z-min;

    @include respond-to(mobile) {
        background-color: rgba($color-primary3, .8);
    }

    @include respond-to(touch) {
        margin: 20px auto;

        &:last-child {
            margin-top: 0;
        }
    }

    @include respond-to(touch_over) {
        margin: 0 auto;
    }
}

.email-join-form {
    @include respond-to(mobile_mid) {
        padding: 30px;
    }

    @include respond-to(mobile) {
        padding: 30px 40px;
    }

    @include respond-to(tablet_plus) {
        height: 230px;
        padding: 45px 70px 30px;
    }
}

.footer-email-title,
.footer-email-description {
    text-align: center;
}

.footer-email-title {
    font-size: rem(22);
    margin-bottom: 24px;
}

.footer-email-description {
    display: block;
    font-size: rem(14);

    @include respond-to(tablet_plus) {
        padding: 0 40px;
        margin-left: -10px;
        margin-right: -10px;
    }
}

.footer-email-field {
    position: relative;
    margin-top: 20px;

    .input-text {
        font-size: rem(14);
        padding-bottom: 12px; // fix to prevent gap caused by border-tape()

        @include respond-to(tablet_plus) {
            padding-right: 100px;
        }
    }

    span.error {
        position: absolute;
        background-color: transparent;
        text-transform: none;
        border: none;
        top: 43px;

        @include respond-to(mobile) {
            position: static;
        }
        @include respond-to(mobile_min) {
            padding: 0;
        }
    }
}

.footer-text-link-container {
    margin-top: 25px;
    text-align: center;
}

.email-join-submit {
    font-family: $font-main-bold;
    font-size: rem(14);
    color: $black01;
    text-transform: none;
    background-color: transparent;

    &:hover {
        color: $color-primary2;
        background-color: transparent;

        .icon {
            color: $color-primary2;
        }
    }

    @include respond-to(tablet_plus) {
        width: 100px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0;
    }

    @include respond-to(mobile) {
        margin-top: 10px;

        .error ~ & {
            margin-top: 0;
        }
    }

    span {
        display: inline-block;
        line-height: 18px;
        vertical-align: middle;
    }

    .icon {
        font-size: rem(8);
        padding-left: 8px;
    }
}

#onetrust-pc-sdk,
#onetrust-banner-sdk {
    *:focus {
        outline: none !important; // Override external styles
    }
}

#onetrust-pc-sdk .ot-tgl input:focus + .ot-switch {
    outline: none !important;
}