.scroll-to-top {
    bottom: 80px;
    right: 50px;
    padding: 1px 7px;
    border-image: $border-color-scroll-to-top 8 fill repeat;
    border-width: 7px;
    background: $color-primary3;
    border-radius: 10px;
    height: 50px;
    width: 50px;
    transition: border-image 300ms ease-out;
    text-align: center;

    @include respond-to(mobile_strong) {
        bottom: 20px;
        padding: 0;
        right: 20px;
    }

    &:before,
    &:after {
        content: " ";
        display: inline-block;
        width: 12px;
        height: 2px;
        border-top: 1px solid $black01;
        border-radius: 2px;
        margin-top: 15px;
        transition: border-color 300ms ease-out;
    }

    &:before {
        transform: rotate(-45deg);
        margin-right: -2px;
    }

    &:after {
        transform: rotate(45deg);
        margin-left: -2px;
    }

    &:hover {
        border-image: $border-blue-url 8 fill repeat;

        &:before,
        &:after {
            border-color: $color-primary2;
        }
    }
}
