.cat-landing-slotbanner-wrapper {

    .slot-assets-list {
        @include full-page-content($scrollbar-fix: true);
    }

    .two-columns-header-slot-wrapper {
        width: 100vw;
        margin-left: -50vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-right: -50vw;

        .slot-assets-list {
            width: inherit;
            margin-left: inherit;
            margin: inherit;
        }

        .slot-assets-list-item {
            @include respond-to(tablet_plus) {
                width: 50%;
            }
        }
    }

    .slot-assets-list-item {
        width: 100%;
    }
}

.category-sidebar-navigation {
    .category-level-null,
    .featured-brands-level-null,
    .category-level-1 {
        > li {
            > .category-link,
            > .featured-brands-link {
                border: none;

                &.toggle {
                    &::after {
                        content: $aricons-plus;
                        transition: all 0.3s ease-in-out;

                        .chanel-section & {
                            color: $black01;
                        }
                    }

                    &.expanded {
                        &::after {
                            content: $aricons-minus;
                            border-bottom: none;
                            margin-top: -10px;
                            transition: all 0.3s ease-in-out;

                            .chanel-section & {
                                color: $black01;
                            }
                        }
                    }
                }
            }

            &:hover {
                > .category-link,
                > .featured-brands-link {
                    &::after {
                        color: $color-primary2;

                        .chanel-section & {
                            color: $black01;
                        }
                    }
                }
            }

            .category-link,
            .featured-brands-link {
                &:hover {
                    color: rgba($black01, .6);

                    &::after {
                        .chanel-section & {
                            color: $black01;
                        }
                    }
                }
            }
        }

        .category-level-1,
        .featured-brands-level-null {
            > li {
                border: 1px solid $color-secondary3;
                margin-bottom: 10px;

                &:hover {
                    @include respond-to(tablet_plus) {
                        background-color: rgba($black01, .03);
                    }
                }
            }
        }
    }

    .category-level-null,
    .featured-brands-level-null {
        > li {
            .category-browse {
                border: 1px solid $color-secondary3;
            }
        }
    }

    .category-level-2 {
        padding-left: 10px;
    }
}

// ------ CHANEL SECTION ONLY -------- //
.chanel-section {
    .category-level-null,
    .featured-brands-level-null,
    .category-level-1 {
        > li {
            position: relative;

            .category-link {
                &:hover {
                    background: transparent;
                }
            }

            .category-link-icon {
                position: absolute;
                top: 0;
                right: 0;
                width: 20%;
                height: 100%;

                &.toggle {
                    &::after {
                        content: $aricons-plus;
                        transition: all 0.3s ease-in-out;
                        font-family: $font-icons;
                        font-size: rem(12);
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        border: none;
                    }
                }

                &.expanded {
                    &::after {
                        content: $aricons-minus;
                    }
                }
            }
        }
    }

    .category-base {
        .categories .category-level-1 {
            > li {
                > .category-link {
                    display: inline-block;
                    padding-right: 30px;
                }
            }
        }
    }

    .products__load-more {
        &:hover {
            background: $color-secondary4;
        }
    }
}
// ------ CHANEL SECTION END -------- //

.column-left-sidebar {
    .primary-clp {
        @include respond-to(tablet_plus) {
            padding-left: 30px;
        }
    }
}

.column-wrapper {
    &.column-left-sidebar {
        margin-top: 40px;

        @include respond-to(mobile) {
            margin-top: 20px;
        }
    }

    &.horizontal-wrapper,
    &.vertical-wrapper {
        margin-top: 0;
    }

    &:not(.column-left-sidebar) {
        .primary-clp {
            @include respond-to(mobile) {
                padding: 0 15px;
            }

            .slot.full-width-block {
                @include full-page-content($scrollbar-fix: true);
            }
        }
    }

    > .category-banner:first-child {
        display: none;
    }
}

.main-title.best-seller-title {
    margin-top: 20px;
}

// Best sellers carousel max width on CLP pages
div[class*="cat-landing-slot"] {
    .carousel-wrapper {
        @include respond-to(tablet_plus) {
            margin-left: auto;
            margin-right: auto;
            max-width: 1260px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
