.product-tile {
    @extend %clearfix;
    color: $nobel;
    overflow: hidden;
    padding: 0 15px 30px 0;
    position: relative;
    height: 100%;
    text-align: left;
    max-width: 220px;

    @include respond-to(m_tablet_down) {
        width: 75%;
        max-width: 250px;
        margin: 0 auto;
        padding: 0 0 30px 0;
    }

    @include respond-to(mobile_strong) {
        width: 100%;
        padding: 0 5px 30px 5px;
    }

    @include respond-to(m_desktop) {
        .horizontal-wrapper & {
            max-width: 292px;
        }
    }

    &-wrapper {
        height: 100%;
    }

    img {
        max-width: 100%;
    }

    .product-image {
        margin: 0 0 12px;

        .product-recommendation-mobile & {
            height: auto;
        }

        .thumb-link {
            @include lazyload-animation;

            display: block;
            position: relative;
            width: 100%;
            text-align: center;

            img {
                position: absolute;
                bottom: 0;
                top: 0;
                right: 0;
                left: 0;
                width: 100%;
            }
        }

        .promo-flag {
            display: none; // Hide for usual tile and show in wide-tiles
        }
    }

    .product-description-link {
        display: block;
        margin-bottom: 20px;
        max-width: 100%;
        text-align: left;
    }

    .product-brand {
        display: block;
        padding-top: 3px;
        font-size: rem(14);
        line-height: 1.1;
        text-transform: uppercase;
        font-family: $font-main-bold;

        @include respond-to(mobile) {
            font-size: rem(13);
        }
    }

    .product-name {
        display: block;
        font-size: rem(14);
        line-height: 1.4;
        margin: 6px 0;
        padding: 0 5px 0 0;

        @include respond-to(mobile) {
            line-height: 1.2;
            font-size: rem(13);
        }
    }

    .product-collection {
        font-size: rem(12);
        font-family: $font-main-bold;

        @include respond-to(mobile) {
            font-size: rem(11);
        }
    }

    .product-pricing {
        display: block;
        font-size: rem(14);
        font-family: $font-main-bold;

        @include respond-to(mobile) {
            font-size: rem(13);
        }

        .now-sell-price {
            color: $red01;
            font-size: rem(14);

            @include respond-to(mobile) {
                font-size: rem(13);
            }
        }

        .product-standard-price {
            text-decoration: line-through;
            color: $charcoal;
            font-family: $font-main-bold;
            font-size: rem(12);

            @include respond-to(mobile) {
                font-size: rem(11);
            }

            &.rrp-price {
                text-decoration: none;
            }
        }
    }

    .product-swatches-all {
        cursor: pointer;
        font-size: rem(12);
        color: $hel-gray;

        @include respond-to(mobile) {
            font-size: rem(11);
        }
    }

    .tile-compare-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 10px;

        .field-wrapper {
            margin-bottom: 0;
        }
    }

    .tile-swaches-wrapper {
        margin-bottom: 10px;
        clear: both;
    }

    .product-swatches {
        ul {
            list-style: none outside none;
            margin: 0;
            overflow: hidden;
            padding: 0;
            font-size: 0;

            &.swatch-toggle {
                background: none repeat scroll 0 0 $white;
                display: none;
                position: absolute;
                left: 0;
                right: 0;

                img {
                    margin-top: 0;
                }
            }
        }

        &:hover ul.swatch-toggle {
            display: block;
        }

        li {
            display: inline-block;
            vertical-align: top;
        }

        .swatch {
            display: block;
            width: 30px;
            margin-right: 5px;

            &.unselectable {
                cursor: default;
                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 30px;
                    border: 2px solid $lightest-gray;
                    border-radius: 50%;
                    z-index: 1;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 28px;
                    left: 0;
                    top: 15px;
                    border-bottom: 2px solid $lightest-gray;
                    transform: rotate(-45deg);
                    z-index: 1;
                }

                img {
                    opacity: 0.75;

                    &:hover {
                        border: none;
                    }
                }
            }

            img {
                height: 30px;
                width: 30px;
                max-width: 100%;
                margin-top: 0;
                border-radius: 50%;
            }
        }

        .selected img,
        img:hover {
            border: 2px solid $lightest-gray;
        }
    }

    .product-promo {
        font-size: 0.8em;
        margin: 0.5em 0;
        text-transform: uppercase;
    }

    .product-compare {
        position: relative;
        display: inline-block;
        line-height: 11px;

        @include respond-to(mobile_min) {
            margin-top: 90px;
        }

        label {
            line-height: 1;
            width: auto;
            font-size: rem(14);

            &:before {
                position: absolute;
                left: -15px;
                top: -1px;
                content: "\f096";

                display: inline-block;
                font: normal normal normal 16px/18px FontAwesome;
                color: $gainsboro;
                margin-right: 10px;
            }
        }

        input[type="checkbox"] {
            display: none;

            &:checked + label:before {
                content: "\f046";
                color: $black01;
            }
        }
    }

    .product-raiting-wrapper {
        line-height: 2;

        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }

        .rating {
            display: inline-block;

            i {
                width: 20px;
                font-size: rem(18);
            }
        }
    }

    .wide-tiles & {
        height: auto !important; // overwrite synced heights set on element

        @include respond-to(m_desktop) {
            max-width: none;
        }

        @include respond-to(mobile) {
            padding: 20px 0 0;
            width: 100%;
        }

        .product-tile-wrapper {
            @extend %clearfix;
        }

        .product-col-1 {
            float: left;
            width: 50%;
            padding: 1.5rem 0;

            @include respond-to(mobile_tablet) {
                float: none;
                width: 100%;
                padding: 0;
            }
        }

        .product-primary-image {
            text-align: center;
        }

        .product-image {
            @include lazyload-animation;

            position: relative;

            .promo-flag {
                display: block;
            }
        }

        .primary-image {
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
        }

        .product-col-2 {
            float: right;
            width: 50%;
            text-align: left;

            @include respond-to(mobile_tablet) {
                float: none;
                width: 100%;
            }
        }

        .product-name {
            margin: 0;
            padding: 18px 0 16px;
            overflow: visible;
            max-height: 100%;
        }

        .product-name-brand {
            display: block;
            font-size: rem(14);
            margin-bottom: 5px;
            color: $nobel;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .product-name-title {
            display: block;
            color: $nobel;
            font-size: rem(14);
            line-height: 1.6em;
            letter-spacing: 1px;
            text-transform: none;
        }

        .product-price {
            font-size: rem(14);
            margin-bottom: 15px;

            .price-standard {
                color: $charcoal;
                font-size: rem(13);
                text-transform: uppercase;
            }

            .price-standard-qq-coment {
                display: inline;
            }

            span + span {
                color: $charcoal;
                font-size: rem(13);
                text-transform: uppercase;
                display: block;
                text-decoration: line-through;

                @include respond-to(mobile_tablet) {
                    display: inline-block;
                }
            }

            .product-standard-price {
                @include respond-to(mobile) {
                    font-size: rem(11);
                }
            }

            .product-sales-price {
                font-size: rem(13);
            }

            .now-sell-price {
                color: $red01;
            }
        }

        .price-sales {
            font-size: rem(22);
            color: $nobel;
            margin: 0;
        }

        .product-review {
            position: static;
            font-size: 0;

            @include respond-to(mob_tablet) {
                margin-top: 0;
            }

            i {
                width: 20px;
                font-size: rem(18);
            }

            span {
                display: inline-block;
                padding-left: 10px;
                color: $charcoal;
            }
        }

        .product-variations {
            ul {
                margin-bottom: 0;
            }

            .label {
                font-size: rem(16);
                display: inline-block;
                cursor: pointer;
                line-height: 1.5;
                margin-bottom: 4px;
                text-transform: capitalize;
            }

            .attribute {
                @extend %clearfix;
                position: relative;
                overflow: visible;

                .toggle {
                    > ul {
                        display: none;
                        max-height: 177px;
                        overflow: auto;
                        margin-bottom: 0;
                    }

                    &.expanded {
                        .swatches {
                            display: block;
                            position: absolute;
                            top: 38px;
                            background: $white;
                            width: 100%;
                            z-index: 1;
                            border: 1px solid $tutu;

                            li {
                                display: block;
                                width: 100%;
                                height: auto;
                                line-height: normal;
                                margin-bottom: 0;
                                padding: 0 5px;
                                background: $white;
                                -webkit-transition: background .3s ease-in-out;
                                transition: background .3s ease-in-out;
                                color: $charcoal;
                                opacity: 0.4;

                                @include respond-to(mobile_tablet) {
                                    text-align: left;
                                }

                                &:hover {
                                    background: $tutu;
                                }

                                &.selectable {
                                    cursor: pointer;
                                    opacity: 1;

                                    .swatchanchor {
                                        height: auto;

                                        span {
                                            text-decoration: none;
                                        }
                                    }
                                }

                                img {
                                    display: inline-block;
                                    max-width: 25px;
                                    height: auto;
                                    margin: 2px 5px 2px 0;
                                    vertical-align: middle;
                                }

                                .swatchanchor {
                                    height: auto;
                                    line-height: normal;
                                    vertical-align: middle;

                                    span {
                                        line-height: 35px;
                                        padding-top: 0;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }

                        .selected-value {
                            color: $tutu;
                        }
                    }

                    .selected-value {
                        position: relative;
                        border: 1px solid $tutu;
                        padding: 0 50px 0 5px;
                        line-height: 35px;
                        color: $nobel;
                        text-transform: none;
                        cursor: pointer;
                        font-size: rem(16);
                        width: 100%;

                        @include respond-to(mobile_tablet) {
                            text-align: left;
                        }

                        &:after {
                            font-family: $font-icons;
                            content: "\e902";
                            position: absolute;
                            right: 0px;
                            top: 50%;
                            width: 30px;
                            text-align: left;
                            font-size: rem(14);
                            padding: 5px;
                            line-height: normal;
                            transform: translate(0,-50%);
                            color: $tutu;
                        }

                        img {
                            display: inline-block;
                            margin: 0 5px 0 0;
                            max-width: 25px;
                            height: auto;
                            vertical-align: middle;
                        }
                    }
                }

                .value {
                    position: relative;
                    width: 55%;

                    @include respond-to(tablet) {
                        width: 62%;
                        margin: 0 auto;
                    }

                    @include respond-to(mobile) {
                        width: 100%;
                    }

                    &.toggle .swatches {
                        .selectable {
                            &:hover {
                                box-shadow: none;
                            }

                            .selected {
                                box-shadow: none;
                            }

                            img {
                                margin-right: 8px;

                                @include respond-to(mobile_tablet) {
                                    margin-left: 3px;
                                }
                            }

                            a {
                                width: 100%;
                                height: 25px;
                                cursor: pointer;
                            }
                        }

                        .swatchanchor {
                            width: 100%;
                            text-align: left;
                            cursor: default;

                            &:hover {
                                color: $nobel;
                            }

                            span {
                                display: inline-block;
                                padding-top: 2px;
                                vertical-align: middle;
                                font-size: rem(16);
                                line-height: 25px;
                            }

                            &:hover img {
                                border: 0;
                            }
                        }
                    }
                }
            }

            .swatches {
                li {
                    @include respond-to(mobile_tablet) {
                        float: none;
                        display: inline-block;
                    }
                }

                .selected-value {
                    display: none;
                }

                .swatchanchor {
                    cursor: pointer;
                    width: 36px;
                    height: 36px;
                    display: block;
                    margin: 0;
                    padding: 0;
                    border:0;
                    font-size: 0;
                    margin-right: 18px;

                    @include respond-to(mobile_tablet) {
                        margin: 0 3px;
                    }

                    &:hover img {
                        border: 2px solid $lightest-gray;
                    }
                }

                img {
                    margin-top: 0;
                    width: 100%;
                    height: auto;
                }

                .swatch-image {
                    max-width: 100%;
                }

                .selectable {
                    margin-right: 18px;

                    @include respond-to(mobile) {
                        margin: 0 9px;
                    }

                    a {
                        cursor: pointer;
                        width: 36px;
                        height: 36px;
                        display: block;
                        margin: 0;
                        padding: 0;
                        border:0;
                        font-size: 0;
                    }

                    &.selected {
                        box-shadow: inset 0 0 4px 4px $tutu;
                    }

                    &:hover {
                        box-shadow: inset 0 0 2px 2px $tutu;
                    }
                }
            }
        }

        .product-add-to-cart {
            margin-bottom: 20px;
        }

        .product-actions {
            font-size: rem(16);
            text-align: left;
            margin-bottom: 30px;

            @include respond-to(mobile) {
                font-size: rem(14);
            }
        }

        .product-details-toggle {
            display: block;
            border-top: 1px solid $lightest-gray;
            border-bottom: 1px solid $lightest-gray;
            background: $white;
            padding: 15px;
            letter-spacing: 1px;
            font-size: rem(14);
            text-transform: uppercase;

            &.expanded {
                .bticons-minus {
                    display: block;
                }
                .bticons-plus {
                    display: none;
                }
            }

            &:hover {
                background: $light-gray;
                color: $nobel;
            }

            @include respond-to(mobile_tablet) {
                text-align: left;
            }

            i {
                float: right;
                line-height: 25px;
            }

            .bticons-minus {
                display: none;
            }
        }

        .product-details-content {
            padding: 15px;
            font-size: rem(16);
            line-height: 1.6;

            @include respond-to(mobile_tablet) {
                text-align: left;
            }
        }

        .promo-flag {
            padding: 5px 15px;
            letter-spacing: 1px;

            @include respond-to(mobile_tablet) {
                width: auto;
                margin: 0;
                text-align: left;
                padding: 0;
            }
        }
    }

    &.brand-chanel {
        outline: 0;

        .product-description-link {
            &:hover {
                .product-name,
                .product-standard-price {
                    color: $dim-gray;
                }
            }
        }

        .bticons-heart-filled {
            color: $black01;
        }

        .bticons-zoom,
        .product-swatches-all,
        .product-description-link {
            &:hover {
                color: $dim-gray;
            }
        }

        .product-collection {
            display: block;
            line-height: 18px;
            margin-top: 2px;
        }

        .product-name {
            line-height: 1.2;
            margin-top: 2px;
            font-weight: 400;
        }

        .product-brand,
        .product-collection,
        .product-description-link,
        .product-pricing,
        .product-swatches-all,
        .product-compare {
            font-family: $sans-serif;
        }

        .product-brand,
        .product-collection,
        .product-pricing {
            font-weight: 700;
        }

        .product-collection,
        .product-name,
        .product-pricing {
            font-size: rem(13);
            text-transform: uppercase;
        }

        .product-brand {
            font-size: rem(16);

            @include respond-to(mobile) {
                font-size: rem(15);
            }
        }
    }
}

.product-search-result-main.wide-tiles {
    border-left: 0;

    .search-result-content {
        border-left: none;
    }

    .carousel {
        border-bottom: 1px solid $lighter-gray;
        border-left: 1px solid $lighter-gray;

        .product-actions {
            text-align: center;
            margin-bottom: 6px;
            font-size: 0;
        }

        .product-name {
            padding: 6px 0;
        }
    }
}

.template_1.wide-tiles {
    @include respond-to(mobile) {
        .product-variations,
        .product-add-to-cart,
        .product-details {
            display: none;
        }

        .product-actions {
            margin-bottom: 0;
        }

        .product-tile {
            padding-bottom: 0;
        }
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
}

.product-recommendation-mobile {
    .carousel {
        .jcarousel-nav {
            top: 35%;
        }
    }
}
