.cart-order-totals {
    margin-bottom: 1rem;
    border: 1px solid $nobel;
    padding: 40px 25px 25px;
    background: $white;
    z-index: 11;

    &.fixed {
        position: fixed;

        @include respond-to(mobile) {
            position: static;
        }
    }

    &.positioned {
        position: fixed;
        top: auto !important; // overwrite js styles
        bottom: 320px;

        @include respond-to(mobile) {
            position: static;
        }
    }

    @include respond-to(tablet_plus) {
        float: right;
        width: 32%;
        max-width: 330px;
    }

    @include respond-to(mobile) {
        position: static !important;
        padding: 40px 0 0;
        border: 0;
        -webkit-order: 1;
        order: 1;

        .is-cart-empty & {
            -webkit-order: 2;
            order: 2;
        }

        + div {
            display: none !important; // Hide empty div to avoid jumping Order Summary
        }
    }

    h3 {
        margin-bottom: 5px;
        font-size: rem(23);

        @include respond-to(mobile) {
            margin-bottom: 5px;
            font-size: rem(21);
        }
    }

    .cart-summary-delivery .toggle-content {
        display: block;

        @include respond-to(mobile) {
            display: none;
        }
    }

    .cart-summary-promobtn,
    .cart-summary-footer,
    .cart-summary-promobtn + .cart-actions,
    .cart-summary-delivery {
        @include respond-to(mobile) {
            display: none;
        }
    }
    
    .promotion-message {
        padding: 1rem 30px 1rem 1rem;
    }
}

.cart-summary-delivery {
    margin: 30px 0 45px;

    @include respond-to(mobile) {
        margin: 0;
    }

    h3 {
        margin-bottom: 5px;
        font-size: rem(23);
    }

    p {
        margin-top: 0;
        font-size: rem(14);
        color: $charcoal;
        line-height: 1.2;
    }

    table {
        td {
            padding: .3rem;
            font-size: rem(14);
            text-align: left;
            color: $charcoal;
        }

        tr > td {
            width: 80%;
            position: relative;

            @include respond-to(mobile) {
                width: 90%;
            }

            &:first-child {
                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 6px;
                    width: 100%;
                    margin-left: 8px;
                    border-bottom: 1px dotted $nobel;
                }
            }

            & + td {
                text-align: left;
                padding-left: 15px;
                color: $hel-gray;
            }
        }
    }
}

.order-totals-table {
    td {
        padding: 4px 10px;
        font-size: rem(14);
        text-align: left;

        td {
            padding: 4px 0;
        }

        tr:first-child td {
            padding-top: 0;
        }

        tr:last-child td {
            padding-bottom: 0;
        }
    }

    tr > td {
        width: 80%;
        position: relative;

        @include respond-to(mobile) {
            width: 90%;
        }

        & + td {
            text-align: right;
            white-space: nowrap;
        }
    }

    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }

    & + .cart-summary-giftmsg + .cart-actions {
        margin-top: 20px;
    }

    .order-sales-tax td {
        font-size: rem(10);

        @include respond-to(mobile) {
            padding: 0 10px;
        }
    }
}

.cart-payments {
    .cart-payments-title {
        font-weight: 500;
        font-size: rem(16);
        text-transform: none;
        margin-bottom: 10px;
    }

    &-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .icon {
        width: 40px;
        margin-right: 15px;
    }

    .visa,
    .paypal {
        width: 35px;
    }

    .visa {
        margin-right: 20px;
    }
}

.order-value {
    white-space: nowrap;
}

.cart-actions {
    @extend %clearfix;
}

.cart-action-checkout button {
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    font-size: rem(16);
}

.cart-summary-giftmsg {
    font-size: rem(14);
    margin: 20px 0;
    color: $charcoal;
}

.cart-summary-loyalty {
    margin-top: 30px;
    font-size: rem(14);

    @include respond-to(mobile) {
        text-align: center;
        margin-top: 0;
    }

    .toggle {
        @include respond-to(mobile) {
            text-align: left;
        }
    }

    h1,
    .h1 {
        font-size: rem(23);
        margin-bottom: 5px;
    }

    div {
        line-height: 15px;
        margin-bottom: 10px;
    }
}

.cart-summary-loyalty-mobile {
    margin: 20px 0;
    font-size: rem(13);
    text-align: left;

    h3 {
        margin-bottom: 5px;
        font-size: rem(18);
    }
}

.cart-summary-discount {
    font-size: rem(16);
}

.cart-summary-footer {
    min-height: 0;
    border: 1px solid $pink;
    color: $pink;
    background: $second-lightest-gray;
    padding: 15px 10px;

    img {
        max-width: 100%;
    }
}

.cart-summary-promobtn {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: rem(13);
    border: 1px solid $nobel;
    padding: 12px 25px;
    margin: 27px 0 5px;
    line-height: 25px;

    @include respond-to(mobile) {
        margin-top: 0;
        padding: 15px 0 15px 18px;
        text-align: left;
        border-width: 1px 0 1px 0;
        border-style: solid;
        border-color: $dim-gray;
        line-height: 1;
        font-size: rem(14);
    }

    &:after {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
        content: '+';
        float: right;
        font-size: rem(17);
        line-height: 25px;

        @include respond-to(mobile) {
            font-size: rem(23);
            right: 20px;
            line-height: 1;
            margin-top: -11px;
        }
    }
}

.cart-action-continue-shopping {
    float: right;

    button.button-text {
        line-height: 3.25em;
        margin-right: 1.0em;
    }
}

.cart-actions-top {
    margin-top: .5rem;
    padding-bottom: 1em;

    .cart-action-continue-shopping {
        float: none;
    }
}

.cart-actions button {
    width: 100%;
    padding: 20px;
    font-size: rem(16);
}