/* Address popup */
.address-popup .ui-dialog-title:after {
    display: none;
}

.button.address-create {
    min-width: 200px;
}

.account-address-banner {
    padding: 30px 0;
    margin-bottom: 20px;
    text-align: center;

    h2 {
        text-transform: capitalize;

        @include respond-to(mobile_strong) {
            font-size: rem(22);
        }

        @include respond-to(mobile_strong_over) {
            font-size: rem(24);
        }
    }

    p {
        width: 60%;
        display: block;
        font-size: rem(14);
        margin: 20px auto 0;
    }
}

#addresses {
    @extend %clearfix;
    margin-bottom: 25px;

    @include respond-to(mobile) {
        @include flexbox;
        @include flex-vertical;
    }

    .address_title {
        float: left;
        margin-top: 15px;
        font-size: rem(18);
        line-height: 45px;

        @include respond-to(mobile) {
            order: 2;
        }
    }

    .address-create {
        display: block;
        float: right;
        margin: 15px 0 25px;
    
        @include respond-to(mobile) {
            float: left;
            width: auto;
            order: 1;
        }
    }

    .address-list {
        li {
            .default-address {
                @include button-type(1);
    
                padding-top: 14px;
                padding-bottom: 14px;
                cursor: default;
                min-width: 200px;
                
                &:hover {
                    border-color: $color-secondary2;
                }
            }

            &:last-of-type {
                border-bottom: 1px solid $very-light-gray;
                margin-bottom: 25px;
            }
        }

        @include respond-to(mobile) {
            order: 3;
        }
    }

    .mini-address-location {
        margin-bottom: 20px;
    }

    .address-action-link,
    .adress-actions a {
        color: $hel-gray;
    }
}

/* Add adress popup */
.edit-adress-form {
    .address-list .form-row {
        width: 100%;
    }

    h1.edit-address-title {
        color: $nobel;
        text-transform: capitalize;
        letter-spacing: 1px;
        text-align: center;

        @include respond-to(mobile_strong) {
            font-size: rem(25);
        }

        @include respond-to(mobile_strong_over) {
            font-size: rem(30);
        }

    }

    .dialog-action-buttons {
        margin-left: 0;
        margin-bottom: 20px;

        .button.space-bottom {
            @include respond-to(mobile) {
                margin-bottom: 25px;
            }
        }
    }

    .cancel-button {
        font-size: rem(16);
    }

    .apply-button {
        @extend .coloured;
        
        float: right;
    }

    .form-caption-before {
        position: relative;

        label,
        .field-wrapper {
            margin-top: 25px;

            @include respond-to(mobile) {
                margin-top: 30px;
            }
        }

        .form-caption {
            position: absolute;
            top: 0;

            @include respond-to(mobile) {
                line-height: 1em;
            }
        }
    }
}

.address-list,
.payment-list {
    @extend %clearfix;
    display: block;
    margin: 0 0 25px;
    padding: 0;

    li {
        float: left;
        list-style-type: none;
        margin: 0 25px 0 0;
        padding: 25px 0;
        width: 100%;
        border-top: 1px solid $very-light-gray;

        @include respond-to (mobile) {
            margin: 0 0 25px;
        }

        .mini-address-title {
           display: none;
        }

        .mini-address-title {
            font-size: rem(22);
            text-transform: uppercase;
        }

        .mini-address-name {
            font-family: $font-main-bold;
            font-size: rem(18);
            margin-bottom: 5px;
        }

        .address-make-default,
        .default-address {
            display: block;
            float: right;

            @include respond-to(mobile) {
                float: left;
                margin: 25px 0;
            }
        }

        .address-make-default {
            @include respond-to(mobile) {
                margin: 20px 0 0;
            }
        }

        .default-address {
            cursor: default;

            @include respond-to(mobile) {
                width: auto;
            }
        }

        .address-delete {
            margin-left: 40px;
        }

        .adress-actions {

            @include respond-to(mobile) {
                display: block;
                float: left;
                width: 100%;
            }
        }

        address {
            font-style: normal;

            br {
                @include respond-to(tablet_plus) {
                    display: none;
                }
            }
        }

        a,
        .button-text {
            line-height: 2rem;
            padding-right: .2rem;
        }
    }

    .payment-list__item {
        border-top: none;
        margin: 0;
        padding-right: 10px;
        width: 33.333%;

        @include respond-to(mobile) {
            float: left;
            padding-bottom: 0;
            padding-right: 0;
            width: 48%;
            margin: 0 1%;
        }
    }
}