.h1, h1 {
    font-family: $font-main-bold;
    font-size: rem(30);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    text-transform: capitalize;
    color: $black01;
    margin: 0;

    @include respond-to(mobile) {
        font-size: rem(26);
    }
}

.h2, h2 {
    font-family: $font-main-bold;
    font-size: rem(24);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    text-transform: capitalize;
    color: $black01;
    margin: 0;

    @include respond-to(mobile) {
        font-size: rem(22);
    }
}

.h3, h3 {
    font-family: $font-main-bold;
    font-size: rem(20);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    text-transform: capitalize;
    color: $black01;
    margin: 0;

    @include respond-to(mobile) {
        font-size: rem(18);
    }
}

.h4, h4 {
    font-family: $font-main-bold;
    font-size: rem(18);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    text-transform: capitalize;
    color: $black01;
    margin: 0;

    @include respond-to(mobile) {
        font-size: rem(16);
    }
}

.h5, h5 {
    font-family: $font-main-bold;
    font-size: rem(16);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    text-transform: capitalize;
    color: $black01;
    margin: 0;

    @include respond-to(mobile) {
        font-size: rem(14);
    }
}

.h6, h6 {
    font-family: $font-main-bold;
    font-size: rem(14);
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    text-transform: capitalize;
    color: $black01;
    margin: 0;

    @include respond-to(mobile) {
        font-size: rem(13);
    }
}

.main-title {
    letter-spacing: $main-title-letter-spacing;
    text-align: center;
    margin: $main-title-margin;
    padding: 0;
    
    @include respond-to(mobile) {
        margin: $main-title-margin-mobile;
    }
    
    span {
        display: inline-block;
    }
}

.title-double-font {
    text-transform: uppercase;

    em {
        font-style: normal;
        text-transform: lowercase;

        @include respond-to(mobile) {
            font-size: rem(23);
        }

        @include respond-to(tablet_plus) {
            font-size: rem(29);
        }

    }
}

p {
    font-size: rem(16);
    line-height: 1.6;
    margin-bottom: 20px;
}

ul,
ol,
dl {
    line-height: 1.6;
    margin: 0 0 20px;
    list-style-position: outside;
}

ul {
    list-style: none;
    padding: 0;
}

ol {
    margin-left: 23px;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
}