// mixins
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.clearfix {
    @extend %clearfix;
}