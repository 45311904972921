//----------------------------------------------------------------------
// Desktop typography
//----------------------------------------------------------------------

// Header Styles
.h1,
%h1 {
    font-size: rem(40);
    line-height: 0.95;
    text-transform: uppercase;
    letter-spacing: 0.002em;
}

.h2,
%h2 {
    font-size: rem(24);
    font-weight: 500;
    line-height: 1.2;

    @include respond-to(mobile) {
        font-size: rem(22);
    }
}

.h3_a,
.h3_b,
%h3_a,
%h3_b {
    font-size: rem(24);
    line-height: 1;
}

.h3_a,
%h3_a {
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.h3_b,
%h3_b {
    text-transform: none;
}

.h4,
%h4 {
    font-size: rem(22);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.h5,
%h5 {
    font-size: rem(16);
    line-height: 1;
    text-transform: none;
}

.main-title {
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.2;
}

// Paragraph Styles
.p1,
.p2,
%p1,
%p2 {
    line-height: 1.1;
    text-transform: none;
}

.p1,
%p1 {
    font-size: rem(18);
}

.p2,
%p2 {
    font-size: rem(16);
}

//Button Styles
.b1,
%b1 {
    font-size: rem(14);
    line-height: 1;
    text-transform: uppercase;
}

//----------------------------------------------------------------------
// Mobile typography
//----------------------------------------------------------------------

// Header Styles Mobile
.h1m_a,
.h1m_b,
%h1m_a,
%h1m_b {
    @include respond-to(mobile) {
        font-size: rem(28);
        line-height: 1;
    }
}
.h1m_a,
%h1m_a {
    @include respond-to(mobile) {
        text-transform: uppercase;
    }
}

.h1m_b,
%h1m_b {
    @include respond-to(mobile) {
        text-transform: none;
        letter-spacing: -0.02em;
    }
}

.h2m_b,
%h2m_b {
    @include respond-to(mobile) {
        font-size: rem(18);
        line-height: 1;
        text-transform: uppercase;
    }
}

.h3m,
%h3m {
    @include respond-to(mobile) {
        font-size: rem(15);
        line-height: 1;
        text-transform: uppercase;
    }
}

.h4m,
%h4m {
    @include respond-to(mobile) {
        font-size: rem(12.5);
        line-height: 1;
        text-transform: none;
    }
}

.h5m,
%h5m {
    @include respond-to(mobile) {
        font-size: rem(12.5);
        line-height: 1;
        text-transform: uppercase;
    }
}

// Paragraph Styles Mobile
.p1m,
.p2m,
%p1m,
%p2m {
    @include respond-to(mobile) {
        line-height: 1;
        text-transform: none;
        letter-spacing: -0.02em;
    }
}

.p1m,
%p1m {
    @include respond-to(mobile) {
        font-size: rem(18);
    }
}

.p2m,
%p2m {
    @include respond-to(mobile) {
        font-size: rem(15);
    }
}

// Button Styles Mobile
.b1m,
%b1m {
    @include respond-to(mobile) {
        font-size: rem(12.5);
        line-height: 1;
        text-transform: uppercase;
    }
}

.title-double-font,
.title-double-font em {
    font-family: $font-main;
}
