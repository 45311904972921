.energy-rating {
    display: inline-block;
    margin: 10px 0 0;
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: $sans-serif;

    @include respond-to (mobile) {
        margin-top: 25px;
    }

    .line-item-wrapper &,
    .product-tile & {
        margin-top: 0;
    }

    .cart-items-form & {
        -webkit-font-smoothing: subpixel-antialiased;

        @include respond-to (mobile) {
            align-self: flex-start;
            margin-top: 15px;
        }

        @include respond-to(mobile_strong) { // Hide on Cart above Price for mobile 
            display: none;
        }
    }

    .visible-mobile & { // Show on Cart under Price for mobile
        @include respond-to(mobile_strong) {
            display: block;
        }
    }

    &-link {
        color: $white;
        position: relative;
        display: inline-block;
        padding: 0 0 0 8px;
        font-weight: 700;
        font-size: rem(18);
        line-height: 23px;
        text-align: left;

        &:hover {
            color: $white;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 11px solid transparent;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
        }

        .product-tile &,
        .cart-items-form & {
            line-height: 16px;
            font-size: rem(12);
            padding: 0 0 0 5px;
    
            &::before {
                border-top-width: 8px;
                border-bottom-width: 8px;
                border-left-width: 10px;
                border-right-width: 10px;
            }
        }

        .product-tile & {
            @include respond-to (mobile) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .cart-items-form & {
            @include respond-to(mobile) {
                align-self: baseline;
            }
        }
    }

    &-wrapper {
        text-align: center;
    }

    &-plus {
        display: inline-block;
        font-size: rem(9);
        vertical-align: top;
        margin-left: -4px;
        line-height: 16px;
        letter-spacing: 1px;

        .product-tile &,
        .cart-items-form & {
            line-height: 12px;
            font-size: rem(6);
            margin-left: -2px;
        }
    }

    &-APLUS3 {
        background-color: $energy-rating-a3;
        min-width: 47px;

        .product-tile &,
        .cart-items-form & {
            min-width: 30px;
        }

        &::before {
            border-left-color: $energy-rating-a3;
        }
    }

    &-APLUS2 {
        background-color: $energy-rating-a2;
        min-width: 47px;

        .product-tile &,
        .cart-items-form & {
            min-width: 30px;
        }

        &::before {
            border-left-color: $energy-rating-a2;
        }
    }

    &-APLUS1 {
        background-color: $energy-rating-a1;
        min-width: 47px;

        .product-tile &,
        .cart-items-form & {
            min-width: 30px;
        }

        &::before {
            border-left-color: $energy-rating-a1;
        }
    }

    &-A {
        background-color: $energy-rating-a;
        min-width: 35px;

        .product-tile &,
        .cart-items-form & {
            min-width: 25px;
        }
        
        &::before {
            border-left-color: $energy-rating-a;
        }
    }

    &-B {
        background-color: $energy-rating-b;
        min-width: 35px;

        .product-tile &,
        .cart-items-form & {
            min-width: 25px;
        }

        &::before {
            border-left-color: $energy-rating-b;
        }
    }

    &-C {
        background-color: $energy-rating-c;
        min-width: 35px;

        .product-tile &,
        .cart-items-form & {
            min-width: 25px;
        }

        &::before {
            border-left-color: $energy-rating-c;
        }
    }

    &-D {
        background-color: $energy-rating-d;
        min-width: 35px;

        .product-tile &,
        .cart-items-form & {
            min-width: 25px;
        }

        &::before {
            border-left-color: $energy-rating-d;
        }
    }
}

.product-fiche-link {
    display: block;
    font-size: rem(12);
    margin: 10px 0 20px;

    .product-tile & {
        margin: 0;
    }

    .wide-tiles & {
        margin-bottom: 20px;
    }

    .cart-items-form & {
        margin-top: 5px;

        @include respond-to(touch_over) {
            font-size: rem(12);
        }

        @include respond-to(mobile_strong) { // Hide on Cart above Price for mobile 
            display: none;
        }
    }

    .visible-mobile & { // Show on Cart under Price for mobile
        @include respond-to(mobile_strong) {
            display: block;
        }
    }
}

.pdp-main .product-col-2 { // hide duplicated label under image for mobile on PDP
    .energy-rating, 
    .product-fiche-link { 
        @include respond-to (mobile) {
            display: none;
        }
    }

    .ui-dialog & { // Show energy on QV
        .energy-rating {
            @include respond-to (mobile) {
                display: inline-block;
            }
        }

        .product-fiche-link {
            @include respond-to (mobile) {
                display: block;
            }
        }
    }
}