// Buttons
button,
.button,
.buttonstyle,
input[type="button"] {
    background-color: $black01;
    border: $btn-primary-border-width solid $btn-primary-border-color;
    color: $btn-primary-color;
    cursor: pointer;
    display: inline-block;
    font-family: $font-main-bold;
    font-size: rem(16);
    line-height: 1;
    margin: 0;
    outline: none;
    padding: $btn-padding;
    text-align: center;
    text-decoration: none;
    transition: background-color 300ms ease-out, border-color 300ms ease-out;

    @include respond-to(mobile_tablet) {
        width: 100%;
    }

    &.full-width {
        width: 100%;
    }

    &.small {
        font-size: rem($btn-small-font-size);
        padding: $btn-small-padding;
    }

    &.large {
        font-size: rem($btn-large-font-size);
        padding-bottom: $btn-large-padding-bottom;
        padding-top: $btn-large-padding-top;
    }

    &.tiny {
        font-size: rem($btn-tiny-font-size);
        padding: $btn-tiny-padding;
    }

    &.coloured {
        color: $btn-secondary-color;
        border-width: $btn-secondary-border-width;
        border-color: $btn-secondary-border-color;
    }

    &.black {
        background: $btn-quadruple-bg;
        color: $btn-quadruple-color;
        border-width: $btn-quadruple-border-width;
        border-color: $btn-quadruple-border-color;

        &:hover {
            background: $btn-quadruple-hover-bg;
            color: $btn-quadruple-hover-color;
            border-color: $btn-quadruple-hover-border-color;
        }
    }

    &.btn-blue {
        background: $btn-auxiliary-bg;
        color: $btn-auxiliary-color;
        border-width: $btn-auxiliary-border-width;
        border-color: $btn-auxiliary-border-color;

        &:hover {
            background: $btn-auxiliary-hover-bg;
            color: $btn-auxiliary-hover-color;
            border-color: $btn-auxiliary-hover-border-color;
            opacity: 0.95;
        }
    }

    &.outlined {
        background-color: $btn-tertiary-bg;
        border-color: $btn-tertiary-border-color;
        border-width: $btn-tertiary-border-width;
        color: $btn-tertiary-color;

        &:hover {
            background-color: $btn-tertiary-hover-bg;
            border-color: $btn-tertiary-hover-border-color;
            color: $btn-tertiary-hover-color;
        }
    }
}

.gen-cta {
    background-color: $link-custom-bg;
    color: $link-custom-text-color;
    padding: $link-custom-padding;
    margin: 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: rem(14);
    letter-spacing: 1px;
    border-style: solid;
    border-width: $link-custom-border-width;
    border-color: $link-custom-border-color;
    display: inline-block;

    @include respond-to(mobile) {
        width: auto;
    }

    &:hover {
        background-color: $link-custom-hover-bg;
        border-color: $link-custom-hover-border-color;
        color: $link-custom-hover-text-color;
    }
}