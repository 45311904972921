@keyframes slideup {
    from {
        top: 100%;
    }
    to {
        top: 15px;
    }
}

#wrapper {
    // BRTREF-880 GF. Sticky header and footer are blinking when scrolling page in IE11 only
    // ticket https://jira.ontrq.com/browse/BRTREF-880
    //-----
    &.pt_storefront,
    &.pt_order-confirmation {
        .ie & {
            position: static;
        }
    }
    //-----

    &.menu-active {
        position: relative;

        @include respond-to(mobile) {
            &.pt_order-confirmation ~ .footer-checkout {
                visibility: hidden;
                z-index: -1;
                opacity: 0;
            }

            #main,
            .top-banner,
            .footer,
            &.pt_order-confirmation ~ .footer-checkout,
            .header-banner {
                transform: none; // discard slide right
            }
        }
    }

    &.mini-cart-active {
        @include respond-to(mobile) {
            &.pt_order-confirmation ~ .footer-checkout {
                visibility: hidden;
                z-index: -1;
                opacity: 0;
            }
        }
    }
}

#secondary {
    @include respond-to(tablet) {
        .pt_account &,
        .pt_wish-list & {
            width: 22%;
        }
    }

    &.secondary-content.refinements {
        max-width: 235px;

        @include respond-to(mobile) {
            max-width: 100%;
        }
    }

    nav {
        padding: 0;

        a {
            @include respond-to(tablet_ext) {
                padding-left: 10px;
            }
        }
    }
}

.pt_order #secondary nav {
    @include respond-to(mobile) {
        padding: 20px 0 0;
    }
}

body {
    .ui-dialog {
        h1 {
            @extend %h3_a;

            @extend %h1m_b;

            &::after {
                display: none;
            }
        }

        .ui-dialog-title {
            &::after {
                display: none;
            }
        }

        &.side_dialog {
            position: fixed !important;
            background: $white;
            border: 0;
            border-radius: 4px;
            overflow: hidden;

            @include respond-to(mobile_strong) {
                animation: 0.5s linear slideup forwards;
                bottom: auto !important;
                left: 15px !important;
                right: 15px !important;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: auto !important;
                max-width: none !important;
                height: 95dvh !important;

                @supports not (height: 95dvh) { // Fix for old versions IOS with float url toolbar
                    height: 82vh !important;
                }
            }

            @include respond-to(mobile_strong_over) {
                height: auto !important;
                top: 15px !important;
                bottom: 15px !important;
                left: auto !important;
                right: 15px !important;
                min-width: 34%;
            }

            .dialog-content {
                max-height: 88vh !important;
                
                @include respond-to(mobile_strong) {
                    order: 1;
                    max-height: none !important;
                }
            }

            .ui-dialog-titlebar {
                background: $light-gray02;
                border-radius: 4px;

                @include respond-to(mobile_strong_over) {
                    padding: 20px;
                }

                @include respond-to(mobile_strong) {
                    padding: 15px;
                    order: 2;
                }
            }

            .ui-dialog-titlebar-close {
                @include icon(close-icon);
            }

            .ui-dialog-title {
                padding: 0;
                margin: 0;
                text-align: left;
                font-size: rem(16);
                text-transform: none;
                font-family: $font-main-bold;
                color: $black01;
                letter-spacing: 0.5px;
            }

            .ui-button {
                background: $white;
                height: 40px;
                width: 40px;
                padding: 3px 14px;
                border-radius: 50px;

                @include respond-to(mobile_strong) {
                    top: 4px;
                    right: 8px;
                }

                &::before {
                    font-size: rem(12);
                    font-weight: 900;
                }
            }
        }
    }
}

.no-desktop {
    #wrapper {
        &.menu-active {
            &:before {
                transform: none;
                left: 80%;
                transition: left 500ms ease;
            }
        }

        &.mini-cart-active {
            &:before {
                transform: none;
                right: $minicart-touch-width;
                left: auto;
                transition: right 500ms ease;
            }

            .close-minicart {
                transform: none;
                right: $minicart-touch-width;
                left: auto;
                transition: right 500ms ease;
            }
        }

        &:before {
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

.site-width {
    margin-left: auto;
    margin-right: auto;
    max-width: 1260px;
    padding-left: 15px;
    padding-right: 15px;
}

.dialog-sendtofriend-wishlist  {
    .ui-dialog-buttonpane {
        @include respond-to(mobile_tablet) {
            padding: 0 25px 25px;

            .ui-dialog-buttonset {
                float: none;
            }
        }
    }

    .ui-dialog-buttonset {
        @include respond-to(mobile_tablet) {
            @include flexbox();
            @include flex-vertical();

        }

        button {
            &.sendtofriend-wishlist-cancel-btn {
                @include respond-to(mobile_tablet) {
                    @include order(2);
                }

                font-size: rem(15);
                padding: 0;
                min-width: 0;
            }

            &.sendtofriend-wishlist-send-btn {
                @include respond-to(mobile_tablet) {
                    @include order(1);
                }
            }

            &.sendtofriend-wishlist-send-btn,
            &.sendtofriend-wishlist-cancel-btn {
                @include respond-to(mobile_tablet) {
                    padding: 5px 0;
                    margin-right: 0;
                }
            }
        }
    }
}

.no-scroll {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.no-desktop {
    #wrapper.mini-cart-active,
    #wrapper.menu-active {
        position: relative;
        overflow: auto;

        @include respond-to(tablet_regular) {
            overflow-y: hidden;
        }
    }
}

body.no-scroll {
    height: auto;
}
