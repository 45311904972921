$elemSize: 52px;
$elemSizeSmall: 38px;

.quantity_selector {
    width: 100%;

    &-inner_wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0;
        min-width: 160px;
        padding: 1px;
        position: relative;
        text-align: center;
        user-select: none;
        z-index: 1;
    }

    &-decrease,
    &-increase {
        background-color: #fff;
        border-radius: 50%;
        color: #000;
        font-size: rem(24);
        height: $elemSize;
        line-height: $elemSize;
        position: relative;
        text-align: center;
        transition: 300ms ease-out, background-color 300ms;
        width: $elemSize;

        @include respond-to(touch_over) {
            &:hover {
                color: #fff;
                cursor: pointer;
                background-color: #000;
            }
        }

        @include respond-to(mobile_strong) {
            height: $elemSizeSmall;
            line-height: $elemSizeSmall;
            width: $elemSizeSmall;
        }

        &::before {
            background-color: #f8f8f8;
            bottom: -1px;
            content: "";
            display: block;
            height: calc(#{$elemSize} + 2px);
            left: -1px;
            position: absolute;
            right: -1px;
            top: -1px;
            width: calc(#{$elemSize} + 2px);
            z-index: -1;

            @include respond-to(mobile_strong) {
                height: calc(#{$elemSizeSmall} + 2px);
                line-height: calc(#{$elemSizeSmall} + 2px);
                width: calc(#{$elemSizeSmall} + 2px);
            }
        }
    }

    &-decrease::before {
        border-radius: 50% 0 0 50%;
    }

    &-increase::before {
        border-radius: 0 50% 50% 0;
    }

    &-input[type="number"] {
        background-color: #f8f8f8;
        border: 0;
        display: block;
        font-size: rem(14);
        height: $elemSize;
        line-height: $elemSize;
        padding: 11px 5px;
        text-align: center;
        width: calc(100% - #{$elemSize * 2});

        &:hover,
        &:focus,
        &:active {
            background-color: #f8f8f8;
        }

        @include respond-to(mobile_strong) {
            height: $elemSizeSmall;
            line-height: $elemSizeSmall;
            width: calc(100% - #{$elemSizeSmall * 2});
        }
    }

    &-input[type="number"] ~ .error {
        margin-top: 10px;
        order: 1;
        text-align: center;
        width: 100%;
    }

    .error-quantity {
        margin-top: 10px;
    }
}

.wishlist-products .quantity_selector,
.cart-row .quantity_selector,
.product-set .quantity_selector {
    &-inner_wrapper {
        margin-bottom: 10px;
        min-width: 120px;

        @include respond-to(mobile_strong_over) {
            max-width: 140px;
        }
    }

    &-decrease,
    &-increase {
        height: $elemSizeSmall;
        line-height: $elemSizeSmall;
        width: $elemSizeSmall;

        &::before {
            height: calc(#{$elemSizeSmall} + 2px);
            width: calc(#{$elemSizeSmall} + 2px);
        }
    }

    &-input {
        height: $elemSizeSmall;
        line-height: $elemSizeSmall;
        width: calc(100% - #{$elemSizeSmall * 2});
    }
}

// TODO need to find better place for this styles
.wishlist-products,
.product-add-to-cart {
    .inventory {
        @include respond-to(mobile_strong) {
            margin-top: 5px;
        }
        
        &-label {
            margin-bottom: 10px;
        }
    }
}
