@media print {
    .pt_order-confirmation #mini-cart, 
    .pt_order-confirmation .menu-utility-user,
    .pt_order-confirmation .menu-utility,
    .pt_order-confirmation .header-banner,
    .pt_order-confirmation #navigation,
    .top-banner-asset, 
    #navigation, 
    #pdp-furniture-order-by-phone-link, 
    .furniture-help-buttons, 
    .footer, 
    .breadcrumb, 
    .furniture-slider__nav-slider, 
    .by-phone-btn, 
    .furniture-view-in-store, 
    .delivery-assets, 
    .delivery-assets__item, 
    .product-add-to-cart, 
    .pdp-buying-guides, 
    .this-range, 
    .pdp-recommendations, 
    .menu-toggle, 
    .header-wishlist, 
    .mini-cart-total,
    .mini-cart, 
    .amp-video-panel, 
    .product-shop-all,
    .furniture-page-main .promotion,
    .usp-content-furniture,
    .delivery-financing-assets,
    .pdp-buying-guides,
    .pdp-main.pdp-furniture .product-price .price-sales ~ .price-standard::after,
    .pdp-main.pdp-furniture .furniture-slider__nav-slider,
    .top-banner::before {
        display: none;
    }

    .container-searchbox,
    .header-search input,
    .header-search .search-button {
        display: none !important;
    }

    .top-banner {
        position: static !important;
    }

    .pdp-main.pdp-furniture {
        .price-switcher .price-standard {
            text-decoration: line-through !important;
        }

        .tab .tab-content {
            height: auto;
            opacity: 1;
            padding: 15px 15px 30px;
        }

        .product-col-1,
        .furniture-slider {
            width: 65% !important;
        }

        #product-content-wrapper {
            width: 35% !important;
        }

        .js-sticky-kit {
            position: static !important;
            width: auto !important;
        }
    }
}
