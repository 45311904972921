.pdp-main.pdp-alternative.pdp-vip {
    margin-top: 30px;

    .product-col-1 {
        width: 53%;

        @include respond-to(mobile) {
            width: 100%;
            float: none;
        }
    }

    .product-name-brand {
        font-size: rem(20);
        padding-bottom: 17px;
        margin-bottom: 10px;
        border-bottom: 1px solid $lightest-gray;

        @include respond-to(mobile) {
            font-size: rem(23);
        }
    }

    .product-name-title {
        text-transform: none;
        font-size: rem(20);

        @include respond-to(mobile) {
            font-size: rem(18);
        }
    }

    .product-subtitle {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .product-price {
        font-size: rem(26);

        .price-sales + .price-standard {
            text-transform: uppercase;
            color: $charcoal;
            font-size: 50%;

            .price-standard-qq-coment {
                display: inline-block;
            }
        }
    }

    .promotion,
    .product-review {
        display: none;
    }

    .release-date-wrapper {
        padding: 5px 0;
        border-bottom: 1px solid $lightest-gray;

        @include respond-to(mobile) {
            display: none;
        }
    }

    .btn-wishlist {
        width: 100%;
        margin: 35px 0 25px;
        padding: 20px 25px;
        font-size: rem(16);
        background: #4b9999;

        &.disabled{background: $white-smoke;}
        &.wishlist-checked{background: $teal-lightest;}
    }

    .link {
        display: inline-block;
        text-transform: uppercase;
        border-bottom: 1px solid;
        padding-bottom: 2px;
        margin-bottom: 20px;

        @include respond-to(mobile) {
            display: block;
            margin: 0 auto;
            text-align: center;
            width: 200px;
        }
    }

    .product-accordion {
        margin-top: 20px;
    }

    .product-variations {
        @include respond-to(mobile) {
            text-align: center;
        }
    }

    .content-block {
        max-width: 100%;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .product-alt-mobile .release-date-wrapper {
        @include respond-to(mobile) {
            display: block;
            border-bottom: 0;
        }
    }

    .content-block-vip {
        @include respond-to(mobile) {
            margin: 15px 0;
            text-align: center;
        }
    }
}