%custom-scrollbar {
    &::-webkit-scrollbar {
        width: 11px;

        @include respond-to(mobile) {
            width: 5px;
        }
    }

    &::-webkit-scrollbar-track {
        background: $requirements-scrollbar-track-color;
        border: 5px solid transparent;
        background-clip: content-box;

        @include respond-to(mobile) {
            background: $color-secondary3;
            border: 0;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: $requirements-scrollbar-color;
        border: 0;
    }
}


.vjs-big-play-button {
    .vjs-default-skin & {
        font-size: 2em;
        background-color: rgba($black01, 0.4);
    }

    &::before {
        font-size: 3em;
        font-family: $font-icons;
        color: $color-primary3;
        content: $aricons-video-play-path2 !important;
    }

    &::after {
        display: none;
    }
}

.video-js {
    &.vjs-tech {
        &:hover {
            .vjs-big-play-button {
                background-color: rgba($black01, 0.4);

                &::before {
                    color: $color-primary3;
                    content: $aricons-video-play-path3 !important;
                }
            }
        }
    }
}