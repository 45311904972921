.full-with-header-slot {
    margin-bottom: 40px;

    .header-banner-content-block-title {
        font-family: $font-main;
        text-transform: uppercase;

        @include respond-to(mobile) {
            font-size: rem(22);
        }

        @include respond-to(tablet_plus) {
            font-size: rem(32);
        }
    }

    .header-banner-content-block-description {
        font-size: rem(14);
    }

    .carousel-pagination,
    .carousel-navigation {
        left: auto;
        position: static;
        width: auto;

        @include respond-to(mobile_strong) {
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .slick-dots {
        text-align: center;
        position: static;
    }

    &.header-banner-carousel {

        // fix before carousel initialization
        // https://jira.ontrq.com/browse/BRTREF-2217
        .full-width-slot-assets-list-item:nth-child(n+2) {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .header-banner-content-block {
            &.promo-left {
                .control-block {
                    @include respond-to(mobile_strong_over) {
                        @include justify-content(space-between);
                    }
                    &__links{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @include respond-to(tablet_plus) {
                            align-items: flex-start;
                        }
                    }
                }

                &.text-align-center {
                    .control-block {
                        @include respond-to(mobile_strong_over) {
                            @include flex-direction(column);
                        }
                    }

                    .carousel-navigation {
                        @include respond-to(mobile_strong_over) {
                            @include align-self(baseline);
                            margin-top: 20px;
                        }

                        .prev {
                            @include respond-to(mobile_strong_over) {
                                margin-left: 0;
                            }
                        }
                    }
                }

                &.text-align-right {
                    .control-block {
                        @include respond-to(mobile_strong_over) {
                            @include justify-content(space-between);
                            @include flex-direction(row-reverse);
                        }
                    }

                    .carousel-navigation {
                        @include respond-to(mobile_strong_over) {
                            @include align-self(center);
                        }

                        .prev {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 10px;
                                margin-left: 0;
                            }
                        }

                        .next {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            &.promo-center {
                .control-block {
                    @include respond-to(mobile_strong_over) {
                        @include flex-direction(column);
                    }
                }

                .carousel-navigation {
                    @include respond-to(mobile_strong_over) {
                        @include align-self(baseline);
                        margin-top: 20px;
                    }

                    .prev {
                        @include respond-to(mobile_strong_over) {
                            margin-left: 0;
                        }
                    }
                }

                &.text-align-left {
                    .control-block {
                        @include respond-to(mobile_strong_over) {
                            @include justify-content(space-between);
                            @include flex-direction(row);
                        }
                    }

                    .carousel-navigation {
                        @include respond-to(mobile_strong_over) {
                            @include align-self(center);
                            margin-top: 0;
                        }
                    }
                }

                &.text-align-right {
                    .control-block {
                        @include respond-to(mobile_strong_over) {
                            @include justify-content(space-between);
                            @include flex-direction(row-reverse);
                        }
                    }

                    .carousel-navigation {
                        @include respond-to(mobile_strong_over) {
                            @include align-self(center);
                            margin-top: 0;
                        }

                        .prev {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 10px;
                                margin-left: 0;
                            }
                        }

                        .next {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            &.promo-right {
                .control-block {
                    @include respond-to(mobile_strong_over) {
                        @include justify-content(space-between);
                        @include flex-direction(row-reverse);
                    }
                }

                .carousel-navigation {
                    .prev {
                        @include respond-to(mobile_strong_over) {
                            margin-right: 10px;
                            margin-left: 0;
                        }
                    }

                    .next {
                        @include respond-to(mobile_strong_over) {
                            margin-right: 20px;
                        }
                    }
                }

                &.text-align-left {
                    .control-block {
                        @include respond-to(mobile_strong_over) {
                            @include flex-direction(row);
                        }
                    }

                    .carousel-navigation {
                        .prev {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 10px;
                                margin-left: 20px;
                            }
                        }

                        .next {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 0;
                            }
                        }
                    }
                }

                &.text-align-center {
                    .control-block {
                        @include respond-to(mobile_strong_over) {
                            @include flex-direction(column);
                        }
                    }

                    .carousel-navigation {
                        @include respond-to(mobile_strong_over) {
                            @include align-self(baseline);
                            margin-top: 20px;
                        }

                        .prev {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 10px;
                                margin-left: 0;
                            }
                        }

                        .next {
                            @include respond-to(mobile_strong_over) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .control-block,
            .carousel-navigation {
                @include respond-to(mobile_strong_over) {
                    @include flexbox;
                }
            }

            .carousel-navigation {
                position: relative;

                @include respond-to(mobile_strong_over) {
                    @include align-self(center);

                    padding-left: 0;
                    width: auto;
                }

                .prev,
                .next {
                    position: static;
                    margin-top: 0;
                    padding: 0;
                    background: transparent;
                    color: inherit;

                    @include respond-to(mobile_strong_over) {
                        @include align-self(center);
                    }
                }

                .prev {
                    @include respond-to(mobile_strong_over) {
                        margin-right: 10px;
                        margin-left: 20px;
                    }
                }
            }

            .carousel-navigation-mobile {
                position: relative;

                .prev,
                .next {
                    border: 0;
                    display: block;
                    position: absolute;
                    top: 50% !important;
                    transform: translateY(-50%);
                    font-size: rem(25);
                    margin-top: 0;
                    background: transparent;
                    color: inherit;

                    @include respond-to(mobile_strong_over) {
                        display: none !important;
                    }

                    .path1,
                    .path2 {
                        &::before {
                            color: inherit;
                        }
                    }
                }

                .prev {
                    transform: translateY(-50%) scaleX(-1);
                    left: -20px;
                }

                .next {
                    right: -20px;
                }

                .header-banner-content-block-title {
                    @include respond-to(mobile_strong) {
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    .header-banner-content-block {
        &.promo-left {
            .header-banner-content-block-link {
                @include respond-to(mobile_strong_over) {
                    margin-left: 20px;
                    &:not(:first-child){
                        margin-top: 20px;
                    }
                }
            }

            &.text-align-center {
                .header-banner-content-block-link {
                    @include respond-to(mobile_strong_over) {
                        margin-left: auto;
                    }
                }
            }

            &.text-align-right {
                .header-banner-content-block-link {
                    @include respond-to(mobile_strong_over) {
                        margin-left: auto;
                        margin-right: 20px;
                    }
                }
            }
        }

        &.promo-center {
            .header-banner-content-block-link {
                @include respond-to(mobile_strong_over) {
                    margin-left: auto;
                    margin-right: auto;
                    &:not(:first-child){
                        margin-top: 20px;
                    }
                }
            }

            &.text-align-left {
                .header-banner-content-block-link {
                    @include respond-to(mobile_strong_over) {
                        margin-left: 20px;
                    }
                }
            }

            &.text-align-right {
                .header-banner-content-block-link {
                    @include respond-to(mobile_strong_over) {
                        margin-right: 20px;
                    }
                }
            }
        }

        &.promo-right {
            .header-banner-content-block-link {
                @include respond-to(mobile_strong_over) {
                    margin-right: 20px;
                    &:not(:first-child){
                        margin-top: 20px;
                    }
                }
            }

            &.text-align-left {
                .header-banner-content-block-link {
                    @include respond-to(mobile_strong_over) {
                        margin-left: 20px;
                        margin-right: auto;
                    }
                }
            }

            &.text-align-center {
                .header-banner-content-block-link {
                    @include respond-to(mobile_strong_over) {
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

.full-width-header-banner {
    @extend %clearfix;
    @include clip-decorator;

    position: relative;
    overflow: hidden;
    .banner-image-mobile,
    .banner-image-desktop{
        font-size: 1px;
        color:transparent;
    }

    &:not(.tablet-portrait) {
        .promo-breadcrumbs-wrapper {
            position: absolute;
            display: none;
            top: 30px;
            left: 15px;
            z-index: 1;

            @include respond-to(mobile_strong_over) {
                display: block;
            }

            &.theme-dark .breadcrumb {
                @include text-link-style($link-color-dark);
            }

            &.theme-light .breadcrumb {
                @include text-link-style($link-color-light);
            }

            .breadcrumb {
                padding: 0;
            }
        }
    }
    &.tablet-portrait {
        .promo-breadcrumbs-wrapper {
            position: absolute;
            display: none;
            top: 30px;
            left: 15px;
            z-index: 1;

            @include respond-to(tablet_plus) {
                display: block;
            }

            &.theme-dark .breadcrumb {
                @include text-link-style($link-color-dark);
            }

            &.theme-light .breadcrumb {
                @include text-link-style($link-color-light);
            }

            .breadcrumb {
                padding: 0;
            }
        }
    }

    &:not(.tablet-portrait) {
        .site-content-width {
            width: 100%;
            max-width: 1260px;
            margin-left: auto;
            margin-right: auto;

            @include respond-to(mobile_strong_over) {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    &.tablet-portrait {
        .site-content-width {
            width: 100%;
            max-width: 1260px;
            margin-left: auto;
            margin-right: auto;

            @include respond-to(tablet_plus) {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    &:not(.tablet-portrait).transparent {
        .header-banner-content-block {
            @include respond-to(mobile_strong) {
                margin-top: -10px;
                padding-top: 40px;
            }
        }

        .light {
            @include respond-to(mobile_strong) {
                color: $black01;
            }

            .header-banner-content-block-link {
                @include respond-to(mobile_strong) {
                    color: $black01;
                    border-color: $black01;
                }

                &:hover {
                    @include respond-to(mobile_strong) {
                        background-color: rgba($black01,.5);
                        border-color: rgba($black01,.5);
                        color: $color-primary3;
                    }
                }
            }
        }
    }

    &.tablet-portrait.transparent {
        .header-banner-content-block {
            @include respond-to(mobile) {
                margin-top: -10px;
                padding-top: 40px;
            }
        }

        .light {
            @include respond-to(mobile) {
                color: $black01;
            }

            .header-banner-content-block-link {
                @include respond-to(mobile) {
                    color: $black01;
                    border-color: $black01;
                }

                &:hover {
                    @include respond-to(mobile) {
                        background-color: rgba($black01,.5);
                        border-color: rgba($black01,.5);
                        color: $color-primary3;
                    }
                }
            }
        }
    }

    &:not(.header-banner-content-block-bottom) {
        .site-content-width {
            @include respond-to(mobile_strong) {
                @include flexbox;

                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .header-banner-content-block {
                @include respond-to(mobile_strong) {
                    @include align-self(flex-end);

                    width: 100%;
                }
            }
        }
    }

    &.header-banner-content-block-bottom {
        .header-banner-content-block {
            @include respond-to(mobile) {
                margin-top: -10px;
                padding-top: 40px;
            }
        }

        .light {

            .header-banner-content-block-link {
                @include respond-to(mobile_strong) {
                    color: $black01;
                    border-color: $black01;
                }

                &:hover {
                    @include respond-to(mobile_strong) {
                        background-color: rgba($black01,.5);
                        border-color: rgba($black01,.5);
                        color: $color-primary3;
                    }
                }
            }
        }
    }

    &.show-tape {
        @include clip-decorator($corners: 4);
    }

    &:not(.tablet-portrait).img-position-right {
        .component-image {
            @include respond-to(tablet_plus) {
                float: right;
            }
        }
    }
    &.tablet-portrait.img-position-right {
        .component-image {
            @include respond-to(tablet_plus) {
                float: right;
            }
        }
    }

    &:not(.tablet-portrait).img-position-left {
        .component-image {
            @include respond-to(tablet_plus) {
                float: left;
            }
        }
    }
    &.tablet-portrait.img-position-left {
        .component-image {
            @include respond-to(tablet_plus) {
                float: left;
            }
        }
    }

    &:not(.tablet-portrait).img-position-center {
        .component-image {
            @include respond-to(mobile_strong_over) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &.tablet-portrait.img-position-center {
        .component-image {
            @include respond-to(tablet_plus) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &:not(.tablet-portrait) .component-image {
        @include respond-to(mobile_strong_over) {
            min-height: 500px;
            max-width: none;
            min-width: 100%;
            width: auto;
            position: relative;
        }

        @include custom-respond-to(1440px) {
            max-width: 100%;
        }
    }

    &.tablet-portrait .component-image {
        @include respond-to(tablet_plus) {
            min-height: 500px;
            max-width: none;
            min-width: 100%;
            width: auto;
            position: relative;
        }

        @include custom-respond-to(1440px) {
            max-width: 100%;
        }
    }

    &:not(.tablet-portrait) .half-width-bg {
        @include respond-to(mobile_strong_over) {
            width: 50%;
            float: left;
            overflow: hidden;
        }

        @include respond-to(mobile_strong) {
            width: 100%;
            float: none;
        }

        &.half-width-bg-secondary {
            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        .component-picture {
            @include respond-to(mobile_strong_over) {
                display: block;
                margin: -3px;
            }
        }
    }

    &.tablet-portrait .half-width-bg {
        @include respond-to(tablet_plus) {
            width: 50%;
            float: left;
            overflow: hidden;
        }

        @include respond-to(mobile) {
            width: 100%;
            float: none;
        }

        &.half-width-bg-secondary {
            @include respond-to(mobile) {
                display: none;
            }
        }

        .component-picture {
            @include respond-to(tablet_plus) {
                display: block;
                margin: -3px;
            }
        }
    }

    .border-type-wrapper {
        @include border-tape($opacity: .5 .7 .5 .5, $direction: 'inside');
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        pointer-events: none;

        &::before,
        &::after {
            background-color: currentColor;
        }

        .border-type-inner {
            &::before,
            &::after {
                background-color: currentColor;
            }
        }
    }
}

.header-banner-content-block {
    position: relative;
    z-index: 1;

    .header-banner-content-block-background-mobile {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        @include respond-to(mobile_strong) {
            display: block;
        }
    }

    .full-width-header-banner:not(.tablet-portrait) & {
        @include respond-to(mobile_strong) {
            background-color: transparent !important;
            padding: 30px;
            text-align: center;
        }

        @include respond-to(mobile_strong_over) {
            @include border-tape($opacity: .5 .7 .5 .5);
            position: absolute;
            top: calc(50% + 15px);
            transform: translateY(-50%);
            width: 410px;
        }

        &::before,
        &::after {
            @include respond-to(mobile_strong_over) {
                background-color: currentColor;
            }
        }

        // position variations
        &.promo-left {
            @include respond-to(mobile_strong_over) {
                left: 15px;
                text-align: left;
                text-align: -webkit-left;
            }
        }

        &.promo-center {
            @include respond-to(mobile_strong_over) {
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                text-align: center;
                text-align: -webkit-center;
            }
        }

        &.promo-right {
            @include respond-to(mobile_strong_over) {
                right: 15px;
                text-align: right;
                text-align: -webkit-right;
            }
        }

        &.text-align-left {
            @include respond-to(mobile_strong_over) {
                text-align: left;
                text-align: -webkit-left;
            }
        }

        &.text-align-center {
            @include respond-to(mobile_strong_over) {
                text-align: center;
                text-align: -webkit-center;
            }
        }

        &.text-align-right {
            @include respond-to(mobile_strong_over) {
                text-align: right;
                text-align: -webkit-right;
            }
        }

        // text colour varioations
        .dark {
            color: $black01;

            .header-banner-content-block-link {
                border-color: $black01;
            }
        }

        .light {
            color: $color-primary3;

            .header-banner-content-block-link {
                border-color: $color-primary3;
                color: $color-primary3;
            }
        }

        .light-mob{
            @include respond-to(mobile_strong){
                color: $color-primary3;
            }

            .header-banner-content-block-link {
                @include respond-to(mobile_strong){
                    color: $color-primary3;
                    border-color: $color-primary3;
                }
            }
        }

        .dark-mob{
            @include respond-to(mobile_strong){
                color: $black01;
            }

            .header-banner-content-block-link {
                @include respond-to(mobile_strong){
                    color: $black01;
                    border-color: $black01;
                }
            }
        }

        .border-type-inner {
            @include respond-to(mobile_strong_over) {
                background-color: transparent;
            }

            &::before,
            &::after {
                @include respond-to(mobile_strong_over) {
                    background-color: currentColor;
                }
            }
        }

        &-title,
        &-description {
            color: inherit;
        }

        .header-banner-content-block-description {
            @include respond-to(mobile_strong_over) {
                padding: 0 20px;
            }

            @include respond-to(mobile_strong) {
                margin-top: 25px;
                margin-bottom: 25px;
                padding: 0 20px;
            }
        }

        .carousel-navigation {
            display: none;
        }

        .carousel-navigation-mobile {
            .prev,
            .next {
                display: none;
            }
        }
    }
    .full-width-header-banner.tablet-portrait & {
        @include respond-to(mobile) {
            background-color: transparent !important;
            padding: 30px;
            text-align: center;
        }

        @include respond-to(tablet_plus) {
            @include border-tape($opacity: .5 .7 .5 .5);
            position: absolute;
            top: calc(50% + 15px);
            transform: translateY(-50%);
            width: 410px;
        }

        &::before,
        &::after {
            @include respond-to(tablet_plus) {
                background-color: currentColor;
            }
        }

        // position variations
        &.promo-left {
            @include respond-to(tablet_plus) {
                left: 15px;
                text-align: left;
                text-align: -webkit-left;
            }
        }

        &.promo-center {
            @include respond-to(tablet_plus) {
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                text-align: center;
                text-align: -webkit-center;
            }
        }

        &.promo-right {
            @include respond-to(tablet_plus) {
                right: 15px;
                text-align: right;
                text-align: -webkit-right;
            }
        }

        &.text-align-left {
            @include respond-to(tablet_plus) {
                text-align: left;
                text-align: -webkit-left;
            }
        }

        &.text-align-center {
            @include respond-to(tablet_plus) {
                text-align: center;
                text-align: -webkit-center;
            }
        }

        &.text-align-right {
            @include respond-to(tablet_plus) {
                text-align: right;
                text-align: -webkit-right;
            }
        }

        // text colour varioations
        .dark {
            color: $black01;

            .header-banner-content-block-link {
                border-color: $black01;
            }
        }

        .light {
            color: $color-primary3;

            .header-banner-content-block-link {
                border-color: $color-primary3;
            }
        }

        .light-mob{
            @include respond-to(mobile_strong){
                color: $color-primary3;
            }

            .header-banner-content-block-link {
                @include respond-to(mobile_strong){
                    color: $color-primary3;
                    border-color: $color-primary3;
                }
            }
        }

        .dark-mob{
            @include respond-to(mobile_strong){
                color: $black01;
            }

            .header-banner-content-block-link {
                @include respond-to(mobile_strong){
                    color: $black01;
                    border-color: $black01;
                }
            }
        }

        .border-type-inner {
            @include respond-to(tablet_plus) {
                background-color: transparent;
            }

            &::before,
            &::after {
                @include respond-to(tablet_plus) {
                    background-color: currentColor;
                }
            }
        }

        &-title,
        &-description {
            color: inherit;
        }

        .header-banner-content-block-description {
            @include respond-to(tablet_plus) {
                padding: 0 20px;
            }

            @include respond-to(mobile) {
                margin-top: 25px;
                margin-bottom: 25px;
            }
        }

        .carousel-navigation {
            display: none;
        }

        .carousel-navigation-mobile {
            .prev,
            .next {
                display: none;
            }
        }
    }
}


.header-banner-content-block-inner {
    .full-width-header-banner:not(.tablet-portrait) & {
        @include respond-to(mobile_strong_over) {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);

            min-height: 370px;
            padding: 115px 50px 35px;
        }
    }
    .full-width-header-banner.tablet-portrait & {
        @include respond-to(tablet_plus) {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);

            min-height: 370px;
            padding: 115px 50px 35px;
        }
    }
}

.header-banner-content-block-cat-name,
.header-banner-content-block-cat-logo,
.header-banner-content-block-badge {
    position: absolute;
    top: 57.5px;
    left: 50px;
    right: 50px;
    transform: translateY(-50%);

    .full-width-header-banner:not(.tablet-portrait) & {
        @include respond-to(mobile_strong) {
            top: 60px;
            left: 30px;
            right: 30px;
        }
    }
    .full-width-header-banner.tablet-portrait & {
        @include respond-to(mobile) {
            top: 60px;
            left: 30px;
            right: 30px;
        }
    }
}

.header-banner-content-block-cat-logo,
.header-banner-content-block-badge {
    max-height: 60px;

    img {
        max-height: 60px;
        width: auto;
        max-width: 200px;

        .full-width-header-banner:not(.tablet-portrait) & {
            @include respond-to(mobile_strong) {
                -webkit-clip-path: none;
                clip-path: none;
            }
        }
    }
}

.header-banner-content-block-cat-name {
    font-family: $font-main-bold;
    font-size: rem(14);
    line-height: 16px;
    text-transform: uppercase;

    .full-width-header-banner:not(.tablet-portrait) & {
        @include respond-to(mobile_strong) {
            position: static;
            transform: none;
            margin-bottom: 10px;
        }
    }

    .full-width-header-banner.tablet-portrait & {
        @include respond-to(mobile) {
            position: static;
            transform: none;
            margin-bottom: 10px;
        }
    }

    &.cat-name-position-left {
        text-align: left;
    }

    &.cat-name-position-center {
        text-align: center;
    }

    &.cat-name-position-right {
        text-align: right;
    }
}

.header-banner-content-block-cat-logo {
    .full-width-header-banner:not(.tablet-portrait) & {
        @include respond-to(mobile_strong) {
            position: static;
            display: table;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            transform: none;
        }
    }
    .full-width-header-banner.tablet-portrait & {
        @include respond-to(mobile) {
            position: static;
            display: table;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            transform: none;
        }
    }

    .full-width-header-banner:not(.tablet-portrait) & {
        &.logo-left {
            @include respond-to(mobile_strong_over) {
                left: 50px;
                right: auto;
            }
        }

        &.logo-center {
            @include respond-to(mobile_strong_over) {
                left: 50px;
                right: 50px;
                text-align: center;
            }
        }

        &.logo-right {
            @include respond-to(mobile_strong_over) {
                left: auto;
                right: 50px;
            }
        }
    }
}

.header-banner-content-block-badge {
    &.badge-left {
        left: 50px;
        right: auto;

        .full-width-header-banner:not(.tablet-portrait) & {
            @include respond-to(mobile_strong) {
                left: 30px;
            }
        }
    }

    &.badge-center {
        left: 50px;
        right: 50px;
        text-align: center;

        .full-width-header-banner:not(.tablet-portrait) & {
            @include respond-to(mobile_strong) {
                left: 30px;
                right: 30px;
            }
        }
    }

    &.badge-right {
        left: auto;
        right: 50px;

        .full-width-header-banner:not(.tablet-portrait) & {
            @include respond-to(mobile_strong) {
                right: 30px;
            }
        }
    }
}

.header-banner-content-block-link {
    .full-width-header-banner:not(.tablet-portrait) & {
        @include respond-to(mobile_strong_over) {
            @include align-self(baseline);
        }
    }
}
