.store-info-container {
    max-width: 1260px;
    margin: 0 auto;

    h1 {
        text-align: center;

        @include custom-respond-to($max-width: 1050px) {
            margin: 30px 0;
        }

        @include custom-respond-to(1051px) {
            margin: 60px 0;
        }
    }

    h3 {
        margin: 20px 0;
    }
}

.hero-image {
    max-width : 1260px;

    img {
        width: 100%;
    }
}

.store-info {
    p {
        font-size: rem(14);
        color: $hel-gray;
        margin: 0;
    }
}

.store-info-item {
    @include custom-respond-to(1051px) {
        padding: 100px 60px;
    }

    .intro {
        @include custom-respond-to($max-width: 1050px) {
            width: 100%;
            padding: 0 20px 40px 20px;
        }

        @include custom-respond-to(1051px) {
            width: 55%;
        }
    }

    .store-opening-hours {
        @include custom-respond-to($max-width: 1050px) {
            width: 100%;
        }

        @include custom-respond-to(1051px) {
            width: 40%;
        }
    }

    .inside {
        p {
            margin-bottom: 1.5em;
        }
    }
}

.static-content-block {
    width: 410px;
    position: relative;
    float: right;
    padding: 9.5px;
    -webkit-clip-path: polygon(0% 10px, 10px 0, calc(100% - 10px) 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100% - 10px));
    clip-path: polygon(0% 10px, 10px 0, calc(100% - 10px) 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100% - 10px));
    background-color: transparent;
    background-clip: content-box;

    h3 {
        text-align: center;
        margin: 0 0 20px 0;
    }
    
    &::before,
    &::after {
        content: '';
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        background: #c7c9e0;
        z-index: 1;
    }

    &::before {
        left: 0;
        opacity: .5;
    }

    &::after {
        right: 0;
        opacity: .7;
    }

    .border-type-inner {
        position: relative;
        z-index: 10;

            &::before,
            &::after {
                content: '';
                width: calc(100% + 10px * 2 - 1px);
                height: 10px;
                position: absolute;
                left: -10px;
                background: #c2d5f1;
                opacity: .5;
            }

            &::before {
                top: -10px;
            }
    }
}

.static-content-block-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f9fcff;

    @include custom-respond-to($max-width: 1050px) {
        padding: 50px 10px;
    }

    @include custom-respond-to(1051px) {
        padding: 50px 40px;
    }
}

.pure-table {
    margin: 0 auto;
    empty-cells: show;

    th, td {
        overflow: visible;
        font-size: rem(18);
        padding: 10px;
    }

    th {
        color: $black01;
        text-align: right;
    }

    td {
        color: $hel-gray;
    }
}

.icon-info-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 40px 20px 0px 20px;
}

.cc-icon {
    width: 20%;
    display: inline-block;

    img {
        width: 30px;
    }
}

.icon-copy {
    width: 80%;
    float: right;
}

#map_wrapper {
    height: 400px;
    margin: 40px 0;
    background: #ccc;
}

#map_canvas {
    width: 100%;
    height: 100%;
}

.store-info-item.extra-pad {
    @include custom-respond-to(1051px) {
        padding: 100px;
    }
}

.store-info-row {
    @include custom-respond-to($max-width: 1050px) {
        margin: 20px;
    }

    &:last-of-type {
        margin-top: 80px;
    }

    .inside-left {
        @include custom-respond-to($max-width: 1050px) {
            width: 100%;
        }

        @include custom-respond-to(1051px) {
            width: 30%;
        }
    }

    .inside-right {
        @include custom-respond-to($max-width: 1050px) {
            width: 100%;
        }

        @include custom-respond-to(1051px) {
            width: 60%;
        }
    }

    h3 {
        margin-top: 9px;

        @include custom-respond-to(1051px) {
            border-right: 1px solid $black01;
        }
    }

    a {
        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}

.in-store-brand-list {
    .storepage-brands {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;

        @include respond-to(mobile) {
            margin: 0;
            padding: 70px 0;
        }

        @include respond-to(tablet_plus) {
            margin: 0 -100% 70px;
            padding: 8em 100% 8em;
        }
    }

    .storepage-brands__title {
        font-family: $font-main;

        @include respond-to(mobile) {
            margin-bottom: 20px;
        }

        @include respond-to(tablet_plus) {
            margin-bottom: 35px;
        }
    }

    .tabcontainer {
        @include respond-to(tablet_plus) {
            width: 75%;
            margin: 0 auto;
        }
    }

    .tab {
        overflow: hidden;
        border: none;

        @include respond-to(mobile) {
            margin-bottom: 60px;
            background-color: #e6e6e6;
        }

        @include respond-to(tablet_plus) {
            margin-bottom: 50px;
        }

        button {
            font-family: $font-main-bold;
            color: $black01;
            padding: 14px 16px;
            border-style: none;

            border-radius: 0;
            background-color: inherit;
            transition: 0.3s;

            @include respond-to(mobile) {
                width: auto;
                font-size: rem(12);
                margin-left: -2px;
                border-bottom: none;
            }

            @include respond-to(tablet_plus) {
                font-size: rem(16);
                margin-left: -5px;
                border-bottom: 2px solid #e6e4e4;
            }

            &:hover {
                color: $medium-blue;
            }
        }

        button.active {
            color: $black01;
            border-bottom: 2px solid $medium-blue;
            transition: border-color 300ms ease-out;
        }
    }

    .tabcontent {
        @include respond-to(mobile) {
            padding-bottom: 35px;
        }
    }

    .brand {
        display: inline-block;
        font-size: rem(14);
        text-transform: uppercase;
        color: $hel-gray;
        vertical-align: top;

        @include respond-to(mobile) {
            width: 100%;
        }

        @include respond-to(tablet_plus) {
            width: 33%;
            margin-bottom: 30px;
        }

        ul {
            line-height: 2.5;

            @include respond-to(mobile) {
                margin: 0;
            }
        }

        a {
            color: #535353;
            text-decoration: underline;
        }
    }

    .all-brands-btn {
        padding: 0 30px;
    }

    .button-type2 {
        padding: 15px 30px;
    }
}