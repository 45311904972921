$co-space1: 12px;

.cart-order-totals {
    @include shared-checkout-sidebar-decoration('.cart-order-totals-details');

    @include respond-to(tablet_plus) {
        float: right;
        width: 35%;
        max-width: 400px;
    }

    &-details {
        .cart-summary-loyalty-mobile {
            display: none;
        }

        .counter {
            font-family: $font-main-bold;
            float: right;
        }

        .rowcoupons-couponcodes-label {
            margin-top: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .rowcoupons {
            margin-left: 10px;
            margin-right: 10px;
        }

        .couponcodes {
            .item-quantity-details {
                button {
                    @include icon(minus);

                    position: absolute;
                    top: auto;
                    right: 10px;
                    bottom: 0;
                    font-size: 0;

                    &::before {
                        font-size: rem(14);
                        display: block;
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.cart-actions {
    padding: 0 10px 10px;

    + .cart-summary-loyalty {
        display: none;
    }

    .button-fancy-large.coloured {
        @include shared-colored-action-button;

        @include icomoon-icon($aricons-small-arrow-right, $font-size: rem(8), $line-height: rem(18), $pseudo: after) {
            padding-left: 12px;
            vertical-align: top;
        }
    }
}

.order-totals-table-container {
    margin: 30px 0 15px;
    position: relative;

    .cart-loyality-points {
        padding: 0 10px 0 0;
        margin-bottom: 10px;
    }

    .toggle-content.expanded & {
        @include respond-to(mobile) {
            padding: 0 10px;
        }
    }

    .loyalti-terms {
        display: none;
    }

    .loyalty-cart_points {
        font-size: rem(14);
        padding: 0 10px 4px;
    }
}

.order-totals-table {
    .cart-loyality-points,
    .order-shipping {
        td {
            @include respond-to(mobile_mid) {
                line-height: rem(18);
            }
        }
    }

    .your-order-total-gift-cart-row,
    .your-order-total-loyalty-row {
        position: relative;

        td {
            @include respond-to(tablet_plus) {
                position: static;
            }
        }

        .order-value {
            color: $red01;
        }

        .remove-redemption-bracket,
        .remove-loyalty-bracket {
            display: none;
        }

        .remove-redemption {
            font-size: 0;
            position: absolute;
            color: $red01;

            @include icomoon-icon($aricons-close-small, $font-size: rem(18));

            @include respond-to(tablet_plus) {
                left: 98%;
            }

            @include respond-to(mobile) {
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                @include icomoon-icon($aricons-close-small, $font-size: rem(30));
            }
        }
    }

    .total-separator {
        td {
            padding-bottom: 25px;
            font-size: 0;

            .payment & {
                padding: 0;
            }
        }
    }

    .order-total {
        border-top: 1px solid $very-light-gray;
        border-bottom: 0;

        td {
            padding-top: 15px;
            font-size: rem(20);
            line-height: 1;

            &:first-child {
                font-family: $font-main-bold;

                @include respond-to(mobile) {
                    padding: 15px 10px 0;
                }
            }
        }
    }

    .excl-delivery {
        font-size: rem(10);
    }

    .order-discount {
        td {
            color: $red01;

            &:first-child {
                font-family: $font-main-bold;
            }
        }
    }

    .order-shipping-discount {
        td {
            color: $red01;
        }
    }

    .promo-adjustment-value {
        color: $red01;
        vertical-align: top;
        line-height: rem(22);
    }

    .remove-coupon {
        font-size: 0;
        position: absolute;
        color: $red01;

        @include icomoon-icon($aricons-close-small, $font-size: rem(18));

        @include respond-to(tablet_plus) {
            left: 92%;
        }

        @include respond-to(mobile) {
            right: calc(100% + 10px);
            top: 50%;
            transform: translateY(-50%);

            @include icomoon-icon($aricons-close-small, $font-size: rem(30));
        }
    }

    tr {
        td:not(.order-totals-inner) {
            &:last-child {
                font-family: $font-main-bold;
            }
        }

        &.order-sales-tax {
            &.hidden-mobile {
                display: table-row !important; // override core_ext
            }
        }
    }
}

.hide-earn-loyalty {
    .cart-loyality-points {
        display: none;
    }
}

.cart-delivery-information {
    margin-top: 50px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

    @include respond-to(mobile_strong_over) {
        margin-top: 60px;
    }

    .delivery-info-header {
        font-size: rem(16);
        text-transform: uppercase;
        margin-bottom: 40px;
        text-align: left;

        @include respond-to(mobile_strong_over) {
            font-size: rem(15);
            margin-bottom: 30px;
        }
    }

    .delivery-info-list {
        font-size: rem(18);
        color: $black01;
        line-height: 1;

        @include respond-to(mobile_strong_over) {
            font-size: rem(16);
        }

        li {
            margin-bottom: 1em;
            position: relative;
            padding-left: 20px;

            @include icomoon-icon($aricons-check, $font-size: rem(8)) {
                position: absolute;
                left: 0;
                top: 5px;
                color: $color-primary2;
            }

            > span {
                vertical-align: middle;
            }

            i {
                vertical-align: middle;
                padding-left: 6px;
                position: relative;

                @include respond-to(mobile_strong_over) {
                    padding-left: 12px;
                }

                &:hover {
                    &::after {
                        position: absolute;
                        left: 5px;
                        top: 25px;
                        width: 20px;
                        height: 20px;
                        transform: rotate(45deg);
                        background: $color-primary2;
                        content: '';

                        @include respond-to(mobile_strong_over) {
                            left: 10px;
                        }
                    }
                }
            }

            i:hover + .tooltip {
                position: absolute;
                display: block;
                width: 275px;
                left: 0;
                top: 30px;
                background: $color-primary2;
                padding: 20px 30px;
                z-index: $z-min;
                color: $color-primary3;
                font-family: $font-main;
                font-size: rem(16);

                @include respond-to(mobile_min) {
                    left: -30px;
                }

                @include respond-to(mobile_strong_over) {
                    font-size: rem(15);
                }
            }
        }
    }

    .tooltip {
        display: none;
    }
}
