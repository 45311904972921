html {
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden; // iOS fix ("overflow-x: hidden;" inserting it only for BODY doesn't help iOS....)
    
    @include respond-to(mobile) {
        overflow-x: hidden;
        width: 100%;
    }
}

body {
    background: none repeat scroll 0 0 $white;
    color: $body-base-color;
    font-family: $font-main;
    font-size: $body-font-size;
    overflow-x: hidden; //for @mixin out-of-wrapper
    
    @include respond-to(mobile) {
        width: 100%;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

a {
    color: $link-default-color;
    outline: none;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.under {
    text-decoration: underline;
    
    &:hover {
        text-decoration: none;
    }
}

.under-wo-hover {
    text-decoration: underline;
}

.underline {
    letter-spacing: 1px;

    @include link_underline($medium-blue);
}

.compact {
    text-transform: uppercase;
    font-size: 80%;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    padding: 1em;
    vertical-align: top;
}

.toggle-content {
    display: none;
    
    &.expanded {
        display: block;
    }
}

.js-component-videojs-hide-native-controls video::-webkit-media-controls-panel-container,
.js-component-videojs-hide-native-controls video::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
    opacity: 0 !important;
    pointer-events: none !important;
}