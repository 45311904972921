body .js-social-feeds.ui-tabs {
    .ui-tabs-nav {
        text-align: center;

        li {
            display: inline-block;
        }
    }

    #facebook.carousel {

    }

    #instagram.carousel {
        .instagram-item {
            background-color: $white;
            padding: 0 15px 20px 15px;
            text-align: center;

            img {
                display: inline-block;
                width: 80%;
            }

            .likes {
                font-size: rem(10);
                color: $dim-gray;
            }
        }
    }

    #pinterest.carousel {
        li {
            > span {
                box-shadow: none;
            }
        }
    }
}

.social-header {
    @include out-of-wrapper();
    padding: 20px 0 10px;
    background: $lightest-gray;
    color: $nobel;
    text-align: center;

    @include respond-to(touch) {
        margin: 0;
    }

    h2 {
        position: relative;
        color: $nobel;

        &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            right: 0;
            margin: auto;
            border-top: 1px solid;
            width: 80px;
        }
    }

    li {
        display: inline-block;
        margin: 0 5px;
        border-radius: 50%;
        width: 20px;
        height: 10px;
    }
}

.social-feed {
    &-titles {
        margin-top: 15px;
        text-align: center;

        .social-feed-element {
            width: 23%;
            margin: 0 1%;
            float: left;
            font-size: rem(13);
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $black01;
            padding-bottom: 8px;

            @include respond-to(mobile) {
                width: 100%;
                float: none;
                margin-bottom: 20px;
            }
        }

        i {
            font-size: rem(11);
            margin-right: 5px;
        }

    }

    .social-container {
        margin-top: 5px;
        border-top: 1px solid $very-light-gray;
        overflow: hidden;

        img {
            max-width: 100%;
        }
    }

    #twitter {
        text-align: center;

        a,
        p {
            font-size: rem(13);
            line-height: 17px;
            color: $black01;
        }

        img {
            margin-top: 10px;
            max-width: 100%;
        }

        .carousel-item + .carousel-item {
            display: none !important;
        }
    }

    #instagram {
        .instagram-item {
            width: 100%;
            margin: 10px 0;
            text-align: center;
        }

        .description {
            margin-top: 10px;
        }

        .likes {
            display: none;
        }

        img {
            max-width: 100%;
        }

        a {
            display: block;
        }
    }

    .facebook-item {
        img,
        video {
            max-width: 100%;
        }

        video {
            padding-top: 100%;
            margin-top: -100%;

            @include respond-to(mobile) {
                padding: 0;
                margin: 0;
            }
        }

        p {
            font-size: rem(13);
            line-height: 17px;
            color: $black01;
            word-wrap: break-word;
        }
    }

    #pinterest {
        padding-top: 10px;

        > span {
            box-shadow: none;
        }

        span[class*="_bd"],
        span[class*="_hd"] {
            border-bottom: 0;
        }

        span[class*="_description"] {
            text-align: center;
            color: $black01;
            font-size: rem(13);
            font-family: $font-main;
        }

        span[class*="_source"],
        span[class*="_stats"],
        span[class*="_ft"],
        span[class*="_menu"],
        span[class*="_domain"] {
            display: none;
        }
    }
}




