.full-width-ad-banner {
    @include clip-decorator($corners: 4);

    @include respond-to(mobile_strong_over) {
        min-height: 450px;
    }

    &.single-image-bg {
        @include respond-to(mobile_strong) {
            @include flexbox;
            @include flex-direction(column-reverse);
        }

        .banner-ad-bg {
            .component-image {
                @include respond-to(mobile_strong_over) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &.double-images {
        .banner-ad-bg {
            @include respond-to(mobile_strong) {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                z-index: -1;
            }

            .component-image {
                @include respond-to(mobile_strong_over) {
                    left: 50%;
                    transform: translateX(-50%);
                }

                @include respond-to(mobile_stronger) {
                    max-width: 110%;
                }
            }
        }
    }

    .banner-ad-bg {
        .component-image {
            display: block;

            @include respond-to(mobile_strong_over) {
                min-height: 450px;
            }
        }
    }

    .header-banner-content-block {
        @include respond-to(mobile_strong) {
            &-inner {
                padding-top: 17px;
            }
        }
    }

    &:not(.transparent) .site-content-width {
        @include respond-to(mobile_strong) {
            position: static;
        }
    }

    .header-banner-content-block-cat-name {
        @include respond-to(mobile_strong) {
            margin-bottom: 4px;
        }
    }

    .header-banner-content-block-description {
        @include respond-to(mobile_strong) {
            margin: 10px 0;
        }
    }

    .border-type-wrapper {
        z-index: 1;
    }

    .site-content-width {
        z-index: 1;
    }

    .header-banner-content-block {
        &.promo-left {
            @include respond-to(mobile_strong_over) {
                left: 8px;
            }
        }
    }

    .header-banner-content-block-inner {
        @include respond-to(mobile_strong_over) {
            padding-top: 70px;
        }

        @include respond-to(mobile_to_desktop) {
            min-height: 320px;
        }

        @include respond-to(mobile_stronger) {
            min-height: 250px;
        }

        @include respond-to(mobile_strong_over) {
            min-height: 350px;
            padding-bottom: 60px;
        }
    }

    .header-banner-content-block-cat-name,
    .header-banner-content-block-cat-logo,
    .header-banner-content-block-badge {
        top: 40px;

        @include respond-to(mobile_stronger) {
            top: 55px;
        }

        @include respond-to(mobile_strong) {
            top: 120px;
        }
    }

    .header-banner-content-block-badge {
        @include respond-to(mobile_strong) {
            top: auto;
            bottom: 0;
        }
    }

    .header-banner-content-block-description {
        @include respond-to(mobile_stronger) {
            margin: 5px 0;
        }
    }

    .banner-ad-picture {
        @include respond-to(mobile_strong) {
            position: static;
        }

        @include respond-to(mobile_strong_over) {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .component-picture {
            margin: 0;

            @include respond-to(mobile_strong_over) {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            }
        }

        .component-image {
            height: auto;
            max-width: 100%;
            min-height: 0;
            min-width: 0;
            position: static;
            transform: none;
            display: block;

            @include respond-to(mobile_strong) {
                width: 100%;
            }

            @include respond-to(mobile_strong_over) {
                position: absolute;
                top: 50%;
                right: 0;
                margin-left: auto;
                transform: translateY(-50%);
            }
        }

        .banner-ad-pocture-desktop {
            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        .banner-ad-pocture-mobile {
            @include respond-to(mobile_strong_over) {
                display: none;
            }
        }
    }

    .border-type-wrapper {
        @include respond-to(mobile_strong) {
            display: block;
        }
    }
}
