body {
    .ui-widget-overlay {
        background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $night-rider;
        opacity: 0.4;
    }

    .ui-dialog {
        background-color: $white;
        padding: 0;
        border: solid 1px #666666;
        box-shadow: 0 0 10px rgba(21, 21, 21, 0.4);
        z-index: $z-popups;

        @include respond-to(mobile) {
            max-width: 85%;
        }

        .ui-widget-header {
            padding: 15px;
            background-color: $white;
            border: 0 none;
            border-radius: 0 0 0 0;
            text-transform: uppercase;
        }

        .ui-icon-closethick {
            height: 32px;
            width: 32px;
            left: 0;
            top: 0;
            margin-left: 0;
            margin-top: 0;
            z-index: 1;
        }

        .ui-button-text {
            display: block;
        }

        .ui-dialog-titlebar-close {
            background-color: transparent;
            border: 0 none;
            border-radius: 0 0 0 0;
            height: 32px;
            margin: 0;
            opacity: 1;
            padding: 0;
            position: absolute;
            z-index: 299;
            right: 15px;
            top: 12px;
            width: 32px;
            text-indent: 0;
            font-size: 0;

            &:before {
                font-family: $font-icons;
                content: "\e901";
                color: $nobel;
                font-size: rem(18);
                line-height: 36px;
            }

            &:hover {
                opacity: 1;
            }
            span {
                margin: 0;
                padding: 0;
            }
        }

        h1,
        h2 {
            font-size: rem(28.8);
            margin: 5px 0;
        }

        .ui-dialog-content {
            padding: 0 25px 25px;
        }
        
        &.dialog-over18sproduct {
            text-align: center;
        }
    }

    .ui-popup-imagezoom .ui-dialog-titlebar .ui-dialog-title:after {
        display: none;
    }

    .no-close .ui-dialog-titlebar-close {
        display: none;
    }

    .ui-accordion .ui-accordion-content {
        padding: 0;
    }

    .ui-tabs {
        .ui-tabs-nav {
            height: 2.45em;
            padding: 0;

            li {
                border-color: $white;
                border-style: solid;
                border-width: 0 0 3px 0;

                &.ui-state-active {
                    border-bottom-color: $citrus;

                    a {
                        color: $black01;
                    }
                }

                a {
                    color: $nobel;
                }
            }
        }

        .ui-tabs-panel {
            background-color: $white;
            border: 1px solid $white;
            line-height: 1.5em;
            margin: 0;
            padding: 20px 10px;

            h3 {
                @include respond-to(tablet_plus) {
                    display: none;
                }
            }
        }
    }

    #vertical-carousel {
        height: 19rem;
        position: relative;
        overflow: hidden;

        ul {
            height: 10000em;
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;

            li {
                float: left;
                height: 19rem;
                width: 100%;

                .capture-product-id {
                    display: none;
                }
            }
        }

        .jcarousel-nav {
            color: $nobel;
            cursor: pointer;
            font-size: rem(14.4);
            position: absolute;
            right: 0;
            text-transform: uppercase;

            &:hover {
                color: $citrus;
            }

            &.inactive {
                display: none;
            }
        }

        .jcarousel-prev {
            bottom: 30px;
        }

        .jcarousel-next {
            bottom: 0;
        }
    }
}