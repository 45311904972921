nav[role="navigation"] {
    @extend %clearfix;
    z-index: $header-inner-start-z-index;

    @include respond-to(mobile_strong) {
        left: - $header-nav-width-mobile;
        margin-top: 0;
        position: fixed;
        width: $header-nav-width-mobile;
        top: 0;
        bottom: 0;
        height: 100vh !important;
        overflow-x: hidden;
        transition: all 500ms ease;
        background-color: $color-primary3;
        z-index: 8;

        #wrapper & {
            top: 0 !important; //temporarily: JS code cause -   block in common-elements.js (lines 328-333)
        }

        #wrapper.menu-active & {
            left: 0;
        }

        // this is a marker for JS to let it know that Hamburger menu rendered
        &::before {
            content: "js-hamburger";
            display: none;
        }
    }

    @include respond-to(mobile_strong_over) {
        color: $night-rider;
        position: relative;
    }

    .user-info {
        a {
            &.user-account::after,
            &::after {
                content: none;
            }

            > span {
                letter-spacing: .1em;
            }

            span {
                display: inline-block;
            }

            .bticons-user {
                line-height: $mobile-menu-utility-height;
            }
        }
    }
}

.menu-toggle {
    display: block;
    background-color: transparent;
    border: 0;
    color: $black01;
    z-index: 3;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include respond-to(mobile_strong_over) {
        display: none;
    }

    @include respond-to(mobile_strong) {
        order: 1;
        width: 30%;
        padding: 0 0 0 20px;
        text-align: left;
    }

    &::after {
        display: none;
    }

    &:hover {
        background-color: transparent;
        color: $dim-gray;
    }

    .menu-icon {
        font-size: rem(16);
    }

    span {
        display: none;
    }
}

.menu-category-wrapper {
    @include respond-to(mobile_strong_over) {
        border-top: 1px solid $color-secondary3;
        margin: 0 auto;
        width: 100%;
        background-color: $color-primary3;
        box-shadow: inset 0 -6px 2px -6px $color-secondary2;
        position: relative;

        .is-sticky & {
            border-top: 0;
            margin-top: -1px;
            width: calc(100% - 20px);

            &::before {
                clip-path: polygon(0% 0%, 0% calc(100% - 10px), 100% 100%, 100% 0%);
                content: '';
                width: 10px;
                position: absolute;
                top: 0;
                left: -10px;
                bottom: 0;
                z-index: 100;
                background-color: $color-primary3;
            }

            &::after {
                clip-path: polygon(0% 0%, 0% 100%, 100% calc(100% - 10px), 100% 0%);
                content: '';
                width: 10px;
                position: absolute;
                top: 0;
                right: -10px;
                bottom: 0;
                background-color: $color-primary3;
            }
        }
    }

    body:not([data-locale="en_IE"]) & { // Hide for international locales
        [data-cgid="shop-by-department-gifts-giftcard"] {
            display: none;
        }
    }

    .bticons-right {
        &::before {
            content: $aricons-small-arrow-right;
        }
    }
}

.btn-close-category-menu {
    background: $black01;
    color: $color-primary3;
    font-size: rem(26);
    padding: 22px 0 0 20px;

    #wrapper & {
        height: $header-height-mobile;
        transform: none;
        left: 0;
        transition: left 500ms ease;

        @include respond-to(mobile_strong_over) {
            display: none;
        }

        &::after {
            content: none;
        }
    }

    #wrapper.menu-active & {
        left: 80%;
    }

    span {
        display: none;
    }
}

.menu-category {
    @extend %clearfix;

    list-style: none;
    margin: 0;
    padding: 0;
    color: $black01;
    text-transform: capitalize;
    letter-spacing: .04em;

    @include respond-to(mobile_strong) {
        &.level-1,
        .level-2,
        .level-3 {
            li {
                border-bottom: 1px solid $color-auxiliary10;
            }
        }
    }

    > .menu-contacts-mobile,
    > .nav-social-icons {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    .shadow-overlay {
        @include respond-to(mobile_strong_over) {
            @include position(fixed, 0, 0, 32px, 0);

            display: block;
            background-color: $color-auxiliary2;
            z-index: -2;
            opacity: 0;
            transition: opacity .3s;
            pointer-events: none;
        }
    }

    &.menu-category-hover.no-transition {
        .level-2.main-wide-menu-wrapper.arnt-new-navigation {
            transition: 0s;
        }
        .shadow-overlay {
            transition: 0s;
        }
    }

    &.level-1 {
        @include respond-to(mobile_strong_over) {
            display: block;
            text-align: center;
            font-size: 0;
        }

        @include respond-to(desktop_over) {
            border-top: 1px solid transparent;
        }

        > li {
            @include respond-to(desktop_over) {
                line-height: $header-inner-wrapper-sticky-height - 1px;
            }

            @include respond-to(mobile_strong_over) {
                font-size: rem(16);
                line-height: $header-inner-wrapper-sticky-height;
                display: inline-block;

                &:hover {
                    > a {
                        i {
                            transform: rotate(90deg) translateX(0); // arrow-down
                            opacity: 1;
                        }

                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }

            > a {

                @include respond-to(mobile_strong_over) {
                    color: $black01;
                    position: relative;
                    padding: 0 15px;

                    &::before {
                        position: absolute;
                        background-color: $color-primary2;
                        bottom: 0;
                        left: 5%;
                        height: 5px;
                        transform: scaleX(0);
                        width: 90%;
                        transition: all .2s;
                        content: '';
                    }

                    i {
                        display: none;
                        position: absolute;
                        bottom: 8px;
                        line-height: 1;
                        left: 50%;
                        margin-left: -5px;
                        color: $color-primary2;
                        transform: rotate(90deg) translateX(8px); // arrow-down
                        padding: 0;
                        opacity: 0;
                        transition: .3s;

                        &::before {
                            content: $aricons-tiny-arrow-right;
                        }
                    }
                }

                @include respond-to(mobile_tablet) {
                    padding: 0 6px;
                }

                @include respond-to(mobile_strong) {
                    padding: 0 35px 0 30px;
                }

                @include custom-respond-to(1024px, 1040px) {
                    padding: 0 10px;
                }
            }
        }
    }

    &.menu-category-hover {
        li:hover > .level-2 {
            @include respond-to(mobile_strong_over) {
                & + .shadow-overlay {
                    opacity: .6;
                    pointer-events: none;
                }
            }
        }
    }

    li {
        @extend %clearfix;
        list-style: none;

        &:hover > a {
            background-color: transparent;
        }
    }

    a {
        display: inline-block;
        font-size: rem(9);
        overflow: visible;
        width: 100%;
        padding: 1rem;
    }

    .brand-item {
        clear: both;
        color: $nobel;
        float: none;
        width: 100%;
        font-size: rem(14);
        line-height: 18px;
        letter-spacing: 1px;
        padding-bottom: 1px;
        text-transform: uppercase;
        -webkit-transition: background-color 0.3s, color 0.3s;
        transition: background-color 0.3s, color 0.3s;

        @include respond-to(mobile_strong) {
            border-bottom: 1px solid $tutu;
            font-size: rem(15);
            letter-spacing: 1px;
            line-height: 24px;
            padding: 6px 20px;

            &:hover {
                background-color: $lightest-gray;
            }
        }

        i {
            display: inline-block;
            float: right;
            font-size: rem(8);
            line-height: 2.4rem;
        }
    }

    .view-all-brands {
        @include respond-to(mobile_strong) {
            display: none;
        }
    }
}

.menu-active .menu-category .brand-item {
    @include respond-to(mobile) {
        font-size: rem(12);
    }
}

.mobile-nav-utility {
    &.nav-back {
        padding: 0.6rem 1.2rem;
        font-size: rem(15);
        line-height: 2rem;
        text-transform: uppercase;
        cursor: pointer;

        i {
            display: inline-block;
            float: left;
            line-height: 2rem;
            font-size: rem(8);
            padding-right: 1rem;
        }
    }

    .menu-category.level-1 &.nav-back {
        font-size: rem(12.5);
        cursor: pointer;
        color: $color-auxiliary19;
        border-bottom: 1px solid $color-auxiliary10;

        i {
            float: left;
            margin-right: 0;
            padding-right: 10px;
        }
    }

    &.upper-level-link {
        @extend %clearfix;
        border-bottom: 1px solid rgba($black01, .2);

        a {
            color: $black01;
            padding-right: 20px;
            letter-spacing: 1px;

            &:hover {
                background-color: $ghost;
            }
        }

        i {
            float: left;
            margin-right: 0;
            padding-right: 10px;
        }
    }
}

.level-1 {
    @include respond-to(mobile_strong) {
        position: absolute;
        top: 102px; // height of menu-utility
        bottom: 0;
        left: 0;
        right: 0;
        overflow-x: hidden;
    }

    .menu-brand-assets li {
        @include respond-to(mobile_strong_over) {
            a {
                padding: 0;
                text-transform: none;
                letter-spacing: 1px;
            }
        }
    }

    > li {
        @extend %clearfix;
        // override background-color for small viewport
        background-color: transparent;
        line-height: 2rem;

        @include respond-to(mobile_strong_over) {
            flex-grow: 1;
        }

        .is-tablet & {
            > a:hover {
                color: $nobel;
                background-color: transparent;
            }

            &.active > a {
                background-color: $white-smoke;
                color: $dim-gray;
            }
        }

        .sticky-wrapper.is-sticky .is-tablet & {
            > a:hover {
                color: $white;
                background-color: transparent;
            }

            &.active > a {
                background-color: $white-smoke;
                color: $nobel;
            }
        }

        .menu-item-toggle {
            position: absolute;
            right: 0;
            color: $nobel;
            cursor: pointer;
            line-height: 2rem;
            font-size: rem(10);
            padding: 0.8rem 0;
            width: 2.1rem;

            @include respond-to(mobile_strong_over) {
                display: none;
            }
        }

        a {
            float: left;
            width: 100%;
            padding: 0.6rem 2rem;
            line-height: 2.4rem;
            letter-spacing: 1px;

            @include respond-to(mobile_strong_over) {
                float: none;
                padding: 0.6rem 1rem;
                font-size: rem(14);
                line-height: 19px;

                &.has-sub-menu {
                    width: 100%;
                }
            }

            @include respond-to(mobile) {
                font-size: rem(12);
            }

            @include respond-to(mobile_stronger) {
                font-size: rem(11);
                padding: 6px;
            }
        }

        > a {
            @include respond-to(desktop_over) {
                padding: 10px 20px;
                font-size: rem(15);
            }
        }

        a,
        .nav-back {
            text-transform: capitalize;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: background-color .3s, color .3s;
            font-size: rem(14);
            font-family: $font-main-bold;

            @include respond-to(mobile_strong) {
                width: 100%;
                padding: 0 35px 0 30px;
                line-height: $mobile-menu-utility-height;
            }

            @include respond-to(desktop_over){
                line-height: $header-inner-wrapper-sticky-height - 1px;
            }

            @include respond-to(mobile_strong_over) {
                padding: 0;
                line-height: $header-inner-wrapper-sticky-height;
            }

            i {
                display: inline-block;
                float: right;
                font-size: rem(10);
                line-height: $mobile-menu-utility-height;
                padding-right: 20px;

                @include respond-to(mobile_strong) {
                    padding-right: 0;
                    margin-right: -20px;
                    margin-right: 0;
                }
            }
        }

        .level-2 {
            li {
                display: block;
                line-height: 16px;
            }

            a {
                @include respond-to(mobile_strong_over) {
                    padding: 0;
                    text-transform: none;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &.menu-category-hover {
        li:hover .has-sub-menu + .level-2 {
            @include respond-to(mobile_strong_over) {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                z-index: 1;
            }

            @include respond-to(tablet_ext) {
                display: block;
            }
        }
    }

    > li.active .has-sub-menu + .level-2 {
        @include respond-to(mobile_strong_over) {
            opacity: 1;
            visibility: visible;
            display: block;
            pointer-events: auto;
        }

        @include respond-to(tablet_ext) {
            display: block;
        }
    }

    .nav-social-icons,
    .utility-links,
    .menu-contacts-mobile {
        margin: 35px 0 0;
        letter-spacing: .04em;

        @include respond-to(mobile_strong) {
            padding: 0 30px;
        }

        ul {
            margin: 0;
            display: flex;
        }

        li {
            border-bottom: 0;
        }

        a {
            font-size: rem(12.5);
            padding: 10px 0;
            width: auto;
            color: $header-links-color;
        }
    }

    .nav-social-icons {
        padding-bottom: 90px;

        .social-links-header {
            text-align: left;
            text-transform: none;
            font-size: rem(15);
            margin: 20px 0 15px;
            color: $color-secondary2;
        }

        .social-icon {
            display: inline-block;
            margin-left: 14%;

            &:first-child {
                margin-left: -5px;
            }

            i {
                font-size: rem(20);
            }

            a {
                padding: 0;
            }
        }
    }

    .utility-links {
        margin-top: 15px;

        a {
            width: 100%;
        }
    }

    > .utility-links {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    .menu-contacts-mobile {
        a {
            font-size: 0;
            padding: 0;
            margin-left: 20px;
            width: auto;
            letter-spacing: 0;

            &:first-child {
                margin: 0;
            }
        }

        i {
            font-size: rem(36);
        }
    }
}

.level-2,
.level-3,
.level-4 {
    display: none;
    color: $black01;
    background-color: $color-primary3;

    @include respond-to(mobile_strong) {
        position: fixed;
        display: block;
        left: - $header-nav-width-mobile;
        right: 0;
        top: 102px; // height of menu-utility
        bottom: 0;
        overflow: hidden;
        overflow-y: auto;
        pointer-events: none;
        transition: all 500ms ease;
        width: $header-nav-width-mobile;
    }
}

.level-2 {
    @extend %clearfix;
    clear: left;

    @include respond-to(mobile_strong) {
        z-index: $mobile-menu-z-index + 1;

        &.nested-item-active {
            height: 0;
            overflow-y: hidden;
        }

        .menu-brand-assets {
            .brand-item {
                border-bottom: 1px solid $color-auxiliary10;

                a {
                    float: none;
                    padding: 0;
                }
            }
        }
    }

    @include respond-to(mobile_strong_over) {
        display: block;
        opacity: 0;
        transition: opacity .3s;
        overflow: auto;

        .menu-brand-assets .brand-item a {
            pointer-events: none;
            line-height: 1;
        }
    }

    @include respond-to(tablet_ext) {
        display: none;
    }

    .big-menu-item-hover {
        > a[data-menu-level="2"] {
            @include respond-to(mobile_strong_over) {
                background-color: rgba($black01, .15);
                color: $color-primary3;
            }

            span {
                &::before {
                    width: 100%;
                }
            }
        }

        > a[data-menu-level="3"] {
            @include respond-to(mobile_strong_over) {
                background-color: $color-primary3;
            }

            span {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    a[data-menu-level="2"],
    a[data-menu-level="3"] {
        > span,
        > i {
            vertical-align: middle;
        }
    }

    a[data-menu-level="2"] {
        @include underline-on-hover($color: $color-primary3);
    }

    a[data-menu-level="3"] {
        @include underline-on-hover($color: $black01);
    }

    li {
        a {
            @include respond-to(mobile_strong_over) {
                line-height: 1;
                padding: 17px 10px;
                text-align: left;
            }

            @include respond-to(desktop_over) {
                padding: 17px 30px;
            }
        }

        > .has-sub-menu > i {
            @include respond-to(mobile_strong_over) {
                float: none;
                line-height: inherit;
                padding: 0 0 2px;
                font-size: rem(7);
                color: transparent;
                transition: padding .2s, color .2s;
            }

            &::before {
                content: $aricons-tiny-arrow-right;

                .no-desktop & {
                    @include respond-to(mobile_strong_over) {
                        content: '';
                    }
                }
            }
        }

        &.big-menu-item-hover > .has-sub-menu > i {
            @include respond-to(mobile_strong_over) {
                color: inherit;
                padding-left: 7px;
            }
        }
    }

    .utility-links-wrapper {
        @include respond-to(mobile_strong) {
            display: none;
        }

        @include respond-to(mobile_strong_over) {
            display: table-footer-group;

            a {
                @include text-link-style($color-primary3);

                font-size: rem(12);
                padding: 10px 30px;
            }
        }
    }

    &.main-wide-menu-wrapper { // basically is level-2 (but width 100%) wrapper
        @include respond-to(mobile_strong_over) {
            @include border-tape();

            -webkit-clip-path: polygon(0% 0%, 0% 0%, 100% 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0% calc(100% - 10px));
            clip-path: polygon(0% 0%, 0% 0%, 100% 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0% calc(100% - 10px));
            position: absolute;
            width: 100%;
            top: calc(100% - 2 * #{$desktop-big-menu-border-width});
            left: 0;
            background-color: rgba($color-primary3, .6);
            z-index: -1;

            &::after {
                display: block; // override clearfix property
            }
        }

        .border-type-inner {
            @include respond-to(mobile_strong_over) {
                padding-top: $desktop-big-menu-border-width;
            }
        }

        .main-nav-wrapper {
            @include respond-to(mobile_strong_over) {
                overflow-y: auto;
                overflow-x: hidden;
            }

            @include respond-to(mobile_stronger) {
                max-height: calc(100vh - 270px);
            }

            @include respond-to(tablet_ext) {
                max-height: calc(100vh - 270px);
            }

            @include respond-to(touch_over) {
                max-height: calc(100vh - 210px);
            }

            .is-sticky & {
                @include respond-to(mobile_stronger) {
                    max-height: calc(100vh - 210px);
                }

                @include respond-to(tablet_ext) {
                    max-height: calc(100vh - 210px);
                }

                @include respond-to(touch_over) {
                    max-height: calc(100vh - 135px);
                }
            }
        }

        .level-2-column {
            @include respond-to(mobile_strong_over) {
                width: $desktop-menu-level-2-width;
                background: $color-primary2;
                display: table;
                min-height: $desktop-big-menu-height;
                padding-bottom: 15px;
                position: relative;
                z-index: $z-min;
            }

            @include respond-to(mobile_stronger) {
                width: $tablet-menu-level-2-width;
                min-height: 600px;
            }

            //fix menu height for Firefox
            .ff & {
                @include respond-to(mobile_strong_over) {
                    height: $desktop-big-menu-height;
                }

                @include respond-to(mobile_stronger) {
                    height: 600px;
                }
            }
        }
    }

    > ul {
        @extend %clearfix;
        padding-bottom: .5em;
        padding-left: 2.1rem;
    }

    .banner {
        display: none;

        @include respond-to(mobile_strong_over) {
            display: block;
        }

        img {
            vertical-align: top;
        }
    }

    .menu-categories-columns-wrapper {
        .menu-category-column {
            margin-bottom: 0;
        }
    }
}

.level-3 {
    @include respond-to(mobile_strong_over) {
        @include position(absolute, 0, auto, 0);

        width: 100%;
        background-color: rgba($black01, .05);
        z-index: -1;
    }

    .level-3-content {
        @include respond-to(mobile_strong_over) {
            width: $desktop-menu-level-3-width;
            margin-left: $desktop-menu-level-2-width;
        }

        @include respond-to(mobile_stronger) {
            width: $tablet-menu-level-3-width;
            margin-left: $tablet-menu-level-2-width;
        }

        .main-promo-content-wrapper { // rightmost panel content slot with image
            @include respond-to(mobile_strong_over) {
                position: absolute;
                left: calc(#{$desktop-menu-level-2-width} + #{$desktop-menu-level-3-width} + #{$desktop-menu-level-4-width});
                width: calc(100vw - #{$desktop-menu-level-2-width} - #{$desktop-menu-level-3-width} - #{$desktop-menu-level-4-width} - #{$desktop-big-menu-border-width});
                background-color: $color-primary3;
                top: 0;
                bottom: 0;
            }

            .desktop & {
                @include respond-to(mobile_strong_over) {
                    width: calc(100vw - #{$desktop-menu-level-2-width} - #{$desktop-menu-level-3-width} - #{$desktop-menu-level-4-width} - #{$desktop-big-menu-border-width} - 16px); //16 px - fix for scrollbar
                }
            }

            @include respond-to(mobile_stronger) {
                display: none;
            }
        }
    }

    .big-menu-item-hover > & {
        @include respond-to(mobile_strong_over) {
            display: block;
        }
    }
}


.menu-active .level-1 > .active .level-2 {
    @include respond-to(mobile_strong) {
        pointer-events: auto;
        left: 0;
    }
}

.menu-active .level-2 .active .level-3 {
    @include respond-to(mobile_strong) {
        z-index: $mobile-menu-z-index + 2;
        pointer-events: auto;
        left: 0;
    }
}

.level-4 {
    @include respond-to(mobile_strong_over) {
        position: absolute;
        left: calc(#{$desktop-menu-level-2-width} + #{$desktop-menu-level-3-width});
        top: 0;
        bottom: 0;
        width: $desktop-menu-level-4-width;
        background-color: $color-primary3;
    }

    @include respond-to(mobile_stronger) {
        left: calc(#{$tablet-menu-level-2-width} + #{$tablet-menu-level-3-width});
        width: $tablet-menu-level-4-width;
    }

    .level-3 .active & {
        @include respond-to(mobile_strong) {
            z-index: $mobile-menu-z-index + 3;
            pointer-events: auto;
            left: 0;
        }
    }

    .big-menu-item-hover > & {
        @include respond-to(mobile_strong_over) {
            display: block;
            overflow-y: auto;
        }
    }

    .level-4-content {
        @include respond-to(mobile_strong_over) {
            display: table;
            height: 100%;
            width: 100%;
            padding: 10px 20px 30px;
        }

        @include respond-to(touch_over) {
            padding: 10px 30px 30px;
        }

        .no-desktop & {
            @include respond-to(mobile_strong_over) {
                padding: 10px 10px 30px;
            }
        }
    }

    .level-4-menu-item-wrapper {
        @include respond-to(mobile_strong) {
            @include icon-toggler; // plus\minus manage

            min-height: $mobile-menu-utility-height + 2; // 2 - bottom border
            border-bottom: 1px solid $color-auxiliary10;
        }

        @include respond-to(mobile_strong_over) {
            float: right;
            width: 45%;

            &:first-child {
                float: left;
                width: 50%;
            }
        }

        @include respond-to(touch_over) {
            width: 40%;

            &:first-child {
                padding-left: 30px;
            }
        }


        .no-desktop &:not(:first-child) {
            @include respond-to(mobile_strong_over) {
                width: 44%;
            }
        }

        &:not(:first-child) {
            .menu-category-column {
                @include respond-to(mobile_strong) {
                    display: none;
                }
            }
        }
    }

    .category-title {
        @include respond-to(mobile_strong) {
            width: 100%;
            padding: 0 40px 0 30px;
            font-size: rem(15);
            line-height: $mobile-menu-utility-height;
        }

        @include respond-to(mobile_strong_over) {
            font-size: rem(16);
            line-height: 1;
            text-align: left;
            margin: 30px 0 15px;
        }

        i {
            @include respond-to(mobile_strong) {
                display: inline-block;
                float: right;
                font-size: rem(10);
                line-height: $mobile-menu-utility-height;
                padding-right: 0;
                margin-right: -20px;
            }

            @include respond-to(mobile_strong_over) {
                font-size: rem(7);
                line-height: 18px;
                vertical-align: text-bottom;
                margin-left: 7px;

                &::before {
                    content: $aricons-tiny-arrow-right;
                }
            }
        }
    }

    .utility-links {
        @include respond-to(mobile_strong_over) {
            clear: both;
            display: table-footer-group;
            font-size: 0;
        }

        a {
            line-height: 1.6;

            @include respond-to(mobile_strong_over) {
                @include text-link-style($black01);

                padding: 0 5px;
                font-size: rem(12);
                line-height: 1;
                width: auto;
                border-left: 1px solid $header-links-color;

                &:first-child {
                    border-left: 0;
                }
            }
        }
    }

    .menu-category & li {
        border-bottom: 0;

        a {
            font-size: rem(12.5);
            line-height: 18px;
            color: $header-links-color;
            padding: 0 10px 0 40px;

            @include respond-to(mobile_strong) {
                padding: 0 10px 0 40px;
                line-height: 40px;
            }

            &:hover {
                color: $header-links-hover-color;
            }
        }

        i {
            float: left;
            margin-right: 0;
            padding-right: 10px;
            font-size: rem(7);
            line-height: 18px;

            @include respond-to(mobile_strong) {
                line-height: 40px;
            }
        }
    }

    .menu-category-column {
        li a {
            @include icomoon-icon($aricons-tiny-arrow-right, $font-size: rem(7), $line-height: 18px) {
                margin-right: 7px;
            }

            @include underline-on-hover($pseudo: after, $color: $black01);

            @include respond-to(mobile_strong) {
                line-height: 16px;
                padding-top: 11px;
                padding-bottom: 11px;
            }

            @include respond-to(mobile_strong_over) {
                font-size: rem(16);
                color: $black01;
                text-transform: capitalize;
                padding: 0;
                margin-bottom: 12px;
            }

            &::before {
                @include respond-to(mobile_strong) {
                    line-height: 16px;
                }

                @include respond-to(desktop_below) {
                    float: left;
                }
            }

            span {
                @include respond-to(desktop_below) {
                    display: block !important;
                    padding-left: 15px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &::after {
                    @include respond-to(desktop_below) {
                        display: none;
                    }
                }
            }

            .no-ie & span {
                display: inline; // inline-block was fix for IE11 in mixin, but it appeared to have side effects on narrow screens
            }
        }
    }

    .menu-content-wrapper ul {
        @include respond-to(mobile_strong) {
            display: none;
        }
    }
}

@include respond-to(mobile_strong_over) {
    .menu-category .shadow-overlay {
        transition: opacity 0.15s;
    }

    .level-2.main-wide-menu-wrapper.arnt-new-navigation {
        opacity: 0;
        visibility: hidden;
        top: calc(100% - 10px);
        background-color: $color-primary3;
        z-index: 100;
        transition: opacity 0.15s;
        max-height: 75vh;

        &:before,
        &:after {
            opacity: 0.5;
            height: 100%;
        }

        .utility-links,
        ul {
            margin: 0;
        }

        li a,
        .brand-item {
            padding: 0;
            font-size: rem(14);
            line-height: 24px;
            color: $black01;
            text-align: left;

            @include respond-to(mobile_tablet) {
                line-height: 16px;
            }

            span {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 1px;
                    right: 100%;
                    background: $color-secondary1;
                    transition: .3s;
                }
            }

            &:hover {
                span:after {
                    right: 0;
                }
            }
        }

        .upper-level-link {
            padding-bottom: 10px;
            color: $black01;
        }

        .brand-item {
            font-family: $font-main-bold;
        }

        .level-2-content {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            align-content: center;
            justify-content: space-between;
            overflow: auto;
            max-height: 70vh;
        }

        .menu-category-column {
            padding: 20px;
            width: 33.33333%;
            flex-basis: 33.33333%;
            max-width: 360px;
            display: inline-block;
            vertical-align: top;
            font-size: rem(14);

            > li {
                margin-bottom: 20px;
            }
        }

        .html-slot-container {
            display: none;
        }

        .menu-categories-columns-wrapper {
            flex-basis: calc((100% - 420px) / 4 * 3);
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            align-items: flex-start;
            font-size: 0;

            @media screen and (max-width: 1400px) {
                flex-basis: calc((100% - 345px) / 4 * 3);
            }

            @include respond-to(touch){
                flex-basis: calc((100% - 280px) / 4 * 3);
            }

            @include respond-to(mobile_tablet) {
                flex-basis: 75%;
            }
        }

        .menu-brand-assets {
            flex-basis: calc((100% - 420px) / 4);
            padding: 20px;

            @media screen and (max-width: 1400px) {
                flex-basis: calc((100% - 345px) / 4);
            }

            @include respond-to(touch){
                flex-basis: calc((100% - 280px) / 4);
            }

            @include respond-to(mobile_tablet) {
                flex-basis: 25%
            }

            .menu-category-column {
                padding: 0;
                flex-basis: 100%;
                width: 100%;
            }
        }

        .menu-content-wrapper {
            width: 420px;
            flex-basis: 420px;
            overflow: hidden;
            position: relative;

            .slot-asset-item,
            .nav-banner {
                position: static;
            }

            .slot-panels-container {
                margin-left: 0;
            }

            @media screen and (max-width: 1400px) {
                width: 345px;
                flex-basis: 345px;
            }

            @include respond-to(touch) {
                flex-basis: 280px;
                width: 280px;

                img {
                    width: 100%;
                }
            }
            @include respond-to(mobile_tablet) {
                display: none;

                .nav-banner .content-block {
                    padding: 20px 5px;
                    position: static;
                }
            }
        }

        .arnt-border-bottom {
            position: absolute;
            width: calc(100% + 10px);
            bottom: 0;
            z-index: 1;
            background-color: inherit;
            height: 10px;
            left: -10px;
            opacity: 0.5;
        }

        .level-3 {
            display: block;
            position: static;
            background: $color-primary3;

            li {
                padding: 0;
                margin: 0;

                & > a {
                    text-transform: capitalize;
                    line-height: 24px;
                    padding-left: 10px;
                    font-family: $font-main;
                }

                &.all-brands-link > a {
                    margin-top: 5px;
                }
            }
        }
    }

    .menu-category-hover li:hover > .level-2 .shadow-overlay {
        opacity: .6;
    }

    .menu-category-hover li:hover .has-sub-menu + .level-2.arnt-new-navigation {
        visibility: visible;
        opacity: 1;
        display: block;
        z-index: 101;
    }
}

@include custom-respond-to($break_mobile_strong, 1023px) { // Fix for adding scroll Menu on Tablet Portrait 
    .level-2.main-wide-menu-wrapper.arnt-new-navigation {
        max-height: 60vh;

        .level-2-content {
            max-height: 55vh;
        }
    }
}

@include custom-respond-to($break_touch, $break_desktop) { // Fix for adding scroll Menu on Tablet Landscape 
    .level-2.main-wide-menu-wrapper.arnt-new-navigation {
        max-height: 50vh;

        .level-2-content {
            max-height: 45vh;
        }
    }
}

@include respond-to(mobile_strong) {
    .level-2.main-wide-menu-wrapper.arnt-new-navigation {
        .brand-item {
            line-height: 50px;
            color: $black01;
            font-size: rem(14);
            padding: 0 35px 0 30px;

            i {
                display: inline-block;
                float: right;
                font-size: 1rem;
                line-height: 50px;

                &::before {
                    content:$aricons-tiny-arrow-right;
                }
            }
        }

        .nav-level4-utility-links > a {
            line-height: 20px;
        }

        .level-2-content {
            a {
                font-family: $font-main;
            }

            .upper-level-link a {
                font-family: $font-main-bold;
            }
        }
    }
}

_:-ms-fullscreen, :root .menu-category .shadow-overlay {
    top: 199px;
    z-index: 0;
}

_:-ms-fullscreen, :root .is-sticky .menu-category .shadow-overlay {
    top: 122px;
}

//media is fix for ie performance
@include respond-to(mobile_strong_over) {
    html.ie {
        .level-2.main-wide-menu-wrapper.arnt-new-navigation {
            display: none;
            transition: 0s;
            visibility: visible;
            pointer-events: auto;
        }

        .shadow-overlay {
            display: none;
            transition: 0s;
            visibility: visible;
            pointer-events: auto;
        }

        &.menu-category-hover {
            .level-2.main-wide-menu-wrapper.arnt-new-navigation {
                display: block;
            }

            .shadow-overlay {
                display: block;
            }
        }
    }
}
