.promotion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid $lighter-gray;
    padding: 15px 20px 15px 15px; 
    margin-bottom: 30px;

    @include respond-to(mobile_strong) {
        padding: 10px; 
        margin-top: 30px;
    }

    &-button_wrapper {
        display: flex;
        align-items: center;
    }

    &-button {
        padding: 10px 15px;
        background-color: $light-gray02;
        color: $black01;
        font-size: rem(12);
        text-transform: capitalize;

        @include respond-to(mobile_strong) {
            @include icon(eye);

            font-size: 0;
            padding: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;

            &::before {
                font-size: rem(14);
                display: inline-block;
                width: 100%;
            }
        }

        &:hover {
            background-color: $second-gray;
        }

        &::after {
            display: none;
        }
    }

    &-image_wrapper {
        flex: 1 0 23%;
        max-width: 23%;
        margin-right: 3%;
    }

    &-image {
        width: 100%;
    }

    &-description {
        padding-right: 2%;
        text-align: left;
        flex: 1 1 45%;
        word-break: break-word;
    }

    &-count {
        font-size: rem(12);
        color: $dim-gray;
        margin: 0 0 5px;

        @include respond-to(mobile_strong) {
            font-size: rem(10);
        }
    }

    &-title {
        font-size: rem(14);
        font-family: $font-main-bold;

        @include respond-to(mobile_strong) {
            font-size: rem(12);
        }
    }

    &-dialog {
        &.ui-dialog {
            width: 35% !important;

            @include respond-to(mobile_stronger) {
                width: 70% !important;
            }
            
            .dialog-content {
                @include respond-to(mobile_strong_over) {
                    padding: 20px;
                }
        
                @include respond-to(mobile_strong) {
                    padding: 15px;
                }
            }
        }
    }
}

.promotion_item {
    display: flex;
    border-radius: 4px;

    @include respond-to(mobile_strong_over) {
        align-items: center;
        background-color: $light-gray02;
        padding: 25px;
        margin-bottom: 20px;
    }

    @include respond-to(mobile_strong) {
        flex-direction: column;
        border-bottom: 1px solid $light-gray02;
        padding: 15px 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &-image_wrapper {
        @include respond-to(mobile_strong_over) {
            flex: 1 1 auto;
            max-width: 26%;
            margin-right: 25px;
        }

        @include respond_to(mobile_strong) {
            height: 0;
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            .expanded & {
                height: auto;
            }
        }
    }

    &-image {
        width: 100%;
    }

    > .promotion_item-name {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    &-name {
        font-size: rem(16);
        font-family: $font-main-bold;

        @include respond-to(mobile_strong_over) {
            margin-bottom: 15px;
        }

        @include respond-to(mobile_strong) {
            @include icon(plus);

            padding-right: 30px;
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                font-weight: 900;
            }

            .expanded & {
                @include icon(minus);
            }
        }
    }

    &-content {
        @include respond-to(mobile_strong_over) {
            flex: 1 1 auto;
        }

        @include respond-to(mobile_strong) {
            .promotion_item-name {
                display: none;
            }
        }

        @include respond_to(mobile_strong) {
            height: 0;
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            .expanded & {
                height: auto;
            }
        }
    }

    &-description {
        font-size: rem(14);
        margin-bottom: 15px;
        line-height: 22px;
    }

    &-link {
        @include link_underline($medium_blue);

        font-size: rem(14);
        line-height: 2;
    }
}

.dialog-quickview {
    @include respond-to(tablet_regular) {
        .promotion {
            padding: 15px 10px 15px 10px;
        }

        .promotion-image_wrapper {
            margin-right: 5px;
        }

        .promotion-title {
            font-size: rem(10);
        }

        .promotion-button {
            padding: 10px;
            font-size: rem(10);
        }
    }
}

.product-detail-chanel {
    .promotion-button {
        font-weight: 600;
    }
}