 .whats-on-in-store-carousel,
 .plp-header-carousel {
    .slick-arrow {
        background: none;
        color: $color-auxiliary13;

        @include respond-to(mobile) {
            bottom: 38%;
            font-size: rem(20);
            top: auto;

            &::before {
                display: none;
            }
        }

        &:hover {
            background: none;
        }

        &:active {
            .path1,
            .path2 {
                &::before {
                    opacity: .65;
                }
            }
        }

        .path1,
        .path2 {
            &::before {
                color: inherit;
                opacity: 0.3;
            }
        }

        &.slick-prev {
            transform: scaleX(-1);
        }
    }
}

/* product carousels */
.js-product-grid.carousel {
    text-align: center;

    .jcarousel-prev {
        left: 0;

        @include respond-to(mobile) {
            left: 0;
        }
    }

    .jcarousel-next {
        right: 0;

        @include respond-to(mobile) {
            right: 0;
        }
    }
}

.slick-arrow {
    background-color: rgba($white, 0.5);
    border: 1px solid $black01;
    border-radius: 50%;


    &::before,
    &::after {
        color: $black01;
        font-size: rem(16);
    }
}

.slick-prev {
    @include icon(arrow-left);
}

.slick-next {
    @include icon(arrow-left);

    transform: rotate(180deg);
}
