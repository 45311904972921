.b-orderhistory-online {
    &.ui-tabs-panel {
        padding-top: 30px;
    }
}

.b-orders_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px 4%;
    margin-bottom: 65px;

    @include respond-to(m_mobile_down) {
        flex-direction: column;
        padding: 0 5%;
        gap: 25px;
    }
}

.b-orders_item {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 35px 29px;

    @include respond-to(mobile_strong_over) {
        width: 48%;
    }

    &-top {
        display: flex;
        margin-bottom: 50px;
    }

    &-image_wrapper {
        width: 50%;
        border-right: 1px solid #D8D8D8;

        @include respond-to(mobile_strong_over) {
            padding: 10px;
        }
    }

    &-image {
        width: 100%;
        display: block;
    }

    &-counter {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &-counter_number {
        font-weight: 600;
        font-size: rem(20);
    }

    &-counter_text {
        text-transform: uppercase;
    }

    &-bottom {
        text-align: left;
        margin-bottom: 55px;
    }

    &-number {
        font-size: rem(20);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1.2;
    }

    .button.button {
        background-color: #f5f5f5;
        color: #151515;
        margin: 0 auto;
        display: block;
        font-size: rem(14);

        @include respond-to(m_tablet) {
            width: 250px;
        }

        @include respond-to(m_mobile_down) {
            max-width: 72%;
        }

        &:hover {
            background-color: #eee;
        }
    }
}

.b-orders_more {
    margin: 20px 33% 0;
    text-align: center;
    font-size: rem(14);

    @include respond-to(m_mobile_down) {
        max-width: 250px;
        margin: 20px auto 0;
    }
}