// Tabs using just CSS, no javascript required
// Using on MA navigation and PDP
.tab {
    border: 1px solid $color-auxiliary17;
    margin-bottom: 10px;

    &:hover {
        border-color: $color-secondary3;
    }

    .tab-switch {
        display: none;

        &:checked {
            + label + .tab-content {
                height: auto;
                opacity: 1;
                padding: 15px 15px 30px;
                transition: all 0.35s;
            }
            
            + .tab-label {
                background-color: $color-auxiliary10;

                @include respond-to(touch_over) {
                    &:hover {
                        background-color: $color-auxiliary11;
                    }
                }

                &::after {
                    content: $aricons-minus;
                }
            }
        }
    }

    .tab-label {
        border-top: 0;
        color: $night-rider;
        cursor: pointer;
        font-size: rem(16);
        letter-spacing: 1px;
        padding: 14px 15px;
        text-transform: uppercase;
        transition: all 0.25s;
        font-family: $font-main-bold;

        &:hover,
        &:active {
            background-color: $second-lightest-gray;
        }

        &::after {
            color: $color-primary2;
            content: $aricons-plus;
            font-size: rem(10);
            line-height: 16px;
            font-family: $font-icons !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            border-bottom: 0;
            float: right;
            position: relative;
        }
    }

    .tab-content {
        height: 0;
        opacity: 0;
        padding: 0;
        text-align: left;
        line-height: 1.6;
        transition: all 0.35s;
        overflow: hidden;
        font-size: rem(14);
        
        p {
            line-height: 1.2;
        }
    }
}