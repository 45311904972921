.breadcrumb,
.search-top-breadcrumbs {
    font-size: rem(12);
    color: $link-color-dark;
    text-align: center;
    padding: 1rem 0;
    line-height: 2rem;

    @include respond-to(mobile_strong_over) {
        text-transform: lowercase;
    }

    @include respond-to(mobile) {
        .the-search-result &,
        .pt_product-search-noresult & {
            display: none;
        }
    }

    li {
        display: inline-block;
        list-style: none outside none;
    }

    a {
        padding: 0;
        color: inherit;
        vertical-align: middle;
        color: $dim-gray;

        &:hover {
            color: $night-rider;
        }
    }
}

.breadcrumb-element-wrapper {
    display: inline-block;

    &:last-child {
        .breadcrumb-element {
            color: $black01;
            cursor: default;
            font-weight: bold;

            &:after {
                content: '';
                display: none;
            }
        }
    }
}

.breadcrumb-element {
    padding: 0;
    line-height: 15px;
    position: relative;
    display: inline-block;

    &::after {
        content: '/';
    }

    &:last-child,
    &.breadcrumb-product-name {
        &::after {
            content: '';
            display: none;
        }
    }

    &::first-letter {
        text-transform: uppercase;
    }
}

.pt_account,
.pt_order,
.pt_wish-list {
    .breadcrumb {
        text-transform: capitalize;
    }
}

.pt_content {
    .breadcrumb {
        text-transform: none;
    }
}

.pt_product-details {
    .breadcrumb {
        @include respond-to(tablet_plus) {
            padding: 25px 0 10px;
        }
    }

    .breadcrumb-element-wrapper {
        @include respond-to(mobile_strong) { // Creating short breadcrumbs on Mobile
            display: none;
    
            &:nth-last-child(2) {
                display: block;
    
                .breadcrumb-element {
                    color: $black01;
    
                   &::before {
                        content: "< Back to ";
                    }
    
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.searchresult-phrase {
    color: $search-result-banner-text-color;
    font-size: rem(15);
    text-align: center;
    padding: rem(15) 0 rem(5) 0;
    margin-bottom: 0;
    display: none;

    @include respond-to(mobile) {
        padding:rem(15) 0 0 0;
    }

    @include respond-to(m_desktop) {
        border-bottom: 1px solid $very-light-gray;
    }

    .the-search-result &,
    .pt_product-search-noresult & {
        display: block;
    }

    a {
        color: $nobel;

        &:hover {
            color: $nobel;
        }
    }

    .res-text-breadcrumb {
        display: inline-block;
        color: $nobel;
        position: relative;
        margin: 0 auto;
        padding-bottom: 1rem;
        text-transform: capitalize;
        font-family: $font-main-bold;
        font-size: rem(14);

        &:after {
            content: "";
            border-bottom: 1px solid $search-result-banner-text-color;
            width: 80%;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
            top: 100%;
        }

        i {
            display: block;
            margin: 0 auto 1rem auto;
            font-size: rem(30);
        }
    }

    .phrase-breadcrumb {
        display: block;
        padding: 10px 0 5px;
        font-size: rem(24);
        line-height: 35px;
        text-transform: capitalize;
        font-family: $font-main;

        @include respond-to(mobile_strong) {
            margin-bottom: 10px;
            padding: 5px 0 0;
        }

        @include respond-to(mobile_strong_over) {
            margin-bottom: 30px;
        }
    }

    .suggest-breadcrumb {
        display: inline-block;
        padding: 0 0 1.5rem 0;
    }
}

.paging-current-page {
    left: -999em;
    position: absolute;
}

.search-top-breadcrumbs {
    display: none;
    padding: 1.5rem 0;
    font-size: rem(14);
    color: $dim-gray;
    text-align: center;

    .the-search-result &,
    .pt_product-search-noresult & {
        display: block;
    }

    a {
        color: $dim-gray;
    }
}

.searchresults {
    display: none;
    text-align: center;
    color: $search-result-banner-text-color;
    padding: 0 0 1.5rem 0;

    .the-search-result &,
    .pt_product-search-noresult & {
        display: block;
    }

    a {
        color: $search-result-banner-link-color;

        &:hover {
            color: $search-result-banner-link-hover-color;
        }
    }
}

.slot-grid-header {
    padding: 0;
    text-align: center;
}