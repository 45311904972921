// From https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
@mixin mq-mobile
{
    @media
    // iphone 4 and 4s
    only screen
        and (min-device-width: 320px)
        and (max-device-width: 480px)
        and (-webkit-min-device-pixel-ratio: 2)
    ,
    // iphone5 and 5s
    only screen
        and (min-device-width: 320px)
        and (max-device-width: 568px)
        and (-webkit-min-device-pixel-ratio: 2)
    ,
    // iphone6
    only screen
        and (min-device-width: 375px)
        and (max-device-width: 667px)
        and (-webkit-min-device-pixel-ratio: 2)
    ,
    // iphone6+
    only screen
        and (min-device-width: 414px)
        and (max-device-width: 736px)
        and (-webkit-min-device-pixel-ratio: 3)
    ,
    // galaxy s5
    screen
        and (device-width: 360px)
        and (device-height: 640px)
        and (-webkit-device-pixel-ratio: 3)
    ,
    // htc one
    screen
        and (device-width: 360px)
        and (device-height: 640px)
        and (-webkit-device-pixel-ratio: 3)
    ,
    // nexus 5
    screen
        and (device-width: 360px)
        and (device-height: 592px)
        and (-webkit-device-pixel-ratio: 3)
    {
        @content;
    }
}
