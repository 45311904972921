.pt_product-compare {
    #main {
        padding-top: 40px;
    }
}

.compare-control-panel {
    @extend %clearfix;
    margin-bottom: 40px;

    @include respond-to(print) {
        display: none;
    }

    &-back {
        float: left;

        .bticons-left {
            font-size: rem(11);
            margin-right: 5px;
        }
    }

    &-print {
        float: right;

        @include respond-to(touch) {
            display: none;
        }

        .fa-print {
            margin-right: 5px;
        }
    }
}

.compare-items {
    border-bottom: 1px solid $gainsboro;
    clear: both;
    margin-bottom: 30px;
    overflow: hidden;
    padding-bottom: 30px;

    button {
        margin-right: 10px;
        padding: 14px 17px;

        @include respond-to(desktop) {
            font-size: rem(13);
            margin-right: 5px;
            padding: 12px;
        }

        @include respond-to(touch) {
            display: block;
            font-size: rem(12);
            margin-top: 4px;
            padding: 8px;
            width: 100%;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.compare-items-panel {
    @extend %clearfix;
    float: left;
    width: 65%;
    display: table;
    table-layout: fixed;

    @include respond-to(mobile) {
        float: none;
        margin-bottom: 10px;
        width: auto;
    }
}

.compare-item {
    display: table-cell;
    width: 16.666%;
    vertical-align: middle;
    background-color: $second-gray;
    overflow: hidden;
    position: relative;
    text-align: center;
    border-right: 10px solid $white;

    @include respond-to(mobile) {
        border-width: 5px;
    }

    &-remove {
        background-color: $black01;
        color: $white;
        cursor: pointer;
        display: none;
        font-size: rem(10);
        padding: 5px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        &:hover {
            color: $white;
        }
    }

    &.active {
        background-color: transparent;

        .compare-item-remove {
            display: block;
        }

        .compare-item-number {
            display: none;
        }
    }

    &-number {
        color: $second-black;
        font-size: rem(20);
    }
}

.compare-item-image {
    max-width: 100%;
}

.compare-buttons {
    float: right;
    padding-top: 20px;
    width: 32%;

    @include respond-to(touch) {
        padding-top: 0;
    }

    @include respond-to(mobile) {
        float: none;
        padding-top: 0;
        width: auto;
    }
}

.category-switch {
    @extend %clearfix;
    margin-bottom: 25px;

    @include respond-to(tablet_plus) {
        margin-bottom: 50px;
    }

    label {
        float: left;
        margin: 0;
        width: 45%;
        line-height: 36px;

        @include respond-to(tablet_plus) {
            width: auto;
            margin-right: 10px;
        }
    }

    select {
        float: left;
        margin: 0;
        width: 55%;

        @include respond-to(tablet_plus) {
            width: 210px;
        }
    }
}

.compare-table {
    border-collapse: collapse;
    border-left: none;
    border: 1px solid $second-light-gray;
    margin-bottom: 40px;
    table-layout: fixed;
    width: 100%;

    @include respond-to(mobile) {
        margin-bottom: 0;
    }

    &-controller {
        @include respond-to(mobile) {
            position: relative;
        }

        &-prev,
        &-next {
            display: none;

            @include respond-to(mobile) {
                background-color: $white;
                border: 1px solid $black01;
                display: block;
                margin-top: -10px;
                padding: 10px;
                position: absolute;
                top: 50%;
            }
        }

        &-prev {
            @include respond-to(mobile) {
                left: -10px;
            }
        }

        &-next {
            @include respond-to(mobile) {
                right: -10px;
            }
        }
    }

    &-wrapper {
        @include respond-to(mobile) {
            margin-bottom: 40px;
            max-width: none;
            overflow: auto;
            padding-top: 25px;
        }
    }

    .attribute-row {
        &:nth-child(2n) {
            background-color: $second-lightest-gray;
        }
    }

    .product-attribute {
        word-break: break-all;
    }

    .action {
        display: block;
        padding: 0.5em 0;
    }

    .remove-link {
        cursor: pointer;
        font-size: rem(10);
        position: absolute;
        left: 50%;
        z-index: 3;
        top: -25px;
        width: auto;
        text-align: center;
        color: $mid-gray;
        transform: translateX(-50%);
        white-space: nowrap;  //IE-fix

        &:hover {
            color: $dark-gray;
        }

        span {
            color: $charcoal;
            text-transform: uppercase;
            font-size: rem(15);
            margin-left: 5px;
            font-family: $font-main;
            text-decoration: underline;
            transition: .3s;

            &:hover {
                color: $dark-gray;
            }
        }

        @include respond-to(print) {
            display: none;
        }
    }

    .value,
    .value-empty,
    .product,
    .product-empty {
        border-left: 1px solid $second-light-gray;
        text-align: center;

        @include respond-to(mobile) {
            width: 191px;
        }
    }

    .product-empty {
        color: $second-gray;
        text-align: center;
        vertical-align: middle;
        padding: 40px 10px;

        @include respond-to(mobile) {
            display: none;
        }

        @include respond-to(print) {
            display: none;
        }

        &-message {
            display: inline-block;
            vertical-align: middle;
            font-size: rem(70);
        }

        &-message-text {
            display: block;
            font-size: rem(16);
        }
    }

    .value-empty {
        @include respond-to(mobile) {
            display: none;
        }

        @include respond-to(print) {
            display: none;
        }
    }

    .product {
        text-align: center;
        padding: 0 0 40px;
        position: relative;

        @include respond-to(mobile) {
            padding-bottom: 20px;
        }
    }

    .value {
        &:first-child {
            text-align: left;
        }

        .value-item {
            &:after {
                content: ', ';
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }
        }
    }

    .compare-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        
        .error-quantity {
            margin: 0;
        }
    }

    .product-tile {
        min-height: 530px;

        @include respond-to(tablet) {
            min-height: 430px;
        }

        .product-image {
            height: 270px;

            @include respond-to(tablet) {
                height: 190px;
            }

            .thumb-link {
                font-size: 0;
            }

            img {
                max-width: 100%;
            }
        }

        .product-actions {
            @include respond-to(print) {
                display: none;
            }
        }

        .tile-swaches-wrapper {
            position: static;
            margin-top: 10px;
        }

        .product-raiting-wrapper {
            bottom: 55px;
        }
    }

    .add-to-cart,
    .open-quick-view {
        background-color: $rose;
        font-size: rem(18);
        width: auto;
        margin-top: 20px;
        padding-bottom: 14px;
        padding-top: 14px;

        @include respond-to(tablet) {
            font-size: rem(13);
            padding: 10px;
        }

        @include respond-to(print) {
            display: none;
        }
    }

    .section-header {
        font-size: 0.9em;
        padding: 1em;
        text-transform: none;
    }

    .selectcategory {
        label {
            display: block;
            font-size: 0.9em;
            margin: 1em 0;
            text-transform: none;
        }

        select {
            margin: 0;
            width: 100px;
        }
    }

    .comparelists {
        padding: 0;
    }

    .compare-lists {
        label,
        select {
            float: none;
            text-align: left;
            width: 100%;
        }
    }

    .tile-compare-wrapper {
        display: none;
    }
}

/* Print version */
@include respond-to(print) {
    .pt_product-compare {
        .header-banner,
        #navigation,
        #mini-cart,
        .header-wishlist,
        .header-search,
        .header-search-icon,
        .footer,
        .menu-toggle,
        .category-switch {
            display: none !important;
        }

        .top-banner {
            height: auto;
        }

        .product-tile .product-image {
            height: auto;

            img {
                max-width: 90%;
            }
        }
    }
}

// js-scroll specific styles
.compare-table-wrapper {
    &.js-scroller-active {
        .js-scroller-nav {
            display: block;
        }
    }

    .js-scroller-nav {
        display: none;
        margin-top: -24px;
        top: 50%;
        background: $white;
        color: $charcoal;
        border: 1px solid $charcoal;
        cursor: pointer;
        font-size: rem(22);
        margin-top: -23px;
        padding: 12px;
        position: absolute;
        top: 50%;
        z-index: 2;
        @include font-icomoon;


        &.js-scroller-next {
            right: -13px;

            &::before {
                content: "\e915";
            }
        }

        &.js-scroller-prev {
            left: -13px;

            &::before {
                content: "\e90c";
            }
        }
    }
}