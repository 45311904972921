.product_actions {
    margin-bottom: 24px;

    .add-to-cart-sticky-wrapper + .personalize-btn-wrapper,
    .add-to-cart-sticky-wrapper + .product-actions-notify-me,
    .add-to-cart-sticky-wrapper + .applepay-button,
    .personalize-btn-wrapper + .product-actions-notify-me {
        margin-top: 12px;
    }

    .product-actions-notify-me {
        text-transform: capitalize;
        font-size: rem(14);
    }

    .add-to-cart-sticky-wrapper {
        &.sticky {
            margin-bottom: 20px;
        }

        button,
        .button {
            padding-left: 10px;
            padding-right: 10px;
        }

        .add-to-cart-disabled:before {
            display: none;
        }
    }
    
    .add-to-cart-button-msg {
        font-family: $font-main;
        margin-left: 6px;
    }

    &-loyalty_msg {
        font-size: rem(14);
        margin-top: 12px;
        text-align: center;

        @include respond-to(desktop_below) {
            font-size: rem(12);
        }
    }
}

.dialog-quickview .product_actions-loyalty_msg {
    display: none;
}

.whishlist_instore-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 35px;

    @include respond-to(mobile) {
        margin-bottom: 15px;
    }

    @include respond-to(m_tablet_down) {
        gap: 15px;
        flex-direction: column;
    }
}

.product_actions-instore_link,
.product_actions-wishlist_link {
    flex: 1 1 45%;

    i {
        height: 14px;
    }
}
