.secondary-navigation {
    &.content-nav {
        > .toggle-nav {
            &:hover {
                color: $black01;
            }
        }
    }

    .toggle,
    .toggle-nav {
        &::after {
            font-size: rem(10);
            content: $aricons-plus;
            color: $color-primary2;

            @include respond-to(mobile) {
                margin-top: -5px;
            }
        }

        &.expanded {
            &::after {
                content: $aricons-minus;
            }
        }
    }
}

.breadcrumb-refined-by {
    font-size: rem(26);
    text-transform: none;
    padding: 0;

    @include respond-to(mobile) {
        font-size: rem(21);
    }

    &-header {
        padding-bottom: 25px;

        @include respond-to(mobile) {
            @include flexbox();
            @include align-items(center);
            @include flex-direction(row-reverse);
            @include justify-content(space-between);
        }
    }

    &-box {
        margin-bottom: 20px;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }

        .breadcrumb-refined-clear-all {
            @extend .button-type2;

            text-decoration: none;
            min-width: 100px;

            @include respond-to(mobile) {
                padding: 12px;
                margin: 0;
            }
        }
    }
}

.search-refinements-container {
    .refinement {
        &.brand {
            .refinement-item {
                text-transform: none;
            }
        }
    }
}

.category-refinement {
    margin-top: 15px;

    ul {
        margin: 0;
    }

    .category-level-1 {
        li {
            margin-bottom: 0;
        }

        > li {
            @extend .button-type2;

            padding: 0;
            margin-bottom: 22px;
            width: 100%;
            border-radius: 0;

            &:after {
                display: none; //Fix nested styles from .button-type2 for ripple-effect 
            }

            &:hover {
                background-color: rgba($black01, .03);
            }

            .chanel-section & {
                border-color: $very-light-gray;
            }

            .category-link {
                text-align: left;
                padding-left: 34px;

                &:hover {
                    color: $link-color-dark;
                }

                &.toggle {
                    @include icomoon-icon($aricons-plus, $font-size: rem(10), $pseudo: after) {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                    }

                    &.expanded::after {
                        content: $aricons-minus;
                        top: 15px;
                        border-bottom: 0;
                    }
                }
            }

            #secondary & a {
                line-height: 1;
                padding: 13px 30px 13px 20px;
                margin: 0;

                @include respond-to(mobile) {
                    font-size: rem(12.5);
                }

                &.toggle::after {
                    @include respond-to(mobile) {
                        top: 15px;
                    }
                }
            }
        }
    }

    .category-level-2 {
        padding-left: 10px;
    }
}

.refinement-top-level {
    margin-bottom: 10px;
}

.search-refinement {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    padding: 21px 28px 15px 15px;
}

.search-refinements__item {
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 10px 12px 10px 15px;
    font-family: $font-main-bold;
    font-size: rem(10);
    color: $white;
    text-transform: uppercase;
    border: 1px solid $lighter-gray;
    border-radius: 20px;
    background-color: $black01;

    &:first-child {
        margin-left: 0;
    }
}

.refined-clear-all__icon {
    @include inline-svg-mono($close-inside-circle, $white);

    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
}

.refined-clear-all__text + .refined-clear-all__icon {
    margin-left: 10px;
}

.refined-clear-all__text {
    vertical-align: middle;
}