.pt_order,
.pt_account {
    &_inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .primary-content {
        width: 66%;
        float: none;

        @include respond-to(m_tablet_down) {
            width: 100%;
            order: 3;
        }
    }

    #secondary {
        width: 27%;
        float: none;

        @include respond-to(m_tablet_down) {
            width: 100%;
            order: 1;
            padding-top: 40px;

            &:before {
                content: "js-tablet-nav";
                display: none;
            }
        }
    }
}

.b-account-customer_info {
    margin: 40px 0 60px;
    text-align: center;
    font-weight: 500;
    width: 100%;

    @include respond-to(m_tablet_down) {
        order: 2;
    }

    .b-account-header {
        font-size: rem(32);
    }

    .b-account-greeting {
        display: inline-block;
        font-style: italic;
        font-family: serif;
        font-size: rem(24);
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 15px;
        color: #000;

        &:after {
            content: "";
            border-bottom: 1px solid;
            width: 55px;
            margin: 0 auto;
            left: 0;
            right: 0;
            position: absolute;
            top: 100%;
        }
    }
}

.pt_account {
    &_inner {
        .form-fieldset-account-birthday p {
            font-family: $font-main-bold;
            font-size: rem(16);
            text-transform: capitalize;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid $color-auxiliary39;
    
            @include respond-to(mobile_strong) {
                margin-bottom: 10px;
            }
    
            a {
                font-family: $font-main-bold;
                color: $color-auxiliary19;
                text-decoration: underline;
    
                &:hover {
                    color: $color-primary2;
                }
            }
        }
    
        .form-fieldset-inline {
            p {
                font-size: rem(14);
            }
        }
    
        .new-password-form {
            margin: 0 auto 50px;
            max-width: 550px;
            padding-top: 50px;
    
            @include respond-to(mobile) {
                max-width: none;
                padding: 15px 0;
            }
    
            .form-row {
                margin: 2rem 0;
    
                @include respond-to(mobile) {
                    margin: 16px 0 0;
                }
            }
    
            .field-wrapper {
                margin-bottom: 40px;
    
                @include respond-to(mobile_strong) {
                    margin-bottom: 20px;
                }
    
                span.error {
                    position: relative;
                }
            }
    
            .form-caption {
                margin-left: 16px;
                margin-top: -5px;
                color: $color-auxiliary39;
    
                @include respond-to(mobile_strong_over) {
                    margin-left: 21px;
                    margin-top: -20px;
                }
            }
    
            .form-row-button {
                @include respond-to(mobile) {
                    margin: 16px 0 0;
                }
            }
        }
    
        .page-email-join-form .field-wrapper {
            span.error {
                @include custom-respond-to(768px, 800px) {
                    padding: 5px 20px;
                }
            }
        }
    
        .error-include .error-form {
            text-align: center;
        }
    }

    .breadcrumb {
        @include respond-to(desktop_plus) {
            padding-top: 20px;
        }
    }

    &_unregistered {
        .primary-content {
            float: none;
            width: 100%;
            padding-top: 0;
        }
    }

    .form-edit-account {
        .form-fieldsset-detailspassword .label-optional {
            display: none;
        }
    
        .field-wrapper {
            @include respond-to(mobile) {
                clear: both;
            }
    
            &-bidthday {
                padding-top: 5px;
    
                p {
                    font-family: $font-main;
                    font-size: rem(14);
                    margin-top: 0;
                    color: $hel-gray;
                }
            }
        }
    
        .form-row-button {
            text-align: left;
        }
    }

    .form-full-width {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding-top: 30px;

        legend {
            font-family: $font-main-bold;
            font-size: rem(16);
            text-transform: capitalize;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid $tutu;

            @include respond-to(mobile) {
                padding-top: 20px;
                margin-bottom: 10px;
            }
        }

        label {
            width: 16%;
            text-align: left;

            @include respond-to(mobile) {
                width: auto;
            }
        }

        .form-row.form-row-loyalty {
            height: 40px;
            clear: left;
            margin: 30px 0 0;

            .field-wrapper {
                width: 50%;

                @include respond-to(mobile) {
                    width: 100%;
                }
            }

            label {
                line-height: 20px;
            }

            & + .form-caption {
                @include respond-to(mobile) {
                    margin-top: 30px;
                }
            }
        }

        .form-fieldset-loyalty {
            .field-wrapper {
                width: 75%;
                padding-left: 0;

                @include respond-to(mobile) {
                    width: auto;
                    float: none;
                    line-height: normal;
                    margin-bottom: 20px;
                }

                &:last-child {
                    @include respond-to(mobile) {
                        margin-bottom: 5px;
                    }
                }

                label {
                    float: none;
                    width: auto;
                    display: inline-block;

                    @include respond-to(mobile) {
                        width: 85%;
                        display: inline-block;
                        line-height: inherit;
                    }
                }

                input[type="checkbox"],
                input[type="radio"] {
                    @include respond-to(mobile) {
                        display: inline-block;
                        vertical-align: top;
                        margin-top: 4px;
                    }
                }
            }

            .form-row.form-row-loyalty {
                height: auto;

                label {
                    float: none;
                    width: auto;
                    margin-bottom: 6px;
                }

                .field-wrapper {
                    padding-left: 0;
                }
            }
        }

        .form-row {
            margin: 2rem 0;

            @include respond-to(mobile) {
                margin: 16px 0 0;
            }

            &-loyalty {
                @include respond-to(mobile_strong) {
                    margin-top: 15px;
                }
            }

            &-one-third {
                display: inline-block;
                vertical-align: top;
                margin: 5px 0;

                @include respond-to(mobile_min) {
                    display: block;
                }

                label {
                    display: none;
                }

                select {
                    margin-right: 20px;

                    @include respond-to(mobile) {
                        margin-right: 0px;
                    }
                }
            }

            &-field-passwordcreate {
                input.error[type=password],
                input.error[type=text] {
                    & + .error {
                        position: relative;
                        margin-bottom: -26px;

                        @include respond-to(mobile_strong) {
                            padding-bottom: 10px;
                        }
                    }
                }

                .form-caption {
                    @include respond-to(mobile_strong_over) {
                        float: left;
                        margin-top: -20px;
                        padding-left: 22px;
                    }
                }
            }

            &.label-inline {
                .field-wrapper {
                    width: auto;
                    margin-left: 18%;

                    @include respond-to(mobile) {
                        margin-left: 0;
                    }
                }
            }

            &.form-row-loyalty {
                margin-top: 16px;
            }
        }

        .field-wrapper {
            width: 100%;
            margin-bottom: 40px;

            @include respond-to(mobile) {
                width: auto;
            }

            @include respond-to(mobile_strong) {
                margin-bottom: 15px;
            }

            label {
                width: 100%;
            }
        }

        .form-caption {
            margin-left: 0;
            color: $hel-gray;
            font-size: rem(14);

            @include respond-to(mobile) {
                margin-left: 0;
            }

            @include respond-to(mobile_strong) {
                margin-top: -5px;
                padding-left: 16px;
            }
        }

        .form-row-button {
            width: 100%;
            text-align: center;
        }

        fieldset,
        .form-fieldset-account-birthday {
            margin-bottom: 30px;
        }

        .form-fieldset-account-birthday {
            p {
                margin: 10px 0 0;
            }

            label {
                width: auto;
            }

            span.error {
                @include respond-to(mobile_strong) {
                    font-size: rem(16);
                    margin-top: -15px;
                    padding: 10px 15px;
                }
            }
        }

        .form-submit-footer {
            padding-top: 40px;
            border-top: 1px solid $tutu;

            a {
                text-decoration: underline;
            }

            button {
                margin: 40px 0 20px;

                @include respond-to(mobile) {
                    margin-bottom: 40px;
                }
            }
        }

        .form-fieldset-inline {
            label {
                display: inline-block;
                margin-right: 0;
            }

            .form-row {
                display: inline-block;
            }

            .field-wrapper.field-wrapper {
                display: inline;
                margin-left: 0;
            }
        }

        .form-fieldset-block-mobile {
            @include respond-to(mobile) {
                display: block;
            }
        }
    }
}

.myaccount-landing-slot {
    margin: 20px 0;

    img {
        max-width: 100%;
    }
}

.account-content-wrapper {
    height: 275px;
}

.account-content-inner {
    display: block;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255,255,255, .8);
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @include respond-to(mobile) {
        width: 80%;
    }

    a {
        width: 80%;
        display: block;
        font-family: $font-main-bold;
        font-size: rem(16);
        margin: 20px auto 0;
        border: 1px solid;
        padding: 5px;
    }

    span {
        display: block;
        font-size: rem(14);
        line-height: 20px;
        margin-top: 10px;
    }
}

/* User agent styling */
.user-agent-logged {
    margin-top: 30px;
}

.user-agent-header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    text-transform: uppercase;
    color: $black01;
    line-height: 20px;
    z-index: 99000;
}

.sticky-wrapper.is-sticky {
    .user-agent-header {
        position: relative;
    }

    .user-agent-logged & .top-banner {
        margin-top: 30px;
    }
}

.header-banner {
    padding-top: 0;
}

.header-banner.with-agent-header {
    padding-top: 30px;

    & + .sticky-wrapper.is-sticky {
        padding-top: 0;
    }
}

.page-email-join-form {

    .field-wrapper {
        margin-bottom: 40px;

        @include respond-to(mobile) {
            margin-bottom: 20px;
        }
    }
    .form-indent {
        margin-left: 0;
    }

    input[type="checkbox"] {
        @include check(checkbox);
    }

    .input-text.phone {
        + .error {
            position: static;
            margin-bottom: -40px;

            @include respond-to(mobile) {
                margin-bottom: -20px;
            }
        }
    }

    .form-row-field-agreed {
        display: inline-block;

        &::after {
            content: '*';
            color: $color-primary2;
            display: inline;
        }

        .field-wrapper {
            display: inline-block;
            float: none;
            width: auto;
        }
    }
}


.myaccount-header-banner,
.create-account-header-banner,
.account-address-banner,
.mywishlist-flayer,
.loyalty-header {
    background: none;
    border: 1px solid $color-auxiliary17;
}

body {
    .customer-migration-dialog {
        max-width: 550px;

        @include custom-respond-to(320px, 600px) {
            max-width: 90%;
        }

        .ui-dialog-title {
            font-size: rem(20);
            margin: 27px auto 7px;
        }

        &.ui-dialog {
            .ui-widget-header {
                padding-bottom: 0;
            }

            .ui-dialog-titlebar-close {
                right: 42px;
                top: 35px;
            }
        }

        .ui-dialog-content {
            padding: 0 50px;

            @include custom-respond-to(320px, 600px) {
                padding: 0 8%;
            }
        }

        .content-center,
        .reset-password-content,
        p {
            font-size: rem(18);
            line-height: 1.3;
            margin-bottom: 0;
            text-align: center;
        }

        #PasswordResetForm {
            margin: 40px auto 65px;
            max-width: 67%;

            @include custom-respond-to(320px, 600px) {
                margin: 20px auto 45px;
                max-width: 300px;
                width: 100%;
            }

            .dialog-action-buttons {
                margin-top: 30px;
            }

            .button-submit {
                float: none;
                width: 100%;
            }
        }

        .reset-password-your-email {
            margin: 40px 0 55px;
        }

        .button-cancel {
            @extend button.coloured;

            margin-bottom: 65px;
            max-width: 67%;
            width: 100%;
        }
    }
}