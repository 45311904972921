.content-slot img {
    height: auto;
    max-width: 100%;
}

// common class for all newly created promo slots
.slot-panels {
    .promo-content-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    picture {
        img {
            display: block;
            max-width: 100%;
            width: 100%;
        }
    }

    .main-title {
        margin-top: rem(20);
    }
}

.slot-grid-header p {
    margin: 0;
    padding: 0;
}

.product-listing-1x4 {
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    padding: .83em 0;

    .search-result-items {
        @include justify-content(space-around);

        border-left: $product-tile-border-width solid $lighter-gray;
    }
}

.account-nav-asset {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    line-height: 1.5em;
    margin: 3.33em 0;
    padding: .83em 0;

    .pt_order & {
        @include respond-to(mobile) {
            display: none;
        }
    }

    h2 {
        font-style: italic;
        margin: 1.16em 0 1.66em;
    }
}

.content-asset {
    .account-options {
        @include flexbox();
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;

        li {
            background-color: $dim-gray;
            border: 3px solid $very-light-gray;
            border-radius: 5px;
            list-style-type: none;
            margin: 1rem;
            text-align: center;
            width: 35%;
            transition: all 1000ms ease;

            &:hover {
                background-color: $rain-forest;
                border-color: $rain-forest;
            }

            a {
                color: $white;
                display: block;
                padding: 1rem;

                i {
                    font-size: rem(60);
                }
            }
        }
    }
}

.browser-compatibility-alert {
    background-color: $sangria;
    color: $white;
    font-size: rem(13);
    line-height: 2em;
    padding: 0.8em;
}

.folder-slot {
    border-style: solid;
    border-color:  $islamic-green;
    border-width: 1px;
    background-color: white;
    padding: 0.5em 0.5em;

    h2{
        color:  $islamic-green;
        margin: 0.5em 0em;
    }
}

.menu-slot {
    display: none;
    border-top: 1px solid $tutu;
    margin-top: 0.5em;
    padding-top: 1.5em;

    .social-links {
        padding-left: 0;

        li {
            display: inline-block;
            padding-left: 0;
        }
    }

    @include respond-to(tablet_plus) {
        width: 13.5rem;
        display: block;
    }
}

.homepage-slides {
    margin: 0;
    position: relative;
    width: 20000em;

    .slide {
        overflow: hidden;
        position: relative;
        min-height: 5rem;

        img {
            height: auto;
            max-width: 100%;
        }

        .banner-message {
            position: absolute;
            top: 10%;

            @include respond-to(tablet_plus) {
                top: 2%;
            }

            .banner-text1 {
                font-family: $sans-serif-alt;
                font-size: rem(20);
                text-transform: uppercase;

                @include respond-to(tablet_plus) {
                    font-size: rem(50);
                }
            }

            .banner-text2 {
                font-size: rem(15);
                font-family: $font-second;
                text-transform: lowercase;

                @include respond-to(tablet_plus) {
                    font-size: rem(40);
                }
            }

            .banner-text3 {
                font-family: $sans-serif-alt;
                font-size: rem(5);
                text-transform: uppercase;

                @include respond-to(tablet_plus) {
                    font-size: rem(13);
                }
            }
        }
    }

    .slide1 {
        .banner-message {
            left: 3%;

            .banner-text1 {
                color: $cerulean;
                display: inline;
            }

            .banner-text2 {
                color: $white;
                display: inline;
            }

            .banner-text3 {
                color: $white;
                text-align: right;
            }
        }
    }

    .slide2 {
        .banner-message {
            left: 3%;

            .banner-text1 {
                color: $citrus;
                display: inline;
                letter-spacing: -0.1rem;
            }

            .banner-text2 {
                color: $dark-gray;
                display: inline;
            }

            .banner-text3 {
                color: $black01;
            }
        }
    }

    .slide3 {
        .banner-message {
            left: 3%;

            .banner-text1 {
                color: $citrus;
                display: inline;
                margin-top: -2rem;
            }

            .banner-text2 {
                color: $white;
                display: inline;
            }

            .banner-text3 {
                color: $black01;
            }
        }
    }

    .slide4 {
        .banner-message {
            right: 3%;

            .banner-text1 {
                color: $citrus;
                margin-top: 0rem;
                text-indent: 3rem;
                @include respond-to(tablet_plus) {
                    margin-top: -1rem;
                }
            }

            .banner-text2 {
                color: $white;
            }

            .banner-text3 {
                color: $white;
            }
        }
    }

    .slide5 {
        .banner-message {
            left: 15%;

            .banner-text1 {
                color: $cerulean;
                display: inline;
            }

            .banner-text2 {
                color: $sangria;
                display: inline;
            }

            .banner-text3 {
                color: $white;
            }
        }
    }
}

#home-bottom-left {
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    width: 100%;

    h2 {
        color: $black01;
        font-family: $sans-serif-alt;
        font-size: rem(20);
        margin: 0;
        padding: 1rem 0;
        text-transform: uppercase;

        @include respond-to(tablet_plus) {
            font-size: rem(23);
        }
    }

    ul {
        margin: -.5em 0 .5em 0;

        li {
            border-right: 1px solid $dim-gray;
            color: $dim-gray;
            display: inline;
            font-family: $sans-serif-alt;
            font-size: rem(8);
            list-style: none outside none;
            margin: 0 5px 0 0;
            padding: 0 5px 0 0;
            text-transform: uppercase;

            &:last-child {
                border: 0 none;
            }

            span {
                margin: 0 10px 0 0;
            }
        }
    }

    img {
        display: block;
    }
}

#home-bottom-center {
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;

    .home-bottom-center-text {
        position: absolute;
        text-align: center;
        top: 18%;
        width: 100%;

        h1 {
            color: $cerulean;
            font-family: $serif;
            font-size: rem(40);
            font-family: $font-second;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
        }

        h2 {
            color: $dim-gray;
            font-family: $sans-serif-alt;
            font-size: rem(13);
            letter-spacing: .2em;
            margin-top: -.05rem;
            text-transform: uppercase;
        }

        h3 {
            color: $dim-gray;
            font-family: $serif;
            font-size: rem(8);
            margin-top: -1rem;
            text-transform: uppercase;
        }
    }

    img {
        display: block;
    }
}

.home-bottom-right {
    font-family: $sans-serif-alt;
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;

    .home-bottom-right-header {
        h2 {
            color: $dim-gray;
            font-size: rem(7);
            padding: .5em 0 0 0;
            margin: 0;

            @include respond-to(tablet_plus) {
                font-size: rem(23);
            }
        }

        h3 {
            color: $rain-forest;
            font-size: rem(22);
            font-style: normal;
            margin: 0;
            padding: 0;
            text-transform: uppercase;

            @include respond-to(tablet_plus) {
                font-size: rem(23);
            }
        }

        img {
            display: block;
        }
    }

    .product-tile {
        border: 0 none;
        margin: 1em auto 0;
        min-height: 255px;
        width: 90%;

        img {
            box-shadow: 0px 0px 5px 3px $very-light-gray;
            max-width: 90%;
        }

        .product-name {
            height: auto;
        }

        .product-sales-price {
            color: $black01;
        }
    }
}

// fix for initialisation of carousel
.hp-best-sellers,
.cat-landing-slot-wrapper {
    .carousel li {
        @include respond-to(desktop_over) { // >= 1261px
            width: 205px;
        }

        @include respond-to(mobile_to_desktop) { // 801px - 1260px
            width: calc(100% / 4);
        }

        @include respond-to(mobile_top) { // 320px - 800px
            width: 50%;
        }

        @include respond-to(mobile_stronger) {  // 768px - 992px
            width: calc(100% / 3);
        }
    }
}

.main-title.best-seller-title {
    @include respond-to(mobile_strong) {
        font-size: rem(22);
    }
}

.recommendations-inner,
.last-visited {
    clear: both;

    .carousel {
        margin-bottom: 30px;
    }
}

.pdp-slot-recommendation {
    clear: both;

    .slot {
        margin-bottom: 35px;
    }

    .product-tile-wrapper {
        @include respond-to(m_desktop) {
            display: flex;
            justify-content: center;
        }
    }
}

.cart-recommendations,
.pdp-recommendations,
.global-recommendations,
.category-slot,
.product-slot {
    .product-tile-wrapper {
        @include respond-to(m_desktop) {
            display: flex;
            justify-content: center;
        }
    }

    .tiles-container {
        @include flexbox();

        border: none;
        flex-flow: row wrap;
        justify-content: center;

        .grid-tile {
            box-shadow: none;
            margin: 1rem;
            padding: 1rem;
            position: relative;
            width: 25%;

            &:before {
                background-color: $lighter-gray;
                content: '';
                height: 100%;
                left: -1px;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:after {
                background-color: $lighter-gray;
                content: '';
                height: 1px;
                left: 0;
                position: absolute;
                top: -1px;
                width: 100%;
            }
        }
    }

    h1 {
        color: $white;
        font-family: $sans-serif-alt;
        font-size: rem(30);
        position: absolute;
        text-align: right;
        bottom: 2%;
        left: 2%;
    }

    h3 {
        background-color: $white;
        bottom: 0;
        color: $black01;
        font-family: $sans-serif-alt;
        font-size: rem(20);
        margin: 0;
        opacity: .6;
        padding: 2rem;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
    }

    .catlanding-banner {
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            height: auto;
            width: 100%;
        }
    }

    .category-tile {
        float: left;
        overflow: hidden;
        padding: 0 .15%;
        position: relative;
        width: 100%;

        @include respond-to(tablet_plus) {
            width: 33%;
        }

        img {
            height: auto;
            width: 100%;
        }
    }
    .cat-banner {
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            height: auto;
            width: 100%;
        }

        h1 {
            color: $night-rider;
            right: 2%;
        }
    }

    .search-result-items {
        .grid-tile {
            box-shadow: none;
            margin: 0;
            padding: 0 .15%;
            width: 100%;

            @include respond-to(tablet_plus) {
                width: 33%;
            }

            .product-tile {
                margin: 0 .15%;
                padding: 1rem 0;
                width: 100%;

                .product-name {
                    @include text-truncate;

                    font-family: $sans-serif-alt;
                    font-size: rem(12);
                    height: auto;
                    margin-top: .5em;

                    a {
                        color: $cerulean;
                    }
                }

                .product-pricing {
                    font-family: $sans-serif-alt;
                    font-size: rem(10);
                }
            }
        }
    }
}

.html-slot {
    background-color: $white-smoke;
    border-color: $gainsboro;
    border-style: solid;
    border-width: 1px 0;

    h1 {
        color: $cerulean;
        font-family: $sans-serif-alt;
        font-size: rem(15);
        margin: 1rem;
        text-align: center;

        a {
            font-family: $serif;
        }
    }
}

/* PLP banner slots */
.banner-slot {
    &.hide-content-mobile .plp-header-txt {
        @include respond-to(mobile) {
            display: none;
        }
    }

    &.hide-additional-content-mobile .additional-markup-content {
        @include respond-to(mobile) {
            display: none;
        }
    }

    h1 {
        margin: 3px 0 8px;
        color: inherit;
    }

    .slot-panels-container {
        padding: 24px 0 12px;
        margin-bottom: -1px;
        @include out-of-wrapper();

        p {
            margin-bottom: 20px;
        }

        a:hover {
            color: rgba($black01, 0.7);
        }
    }

    .plp-header-subtitle {
        position: relative;
        font-size: rem(24);

        @include respond-to(mobile) {
            font-size: rem(22);
        }

        &.header-subtitle-underline {
            font-family: $font-main;
            padding-bottom: 20px;
            letter-spacing: 1px;

            &:after {
                width: 70px;
                bottom: 10px;
            }
        }
    }

    &.title-underlined .plp-header-subtitle {
        &:after {
            content: '';
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            width: 50px;
            border-bottom: 1px solid;
        }
    }

    .plp-header-txt {
        width: 70%;
        margin: 0 auto;
        max-width: 800px;

        @include respond-to(mobile) {
            width: 92%;
        }
    }

    .plp-header-txt-ctas {
        text-align: center;

        li {
            display: inline-block;
            margin: 0 0 12px 22px;
        }

        a {
            color: inherit;
        }
    }

    // banner-1
    &.content-half {
        text-align: center;

        .slot-panels-container {
            display: inline-block;
            width: 66.6667%;

            @include respond-to(tablet) {
                width: 75%;
            }

            @include respond-to(mobile) {
                width: 100%;
            }

            p {
                margin: 0 75px 20px;

                @include respond-to(mobile) {
                    margin: 0 0 20px;
                }
            }
        }
    }

    // banner-2
    &.additional-content-position-left,
    &.additional-content-position-right {
        .slot-panels-container {
            @include flexbox();
            @include align-items(center);

            @include respond-to(mobile) {
                @include flex-vertical();
            }

            .additional-markup-content {
                @include respond-to(m_tablet_down) {
                    padding: 20px 10px;
                }

                img {
                    border-radius: 5px;
                }
            }

            .additional-markup-content,
            .plp-header-txt {
                width: 48%;
                margin: 0 1%;

                @include respond-to(mobile) {
                    width: 98%;
                }
            }
        }
    }

    &.additional-content-position-left {
        .additional-markup-content {
            @include order(1);


            @include respond-to(mobile) {
                @include order(2);
            }
        }
        .plp-header-txt {
            @include order(2);

            @include respond-to(mobile) {
                @include order(1);
            }
        }
    }

    // banner-3
    .plp-promo-accordion {
        text-align: center;

        @include respond-to(touch) {
            max-width: 55%;
            margin: 0 auto;
        }

        @include respond-to(mobile) {
            max-width: 100%;
        }

        li {
            display: inline-block;
            width: 32%;
            margin-right: 1%;
            font-size: 0;
            cursor: pointer;

            @include respond-to(touch) {
                display: block;
                width: 100%;
            }
        }

        a {
            color: inherit;
        }

        .accordion-content {
            @include flexbox();

            @include respond-to(touch) {
                display: none;
            }
        }


        .img {
            display: inline-block;
            vertical-align: top;
            width: 46%;
            background-size: cover;
            background-position: center;

            @include respond-to(touch) {
                height: 100px;
                width: 41%;
            }

            @include respond-to(mobile) {
                float: left;
            }
        }

        .content {
            display: inline-block;
            width: 55%;
            padding: 18px;
            text-align: left;

            @include respond-to(touch) {
                padding-top: 0;
            }

            @include respond-to(mobile) {
                padding: 10px;
            }

            h2 {
                font-size: rem(16);
                color: inherit;
            }

            p {
                font-size: rem(14);
                line-height: 22px;
            }

            .text-underline {
                display: inline-block;
                margin-bottom: 0;
                padding-bottom: 1px;
                border-bottom: 1px solid;
                font-size: rem(12);
                letter-spacing: 1px;
                line-height: 13px;
                text-transform: uppercase;
            }
        }

        .accordion-title {
            display: none;
            font-size: rem(16);
            letter-spacing: 1px;
            padding: 16px;
            text-align: left;
            margin-bottom: 10px;

            &:after {
                content: '+';
                float: right;
                font-size: rem(22);
                line-height: 25px;
            }

            @include respond-to(touch) {
                display: block;
            }

            &.expanded {
                margin-bottom: 0;

                &:after {
                    content: '-';
                }

                & + .accordion-content {
                    display: block;
                    margin-bottom: 10px;
                    padding: 15px;

                    @include respond-to(touch) {
                        padding: 10px;
                    }

                    @include respond-to(mobile) {
                        padding: 10px 15px 20px;
                    }
                }
            }
        }
    }

    &.hover-shadow-light,
    .hover-shadow-light {
        .accordion-title,
        .content {
            box-shadow: inset 0 0 100px 100px rgba(255,255,255, 0.2);
        }

        .content {
            @include respond-to(touch) {
                box-shadow: none;
            }
        }

        .accordion-content {
            @include respond-to(touch) {
                box-shadow: inset 0 0 100px 100px rgba(255,255,255, 0.2);
            }
        }
    }

    &.hover-shadow-dark,
    .hover-shadow-dark {
        .accordion-title,
        .content {
            box-shadow: inset 0 0 100px 100px rgba(0,0,0, 0.1);
        }

        .content {
            @include respond-to(touch) {
                box-shadow: none;
            }
        }

        .accordion-content {
            @include respond-to(touch) {
                box-shadow: inset 0 0 100px 100px rgba(0,0,0, 0.1);
            }
        }
    }

    // banner-5,6
    .video-panel-iframe {
        height: 338px;
        width: 100%;

        @include respond-to(tablet) {
            height: 270px;
        }

        @include respond-to(mobile) {
            height: 350px;
        }

        @include respond-to(mobile_mid) {
            height: 270px;
        }

        @include respond-to(mobile_min) {
            height: 180px;
        }
    }
}

.category-chanel-link {
    display: block;
}

.cat-banner .chanel-banner {
    @include respond-to(mobile) {
        @include out-of-wrapper();
        display: block;
        height: 100%;
        background: $black01;
    }
}

.cp-two-image {
    background-color: $white;
    margin: 0 auto;
    max-width: rem(1270);
    padding-left: rem(40);

    @include respond-to(mobile) {
        margin: auto -30px;
        padding-left: 0;
    }

    &.view-position-right {
        @include respond-to(desktop_plus) {
            .wrapper {
                flex-direction: row-reverse;
            }
        }
    }

    .main-title {
        font-size: rem(35);
        font-weight: bold;
        margin-bottom: rem(20);
        text-align: left;

        @include respond-to(mobile) {
            padding-left: rem(25);
        }

        @include respond-to(tablet_regular) {
            padding-left: rem(30);
        }
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        padding-bottom: rem(50);

        @include respond-to(mobile) {
            flex-direction: column;
            padding-bottom: rem(20);
        }
    }

    .image-small-wrapper,
    .image-big-wrapper {
        width: 49%;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .image-small-wrapper {
        @include respond-to(mobile) {
            margin-bottom: rem(10);
            padding-left: rem(50);
        }
    }

    .image-big-wrapper {
        @include respond-to(mobile) {
            padding-right: rem(50);
        }
    }

    .image-small,
    .image-big {
        max-width: 100%;
        height: 0;
        overflow: hidden;
        position: relative;
        width: 100%;

        img,
        video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .image-small {
        padding-top: 56.25%;
    }

    .image-big {
        padding-top: 75%;
    }

    .asset-link {
        display: inline-block;
        padding: rem(15);

        @include respond-to(mobile) {
            padding-left: rem(25);
        }

        @include respond-to(tablet_regular) {
            padding-left: rem(30);
        }
    }

    .asset-title {
        @extend .h3;

        margin-bottom: rem(10);
    }

    .asset-title,
    .asset-description {
        display: block;
    }

    .asset-description {
        margin-bottom: rem(10);
    }

    .asset-button.button.outlined {
        padding: 14px 35px;
        display: inline-block;
        min-width: rem(150);

        @include respond-to(mobile) {
            min-width: none;
            width: auto;
        }
    }
}
