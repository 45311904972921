.cart-recommendations,
.pdp-recommendations,
.global-recommendations {
    clear: both;
    
    .carousel {
        @include shared-jcarousel-nav;

        .jcarousel-nav.jcarousel-prev,
        .jcarousel-nav.jcarousel-next {
            top: 35%;
        }
    }

    .main-title {
        text-transform: capitalize;
    }
}

.category-preview-carousel {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 40px;

    @include respond-to(desktop) {
        justify-content: space-evenly;
    }

    @include respond-to(mobile) {
        flex-direction: column;
    }

    &.shop_now-quickview {
        .product-tile {
            .shop_now-button,
            .button-dual_functional .bticons-zoom {
                @extend .button-type3;
                
                background-color: transparent;
                border-color: $color-secondary2;
                border-width: 1px;
                border-style: solid;
                border-radius: 50px;
                color: $black01;
                max-width: 250px;
                min-width: auto;
                padding: 11px;

                &::after {
                    display: none;
                }

                @include respond-to(touch_over) {
                    &:hover {
                        background-color: $white;
                        border-color: $color-primary2;
                        color: $black01;
                    }
                }

                @include respond-to(mobile_strong) {
                    width: 90%;
                }
            }

            .button-dual_functional .bticons-zoom {
                &::before {
                    content: attr(data-alt-title);
                    font-family: $font-main-bold;
                }
            }
        }

        .carousel-item {
            padding-bottom: rem(50);
        }
    }

    .main-title {
        font-size: rem(35);
        font-weight: bold;
        margin-bottom: rem(20);
        text-align: left;
        width: 100%;

        @include respond-to(mobile) {
            display: none;
        }
    }

    .category-preview-content {
        margin-right: 7%;

        @include respond-to(mobile) {
            margin: 0 auto 20px;
            padding: 0 20px;
        }

        .main-title {
            @include respond-to(mobile) {
                display: block;
            }

            @include respond-to(tablet_plus) {
                display: none;
            }
        }
    }

    .category-preview-image {
        position: relative;
        width: rem(330);

        @include respond-to(mobile) {
            width: rem(255);
            margin: 0 auto;
        }

        .preview-image-wrap {
            margin: 0;
            padding-bottom: 100%;
        }

        img {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .category-main-content {
        @include respond-to(desktop_plus) {
            width: 55%;
        }

        @include respond-to(touch_over) {
            width: 65%;
        }

        @include respond-to(mobile) {
            margin: 0;
            width: 100%;

            .carousel {
                .product-tile {
                    margin: 0 auto;
                }

                .jcarousel-nav {
                    display: none;
                }

                .jcarousel-control {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin: 15px 0;

                    a {
                        background-color: $color-auxiliary24;
                        border-radius: 5px;
                        font-size: 0;
                        height: 3px;
                        margin: 0 2px;
                        padding: 0;
                        width: 35px;
                    }

                    a.active {
                        background-color: $medium-blue;
                    }
                }
            }
        }

        .product-tile {
            @include respond-to(touch_over) {
                max-width: 180px;
                margin: 0 auto;
                padding: 0;
            }
        }

        .carousel {
            .jcarousel-nav {
                top: 160px;

                &.jcarousel-prev {
                    left: -12px;
                }
            }
        }

        .product-image {
            @include respond-to(tablet_plus) {
                width: rem(180);
            }
        }

        .product-pricing {
            margin-top: 0;
        }

        .tile-swaches-wrapper {
            display: none;
        }
    }
}

.shop_look {
    text-align: center;
    margin: 40px 0 50px;

    &-title {
        margin-bottom: 20px;
        font-family: $font-main-bold;
        font-size: rem(32);
        text-transform: none;
    }

    &-text {
        margin-bottom: 20px;
        padding: 0 40px;

        a {
            @include link_underline($medium-blue);
        }
    }

    &-image {
        position: relative;
        overflow: hidden;

        @include respond-to(m_desktop) {
            width: 80%;
            margin: 0 auto;
        }

        &::after {
            content: "";
            display: block;
            padding-top: 56.25%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
        }
    }

    .slick-track {
        display: flex;
        justify-content: space-around;
        min-width: 100%;
    }

    .slick-slider {
        margin-top: 20px;
        position: relative;

        @include respond-to(m_desktop) {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .slick-slide {
        height: auto;
    }

    .slick-arrow {
        @include icon(simple-arrow-left);

        border: 0;
        position: absolute;
        background-color: transparent;
        border-radius: 0;
        padding: 0;

        &::before {
            font-size: rem(22);
            color: $nobel;
        }

        &::after {
            display: none;
        }

        &.slick-disabled {
            opacity: 0.5;
            cursor: unset;
            display: block;
        }
    }

    .slick-prev {
        left: -50px;

        @include respond-to(m_tablet_down) {
            left: 0;
        }
    }

    .slick-next {
        right: -50px;

        @include respond-to(m_tablet_down) {
            right: 0;
        }
    }

    .product-actions,
    .product-tile .promo-flag,
    .try-on-badge {
        display: none;
    }

    .product-tile {
        padding-bottom: 0;
    }
}

.search-result-items .global-recommendations {
    width: 100%;
    
    .carousel .product-image {
        height: auto;

        img {
            max-height: none;
        }
    }
}