[class^="aricons-"], [class*=" aricons-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-arnotts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  letter-spacing: 0 !important; // fix multicolor\multipath icons
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Please do not change styles above!

.aricons-arnotts-logo {
  &:before {
    content: $aricons-arnotts-logo;
  }
}
.aricons-social-twitter {
  &:before {
    content: $aricons-social-twitter;
  }
}
.aricons-social-pinterest {
  &:before {
    content: $aricons-social-pinterest;
  }
}
.aricons-social-instagram {
  &:before {
    content: $aricons-social-instagram;
  }
}
.aricons-social-facebook {
  &:before {
    content: $aricons-social-facebook;
  }
}
.aricons-email-fill {
  &:before {
    content: $aricons-email-fill;
  }
}
.aricons-social-youtube {
  &:before {
    content: $aricons-social-youtube;
  }
}
.aricons-email {
  &:before {
    content: $aricons-email;
  }
}
.aricons-burger-menu {
  &:before {
    content: $aricons-burger-menu;
  }
}
.aricons-bag-empty {
  &:before {
    content: $aricons-bag-empty;
  }
}
.aricons-bag-full .path1 {
  &:before {
    content: $aricons-bag-full-path1;
    color: $black01;
  }
}
.aricons-bag-full .path2 {
  &:before {
    content: $aricons-bag-full-path2;
    margin-left: -0.7080078125em;
  }
}
.aricons-close {
  &:before {
    content: $aricons-close;
  }
}
.aricons-home {
  &:before {
    content: $aricons-home;
  }
}
.aricons-minus {
  &:before {
    content: $aricons-minus;
  }
}
.aricons-plus {
  &:before {
    content: $aricons-plus;
  }
}
.bticons-search {
  &:before {
    content: $aricons-search;
  }
}
.aricons-search-plus {
  &:before {
    content: $aricons-search-plus;
  }
}
.aricons-share {
  &:before {
    content: $aricons-share;
  }
}
.aricons-small-arrow-down {
  &:before {
    content: $aricons-small-arrow-down;
  }
}
.aricons-small-arrow-left {
  &:before {
    content: $aricons-small-arrow-left;
  }
}
.aricons-small-arrow-right {
  &:before {
    content: $aricons-small-arrow-right;
  }
}
.aricons-small-arrow-up {
  &:before {
    content: $aricons-small-arrow-up;
  }
}
.aricons-wishlist-empty {
  &:before {
    content: $aricons-wishlist-empty;
  }
}
.aricons-wishlist-full {
  &:before {
    content: $aricons-wishlist-full;
  }
}
.aricons-wishlist .path1 {
  &:before {
    content: $aricons-wishlist-path1;
    color: rgb(255, 0, 0);
  }
}
.aricons-wishlist .path2 {
  &:before {
    content: $aricons-wishlist-path2;
    margin-left: -1.1298828125em;
    color: $black01;
  }
}
.aricons-wishlist .path3 {
  &:before {
    content: $aricons-wishlist-path3;
    margin-left: -1.1298828125em;
    color: rgb(191, 38, 47);
  }
}
.aricons-bag .path1 {
  &:before {
    content: $aricons-bag-path1;
    color: $black01;
  }
}
.aricons-bag .path2 {
  &:before {
    content: $aricons-bag-path2;
    margin-left: -0.9013671875em;
  }
}
.aricons-bag .path3 {
  &:before {
    content: $aricons-bag-path3;
    margin-left: -0.9013671875em;
    color: rgb(142, 146, 150);
  }
}
.bticons-user .path1 {
  &:before {
    content: $aricons-user-path1;
    color: rgb(255, 38, 48);
  }
}
.bticons-user .path2 {
  &:before {
    content: $aricons-user-path2;
    margin-left: -0.7783203125em;
    color: rgb(30, 38, 48);
  }
}
.aricons-video-pause .path1 {
  &:before {
    content: $aricons-video-pause-path1;
    color: rgb(1, 1, 1);
    opacity: 0.35;
  }
}
.aricons-video-pause .path2 {
  &:before {
    content: $aricons-video-pause-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.aricons-video-play .path1 {
  &:before {
    content: $aricons-video-play-path1;
    color: rgb(1, 1, 1);
    opacity: 0.35;
  }
}
.aricons-video-play .path2 {
  &:before {
    content: $aricons-video-play-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.aricons-video-play .path3 {
  &:before {
    content: $aricons-video-play-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.aricons-thumb-size {
  &:before {
    content: $aricons-thumb-size;
  }
}
.aricons-tag .path1 {
  &:before {
    content: $aricons-tag-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-tag .path2 {
  &:before {
    content: $aricons-tag-path2;
    margin-left: -0.9970703125em;
  }
}
.aricons-lock {
  &:before {
    content: $aricons-lock;
  }
}
.aricons-check {
  &:before {
    content: $aricons-check;
  }
}
.aricons-social-twitter-fill .path1 {
  &:before {
    content: $aricons-social-twitter-fill-path1;
    color: $black01;
    opacity: 0.4;
  }
}
.aricons-social-twitter-fill .path2 {
  &:before {
    content: $aricons-social-twitter-fill-path2;
    margin-left: -1em;
    color: $black01;
  }
}
.aricons-social-pinterest-fill .path1 {
  &:before {
    content: $aricons-social-pinterest-fill-path1;
    color: $black01;
    opacity: 0.4;
  }
}
.aricons-social-pinterest-fill .path2 {
  &:before {
    content: $aricons-social-pinterest-fill-path2;
    margin-left: -1em;
    color: $black01;
  }
}
.aricons-social-instagram-fill .path1 {
  &:before {
    content: $aricons-social-instagram-fill-path1;
    color: $black01;
    opacity: 0.4;
  }
}
.aricons-social-instagram-fill .path2 {
  &:before {
    content: $aricons-social-instagram-fill-path2;
    margin-left: -1em;
    color: $black01;
  }
}
.aricons-social-facebook-fill .path1 {
  &:before {
    content: $aricons-social-facebook-fill-path1;
    color: $black01;
  }
}
.aricons-social-facebook-fill .path2 {
  &:before {
    content: $aricons-social-facebook-fill-path2;
    margin-left: -1em;
    color: $black01;
    opacity: 0.4;
  }
}
.aricons-social-email-fill .path1 {
  &:before {
    content: $aricons-social-email-fill-path1;
    color: $black01;
    opacity: 0.4;
  }
}
.aricons-social-email-fill .path2 {
  &:before {
    content: $aricons-social-email-fill-path2;
    margin-left: -1em;
    color: $black01;
  }
}
.aricons-share-circle .path1 {
  &:before {
    content: $aricons-share-circle-path1;
  }
}
.aricons-share-circle .path2 {
  &:before {
    content: $aricons-share-circle-path2;
    margin-left: -1em;
    color: $black01;
  }
}
.aricons-spinner-minus .path1 {
  &:before {
    content: $aricons-spinner-minus-path1;
    color: rgb(240, 241, 244);
  }
}
.aricons-spinner-minus .path2 {
  &:before {
    content: $aricons-spinner-minus-path2;
    margin-left: -1em;
    color: rgb(173, 177, 180);
  }
}
.aricons-spinner-minus .path3 {
  &:before {
    content: $aricons-spinner-minus-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.aricons-spinner-plus .path1 {
  &:before {
    content: $aricons-spinner-plus-path1;
    color: rgb(240, 241, 244);
  }
}
.aricons-spinner-plus .path2 {
  &:before {
    content: $aricons-spinner-plus-path2;
    margin-left: -1em;
    color: rgb(173, 177, 180);
  }
}
.aricons-spinner-plus .path3 {
  &:before {
    content: $aricons-spinner-plus-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.aricons-number-1 .path1 {
  &:before {
    content: $aricons-number-1-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-number-1 .path2 {
  &:before {
    content: $aricons-number-1-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-2 .path1 {
  &:before {
    content: $aricons-number-2-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-number-2 .path2 {
  &:before {
    content: $aricons-number-2-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-3 .path1 {
  &:before {
    content: $aricons-number-3-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-number-3 .path2 {
  &:before {
    content: $aricons-number-3-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-4 .path1 {
  &:before {
    content: $aricons-number-4-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-number-4 .path2 {
  &:before {
    content: $aricons-number-4-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-5 .path1 {
  &:before {
    content: $aricons-number-5-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-number-5 .path2 {
  &:before {
    content: $aricons-number-5-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-6 .path1 {
  &:before {
    content: $aricons-number-6-path1;
    color: rgb(58, 176, 194);
  }
}
.aricons-number-6 .path2 {
  &:before {
    content: $aricons-number-6-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-7 .path1 {
  &:before {
    content: $aricons-number-7-path1;
    color: rgb(58, 176, 194);
  }
}
.aricons-number-7 .path2 {
  &:before {
    content: $aricons-number-7-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-8 .path1 {
  &:before {
    content: $aricons-number-8-path1;
    color: rgb(58, 176, 194);
  }
}
.aricons-number-8 .path2 {
  &:before {
    content: $aricons-number-8-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-number-9 .path1 {
  &:before {
    content: $aricons-number-9-path1;
    color: rgb(58, 176, 194);
  }
}
.aricons-number-9 .path2 {
  &:before {
    content: $aricons-number-9-path2;
    margin-left: -1em;
    color: rgb(30, 38, 49);
  }
}
.aricons-apply {
  &:before {
    content: $aricons-apply;
  }
}
.aricons-dots .path1 {
  &:before {
    content: $aricons-dots-path1;
    color: rgb(179, 179, 179);
  }
}
.aricons-dots .path2 {
  &:before {
    content: $aricons-dots-path2;
    margin-left: -1.0068359375em;
    color: rgb(179, 179, 179);
  }
}
.aricons-dots .path3 {
  &:before {
    content: $aricons-dots-path3;
    margin-left: -1.0068359375em;
  }
}
.aricons-checkbox .path1 {
  &:before {
    content: $aricons-checkbox-path1;
    color: rgb(59, 175, 193);
  }
}
.aricons-checkbox .path2 {
  &:before {
    content: $aricons-checkbox-path2;
    margin-left: -1em;
    color: rgb(180, 178, 180);
  }
}
.aricons-checkbox2 .path1 {
  &:before {
    content: $aricons-checkbox2-path1;
    color: rgb(180, 178, 180);
  }
}
.aricons-checkbox2 .path2 {
  &:before {
    content: $aricons-checkbox2-path2;
    margin-left: -1em;
    color: rgb(59, 175, 193);
  }
}
.aricons-radiobutton .path1 {
  &:before {
    content: $aricons-radiobutton-path1;
    color: rgb(179, 176, 195);
  }
}
.aricons-radiobutton .path2 {
  &:before {
    content: $aricons-radiobutton-path2;
    margin-left: -1em;
  }
}
.aricons-close-small {
  &:before {
    content: $aricons-close-small;
  }
}
.aricons-close-big .path1 {
  &:before {
    content: $aricons-close-big-path1;
    color: rgb(30, 38, 48);
  }
}
.aricons-close-big .path2 {
  &:before {
    content: $aricons-close-big-path2;
    margin-left: -1em;
    color: rgb(128, 38, 48);
  }
}
.aricons-arrow-big .path1 {
  &:before {
    content: $aricons-arrow-big-path1;
    color: rgb(255, 0, 0);
    opacity: 0.6;
  }
}
.aricons-arrow-big .path2 {
  &:before {
    content: $aricons-arrow-big-path2;
    margin-left: -0.619140625em;
    color: rgb(0, 0, 0);
    opacity: 0.6;
  }
}
.aricons-phone {
  &:before {
    content: $aricons-phone;
  }
}
.aricons-location {
  &:before {
    content: $aricons-location;
  }
}
.aricons-printer {
  &:before {
    content: $aricons-printer;
  }
}
.aricons-information {
  &:before {
    content: $aricons-information;
  }
}
.aricons-remove-bin {
  &:before {
    content: $aricons-remove-bin;
  }
}
.aricons-screen-balloon .path1 {
  &:before {
    content: $aricons-screen-balloon-path1;
    color: rgb(229, 229, 229);
  }
}
.aricons-screen-balloon .path2 {
  &:before {
    content: $aricons-screen-balloon-path2;
    margin-left: -1.083984375em;
    color: rgb(0, 0, 0);
  }
}
.aricons-cellphone-balloon {
  &:before {
    content: $aricons-cellphone-balloon;
  }
}
.aricons-balloon {
  &:before {
    content: $aricons-balloon;
  }
}
.aricons-bag-arnotts {
  &:before {
    content: $aricons-bag-arnotts;
  }
}
.aricons-close-fine {
  &:before {
    content: $aricons-close-fine;
  }
}
.aricons-tiny-arrow-left {
  &:before {
    content: $aricons-tiny-arrow-left;
  }
}
.aricons-tiny-arrow-right {
  &:before {
    content: $aricons-tiny-arrow-right;
  }
}
.aricons-up-button .path1 {
  &:before {
    content: $aricons-up-button-path1;
    color: rgb(9, 125, 130);
  }
}
.aricons-up-button .path2 {
  &:before {
    content: $aricons-up-button-path2;
    margin-left: -1.00390625em;
    color: rgb(119, 125, 130);
  }
}
.aricons-spinner-minus-small .path1 {
  &:before {
    content: $aricons-spinner-minus-small-path1;
    color: rgb(240, 241, 244);
  }
}
.aricons-spinner-minus-small .path2 {
  &:before {
    content: $aricons-spinner-minus-small-path2;
    margin-left: -1em;
    color: rgb(173, 177, 180);
  }
}
.aricons-spinner-minus-small .path3 {
  &:before {
    content: $aricons-spinner-minus-small-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.aricons-spinner-plus-small .path1 {
  &:before {
    content: $aricons-spinner-plus-small-path1;
    color: rgb(240, 241, 244);
  }
}
.aricons-spinner-plus-small .path2 {
  &:before {
    content: $aricons-spinner-plus-small-path2;
    margin-left: -1em;
    color: rgb(173, 177, 180);
  }
}
.aricons-spinner-plus-small .path3 {
  &:before {
    content: $aricons-spinner-plus-small-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.aricons-close-2 .path1 {
  &:before {
    content: $aricons-close-2-path1;
    color: rgb(0, 128, 128);
  }
}
.aricons-close-2 .path2 {
  &:before {
    content: $aricons-close-2-path2;
    margin-left: -1em;
    color: $black01;
  }
}
.aricons-plane {
  &:before {
    content: $aricons-plane;
  }
}
