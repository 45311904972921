@font-face {
    font-family: "iconfont";
    src: url('../fonts/iconfont/iconfont.eot');
    src: url('../fonts/iconfont/iconfont.eot?#iefix') format('eot'),
        url('../fonts/iconfont/iconfont.woff2') format('woff2'),
        url('../fonts/iconfont/iconfont.woff') format('woff'),
        url('../fonts/iconfont/iconfont.ttf') format('truetype'),
        url('../fonts/iconfont/iconfont.svg#iconfont') format('svg');
}

@mixin icon-styles {
    font-family: "iconfont";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
}

%icon {
    @include icon-styles;
}

@function icon-char($filename) {
    $char: "";

    @if $filename == arrow-circle-left-large {
        $char: "\E001";
    }
    @if $filename == arrow-circle-left {
        $char: "\E002";
    }
    @if $filename == arrow-circle-right-large {
        $char: "\E003";
    }
    @if $filename == arrow-circle-right {
        $char: "\E004";
    }
    @if $filename == arrow-left {
        $char: "\E005";
    }
    @if $filename == arrow_bottom {
        $char: "\E006";
    }
    @if $filename == arrow_select {
        $char: "\E007";
    }
    @if $filename == arrow_top {
        $char: "\E008";
    }
    @if $filename == bag {
        $char: "\E009";
    }
    @if $filename == bell {
        $char: "\E00A";
    }
    @if $filename == bt-lock {
        $char: "\E00B";
    }
    @if $filename == bt-phone {
        $char: "\E00C";
    }
    @if $filename == calendar {
        $char: "\E00D";
    }
    @if $filename == chat {
        $char: "\E00E";
    }
    @if $filename == checked {
        $char: "\E00F";
    }
    @if $filename == close-icon {
        $char: "\E010";
    }
    @if $filename == done_black {
        $char: "\E011";
    }
    @if $filename == eye {
        $char: "\E012";
    }
    @if $filename == globe {
        $char: "\E013";
    }
    @if $filename == info {
        $char: "\E014";
    }
    @if $filename == instore {
        $char: "\E015";
    }
    @if $filename == invalid {
        $char: "\E016";
    }
    @if $filename == lock {
        $char: "\E017";
    }
    @if $filename == logo-checkout {
        $char: "\E018";
    }
    @if $filename == minus {
        $char: "\E019";
    }
    @if $filename == outline-info {
        $char: "\E01A";
    }
    @if $filename == plus {
        $char: "\E01B";
    }
    @if $filename == printer {
        $char: "\E01C";
    }
    @if $filename == ruler {
        $char: "\E01D";
    }
    @if $filename == simple-arrow-left {
        $char: "\E01E";
    }
    @if $filename == simple-arrow-right {
        $char: "\E01F";
    }
    @if $filename == sms {
        $char: "\E020";
    }
    @if $filename == tracking {
        $char: "\E021";
    }
    @if $filename == valid {
        $char: "\E022";
    }
    @if $filename == valid_green {
        $char: "\E023";
    }
    @if $filename == warning {
        $char: "\E024";
    }

    @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
    &:#{$insert} {
        @if $extend {
            @extend %icon;
        } @else {
            @include icon-styles;
        }
        content: icon-char($filename);
    }
}

.icon-arrow-circle-left-large {
    @include icon(arrow-circle-left-large);
}
.icon-arrow-circle-left {
    @include icon(arrow-circle-left);
}
.icon-arrow-circle-right-large {
    @include icon(arrow-circle-right-large);
}
.icon-arrow-circle-right {
    @include icon(arrow-circle-right);
}
.icon-arrow-left {
    @include icon(arrow-left);
}
.icon-arrow_bottom {
    @include icon(arrow_bottom);
}
.icon-arrow_select {
    @include icon(arrow_select);
}
.icon-arrow_top {
    @include icon(arrow_top);
}
.icon-bag {
    @include icon(bag);
}
.icon-bell {
    @include icon(bell);
}
.icon-bt-lock {
    @include icon(bt-lock);
}
.icon-bt-phone {
    @include icon(bt-phone);
}
.icon-calendar {
    @include icon(calendar);
}
.icon-chat {
    @include icon(chat);
}
.icon-checked {
    @include icon(checked);
}
.icon-close-icon {
    @include icon(close-icon);
}
.icon-done_black {
    @include icon(done_black);
}
.icon-eye {
    @include icon(eye);
}
.icon-globe {
    @include icon(globe);
}
.icon-info {
    @include icon(info);
}
.icon-instore {
    @include icon(instore);
}
.icon-invalid {
    @include icon(invalid);
}
.icon-lock {
    @include icon(lock);
}
.icon-logo-checkout {
    @include icon(logo-checkout);
}
.icon-minus {
    @include icon(minus);
}
.icon-outline-info {
    @include icon(outline-info);
}
.icon-plus {
    @include icon(plus);
}
.icon-printer {
    @include icon(printer);
}
.icon-ruler {
    @include icon(ruler);
}
.icon-simple-arrow-left {
    @include icon(simple-arrow-left);
}
.icon-simple-arrow-right {
    @include icon(simple-arrow-right);
}
.icon-sms {
    @include icon(sms);
}
.icon-tracking {
    @include icon(tracking);
}
.icon-valid {
    @include icon(valid);
}
.icon-valid_green {
    @include icon(valid_green);
}
.icon-warning {
    @include icon(warning);
}