@charset "UTF-8";

@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon.eot?4r0ucj");
    src: url("../fonts/icomoon.eot?4r0ucj#iefix") format("embedded-opentype"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/icomoon.ttf?4r0ucj") format("truetype"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/icomoon.woff?4r0ucj") format("woff"),
             url("//cdn-media.amplience.com/brown_thomas/fonts/icomoon.svg?4r0ucj#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }


@font-face {
        font-family: 'icomoon-arnotts';
        src: url('../fonts/icomoon-arnotts.eot?grlqom');
        src: url('../fonts/icomoon-arnotts.eot?grlqom#iefix') format('embedded-opentype'),
             url('../fonts/icomoon-arnotts.ttf?grlqom') format('truetype'),
             url('../fonts/icomoon-arnotts.woff?grlqom') format('woff'),
             url('../fonts/icomoon-arnotts.svg?grlqom#icomoon-arnotts') format('svg');
        font-weight: normal;
        font-style: normal;
}


/**
 * @license
 * MyFonts Webfont Build ID 3825148, 2019-10-17T10:21:23-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Nexa-Black by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/black/
 * 
 * Webfont: Nexa-Regular by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/regular/
 * 
 * Webfont: Nexa-XBold by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/xbold/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3825148
 * Licensed pageviews: 1,000,000
 * Webfonts copyright: Copyright \(c\) 2012 by Fontfabric LLC. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a5dfc");


@font-face {
    font-family: 'Nexa-Black';
    src: url('../fonts/nexa-black.eot');
    src: url('../fonts/nexa-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/nexa-black.woff2') format('woff2'),
         url('../fonts/nexa-black.woff') format('woff'),
         url('../fonts/nexa-black.ttf') format('truetype'),
         url('../fonts/nexa-black.svg#wf') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Regular';
    src: url('../fonts/nexa-regular.eot');
    src: url('../fonts/nexa-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/nexa-regular.woff2') format('woff2'),
         url('../fonts/nexa-regular.woff') format('woff'),
         url('../fonts/nexa-regular.ttf') format('truetype'),
         url('../fonts/nexa-regular.svg#wf') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-XBold';
    src: url('../fonts/nexa-bold.eot');
    src: url('../fonts/nexa-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/nexa-bold.woff2') format('woff2'),
         url('../fonts/nexa-bold.woff') format('woff'),
         url('../fonts/nexa-bold.ttf') format('truetype'),
         url('../fonts/nexa-bold.svg#wf') format('svg');
    font-weight: normal;
    font-style: normal;
}



[class^="bticons-"], [class*=" bticons-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.bticons-spin {
    -webkit-animation: bticons-spin 2s infinite linear;
    animation: bticons-spin 2s infinite linear; }

@-webkit-keyframes bticons-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

@keyframes bticons-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg); } }

.bticons-bag:before {
    content: "\e900"; }

.bticons-close:before {
    content: "\e901"; }

.bticons-down:before {
    content: "\e902"; }

.bticons-email:before {
    content: "\e903"; }

.bticons-facebook:before {
    content: "\e904"; }

.bticons-gift:before {
    content: "\e905"; }

.bticons-google:before {
    content: "\e906"; }

.bticons-grid:before {
    content: "\e907"; }

.bticons-heart-filled:before {
    content: "\e908"; }

.bticons-heart-outline:before {
    content: "\e909"; }

.bticons-instagram:before {
    content: "\e90a"; }

.bticons-left-small:before {
    content: "\e90b"; }

.bticons-left:before {
    content: "\e90c"; }

.bticons-loading:before {
    content: "\e90d"; }

.bticons-location:before {
    content: "\e90e"; }

.bticons-minus:before {
    content: "\e90f"; }

.bticons-pagination:before {
    content: "\e910"; }

.bticons-pinterest:before {
    content: "\e911"; }

.bticons-plus:before {
    content: "\e912"; }

.bticons-question:before {
    content: "\e913"; }

.bticons-right-small:before {
    content: "\e914"; }

.bticons-right:before {
    content: "\e915"; }

.bticons-search-small:before {
    content: "\e916"; }

.bticons-search:before {
    content: "\e917"; }

.bticons-share:before {
    content: "\e918"; }

.bticons-single:before {
    content: "\e919"; }

.bticons-star-filled:before {
    content: "\e91a"; }

.bticons-star-half:before {
    content: "\e91b"; }

.bticons-star-outline:before {
    content: "\e91c"; }

.bticons-twitter:before {
    content: "\e91d"; }

.bticons-up:before {
    content: "\e91e"; }

.bticons-user:before {
    content: "\e91f"; }

.bticons-youtube:before {
    content: "\e920"; }

.bticons-zoom-2:before {
    content: "\e921"; }

.bticons-zoom:before {
    content: "\e922"; }

.bticons-logo:before {
    content: "\e923"; }

/*# sourceMappingURL=fonts.css.map */


// redirect old classes to new font-icon
.bticons-right,
.bticons-left,
.bticons-heart-outline,
.bticons-heart-filled,
.bticons-plus,
.bticons-minus,
.bticons-heart-outline,
.bticons-zoom
 {
    font-family: "icomoon-arnotts" !important;
}