// STYLES FOR VIRTUAL TRY ON

.try-on-badge {
    font-family: $font-main-bold;
    border: 1px solid $black01;
    border-radius: 20px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        background: url("../images/try-it-on.svg") center / cover no-repeat;
    }

    .carousel & {
        display: none;
    }

    // TRY IT ON PLP
    .pt_product-search-result & {
        padding: 5px 9px 5px 25px;
        margin: 0 auto 10px;
        font-size: rem(8);

        &::before {
            top: 5px;
            left: 7px;
            width: 14px;
            height: 12px;
        }
    }

    // TRY IT ON PDP
    .product-col-mobile &,
    .product-primary-image & {
        padding: 12px 21px 11px 45px;
        margin: 30px auto 0;
        font-size: rem(12);

        @include respond-to(mobile) {
            margin: 25px auto 20px;
        }

        &::before {
            top: 12px;
            left: 16px;
            width: 21px;
            height: 18px;
        }
    }

    .product-primary-image & { // Hide duplicated badge under image for mobile
        @include respond-to(mobile) {
            display: none;
        }
    }
}

.try-on-wrapper {
    position: absolute;
    top: 0;
    z-index: 21;
    background: transparent linear-gradient(90deg, #151515 0%, #626262 100%) 0% 0% no-repeat;
    overflow: hidden;

    @include respond-to(tablet_plus) {
        width: 450px;
        left: 65px;
        height: 450px;
    }

    &.try-on-thumbnails {
        @include respond-to(tablet_plus) {
            left: 165px;
            width: 409px;
            height: 409px;
        }

        @include respond-to(mobile_to_desktop) {
            left: 115px;
        }

        .product-detail-dior & {
            @include respond-to(touch_over) {
                top: 161px; // Calc of brand banner
            }
    
            @include respond-to(tablet_ext) {
                top: 141px;
            }
        }

        .product-detail-chanel & {
            @include respond-to(touch_over) {
                top: 131px; // Calc of brand banner
            }
    
            @include respond-to(tablet_ext) {
                top: 116px;
            }
        }
    }

    .product-detail-dior & {
        @include respond-to(touch_over) {
            top: 165px; // Calc of brand banner
        }

        @include respond-to(tablet_ext) {
            top: 145px;
        }
    }

    .product-detail-chanel & {
        @include respond-to(touch_over) {
            top: 135px; // Calc of brand banner
        }

        @include respond-to(tablet_ext) {
            top: 120px;
        }
    }

    .memomi-iframe {
        width: 133.333%;
        height: 100%;
        border: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include respond-to(m_tablet_down) {
            width: calc((100vh - 100px) * 1.33);
        }
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background: $black01;
        border-radius: 50%;
        border: 4px solid $black01;
        overflow: hidden;
        width: 30px;
        height: 30px;

        &::before {
            font-family: "icomoon-arnotts";
            content: "\e95b";
            font-size: rem(20);
            color: $white;
            font-weight: 800;
            position: relative;
            top: 1px;
            left: 1px;
        }
    }

    .vto-opened & {
        @include respond-to(mobile) {
            width: 100%;
            height: calc((100vh - calc(100vh - 100%)) - 220px); // Fix for IOS Viewport height
            position: fixed;
            left: 0;
            right: 0;
        }
    }
}

.vto-label,
.vto-value {
    display: none;
}

// POPUP VIRTUAL TRY ON PDP
.vto-opened {
    @include respond-to(mobile) { // Hide unnecessary elements to imitate Popup
        .footer,
        .header-cookies,
        .header-banner,
        .breadcrumb,
        .btn-close-category-menu,
        .menu-toggle,
        .primary-logo,
        .container-searchbox,
        .containter-cart-wish .header-wishlist,
        #navigation,
        .product-row-brand-logo,
        .product-info,
        #product-nav-container,
        .product-col-mobile,
        .pdp-main .pdp-slot-recommendation,
        .pdp-main .pdp-recommendations,
        .product-recommendations,
        .product-description,
        .product-shop-all,
        .pdp-about-brand,
        .pdp-main .product-col-1,
        .toggle,
        .promotion,
        .inventory,
        .product-delivery-details-message,
        .prod-feedback-txt,
        .product-chanel-title,
        .chanel-collection,
        .product-name,
        .price-switcher,
        .product-bundle-list,
        .item-stock-info,
        .product-outofstock,
        .applepay-button {
            display: none;
        }

        [data-slot-id="header-slider-panel"] { // Avoid recounting js styles after vto closed
            height: 0;
            overflow: hidden;
        }

        .mini-cart-total {
            height: 0;
            width: 0;
            overflow: hidden;
        }

        #wrapper.mini-cart-active {
            overflow: visible; // Fix for show mini cart content
        }

        #header-wrapper {
            min-height: 0;
        }

        .top-banner {
            height: 0;
        }

        #sf-widget-companion-wrapper,
        .promo-flag,
        .menu-utility-user {
            display: none !important;
        }

        .error-quantity {
            margin: -25px 0 0;
            font-size: rem(12);
        }
    }

    .product-primary-image,
    .pl-trustmark {
        display: none !important;
    }

    #product-content-wrapper {
        @include respond-to(mobile) {
            position: fixed;
            bottom: 25px;
            left: 0;
            right: 0;
            padding: 0;
        }
    }

    .product-variations {
        @include respond-to(mobile) {
            text-align: left;
            font-size: rem(14);
            margin: 0 0 20px 15px;

            .label,
            .vto-label,
            .vto-value {
                display: inline-block;
            }
        }

        .variation-container {
            @include respond-to(mobile) {
                margin-bottom: 15px;
            }
        }
    }

    .available-delivery-msg {
        @include respond-to(mobile) {
            margin: 0 !important;
        }
    }

    .delivery-not-available .product-variations {
        @include respond-to(mobile) {
            margin-bottom: 15px;
        }
    }

    .pdp-main {
        position: relative;

        button.applepay-button {
            @include respond-to(mobile) {
                margin: 15px 0 0 !important;
            }
        }
    }

    .add-to-cart,
    .add-to-cart-disabled {
        border-radius: 25px;
    }

    .pdpForm {
        @include respond-to(mobile) {
            padding: 0 15px;
            display: block !important;
        }

        .add-to-cart,
        .add-to-cart-disabled {
            @include respond-to(mobile) {
                margin: 0;
            }
        }
    }
}

body.vto-opened { // Fix for show mini cart content
    @include respond-to(mobile) {
        overflow: visible;
    }
}