.main-promo-content-wrapper {
    .slot-asset-item {
        height: 100%;
    }
}

.nav-banner {
    position: relative;
    overflow: hidden;
    height: 100%;

    img {
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 0;
        min-width: 100%;
    }

    .badge {
        @include shared-badge;

        font-size: rem(18);
        left: -50px;
        top: 40px;
        width: 200px;
    }

    .content-block {
        padding: 35px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-family: $font-main-bold;
    }

    &-top-title {
        display: block;
        margin-bottom: 15px;
    }

    &-title {
        margin-bottom: 5px;
        font-size: rem(22);
    }

    &-description {
        margin-bottom: 5px;
    }

    &-link {
        //additional classes to cover styles from refapp
        .menu-category & {
            width: auto;
        }

        .level-1 > li & {
            line-height: 1;
        }
    }
}