.ui-dialog-content {
    .product-col-1 {
        padding: 0px 15px;
        width: 48%;

        @include respond-to(mobile_strong) {
            float: none;
            width: 100%;
        }

        .product-image {
            max-width: 100%;
        }
    }

    .product-col-2 {
        padding: 0px 15px;
        margin-left: 0;
        width: 52%;

        @include respond-to(mobile_strong) {
            float: none;
            width: 100%;
        }
    }

    .pdp-main {
        .product-set-list {
            height: 500px;
            overflow: auto;
        }
    }

    .pdp-alternative {
        .product-socials {
            display: none;
        }

        .product-price {
            margin-bottom: 10px;
        }

        .product-accordion {
            margin-top: 20px;
        }
    }
}

.zoomPad {
    position: relative;
    float: left;
    z-index: 99;
    cursor: crosshair;
    width: 100%;
}

.zoomPreload {
    -moz-opacity: 0.8;
    opacity: 0.8;
    filter: alpha(opacity = 80);
    color: $night-rider;
    font-size: rem(12);
    font-family: $sans-serif;
    text-decoration: none;
    border: 1px solid $very-light-gray;
    background-color: $white;
    padding: 8px;
    text-align: center;
    background-image: url(../images/zoomloader.gif);
    background-repeat: no-repeat;
    background-position: 43px 30px;
    z-index: 110;
    width: 90px;
    height: 43px;
    position: absolute;
    top: 0px;
    left: 0px;
    *width: 100px;
    *height: 49px;
}

.zoomPup {
    overflow: hidden;
    background-color: $white;
    -moz-opacity: 0.6;
    opacity: 0.6;
    filter: alpha(opacity = 60);
    z-index: 120;
    position: absolute;
    border: 1px solid $very-light-gray;
    z-index: 101;
    cursor: crosshair;
}

.zoomOverlay {
    position: absolute;
    left: 0px;
    top: 0px;
    background: $white;
    z-index: 5000;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 101;
}

.zoomWindow {
    position: absolute;
    left: 110%;
    top: 40px;
    background: $white;
    z-index: 6000;
    height: auto;
    z-index: 10000;
    z-index: 110;
}

.zoomWrapper {
    position: relative;
    border: 1px solid $nobel;
    z-index: 110;
}

.zoomWrapperTitle {
    display: block;
    background: $nobel;
    color: $white;
    height: 18px;
    line-height: 18px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: rem(10);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 120;
    -moz-opacity: 0.6;
    opacity: 0.6;
    filter: alpha(opacity = 60);
}

.zoomWrapperImage {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 110;

    img {
        border: 0px;
        display: block;
        position: absolute;
        z-index: 101;
    }
}

.zoomIframe {
    z-index: -1;
    filter: alpha(opacity = 0);
    -moz-opacity: 0.80;
    opacity: 0.80;
    position: absolute;
    display: block;
}

.quickview-nav {
    @include respond-to(mobile_strong) {
        display: none;
    }
    
    .quickview-next {
        float: right;
    }
}

.quickview-actions {
    display: none;
}

.dialog-quickview {
    .ui-dialog-titlebar .ui-dialog-title {
        display: none;
    }

    .product-image {
        display: block;
    }

    .pdp-main.pdp-alternative.pdp-vip {
        .product-col-1 {
            @include respond-to(touch) {
                width: 48%;
                float: left;
            }
        }

        .product-name,
        .product-price {
            @include respond-to(mobile) {
                display: block;
            }
        }

        .product-name,
        .price-switcher,
        .product-variations,
        .prod-feedback-txt {
            @include respond-to(mobile) {
                float: right;
                width: 46%;
                clear: right;
            }
        }
    }

    .pdp-vip .product-alt-mobile {
        @include respond-to(mobile) {
            display: none;
        }
    }

    .product-name {
        margin: 0;
        padding: 18px 0 16px;
        font-size: rem(18);
        text-align: left;

        &:after {
            display: none;
        }

        &-brand {
            font-size: rem(23);
            margin-bottom: 5px;
            color: $nobel;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: block;
        }

        &-title {
            display: block;
            text-transform: capitalize;
            font-size: rem(18);
            margin-bottom: 6px;
            letter-spacing: 1px;
        }
    }

    .price-switcher {
        @include respond-to(mobile) {
            display: block;
        }
    }

    .pdp-main .product-primary-image {
        display: block;
        margin: 0 auto 25px;
    }

    .pdp-main .product-add-to-cart .inventory {
        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    .product-shop-all {
        margin: 40px 0 0;
    }

    @include respond-to(touch) {
        .pdp-main,
        .pdp-main.pdp-alternative.pdp-vip {
            margin: 0;
        }

        .product-shop-all,
        .product-col-mobile {
            display: none;
        }

        .product-col-2 {
            float: right;
            padding-right: 5%;
            width: 52%;
            display: block;
            text-align: left;
        }
    }

    .product-price {
        .price-sales {
            font-size: rem(21);
            margin: 0;
        }
        .price-standard {
            color: $charcoal;
            font-size: rem(13);
            text-transform: uppercase;

            .price-standard-qq-coment {
                display: inline-block;
            }
        }
    }

    .product-variations {
        @include respond-to(touch) {
            .label {
                text-align: left;
            }

            .value {
                margin: 0;

                .swatches {
                    text-align: left;

                    li:first-child {
                        margin-left: 0;
                    }
                }
            }

            .prod-feedback-txt {
                text-align: left;
            }
        }

        > ul {
            margin-bottom: 0;
        }

        .label {
            font-size: rem(16);
            cursor: pointer;
            display: block;
            line-height: 1.5;
            margin-bottom: 4px;
            text-transform: capitalize;
        }

        .swatches {
            .selected-value {
                display: none;
            }

            .selectable,
            .unselectable {
                margin-right: 18px;

                @include respond-to(mobile) {
                    margin-left: 0;
                }
            }

            .selectable {
                a {
                    cursor: pointer;
                    height: 36px;
                    display: block;
                    margin: 0;
                    padding: 0;
                    border:0;
                    font-size: 0;
                }
            }

            &.size {
                .selectable a,
                .swatchanchor {
                    font-size: rem(16);
                    line-height: 36px;
                    text-align: center;
                }
            }
        }
    }

    .quick-view-description {
        &-link {
            text-transform: uppercase;
            font-size: rem(13);
            display: block;
            text-decoration: underline;
            margin-top: 29px;
            letter-spacing: 1px;

            &:hover {
                color: $dim-gray;
            }
        }
    }

    .quickview-nav {
        clear: both;
    }

    .quickview-prev,
    .quickview-next {
        color: $hel-gray;
        background-color: transparent;
        letter-spacing: 0;
        margin-top: 50px;

        @include respond-to(touch) {
            width: auto;
        }
    }

    .bticons-left {
        padding-right: 14px;
        font-size: rem(16);
    }

    .bticons-right {
        padding-left: 14px;
        font-size: rem(16);
    }

    .quickview-actions {
        @include respond-to(m_tablet_down) {
            clear: both;
            display: block;
            overflow: hidden;

            .add-to-cart,
            .whishlist_instore-actions {
                margin-bottom: 20px;
            }

            .quick-view-description-link {
                margin-top: 0;
                text-align: center;
                display: block;
            }
        }
    }
}

/* Chanel quickview */

#QuickViewDialog {
    &.ui-dialog-content {
        .chanel-banner {
            margin-top: 25px;
        }

        .product-chanel-title {
            padding-bottom: 0;
            margin-bottom: 0;

            &:after {
                display: none;
            }
        }

        .pdp-main.product-detail-chanel {
            .product-detail,
            .product-chanel-title {
                @include respond-to(mobile_strong_over) {
                    text-align: left;
                }

                @include respond-to(m_mobile_down) {
                    text-align: center;
                }
            }

            .product-name {
                padding-top: 0;
            }

            .product-name-title {
                font-size: rem(14);
            }
        }

        .inventory + .prod-feedback-txt {
            @include respond-to(touch) {
                margin-top: 10px;
            }
        }

        .is-product-set .quick-view-description-link {
            margin: 10px 0 35px;
        }

        @include respond-to(mobile_strong) {
            padding: 10px 15px 80px;
            margin-top: -30px;

            .product-col-1 {
                padding: 0;
            }

            .product-col-2 {
                margin-top: 15px;

                .product-name {
                    padding: 0 15px 0;

                    .product-name-brand {
                        font-size: 100%;
                    }

                    .product-name-title {
                        font-size: 100%;
                        line-height: 1;
                    }
                }

                .product-sales-price {
                    font-size: 100%;
                    line-height: 1;
                }

                .product-variations .attribute .value {
                    width: 80%;
                    margin: 0 auto;
                }

                .product-add-to-cart {
                    display: none;
                }
            }

            .product_actions-wishlist_link {
                &:hover {
                    background: $white;
                    color: $black01;
                }
            }

            .bticons-heart-outline::before,
            .bticons-heart-filled::before {
                font-size: rem(18);
            }
        }
    }
}
