#wrapper {
    margin: auto;

    &.pt_storefront,
    &.pt_order-confirmation {
        position: relative;
        width: 100%;
    }

    &.pt_categorylanding {
        width: 100%;

        .primary-content {
            padding-bottom: 0;

            @include respond-to(tablet_plus) {
                width: 80%;
            }
        }

        .refinements {
            border-right: 0;
            display: none;
            width: 0;

            @include respond-to(tablet_plus) {
                display: block;
                width: 20%;
            }
        }
    }

    @include respond-to(touch) {
        &.pt_order-confirmation ~ .footer-checkout {
            position: relative;
        }

        #main,
        .top-banner,
        .footer,
        &.pt_order-confirmation ~ .footer-checkout,
        .header-banner {
            transition: transform 500ms ease; // initialize 3d transforms animation
        }
    }

    &.menu-active {
        position: relative;

        @include respond-to(mobile) {
            &.pt_order-confirmation ~ .footer-checkout {
                visibility: hidden;
                z-index: -1;
                opacity: 0;
            }

            #main,
            .top-banner,
            .footer,
            &.pt_order-confirmation ~ .footer-checkout,
            .header-banner {
                transform: translateX(66%); // slide right
            }
        }

        .is-sticky nav[role="navigation"] {
            top: 0;
            background: $white;

            &:before {
                display: block;
                content: "\e901";
                width: 100%;
                height: 6rem;
                position: absolute;
                top: 0;
                right: -100%;
                padding: 1.8rem;
                z-index: 9999;
                font-size: rem(18);
                color: $white;
                background-color: $nobel;
                cursor: pointer;
                @include font-icomoon;
            }
        }
    }

    &.mini-cart-active {

        @include respond-to(touch) {
            &.pt_order-confirmation ~ .footer-checkout {
                visibility: hidden;
                z-index: -1;
                opacity: 0;
            }

            #main,
            .top-banner,
            .footer,
            .header-banner {
                transform: translateX(-$minicart-mobile-width); // slide left
            }

            .ios & .footer { // this is iOS specisif issue - avoid blinking of  fixed footer on page on cart open (iPad mostl visible)
                transform: none;
            }
        }

        @include respond-to(tablet_regular) {
            #main {
                max-width: 1024px;
            }
        }

        .is-sticky {
            .mini-cart-content {
                position: fixed;
            }

            .mini-cart-inner {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                overflow-x: hidden;
            }
        }
    }

    @include respond-to(tablet_plus) {
        &.menu-active {
            left: auto;
        }
    }
}

.mini-cart-content {
    margin: 0;

    &.pt_storefront,
    &.pt_order-confirmation {
        position: relative;
        width: 100%;
    }

    @include respond-to(touch_over) {
        .no-desktop &.mini-cart-active {
            margin: auto;
            left: auto;
        }
    }
}

#main,
.main,
.main-hp {
    @extend %clearfix;
    margin-left: auto;
    margin-right: auto;
    max-width: 1260px;
    padding-left: 15px;
    padding-right: 15px;
}

.primary-content {
    @extend %clearfix;
    width: 100%;

    @include respond-to(tablet_plus) {
        float: right;
        width: 75%;
    }

    .primary-focus & {
        float: left;
    }

    .full-width & {
        width: 100%;
    }
}

.page-content {
    .pt_order & {
        @include respond-to(mobile) {
            @include flexbox();
            @include flex-vertical();
        }
    }
}

#primary {
    .pt_order & {
        @include respond-to(mobile) {
            order: 2;
        }
    }
}

#secondary {
    nav {
        a {
            padding-left: 10px;
        }
    }

    .secondary-navigation.content-nav {
        li a {
            padding-left: 10px;
        }
    }

    @include respond-to(tablet_plus) {
        float: left;
        width: 22%;

        nav {
            padding-left: 10px;
            padding-right: 10px;

            a {
                display: block;
            }

            ul {
                margin-top: 0;
            }
        }

        .primary-focus & {
            float: right;
        }
    }
    // Remove Refinements for responsive design
    .pt_content-search-result & {
        display: none;

        @include respond-to(tablet_plus) {
            display: block;
        }
    }

    .pt_order & {
        @include respond-to(mobile) {
            order: 1;

            nav {
                width: 100%;
                padding: 10px 15px;
            }
        }
    }
}

.secondary-content {
    clear: both;
}

/*checkout*/
.pt_checkout {
    .header-banner,
    .menu-toggle,
    nav[role="navigation"],
    #mini-cart,
    .header-wishlist,
    .header-search-icon,
    .header-search {
        display: none !important;
    }

    .top-banner {
        height: 4.6rem;
        border-bottom: 1px solid $very-light-gray;

        @include respond-to(tablet_plus) {
            height: 9.6rem;
        }
    }

    .sticky-wrapper {
        &.is-sticky {
            position: static;

            .top-banner {
                position: static !important;
                height: 9.6rem;
                border-bottom: 1px solid $very-light-gray;
                background-color: $white;
            }
        }
    }

    #main {
        padding-bottom: 65px;
    }

    .primary-content {
        width: 68%;

        @include respond-to(mobile) {
            margin: 2rem 0 4rem;
            float: none;
            width: auto;
        }
    }

    #secondary {
        width: 27%;

        @include respond-to(mobile) {
            float: none;
            width: auto;
        }
    }
}

body {
    .ui-dialog {
        .ui-widget-header {
            @extend %clearfix;

            #cart-page & {
                height: auto;
            }
        }

        .ui-dialog-titlebar {
            text-align: center;
        }

        h1,
        .ui-dialog-title {
            position: relative;
            display: block;
            margin: 10px auto;
            text-overflow: clip;
            white-space: normal;

            @include respond-to(mobile) {
                margin: 5px auto;
                word-wrap: break-word;
            }

            &:after {
                content: '';
                position: absolute;
                left: 25%;
                bottom: 0px;
                width: 50%;
                border-bottom: 1px solid $nobel;
            }

            #cart-page & {
                overflow: visible;
            }
        }

        h1 {
            font-size: rem(24);
            line-height: 1.2;
            text-align: center;
            padding-bottom: 6px;

            @include respond-to(mobile) {
                font-size: rem(18);
            }
        }

        /* Testimonials popup */
        &.dialog-testimonials {
            width: auto !important; //to overwrite js styling

            .ui-dialog-title {
                display: none;
            }
        }

        .notify-container {
            margin-top: 0;
        }

        .ui-dialog-title {
            width: 100%;
            float: none;
            font-family: $font-main;
            color: $night-rider;
            padding-left: calc(15% - 11px);
            padding-right: calc(15% - 11px);

            @include respond-to(mobile) {
                font-size: rem(20);
            }

            @include respond-to(tablet_plus) {
                font-size: rem(24);
            }
        }

        .dialog-content {
            @include respond-to(tablet_plus) {
                padding: 0 15% 20px 15%;
            }
        }

        // Mobile QuickView for Magazine
        &.dialog-quickview {
            @include respond-to(mobile_strong) {
                max-width: 100%;
                border: 0;

                .pdp-main {
                    .product-primary-image {
                        width: 80%;
                    }
                }

                .product-col-1 {
                    float: none;
                    width: auto;

                    .product-image {
                        &.main-video {
                            margin-top: 0;
                        }
                    }
                }

                .product-col-2 {
                    float: none;
                    width: auto;
                    padding-right: 0;
                    padding-left: 0;
                    text-align: center;
                }

                .product-name-brand {
                    font-size: rem(16);
                    line-height: 1;
                    margin-bottom: 0;
                }

                .product-name {
                    text-align: center;
                    font-size: rem(16);
                    line-height: 1;
                    padding-bottom: 0;
                }

                .product-name-title {
                    margin-bottom: 0;
                }

                .price-switcher {
                    margin-bottom: 15px;
                }

                .product-price {
                    font-size: rem(16);

                    .price-sales {
                        font-size: rem(16);
                    }
                }

                .product-variations,
                .product-options {
                    .attribute .value {
                        width: auto;
                    }
                }

                .product-variations {
                    .label {
                        text-align: center;
                    }

                    .value .swatches {
                        text-align: center;
                        float: none;

                        > li {
                            &:only-child {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .quickview-actions {
                    button {
                        width: 80%;
                        float: left;
                    }

                    .whishlist_instore-actions {
                        width: 20%;
                        float: left;
                    }

                    .quick-view-description-link {
                        clear: both;
                        text-transform: capitalize;
                        font-size: rem(16);
                        letter-spacing: 0;
                    }
                }

            .ui-dialog-titlebar {
                position: static;
            }

            .ui-dialog-titlebar-close {
                right: auto;
                top: auto;
                bottom: 20px;
                left: 50%;
                width: 40px;
                height: 40px;
                transform: translateX(-50%);

                &::before {
                    font-size: rem(28);
                    line-height: 40px;
                }
            }

            .ui-dialog-content {
                padding-bottom: 80px;
            }

            }//@include respond-to(mobile)
        }//.dialog-quickview
    }
}

.no-desktop {
    #wrapper {
        width: 100%; // fix issues when wrapper becomes fixed for wrapper no-scroll effect when bugreg menu is opened

        &:before {
            content: "";
            position: fixed;
            z-index: -1;
            top: 60px;
            bottom: 0;
            left: 0;
            right: 0;
            background: $black01;
            opacity: 0;
            transition: transform 500ms ease;
        }

        &.mini-cart-active,
        &.menu-active {
            &:before {
                z-index: 9997;
                opacity: 0.3;
            }
        }

        &.menu-active {
            &:before {
                transform: translateX(66%);
            }
        }

        &.mini-cart-active {
            @include respond-to(tablet_regular) {
                position: relative;

                .header-banner {
                    display: none;
                }

                .primary-logo {
                    padding: 58px 0 30px;
                    margin: 0 auto;
                }

                .is-sticky  {
                    .primary-logo {
                        padding: 0;
                        margin: 0 auto;
                    }
                }
            }

            &:before {
                transform: translateX(-$minicart-mobile-width);

                @include respond-to(tablet_plus) {
                    transform: translateX(-$minicart-mobile-width);
                }
            }

            .header-search {
                display: none;
            }
        }
    }

    .mini-cart-active .js-close-minicart {
        top: -$header-banner-height; //height of ".header-banner"
        z-index: 9999;
        opacity: 1;
        transform: translateX(-$minicart-mobile-width);
        transition: transform 500ms ease;

        @include respond-to(mobile_strong_over) {
            top: -$header-banner-height*2;
        }

        &:before {
            content: "\e901";
            position: absolute;
            z-index: 2;
            height: 60px;
            top: 0;
            left: 0;
            right: 0;
            padding: 1.8rem;
            padding-left: calc(100% - 5.2rem);
            @include font-icomoon;
            font-size: rem(18);
            text-align: right;
            color: $white;
            background-color: $nobel;
            cursor: pointer;

            &:hover {
                color: $very-light-gray;
            }
        }
    }

    .mini-cart-content {
        position: fixed;
        display: block;
        left: 100%;
        top: -$header-banner-height; //height of ".header-banner"
        bottom: 0;
        right: 0;
        z-index: 10;
        overflow-y: auto;

        @include respond-to(touch_over) {
            margin-top: -30px;
        }

        @include respond-to(tablet_regular) {
            top: 0 !important;
        }

        .jcarousel-wrapper.jcarousel-wrapper-vertical { // what is this?...
            margin: 0;
        }
    }

    .is-sticky {
        .mini-cart-content,
        .js-close-minicart {
            top: 0 !important;
        }
    }
}

.noscroll {
    position: fixed;
    overflow: hidden;

    @include respond-to(tablet_regular) {
        max-width: 100%;
    }
}

#js-wish-list-switch,
#js-orderinfo-switch,
#js-my-account-switch {
    display: none;
}