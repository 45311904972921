.product-details__info {
    position: relative;
    border: 1px solid $very-light-grey;
    margin-bottom: 50px;

    @include respond-to(mobile_strong) {
        border-right: none;
        border-left: none;
        margin: 0 -15px 50px;
    }

    /* Social icons */
    .socialsharing {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        z-index: 1;
        background-color: $white;
        margin: 0;
        padding-top: 0;

        .aricons-social-facebook-fill,
        .aricons-social-twitter-fill,
        .aricons-social-email-fill { // Fix cropped icon border (mobile real device)
            border: 1px solid $dim-gray;
            border-radius: 50%;
        }

        .aricons-social-facebook-fill .path2::before,
        .aricons-social-twitter-fill .path1::before,
        .aricons-social-email-fill .path1::before { // Fix cropped icon border (mobile real device)
            display: none;
        }

        .aricons-social-twitter-fill .path2::before,
        .aricons-social-email-fill .path2::before { // Fix cropped icon border (mobile real device)
            margin: 0;
        }

        .share-icon {
            line-height: 1;
            margin: 0;

            &:hover {
                .aricons-social-facebook-fill,
                .aricons-social-twitter-fill,
                .aricons-social-email-fill {
                    border-color: $black01;
                }
            }

            .path1,
            .path2 {
                &::before {
                    transition: all .3s;
                }
            }

            &:hover {
                .aricons-social-facebook-fill {
                    .path1 {
                        &::before {
                            color: $color-primary2;
                        }
                    }

                    .path2 {
                        &::before {
                            opacity: 1;
                        }
                    }
                }

                .aricons-social-twitter-fill {
                    .path1 {
                        &::before {
                            opacity: 1;
                        }
                    }

                    .path2 {
                        &::before {
                            color: $color-primary2;
                        }
                    }
                }

                .aricons-social-pinterest-fill {
                    .path1 {
                        &::before {
                            opacity: 1;
                        }
                    }

                    .path2 {
                        &::before {
                            color: $color-primary2;
                        }
                    }
                }

                .aricons-social-instagram-fill {
                    .path1 {
                        &::before {
                            opacity: 1;
                        }
                    }

                    .path2 {
                        &::before {
                            color: $color-primary2;
                        }
                    }
                }

                .aricons-social-email-fill {
                    .path1 {
                        &::before {
                            opacity: 1;
                        }
                    }

                    .path2 {
                        &::before {
                            color: $color-primary2;
                        }
                    }
                }
            }
        }

        .share-icon--facebook {
            @include inline-svg-mono($facebook, $nobel);

            &:hover {
                @include inline-svg-mono($facebook, $medium-blue);
            }
        }

        .share-icon--twitter {
            @include inline-svg-mono($twitter, $nobel);

            &:hover {
                @include inline-svg-mono($twitter, $medium-blue);
            }
        }

        .share-icon--whatsapp {
            @include inline-svg-mono($whatsApp, $medium-blue);

            &:hover {
                @include inline-svg-mono($whatsApp, $medium-blue);
            }
        }

        .share-icon--pinterest {
            @include inline-svg-mono($pinterest, $nobel);

            &:hover {
                @include inline-svg-mono($pinterest, $medium-blue);
            }
        }

        .share-icon--email {
            @include inline-svg-mono($email, $nobel);

            &:hover {
                @include inline-svg-mono($email, $medium-blue);
            }
        }

        .share-icon--cancel {
            @include inline-svg-mono($cancel, $nobel);

            display: block;
            width: 20px;
            height: 20px;
            margin: 0 auto 10px;
        }

        .socialsharing__cancel {
            text-align: center;
            font-size: rem(12);
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    .product-share-block {
        width: 80%;
        position: relative;

        @include respond-to(mobile) {
            padding-top: 0;
        }
    }

    .toggle-content {
        display: flex;
        justify-content: space-evenly;
        position: static;
        margin: 0;
        padding-top: 3px;
        font-size: rem(32);
    }

    .toggle {
        display: none;
    }
}

.product-details__info-list {
    display: flex;
}

.product-details__info-item {
    height: 73px;
    text-align: center;
    font-size: rem(12);
    color: $black01;
    text-transform: capitalize;
    border-right: 1px solid $very-light-grey;
    padding: 15px 0;
    width: 100%; // Fixed flex item width evently

    &:last-child {
        border-right: none;
    }
}

.product-details__info-item--share {
    cursor: pointer;

    &:hover .product-details__info-icon--share {
        @include inline-svg-mono($share, $medium-blue);
    }
}

.product-details__info-icon {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 auto 10px;
}

.product-details__info-icon--exclamation {
    @include inline-svg-mono($exclamation, $red01);
}

.product-details__info-icon--tick {
    @include inline-svg-mono($tick, $islamic-green);
    
    display: block;
    margin: 5px auto;
}

.product-details__info-icon--share {
    @include inline-svg-mono($share, $nobel);
}

.product-details__info-points {
    min-height: 20px;
    display: block;
    font-size: rem(14);
    font-family: $font-main-black;
    margin-bottom: 10px;
}