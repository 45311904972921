
/* Password reset page */
.password-success {
    text-align: center;
    
    .error-page-footer-slot .cat-blocks-carousel {
        background: $white;
    }
}

.cta-link {
    color: inherit;
    text-decoration: underline;
}

.my-account {
    &-title {
        margin: 0 0 20px;
        padding: 0;

        .pt_account & {
            display: none;
        }
    }
}

.login-box {
    h3 {
        margin: 0;
    }

    p {
        margin: 1em 0;
    }

    .spacy-copy {
        min-height: 0;
    }

    .error {
        margin-left: 0;
    }

    .my-account-subtitle {
        font-size: rem(20);
        text-transform: none;
    }

    .order-track-title {
        font-size: rem(18);
    }

    &.login-account,
    &.login-order-track {
        padding-right: 50px;
        margin-bottom: 50px;

        @include respond-to(mobile) {
            padding-right: 0;
        }
    }

    &.login-account {
        .pt_account & {
            @include shared-checkout-sidebar-decoration;
        }

        .form-row-button {
            button {
                @extend button.coloured;
            }
        }
    }

    &.login-order-track {
        @include hidden;

        .field-wrapper {
            .input-text {
                + .error {
                    position: relative;
                }
            }
        }
    }

    &-content {
        border-top: 0;
        margin-top: 12px;
        padding-top: 10px;
    }

    &.login-create-account {
        padding: 0 0 0 25px;
        margin-bottom: 50px;

        @include respond-to(mobile) {
            margin-top: 30px;
        }

        @include respond-to(touch) {
            padding: 0;
        }
    }

    &.login-account,
    &.login-order-track {
        padding-right: 25px;

        @include respond-to(touch) {
            padding-right: 0;
        }
    }

    &.login-create-account,
    &.login-order-track {
        .form-row-button {
            button {
                @include button-type(1);
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }

    &.login-account,
    &.login-create-account,
    &.login-order-track {
        .pt_account_inner & {
            form,
            p {
                @include respond-to(touch_over) {
                    max-width: 350px;
                }
            }
        }

        button {
            @include respond-to(desktop_plus) {
                max-width: 350px;
                width: 100%;
            }
        }
    }

    .login-create-account-wrap,
    .returning-customers-title {
        @include hidden;
    }
}

.login-rememberme {
    margin-top: 20px;
    margin-left: 0;
}

.login-oauth p:first-child {
    font-size: 2em;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
}

#OAuthProvider {
    display: block;
    margin-bottom: 1em;
    width: 80%;
}

.pt_account_unregistered {
    @include shared-login-page-layout;
}

/* Subscribe pages */
.newslettersubscribe {
    .breadcrumb,
    .sign-up-email {
        display: none;
    }

    #main {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.newslettersubscribe-inner {
    position: relative;
    min-height: 1000px;
    height: calc(100vh - 222px);

    @include respond-to(mobile) {
        background: url(../images/newslettersubscribe/newslettersubscribe_mobile.jfif) no-repeat top center;
        background-size: cover;
    }

    @include respond-to(tablet_plus) {
        background: url(../images/newslettersubscribe/newslettersubscribe_desktop.jfif) no-repeat top center;
        background-size: cover;
    }
}

#primary.newslettersubscribe__content {
    max-width: 100%;
}

.newslettersubscribe__content {
    .coloured {
        background-color: $color-primary2;
        border-color: $color-primary2;

        @include respond-to(touch_over) {
            &:hover {
                background-color: $black01;
                border-color: $black01;
            }
        }
    }
}

.newslettersubscribe__request {
    width: 90%;
    max-width: 1170px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.copy-block {
    max-width: 750px;
    padding: 20px;
    margin: 60px auto 0;
}

.copy-block__heading {
    margin-bottom: 20px;
    font-size: rem(48);
    text-align: center;
    text-transform: none;
    color: $color-primary3;
}

.copy-block__para {
    font-size: rem(21);
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: center;
    color: $color-primary3;

    @include respond-to(mobile) {
        width: 100%;
    }

    @include respond-to(tablet_plus) {
        width: 90%;
        margin: 0 auto;
    }
}

.newslettersubscribe__form {
    max-width: 900px;
    margin: 40px auto;

    @include respond-to(mobile) {
        padding: 0 20px;
    }

    @include respond-to(tablet_plus) {
        padding: 0px 140px;
    }

    fieldset > .form-row,
    fieldset > .form-row > .field-wrapper {
        margin: 0;
        padding: 0;
    }

    .field-wrapper .field-wrapper {
        @include respond-to(mobile) {
            margin-bottom: 20px;
        }

        @include respond-to(tablet_plus) {
            margin-bottom: 40px;
        }
    }

    .field-wrapper .error {
        border-color: $color-auxiliary41 !important;
        border-width: 2px !important;
    }

    .field-wrapper .input-text {
        height: 60px;
        background: $color-primary3;
    }

    .coloured {
        float: left;
        padding: 18px 40px;
        font-size: 16px;
    }
}

.newslettersubscribe__response {
    .b-account-customer_info {
        display: none;
    }
}

// Moved common styles from old Checkout
.checkoutlogin-col-wrapper {
    @extend %clearfix;
}

.checkout-create-account,
.checkout-as-guest {
    button[type="submit"] {
        @extend .button-type1;
        @extend .button-type1m;

        max-width: 260px;
        padding: 16px 0;
        width: 100%;
        font-size: rem(16);

        @include respond-to(mobile_strong) {
            padding: 12px 0;
        }

        @include icomoon-icon($aricons-lock, $font-size: rem(13)) {
            padding-right: 12px;
        }
    }
}

.preferences-email,
.preferences-title-email,
.preference-notes-email,
.preference-frequency {
    @include hidden;
}

.reset-password-content {
    padding-top: 0;
    margin-bottom: 20px;
    margin-top: 50px;
    max-width: 550px;

    .reset-password-your-email {
        margin-bottom: 25px;
    }

    @include respond-to(mobile) {
        padding: 0;
        word-break: break-word;
        max-width: none;
    }
}

.primary-content > #PasswordResetForm {
    max-width: 70%;
    margin: 0 auto 20px;
}

.dialog-action-buttons {
    border-top: 1px solid $very-light-gray;
    margin: 21px 0 0;
    padding: 25px 0 0;

    .button.space-bottom {
        @include respond-to(mobile) {
            margin-bottom: 25px;
        }
    }
}

#PasswordResetForm {
    fieldset {
        position: relative;
    }

    button {
        @extend button.coloured;
        float: right;
        top: 0px;
    }

    p {
        text-align: center;
    }

    .dialog-action-buttons {
        border-top: 0;
        padding-top: 0;

        @include respond-to(mobile_strong_over) {
            margin-top: 41px;
        }

        .ui-dialog.customer-password-reset & {
            @include respond-to(mobile_strong_over) {
                margin-top: 21px;
            }
        }

        @include respond-to(mobile) {
            @include flexbox();
            @include flex-vertical();
        }
    }

    .button {
        @include respond-to(mobile) {
            width: auto;
            padding: 14px;
        }

        @include respond-to(mobile_min) {
            width: 100%;
        }
    }

    .button-submit {
        @include respond-to(mobile) {
            order: 1;
            margin-bottom: 20px;
        }
    }

    .button-cancel {
        font-size: rem(16);

        @include respond-to(mobile) {
            order: 2;
        }
    }

    .button-submit,
    .button-cancel {
        @include respond-to(mobile) {
            width: 100%;
        }

        @include respond-to(tablet) {
            width: auto;
        }
    }
}

h1 .account-logout {
    font-size: 0.6em;
    float: none;
}

.reset-password-wrapper {
    .content-center {
        font-family: $font-main-bold;
        font-size: rem(14);
    }
}

.reset-password-title {
    display: none;
}