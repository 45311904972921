.int-country-selector-dialog {
    &.ui-dialog {
        @include respond-to(mobile_strong) {
            left: 0 !important;
            top: auto !important;
            bottom: 0;
            max-width: none;
            position: fixed;
            width: 100% !important;
        }
    }

    .ui-dialog-title {
        display: none !important;
    }

    .dialog-content {
        padding: 0 30px 25px;
    }
}

.int-country-selector-dialog-title {
    font-size: rem(16);
    font-family: $font-main-bold;
    margin-bottom: 20px;
}

.int-country-selector-dialog-description {
    font-size: rem(12);
    max-width: 80%;
    margin-bottom: 15px;

    @include respond-to(mobile_strong) {
        max-width: 65%;
    }
}

.int-country-selector-select {
    margin-bottom: 25px;
}

.int-country-selector-dialog-policy {
    font-size: rem(12);
    color: $smoke;
    margin-top: 25px;
}

.int-country-selector-dialog-button {
    .button {
        background-color: $medium-blue;
        width: 100%;
    }
}