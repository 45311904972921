.product-add-to-cart-sku {
    position: relative;

    .add-all-skus-to-cart-error {
        position: absolute;
        left: 0;
        top: -22px;
        line-height: 22px;
        font-size: rem(16);
        color: $red01;
        margin: 0;
    }
}

.bonus-discount-container {
    display: none;
}