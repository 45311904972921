#product-nav-container {
    display: none;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;

    div {
        float: left;
        text-align: center;
        width: 77px;
    }

    img {
        max-height: 100%;
        max-width: 80%;
    }

    span {
        display: block;
        height: 65px;
    }

    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
}

.pdp-main {
    @extend %clearfix;
    margin-top: 30px;

    .slot-panels-container {
        margin-left: 0;
    }

    .product-image-container {
        position: relative;
    }

    .pl-trustmark {
        position: absolute;
        top: 0;
        right: 20px;

        .PL-mark {
            width: 40px !important;
            height: 40px !important;
            background-size: 40px 40px!important;
        }
    }

    .product-col-1 {
        float: left;
        width: 49%;

        @include respond-to(mobile) {
            @include flexbox();
            position: relative;
            float: none;
            width: 100%;
            margin-top: 20px;
            -webkit-box-orient: vertical;
            -webkit-flex-flow: column;
            flex-flow: column;
        }
    }

    .product-col-mobile {
        display: none;

        @include respond-to(mobile) {
            display: block;
            text-align: center;
        }
    }

    .product-col-2 {
        float: right;
        padding-right: 5%;
        width: 45%;

        @include respond-to(mobile) {
            float: none;
            padding: 0;
            width: 100%;
        }
    }

    .yotpo {
        .yotpo-stars {
            margin-right: 6px;

            .sr-only {
                display: none;
            }
        }

        .yotpo-nav-content > div.yotpo-active,
        input.yotpo-default-button.write-question-button.write-first-question-button {
            margin-left: 0 !important;
        }

        .text-m {
            &.write-review-btn-hidden {
                &:before { content: ""; }
                &:after  { content: ""; }
            }
        }

        .yotpo-icon-star {
            &::before {
                color: #000;
            }
        }
    }

    .product-name {
        margin-bottom: 12px;

        @include respond-to(mobile) {
            display: inline-block;
            margin: 0;
            padding: 0 10px;
        }
    }

    .product-detail {
        @include respond-to(mobile) {
            text-align: center;
        }
    }

    .product-name-brand {
        display: block;
        font-size: rem(23);
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 4px;
    }

    .product-collection {
        display: block;
        font-size: rem(16);
    }

    .product-name-title {
        display: block;
        letter-spacing: 1px;
        line-height: 1.6em;
        margin: 0 0 6px;
        text-transform: none;
    }

    .product-number {
        color: $very-light-gray;
        font-size: rem(6.5);
        margin-bottom: .5rem;
    }

    .product-price {
        margin: 20px 0 11px;

        @include respond-to (mobile) {
            margin: 0;
        }

        .price-sales,
        .salesprice {
            ~ .price-standard {
                color: #666666;
                font-size: 60%;
                text-transform: uppercase;
                text-decoration: line-through;

                .price-standard-qq-coment {
                    display: inline;
                }
            }
        }

        .salesprice {
            display: inline;
        }

        .now-sell-price {
            color: $red01;
        }
    }

    .product-review {
        @extend %clearfix;
        margin-bottom: 5px;
        margin-top: 20px;
        overflow: hidden;

        @include respond-to (mobile) {
            margin-top: 20px;
            text-align: center;
        }
    }

    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }

        .label {
            padding: 0;
        }
    }

    .product-primary-image {
        display: block;
        margin: 0 auto 20px;
        position: relative;
        max-width: 100%;
        text-align: center;

        @include respond-to(mobile) {
            width: 100%;
        }

        .main-image {
            @include lazyload-animation;

            position: relative;
        }
    }

    .primary-image,
    .zoom-picture {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;

        @include respond-to(mobile) {
            padding-top: 0;
        }
    }

    .product-tile {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .product-actions {
            display: block;
        }
    }

    .product-info {
        clear: both;
        padding-top: 1em;

        ul {
            font-size: rem(8);
            list-style: square;
            padding: 0 5em;

            @include respond-to(tablet_plus) {
                padding-bottom: 1em;
                padding-top: 1em;
            }

            &.tab-content-list {
                margin: 0;
            }
        }

        .tabs {
            .tab-content {
                margin-bottom: 0;

                p:last-child {
                    margin-bottom: 0;
                }
            }

            .slot-panels-container {
                margin-left: 0;
            }

            .tab-label {
                position: relative;
                z-index: 2;
            }
        }
    }
    
    &.personalizable-supersocks {
        .product-description,
        .product-variations,
        .inventory,
        .item-stock-info,
        .prod-feedback-txt,
        #product-content > .tablet-only-helper {
            display: none;
        }

        .supersocks-iframe {
            min-height: 700px;
            margin-bottom: 30px;
        }

        .product-col-1 {
            @include respond-to(m_desktop) {
                margin-bottom: 80px;
            }
        }

        .product-col-2 {
            margin-bottom: 50px;
        }
    }

    // Product Add to Cart Area (Availability, Quantity)
    .personalize {
        margin: 20px 0;
    }

    .add-to-cart-disabled {
        cursor: default;
        width: 100%;
    }

    .promotion {
        .promotion-block {
            display: flex;
            flex-direction: column;
            max-height: 270px;
            text-align: center;
            padding: 20px 10px;
            margin: 30px 0 20px;
            font-size: rem(15);
            border: 1px dotted $black01;
            overflow: hidden;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100px;
                background: linear-gradient(0deg, rgba(255,255,255, 1) 20%, rgba(255,255,255, 1) 50%, rgba(255,255,255,0.5) 90%);
            }

            &-title {
                font-size: rem(18);
                font-family: $font-main-bold;
                margin-bottom: 10px;
            }

            .promotion-read_more {
                @include link_underline($medium-blue);
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: rem(14);
                text-align: center;
                color: $black01;
                font-family: $font-main-bold;
                text-transform: capitalize;
                z-index: 10;
            }

            &.expanded {
                max-height: none;

                &::after {
                    display: none;
                }

                .promotion-read_more {
                    display: none;
                }
            }

            .promotion-image {
                max-width: 30%;
                margin: 0 auto 10px;

                img {
                    width: 100%;
                    display: block;
                }
            }
        }

        .promotion-details {
            border-bottom: 1px solid;
            font-size: rem(13);
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    .error-quantity {
        margin-bottom: 15px;
    }

    // Alcohol Delivery
    .alco-delivery-info {
        border: 1px solid $black01;
        margin-bottom: 35px;
        padding: 20px;
        text-align: center;

        &-title {
            margin-bottom: 10px;
        }

        p {
            margin: 0;
        }
    }

    // Gift wrapping message
    .gift-message-box {
        margin: 10px 0 20px;
        padding: 15px 15px 20px;
        background-color: $lighter-gray;

        @include respond-to(mobile) {
            text-align: left;
        }

        &__text,
        &__label_medium {
            margin: 0;
            font-size: rem(14);
        }

        &__text {
            @include respond-to(mobile) {
                font-size: rem(12);
            }
        }

        &__label_medium {
            font-weight: 700;
            margin-bottom: 10px;
        }

        &__remain {
            font-size: rem(12);
            color: $storm-grey;
            margin: 0 0 15px;
        }

        .field-wrapper {
            margin: 0;
        }

        .form-row {
            margin: 0 0 15px;
        }

        textarea {
            background-color: $white;
        }

        label {
            @include respond-to(mobile) {
                display: block;
            }
        }

        .label-optional {
            display: none;
        }
    }

    // Product Set Display
    .product-set {
        .product-name {
            font-size: rem(23);
            letter-spacing: 1px;
            line-height: 1;
            margin-bottom: 6px;
        }

        .product-price {
            font-size: rem(22);
            line-height: 35px;
            margin: 0 0 20px;
        }

        .product-number {
            display: none;
        }

        .ps-description-desktop {
            margin-bottom: 20px;
            font-size: rem(14);
            line-height: 1.5;
        }

        .add-all-to-cart {
            margin-bottom: 24px;
        }

        .product-set-list {
            margin-bottom: 35px;

            .product-set-item {
                @extend %clearfix;
                border-top: 1px solid $very-light-gray;
                clear: both;
                overflow: hidden;
                padding: 24px 0 24px 150px;

                &:last-child {
                    border-bottom: 1px solid $very-light-gray;
                }
            }

            .product-set-image {
                @include lazyload-animation;

                margin-left: -150px;
                width: 125px;
                height: 125px;
                float: left;
                position: relative;
                padding-bottom: 0;

                .product-thumbnail {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    max-width: 100%;
                }
            }

            .product-set-details {
                margin-bottom: 35px;

                @include respond-to(mobile) {
                    text-align: left;
                }
            }

            .product-name {
                font-size: rem(18);
                letter-spacing: 1px;
                margin-bottom: 12px;
                text-transform: none;

                @include respond-to(mobile) {
                    display: block;
                    padding-left: 0;
                }
            }

            .product-number,
            .availability-web {
                display: none;
            }

            .product-price {
                font-size: rem(16);
                line-height: 1;
                margin: 0 0 30px;

                @include respond-to(mobile) {
                    display: block;
                }
            }

            .product-variations {
                @include respond-to(mobile) {
                    text-align: left;

                    .attribute .value {
                        width: 100%;
                    }
                }

                .toggle {
                    .selected-value,
                    &.expanded > ul {
                        @include respond-to(mobile_tablet) {
                            width: 100%;
                        }
                    }
                }

                > ul {
                    float: left;
                    width: 100%;
                }
            }

            .variation-attributes {
                @include respond-to(mobile) {
                    text-align: left;
                }

                > div {
                    margin: 0 0 15px;
                }

            }

            .explore-colours {
                font-size: rem(14);
                margin-left: 5px;
            }

            .selected-value {
                white-space: nowrap;
            }

            .inventory + .variation-attributes {
                margin-top: -20px;
            }

            .add-to-cart {
                margin-bottom: 0;

                @include respond-to(mobile) {
                    font-size: rem(14);
                }
            }
        }

        .product-add-to-cart {
            .label-buy-all {
                display: none;
            }
        }
    }

    // Product Recommendations
    .recommendations {
        clear: left;
        padding: 2em 0;

        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }

        h2 {
            font-size: 2em;
            font-style: italic;
            margin-left: 14px;
        }

        #carousel-recommendations {
            margin: auto;
            position: relative;
            overflow: hidden;
            width: 250px;

            @media screen and (min-width: 690px) {
                width: 500px;
            }

            @include respond-to(tablet_plus) {
                width: 750px;
            }
        }

        ul {
            list-style-type: none;
            position: relative;
            width: 2000em;
        }

        li {
            float: left;
            width: 250px;
        }

        .product-tile {
            padding: 0 2em;
        }

        .product-name {
            font-size: 1em;
            margin: .2em 0;
            width: 100%;
        }

        .product-price {
            font-size: 1em;
        }

        .jcarousel-clip {
            margin: auto;
            overflow: hidden;
            width: 300px;
        }

        .jcarousel-nav {
            cursor: pointer;
            position: absolute;
            top: 20%;

            &.inactive {
                display: none;
            }
        }

        .jcarousel-prev {
            left: 0;
        }

        .jcarousel-next {
            right: 0;
        }

        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }
    }

    .product-outofstock,
    .product-variations,
    .pdpForm {
        .is-product-set {
            width: 100%;
        }
    }
    &.is-product-set {
        .product-name-title {
            font-size: rem(20);
            letter-spacing: 0;

            @include respond-to(mobile) {
                font-size: rem(18);
            }

            @include respond-to(mobile_strong_over) {
                margin: 0 0 26px;
            }
        }
    }
    .product-col-2.product-set {
        .qty-title {
            width: 160px;
            line-height: 30px;
            font-size: rem(14);
            float: right;
            text-align: center;
            text-transform: uppercase;

            @include respond-to(mobile) {
                display: none;
                font-size: rem(16);
            }
        }
    }
}

.main-image {
    display: block;
}

.main-video .video-inner {
    padding-bottom: 100%;
    position: relative;
    height: 100%;

    .video-content {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: #fff;
    }
}

.zoomImg,
.primary-image {
    max-width: 100%;
    display: block;
}

.zoomImg {
    cursor: url("../images/bt-zoom-cursor.svg") 10 10, pointer !important;
}

.rating {
    margin-top: 5px;
    color: $black01;

    .product-info & {
        font-size: 2em;
    }

    i {
        margin-right: 4px;
    }
}

.product-alt-mobile {
    display: none;

    @include respond-to(mobile) {
        display: block;
        text-align: center;
    }
}

.product-variations,
.product-options {

    @include respond-to(mobile) {
        text-align: center;
    }

    .attribute {
        margin: 0 0 15px;
        padding: 0;
        position: relative;
        clear: both;

        h3,
        .label {
            margin-bottom: .3rem;
            font-size: rem(14);
        }

        .value {
            width: 100%;
            margin-top: 3px;
            position: relative;

            @include respond-to(mobile) {
                width: 80%;
                margin: 0 auto;
            }

            .variation-select {
                width: 100%;
                font-size: rem(14);

                @include respond-to(mobile) {
                    text-align: left;
                    text-align: -moz-left;
                    text-align: -webkit-left;
                    text-align-last: left;
                    -moz-text-align-last: left;
                    padding-right: 4px;
                }
            }
        }

        .is-explore-colours {
            @include respond-to(desktop_plus) {
                display: inline;
            }
        }

        ul {
            display: inline-block;
            width: 100%;

            &.size {
                display: inline-block;
                float: none;
                width: auto;
            }
        }
    }

    .swatches {
        font-size: 0;

        &.color {
            .selected-value {
                display: none;
            }
        }

        li {
            display: inline-block;
            margin: 0 22px 8px 0;
            vertical-align: middle;
            width: 36px;

            @include respond-to(mobile) {
                margin: 0 0 8px 22px;

                &:only-child,
                &:first-child {
                    margin-left: 0;
                }
            }

            .swatchanchor-image {
                display: inline-block;
            }

            &:only-child,
            &:last-child {
                margin-right: 0;
            }

            &.unselectable {
                .product-detail & {
                    opacity: 1 ; // restore opacity & avoid important;
                }

                a {
                    cursor: default;
                    position: relative;
                    overflow: hidden;

                    @include respond-to(mobile) {
                        margin: 0;
                    }

                    img {
                        opacity: 0.75;
                        border: 2px solid darken($lightest-gray, 5%); // compensate .75 opacity on image
                    }

                    span {
                        opacity: .4; // apply opacity only on text - not on .li.unselectable
                    }
                }
            }
        }

        .swatchanchor {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: block;
            padding: 0;
            border: 0;
            font-size: 0;
            margin: 0 18px 0 0;

            @include respond-to(mobile_tablet) {
                margin: 0 3px;
            }

            &:hover img {
                border: 2px solid $lightest-gray;
            }
        }

        .selected-value {
            .swatchanchor {
                img,
                &:hover img {
                    border: 4px solid $tutu;
                    opacity: 0.6;
                }
            }
        }

        img {
            margin-top: 0;
            width: 100%;
            height: auto;
        }

        .swatch-image {
            max-width: 100%;
        }

        .selectable {
            a {
                cursor: pointer;
                width: 36px;
                height: 36px;
                display: block;
                margin: 0;
                padding: 0;
                border:0;
                font-size: 0;
            }

            &.selected img {
                border: 4px solid $tutu;
            }

            &:hover img {
                border: 2px solid $lightest-gray;
            }

            &.selected-value {
                a {
                    cursor: default;
                }
            }
        }

        .unselectable.selected {
            a::before {
                border-color: lighten($tutu, 7%); // compensate .75 opacity on image
            }

            img {
                border: 2px solid $tutu;
            }
        }
    }

    .color {
        li {
            a {
                line-height: 0;
                width: 2.625rem;
                height: 2.625rem;

                @include respond-to(tablet_plus) {
                    width: 2rem;
                    height: 2rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
        }

        select {
            background-color: $white;
            border: 1px solid $gainsboro;
            border-radius: 0;
            height: 42px;
            width: 100%;
            margin-left: 0;

            @include respond-to(tablet_plus) {
                height: 32px;
            }
        }
    }

    .toggle {
        .swatches.size,
        > ul {
            display: none;
            max-height: 177px;
            overflow: auto;
        }

        &.expanded {
            .swatches.size,
            > ul {
                display: block;
                position: absolute;
                top: calc(100% - 1px);
                background: $white;
                width: 55%;
                z-index: 2;
                border: 1px solid $tutu;

                @include respond-to(mobile) {
                    width: 80%;
                }

                &.size {
                    top: 66px;

                    .bonus-product-tile & {
                        width: 170px;
                        max-height: 130px;
                        top: 38px;
                        left: 50%;
                        margin-left: -85px;
                        margin-bottom: 0;
                        text-align: left;

                        @include respond-to(mobile) {
                            width: 150px;
                            margin-left: -75px;
                        }
                        @include respond-to(mobile_mid) {
                            width: 130px;
                            margin-left: -65px;
                        }
                    }
                }

                li {
                    display: block;
                    width: 100%;
                    line-height: normal;
                    min-height: 36px;
                    height: auto;
                    margin: 0;
                    padding: 5px;
                    background: $white;
                    -webkit-transition: background .3s ease-in-out;
                    transition: background .3s ease-in-out;
                    color: $charcoal;
                    opacity: 0.4;

                    @include respond-to(mobile_tablet) {
                        text-align: left;
                    }

                    @include respond-to(mobile) {
                        width: 95%;
                        margin: 0;
                    }

                    &:hover {
                        background: $tutu;
                    }

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }

                    &.selectable {
                        cursor: pointer;
                        opacity: 1;
                        box-shadow: none;

                        a {
                            cursor: pointer;
                        }
                    }


                    &.selected-value {
                        display: none;
                    }

                    img {
                        display: inline-block;
                        max-width: 25px;
                        height: auto;
                        margin: 0;
                        vertical-align: middle;
                        padding-top: 0;
                    }

                    a {
                        cursor: default;
                        vertical-align: middle;
                        width: 100%;
                    }

                    a.swatchanchor {
                        @include flexbox();
                        @include align-items(center);

                        height: auto;
                        line-height: normal;
                        width: auto;

                        span {
                            line-height: normal;
                            word-break: break-word;
                        }
                    }

                    .swatchanchor-image {
                        min-width: 35px;
                        position: relative;
                    }

                    span {
                        display: inline-block;
                        font-size: rem(16);
                        vertical-align: middle;
                        padding-left: 4px;
                        line-height: 35px;
                    }

                    &.unselectable {
                         a.swatchanchor {
                            &.with-image {
                                &:before {
                                    display: none;
                                }
                            }
                        }

                        .swatchanchor-image {
                            &.with-image {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .selected-value {
                color: $tutu;
            }

            .swatchanchor {
                font-size: rem(16);
                line-height: 36px;
            }
        }

        .selected-value {
            display: inline-block;
            position: relative;
            border: 1px solid $tutu;
            padding: 0px 35px 0px 5px;
            color: $black01;
            text-transform: none;
            white-space: nowrap;
            cursor: pointer;
            font-size: rem(14);
            width: 55%;
            line-height: rem(14);

            @include respond-to(1200px) {
                width: 70%;
            }

            @include respond-to(mobile_tablet) {
                text-align: left;
                width: 100%;
            }

            @include respond-to(mobile) {
                width: 100%;
            }

            &:after {
                font-family: $font-icons;
                content: "\e902";
                position: absolute;
                right: 5px;
                top: 50%;
                text-align: center;
                font-size: rem(14);
                padding: 5px;
                line-height: normal;
                color: $tutu;
                transform: translateY(-50%);
                pointer-events: none;
            }

            img {
                display: inline-block;
                margin: 5px 5px 5px 0;
                max-width: 25px;
                max-height: 25px;
                height: auto;
                vertical-align: middle;
                border-radius: 50%;
            }

            span {
                @include text-truncate;

                display: inline-block;
                vertical-align: top;
                line-height: 35px;
                padding: 0;
                width: calc(100% - 35px);

                @include respond-to(desktop_over) {
                    vertical-align: middle;
                }
            }
        }

        &.toggle-disabled {
            .selected-value {
                color: $tutu;
            }
        }
    }
}

.bonus-product-tile {
    .product-tile {
        overflow: visible;
    }

    .product-options,
    .product-variations {
        .toggle {
            .selected-value {
                width: 170px;
                display: flex;
                margin: 0 auto;
                padding: 0px 30px 0px 5px;

                span {
                    @include text-truncate;
                    width: 100px;
                }

                @include respond-to(mobile) {
                    width: 150px;
                    padding: 0 35px 0 5px;
                }
                @include respond-to(mobile_mid) {
                    width: 130px;
                }
            }

            &.expanded > ul {
                width: 170px;
                max-height: 130px;
                top: 38px;
                left: 50%;
                margin-left: -85px;
                text-align: left;

                @include respond-to(mobile) {
                    width: 150px;
                    margin-left: -75px
                }

                @include respond-to(mobile_mid) {
                    width: 130px;
                    margin-left: -65px;
                }

                img {
                    margin: 0;
                }

                span {
                    width: calc(100% - 30px);
                    word-wrap: break-word;
                    line-height: 16px;
                }
            }
        }
    }
}

.explore-colours {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    margin-left: 25px;
    line-height: 25px;

    @include respond-to(mobile_tablet) {
        display: block;
        margin-top: 15px;
        margin-left: 0;
        font-size: rem(14);
        line-height: 20px;
    }

    a {
        border-bottom: 1px solid;
    }
}

.pdp-book-appointment {
    border: 1px solid;
    padding: 20px;
    font-size: rem(14);
    text-align: center;

    b {
        display: block;
        text-transform: uppercase;
        font-size: rem(14);
    }

    a {
        display: block;
        margin-top: 10px;
        font-size: rem(15);
        text-decoration: underline;

        @include respond-to(mobile) {
            margin: 20px 0 10px;
        }
    }
}

#product-code {
    text-align: right;
    margin-top: 10px;
    color: $dim-gray;
}

/* Explore Colours */
.product-variations {
    &.ui-tabs {
        padding: 0;
    }

    .product-variations-tabs {
        &.ui-tabs-nav {
            float: none;
            font-size: 0;
            height: auto;

            .ui-tabs-anchor {
                border: 1px solid $charcoal;
                color: $charcoal;
                display: block;
                float: none;
                font-size: rem(16);
                line-height: 25px;
                padding: 6px 11px;
                position: relative;
                text-align: center;
                text-transform: uppercase;
                transition: none;
                z-index: 1;
            }

            li {
                background-color: $white;
                border: none;
                margin: 0;
                padding: 0;
                width: 33.333%;
                margin-left: -1px;

                &.ui-state-active {
                    .ui-tabs-anchor {
                        border: 2px solid $nobel;
                        color: $nobel;
                        padding: 5px 10px;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .ui-tabs .ui-tabs-panel {
        border: none;
        padding: 30px 0 0;

        .swatches {
            float: none;
            overflow: hidden;
            padding-left: 150px;

            @include respond-to(mobile) {
                padding-left: 0;
            }

            .selectable,
            .unselectable {
                float: left;
                margin: 0 23px 15px 0;
            }

            .product-variations-group-title {
                clear: both;
                float: left;
                margin-left: -150px;
                width: auto;
                line-height: 21px;
                color: $hel-gray;
                font-size: rem(14);

                @include respond-to(mobile) {
                    float: none;
                    margin-left: 0;
                    display: block;
                    text-align: left;
                }
            }
        }
    }
}

.product-variations-selected-color {
    padding-left: 150px;

    @include respond-to(mobile) {
        padding-left: 0;
    }
}

.pdp-call-for-details {
    font-family: $font-main-bold;
    font-size: rem(18);
    color: $white;
    text-align: center;
    text-transform: capitalize;
    padding: 15px 5px;
    margin: 25px 0 15px;
    background: $black01;
    border-radius: 50px;

    span {
        margin-right: 20px;

        @include respond-to(mobile) {
            display: block;
        }
    }

    i {
        @include respond-to(mobile) {
            display: none;
        }
    }
}

/* Out of stock message */
.product-outofstock {
    margin: 30px 0;

    @include respond-to(mobile) {
        text-align: center;

        .wishlist-products-form & {
            width: 100%;
        }
    }

    .oos-link {
        text-decoration: underline;
        color: $dim-gray;
        cursor: pointer;

        @include respond-to(mobile) {
            display: block;
            margin-top: 20px;
        }

        &:hover {
            color: $dim-gray;
        }
    }

    .select-error {
        display: none;
        margin-top: 10px;
        color: $red01;
    }
}

.oos-label {
    margin-right: 10px;
    padding: 2px 8px;
    border: 1px solid $red01;
    text-transform: uppercase;
    color: $red01;
    font-size: rem(14);

    @include respond-to(mobile) {
        margin-right: 0;
    }
}

#dialog-container {
    & + .ui-dialog-buttonpane {
        display: block;
    }

    &.notifyme-submitted + .ui-dialog-buttonpane {
        display: block;
    }
}

.notify-container {
    margin-top: 40px;

    @include respond-to(mobile) {
        margin-top: 20px;
    }

    .form-row {
        width: 70%;
        display: inline-block;

        @include respond-to(mobile) {
            margin-left: 5%;
            width: 90%;
        }
    }

    span {
        display: block;
        margin-bottom: 30px;
        text-align: center;
    }

    button {
        display: inline-block;
        padding: 11px 23px;
        vertical-align: top;
        margin: 5px 0 0 20px;
        width: 25%;

        @include respond-to(mobile) {
            width: 90%;
            margin: 0 5% 20px;
        }
    }
}

/* Chanel PDP template */
.product-row-brand-logo {
    @include respond-to(mobile) {
        text-align: center;
    }

    a {
        display: block;
    }
}

.product-detail-chanel {
    .chanel-collection {
        display: block;
        font-size: rem(16);
        letter-spacing: 1px;
    }

    .price-switcher {
        @include respond-to(mobile) {
            display: block;
        }
    }
}

/* Dior PDP template */
.product-detail-dior {
    .add-to-cart {
        background-color: $black01;

        &:hover,
        &:focus {
            background-color: $chanel-black-hover;
        }
    }

    .chanel-banner {
        @include respond-to(tablet_plus) {
            margin: -25px 0 55px;
        }
    }
}

.about-brand {
    background-color: $white-smoke;
    margin-bottom: 60px;
    padding: 40px 15px 80px;

    &-title {
        position: relative;
        color: $nobel;
        font-size: rem(30);
        letter-spacing: 1px;
        margin: 0 0 35px;
        padding: 0;
        text-align: center;
        text-transform: uppercase;

        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            width: 150px;
            border-bottom: 1px solid;
            margin-left: -75px;
        }

        span {
            display: inline-block;
            position: relative;

            &:after {
                @include position(absolute, 0, 0, 100%);

                border-bottom: 1px solid;
                content: "";
                margin: 0 auto;
                width: 50%;
            }
        }
    }

    &-content {
        background-color: $white;
        font-size: 0;
        padding: 0;

        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }

    &-image {
        display: inline-block;
        vertical-align: middle;
        width: 50%;

        img {
            max-width: 100%;
        }
    }

    &-info {
        display: inline-block;
        padding: 30px;
        vertical-align: top;
        width: 50%;
    }

    &-info-title {
        color: $night-rider;
        display: inline-block;
        font-size: rem(20);
        margin: 0 0 20px;
        text-transform: uppercase;
    }

    &-info-description {
        font-size: rem(16);
        margin-bottom: 25px;
    }

    &-info-more {
        border-bottom: 1px solid $dim-gray;
        color: $nobel;
        display: inline-block;
        font-size: rem(13);
        margin-bottom: 15px;
        text-transform: uppercase;
    }
}

.dialog-quickview .ui-dialog-content {
    .pdp-about-brand {
        margin: 10px auto;
    }
}

/* Back in stock popup */
.b-notifyme_form-result {
    text-align: center;

    h4 {
        font-size: rem(16);
        text-transform: none;
    }
}

.notifyme-submitted + .ui-widget-content .ui-dialog-buttonset {
    display: none;
}

/* Get the look */
.product-recommendations-carousel {
    clear: both;
    margin: 70px 0;

    .product-actions {
        display: none;
    }
}

/* Check store availability */
.store-container {
    .bticons-logo {
        text-align: center;
        font-size: rem(29);
    }

    h3 {
        display: block;
        width: 100%;
        margin-top: 15px;
        text-align: center;
        font-size: rem(18);

        &:after {
            content: '';
            border-bottom: 1px solid;
            width: 22%;
            display: block;
            margin: 15px auto;
        }
    }

    h4 {
        font-size: rem(15);
        text-align: center;
    }

    h5 {
        margin-top: 5px;
        text-align: center;
    }

    a {
        text-align: center;
        display: block;
        text-decoration: underline;
        font-size: rem(13);
    }
}

.store-detail-inner {
    padding: 0 100px;

    @include respond-to(mobile) {
        padding: 0 5%;
    }

    .store-detail {
        display: inline-block;
        width: 28%;
        margin: 0 2%;
        vertical-align: top;
        min-height: 230px;

        @include respond-to(mobile) {
            display: block;
            width: 100%
        }

        .store-image {
            text-align: center;
        }

        img {
            max-height: 130px;
            max-width: 100%;
        }
    }
}

/* YML section */
.cart-recommendations,
.pdp-recommendations,
.global-recommendations {
    .carousel li {
        width: 200px; // fix for initialisation of carousel
    }

    .pdp-main &,
    .pt_cart &,
    .pt_product-search-noresult & {
        clear: both;

        @include respond-to(mobile) {
            display: block;
            padding: 0;
        }
    }
}

/* Gift card */
.product-add-giftcard {
    margin-top: 25px;

    .char-count,
    .select-gift-radio {
        display: none;
    }

    .button-label {
        width: 18%;
        display: inline-block;
        vertical-align: top;
        margin: 0 1% 20px 0;
        padding: 12px 0;
        background: $second-gray;
        border: 1px solid $tutu;
        text-align: center;

        @include respond-to(mobile) {
            width: 17.5%;
            font-size: rem(14);
        }

        &:hover {
            background: $lightest-gray;
        }
    }

    .select-gift-radio:checked + .button-label {
        color: $white;
        background: $black01;
    }

    .form-row label {
        margin-bottom: 10px;
    }
}

.pdp-gift-card {
    .product-gift-subtitle {
        @include respond-to(mobile) {
            display: block;
            text-align: center;
            margin-top: 20px;
        }
    }
}

.featured-in-panels {
    @extend %clearfix;
    @include flexbox();

    @include respond-to(mobile) {
        display: block;
    }

    &.slot-panels {
        .asset {
            &-image {
                width: 100%;
            }

            &-text {
                min-height: 90px;
            }

            &-link {
                height: 100%;
            }
        }
    }
}

/* VideoJS */
.main-video {
    &.product-image {
        @include respond-to(mobile) {
            margin-top: 0;
        }

        .ui-dialog-content .product-col-1 & {
            margin: 0 auto;
            max-width: 470px;

            @include respond-to(mobile) {
                margin: 50px 0 auto;
                max-width: 450px;
            }
        }

        .desktop .ui-dialog-content .product-col-1 & {
            max-width: 100%;
        }
    }

    .video-js {
        @include respond-to(mobile_tablet) {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
}

.promo-flag {
    .ui-dialog-content & {
        display: none;
    }
}

.video-js {
    padding-top: 100%;

    .vjs-big-play-button {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 72px;
        border: 0;
    }
}

.prod-feedback-txt {
    clear: both;
    margin-bottom: 10px;
    font-size: rem(14);

    .product-detail .product-variations & {
        display: none;
    }

    .product-delivery-details-message + &,
    .content-asset + &,
    .inventory + & {
        margin-bottom: 20px;
        margin-top: 10px;
    }
}

.item-stock-info {
    margin-bottom: rem(20);
    font-size: rem(14);
}

.pdpForm fieldset  {
    width: 100%;
}
.price-sales__now-text {
    display: none;
}

.klarna_placement 
{
#credit_strip_badge::part(osm-container){
    border: 0;
    padding: 0;  
}
}

.klarna_placement #credit_strip_badge::part(osm-container) {
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    background:white;
    border: 0;
    padding: 0; 
}

.klarna_placement #top_strip_badge::part(osm-container){
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-top: 12px;
    background:white;
    border: 0;
    padding: 0; 
    justify-content: left;
}

.klarna_placement #top_strip_badge::part(osm-badge) , #credit_strip_badge::part(osm-badge) {
    height: 24px;
}

.klarna_placement #top_strip_badge::part(osm-message),#credit_strip_badge::part(osm-message) {
   
    flex-direction: column;
    align-items: start;
    font-size: 14px;
}

.klarna_placement #top_strip_badge::part(osm-cta),#credit_strip_badge::part(osm-cta) {
    display: block;
    font-size: 14px;
}

@media screen and (max-width: 800px) {
    .klarna_placement #credit_strip_badge::part(osm-cta) {
        margin: 4px auto;
    }
    }