.pt_account {
    .loyalty-choose {
        @extend %clearfix;
        margin-bottom: 40px;

        &-title {
            margin-bottom: 25px;
        }

        .loyalty-notregistred-label {
            margin-bottom: 10px;
            text-align: center;
            display: none;

            @include respond-to(mobile) {
                text-align: left;
            }
        }

        .button {
            @include button-type(1);
            font-size: rem(16);
            padding: 14px 28px;
        }
    }

    .loyalty-notregistred {
        text-align: center;

        .button {
            text-transform: uppercase;
        }
    }

    .loyalty-disabled {
        margin-bottom: 80px;
    }
}

.loyalty-benefits-cards {
    .slot {
        @include flexbox();

        @include respond-to(mobile) {
            display: block;
            margin: 0;
        }
    }

    .asset {
        flex: 1;
        margin: 0 15px;
        padding: 25px 25px 5px;

        @include respond-to(mobile) {
            flex: none;
            margin: 0 0 25px;
            padding: 0;
        }

        .card-logo {
            margin-bottom: 35px;
            max-width: 100%;
            text-align: center;
        }

        h4 {
            margin-bottom: 15px;
        }

        p {
            margin: 0 0 20px;
        }

        ul {
            list-style-type: disc;
            margin: 0 0 20px 17px;
        }

        td {
            vertical-align: middle;
        }
    }
}

.loyalty-registered {
    @extend %clearfix;
}

.loyalty-btn-startshopping {
    @include button-type(1);
    width: 51%;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;

    @include respond-to(mobile) {
        width: 100%;
    }
}

.loyalty-col-actions {
    float: left;
    margin: 0 20px 35px 0;
    width: 308px;

    > p {
        margin: 0 0 40px 0;
    }

    @include respond-to(mobile) {
        float: none;
        margin-right: 0;
        width: auto;
    }

    .text-center {
        text-align: center;

        @include respond-to(mobile) {
            text-align: left;
        }
    }

    .button {
        display: block;
        margin-top: 15px;
        text-transform: none;
    }

    .box {
        background: $ghost;
        margin-top: 15px;
        padding: 12px;
    }
}

.loyalty-col-contact {
    float: left;
    padding-top: 61px;
    text-align: center;
    width: 308px;

    @include respond-to(mobile) {
        float: none;
        padding-top: 20px;
        width: auto;
    }

    .box-call {
        margin-top: 20px;
    }
}

.loyalty-add-card {
    @extend %clearfix;
    margin-bottom: 40px;

    &-form {
        margin-top: 20px;
    }

    &-submit {
        float: right;
    }
}

.loyalty-header {
    margin-bottom: 25px;
    padding: 25px;
    text-align: center;

    h2 {
        padding: 20px 0;
    }
    
    .h1 {
        font-size: rem(30);
    }
}

.loyalty-benefits .main-title {
    display: none;
}

.loyalty-add-card-submit,
.loyalty-data-cardnumber .link {
    @extend button.coloured;
}

.loyalty_card-label.loyalty_card-label {
    &::before,
    &::after {
        margin-top: 0;
    }
}

.loyalty_card-text {
    height: 0;
    overflow: hidden;
    line-height: 1.5;
    transition: height 0.5s ease-in-out;
}

.loyalty_card-input:checked  ~ .loyalty_card-label .loyalty_card-text {
    height: auto;
    overflow: visible;
    margin-top: 10px;
    transition: height 0.5s ease-in-out;
}

.loyaltynumber-container {
    clear: left;

    .field-wrapper {
        .input-text {
            @include respond-to(touch_over) {
                min-width: 300px;
            }

            + .error {
                white-space: nowrap;

                @include respond-to(mobile) {
                    position: relative;
                    margin-bottom: -15px;
                }
            }
        }
    }
}

.loyalty-col-actions {
    .button {
        @extend button.coloured;

        text-transform: uppercase;
    }
}

.form-fieldset-loyalty {
    .account-loyalty-left {
        margin-bottom: 40px;

        @include respond-to(mobile) {
            margin-bottom: 20px;
        }
    }
}