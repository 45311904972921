.the-search-result,
.pt_product-search-noresult {
    .breadcrumb {
        @include respond-to(mobile_strong_over) {
            position: absolute;
            z-index: $z-min;
        }
    }
}

.the-search-result {
    .slot-grid-header {
        border-bottom: none;
        margin: 20px 0;
    }
}

.searchresult-phrase,
.searchresults {
    //TODO update after PR is marged
    //https://bitbucket.org/brownthomas/ecom-brownthomas/pull-requests/2642
    //@include full-page-content($scrollbar-fix: true);
    @include full-page-content($scrollbar-fix: true);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100% - 10px));
    background-color: $color-secondary3;
    color: $black01;
}

.searchresult-phrase {
    @include respond-to(mobile_strong) {
        padding-top: 17px;
    }

    @include respond-to(mobile_strong_over) {
        padding-top: 32px;
    }

    .breadcrumb-result-text {
        margin-right: 0;
    }

    .res-text-breadcrumb {
        padding: 0;

        i {
            display: none;
        }

        &:after {
            display: none;
        }

        .bticons-search {
            @include icomoon-icon($aricons-search, $font-size: rem(40), $pseudo: before);

            @include respond-to(mobile_strong_over) {
                margin-bottom: 20px;
            }
        }
    }

    .suggest-breadcrumb {
        display: none;
    }

    .breadcrumb-element {
        border-bottom: none;
    }

    a {
        &:hover {
            color: $black01;
            opacity: .6;
        }
    }

    + .breadcrumb {
        .breadcrumb-element-wrapper:first-child {
            .breadcrumb-result-text {
                vertical-align: middle;

                + .breadcrumb-element {
                    width: auto;
                    vertical-align: baseline;
                    margin-right: 5px;
                    margin-left: 5px;

                    &::before {
                        display: none;
                    }

                    &::after {
                        position: static;
                    }
                }
            }
        }
    }
}

.searchresults {
    display: none!important;
    @include clip-decorator;
    background-color: $color-auxiliary22;
    color: $black01;

    @include respond-to(mobile_strong) {
        padding-bottom: 40px;
    }

    @include respond-to(mobile_strong_over) {
        padding-bottom: 75px;
    }
}

.search-tab-holder {
    @include respond-to(mobile_strong) {
        margin: 0;
    }

    .search-tab-item {
        display: none;

        &.search-tab-active {
            color: $color-primary2;

            &:before,
            &:after {
                display: none;
            }
        }

        &.js-product-search-tab {
            text-align: left;
        }

        &.js-video-search-tab {
            text-align: right;
        }

        .search-tab-results {
            font-size: rem(16);
            text-transform: uppercase;
        }
    }
}

.search-result-content {
    border: 0;
}

.search-result-items {
    border: 0;
    margin-bottom: 30px;

    .grid-tile {
        border: 0;
    }
}

.products__item-count {
    text-align: center;
    font-size: rem(14);
    color: $hel-gray;
    margin-bottom: 15px;
}

.products__load-more {
    @extend .button;
    @extend .coloured;

    left: 50%;
    transform: translateX(-50%);
    min-width: 400px;

    @include respond-to(mobile_strong) {
        min-width: 345px;
    }
}

.grid-tile .slot-panels {
    @include border-tape($border-color: $color-primary3, $direction: 'in', $bgcolor: $color-primary3);

    margin-bottom: 20px;

    @include respond-to(mobile_strong_over) {
        margin-bottom: 70px;
    }

    .promo-content-wrapper {
        margin-top: 80%;

        @include respond-to(mobile_mid) {
            margin-top: 75%;
        }

        .button-type5 {
            min-width: 130px;

            @include respond-to(mobile) {
                padding: 12px;
            }
        }

        &.promo-right {
            padding-right: 0;

            @include respond-to(mobile_strong_over) {
                padding-left: 11%;
            }
        }
    }
}

.slot-grid-header {
    padding: 0;
    border: 0;

    &.full-width-block {
        @include full-page-content($scrollbar-fix: true);
    }

    .slot-assets-list-item {
        width: 100%;
        float: left;

        @include respond-to(tablet_plus) {
            max-height: 500px;
        }
    }

    .slick-dots {
        position: static;

        .aricons-dots {
            @include respond-to(tablet_plus) {
                font-size: rem(12);
            }
        }
    }

    button.slick-arrow { // increase specifisity
        color: $color-primary3;
        bottom: auto;
        top: 53%;
    }

    .categories { //CATEGORIES IN TOP BANNER ONLY FOR PLP
        position: relative;
        font-size: rem(14);
        text-align: center;
        background-color: $lighter-gray;

        @include respond-to(m_desktop) {
            margin-left: -100%;
            margin-right: -100%;
            padding: 8px 100%;
        }

        @include respond-to(m_tablet_down) {
            height: 50px;
            overflow: hidden;
            margin-left: rem(-15);
            margin-right:  rem(-15);
            padding: 8px 15px;
        }

        &-list {
            margin: 0;

            @include respond-to(m_desktop) {
                max-width: 1260px;
                margin: 0 auto;
            }
        }

        li,
        .slick-slide {
            display: inline-block;
            margin: 0 30px 0 0;

            &:last-child {
                margin: 0;
            }
        }

        .category-link {
            display: inline-block;
            line-height: 34px;
            font-weight: 500;
        }
    }

    .categories-arrow {
        display: none;

        &.next {
            @include icon(simple-arrow-right);

            right: 0;
        }

        &.prev {
            @include icon(simple-arrow-left);

            left: 0;
        }
    }

    button.slick-arrow {
        color: $black01;
        top: 50%;
    }

    .slick-arrow {
        &:not(.slick-disabled) {
            display: block !important;
        }

        &.slick-hidden {
            display: none !important;
        }

        transform: translateY(-50%);
        margin: 0;
        background-color: rgba(238, 238, 238, 1);
        border: 0;

        &:before {
            font-size: rem(12);
            font-weight: bold;
        }
    }

    .cat-landing-slotlinks {
        margin: 20px 0 30px;
    
        .slick-arrow {
            border: 0;
            border-radius: 0;
            background-color: transparent;
            margin-top: -35px;
            
            &::before {
                font-size: rem(32);
                color: $medium-blue;
                opacity: 0.3;
            }
    
            &::after {
                display: none;
            }
        }
    
        .slick-next {
            transform: rotate(180deg);
            right: -20px;
        }
    
        .slick-prev {
            left: -20px;
            transform: none;
        }
    
        .slick-track {
            margin: 0 auto;
        }
    }
    
    .category_item {
        width: 81%;
        margin: 0 auto;
        text-align: center;
        display: none; // hide till slick initialized

        @include respond-to(mobile_stronger) {
            width: 67%;
        }

        @include respond-to(mobile_strong) {
            width: 84%;
        }
    
        &-image {
            margin-bottom: 20px;
            position: relative;
            padding-top: 100%;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    
        &-link {
            &:hover .category_item-title {
                opacity: 0.5;
            }
        }
    }

    .slick-initialized .category_item {
        display: block;
    }
}

.plp-header-carousel {
    .slick-dots {
        position: static;

        .aricons-dots {
            @include respond-to(tablet_plus) {
                font-size: rem(12);
            }
        }
    }
}

// handle multiple breadckumbs logic
.bc-placeholder-present {
    @include respond-to(mobile_strong_over) {
        display: none;
    }

    .promo-breadcrumbs-wrapper {
        display: none;

        @include respond-to(mobile_strong_over) {
            display: block;
        }
    }
}

#content-search-result-main {

    .search-result-options {
        padding-bottom: 0;

        .pag-items-show {
            margin-left: 0;
        }
    }

    .search-result-content {
        border: none;
    }

    .search-result-items {
        border: none;
    }

    .content-search-grid {
        @include respond-to(mobile_strong_over) {
            @include flexbox();
            @include flex-wrap();
            @include justify-content(space-between);
        }

        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid $color-auxiliary17;

        @include respond-to(mobile_strong) {
            padding-bottom: 20px;
            margin-bottom: 30px;
            text-align: center;
        }

        &.feature-search-grid {
            .content-image {
                @include respond-to(mobile_strong) {
                    max-width: 100%;
                }

                @include respond-to(mobile_strong_over) {
                    @include align-self(center);
                }
            }

            .content-item-info {
                @include respond-to(mobile_strong_over) {
                    @include align-self(center);
                    width: 50%;
                }
            }
        }

        .content-item-title {
            font-size: rem(22);
        }

        .content-item-link {
            a:not(.button),
            a:not(.whats-on-cta) {
                color: $black01;
                border-bottom-color: $black01;
                opacity: .6;

                &:hover {
                    color: $black01;
                    border-bottom-color: $black01;
                    opacity: 1;
                }
            }

            .button,
            .whats-on-cta {
                background-color: $color-primary2;
                border-bottom: none;
                transition: all .3s;

                &:hover {
                    background-color: $color-primary2;
                    opacity: .6;
                }
            }
        }
    }
}

.error-page-top,
.browser-compatibility-alert {
    @include full-page-content;

    background: linear-gradient($color-auxiliary38, $color-primary3);
    color: $black01;
    margin-top: -10px;
    padding-top: 60px;

    .main-title,
    .error-page-message,
    .browser-compatibility-alert-inner-wrapper,
    &.no-cookies .browser-error {
        @extend .site-width;

        @include respond-to(mobile) {
            padding: 0;
        }
    }
}

.pt_error {
    .main-title {
        text-transform: uppercase;
    }
}

.error-page-footer {
    .carousel-collection {
        @include shared-jcarousel-nav;

        .jcarousel-nav {
            font-size: rem(36);
        }
    }
}

.error-page-footer {
    &-slot {
        .cat-blocks-carousel {
            .carousel-item {
                .category-shop-link {
                    a {
                        @include button-type(1);
                    }
                }
            }
        }

        .slot-panels .asset {
            padding-left: 6px;
        }
    }
}

.error-page-search {
    .error-page-search-wrap {
        input {
            font-size: rem(16);
        }
    }
}

.toggle-grid {
    @include respond-to(m_tablet_down) {
        height: 58px;
        display: block;
        font-size: rem(24);
        line-height: 58px;
        text-align: center;
    }

    @include respond-to(m_desktop) {
        display: none;
        position: absolute;
        right: 0;
    }

    i {
        width: 17px;
        height: 17px;
        display: inline-block;
        cursor: pointer;

        @include respond-to(mobile_strong) {
            margin-right: 6px;
        }

        @include respond-to(tablet_regular) {
            margin-right: 15px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:before {
            display: none;
        }
    }

    .bticons-grid {
        @include inline-svg-mono($grid-view, $black01);
    }

    .bticons-single {
        @include inline-svg-mono($single-view, $very-light-grey);
    }

    &.wide {
        .bticons-grid {
            @include inline-svg-mono($grid-view, $very-light-grey);
        }

        .bticons-single {
            @include inline-svg-mono($single-view, $black01);
        }
    }
}

.search-result-options {
    @include respond-to(m_tablet_down) {
        margin: 0 -15px 60px;
    }

    @include respond-to(m_desktop) {
        margin: 20px 0 40px;
    }

    &__inner {
        @extend %clearfix;

        position: relative;

        @include respond-to(m_tablet_down) {
            height: 60px;
            border-top: 1px solid $lighter-gray;
            border-bottom: 1px solid $lighter-gray;
        }

        &.sticky {
            @include respond-to(m_tablet_down) {
                position: fixed;
                top: 117px;
                width: 100%;
                background: $white;
                z-index: 1000;

                .change-image-view,
                .pag-items-show {
                    display: none;
                }
            }
        }

        .sort-by {
            position: relative;
            float: left;

            @include respond-to(m_tablet_down) {
                width: 50%;

                &:after {
                    @include respond-to(m_tablet_down) {
                        content: '';
                        position: absolute;
                        top: calc(50% - 3px);
                        right: 16px;
                        display: inline-block;
                        padding: 3px;
                        border: solid $black01;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg) translateX(-50%);
                    }
                }
            }

            @include respond-to(m_desktop) {
                min-width: 236px;

                &:before {
                    @include inline-svg-mono($select-arrow-down, $black01);

                    content: '';
                    width: 20px;
                    height: 19px;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    z-index: -1;
                }
            }

            .simple-submit {
                display: none;
            }

            select {
                font-style: normal !important;
                color: $hel-gray;
                background: none;

                @include respond-to(m_tablet_down) {
                    height: 58px;
                    line-height: 58px;
                    font-family: $font-main-bold;
                    font-size: rem(12);
                    text-transform: uppercase;
                    text-align: center;
                    padding: 0 35px 0 15px;
                    border: none;
                }

                @include respond-to(m_desktop) {
                    height: 40px;
                    font-size: rem(14);
                    line-height: 40px;
                    padding: 0 38px 0 15px;
                    border: 1px solid $very-light-grey;
                }
            }
        }
    }
}
