@import "breakpoints";

//Ease Smooth
$easeSmooth: cubic-bezier(0.57, 0, 0.08, 1);

// Quad
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);

.mosaic {
    margin: 50px 0 100px;
    position: relative;
    width: 100%;

    &.mosaic-waypoint {
        .mosaic-widget {
            opacity: 1;
            transform: translateY(-50%) translateX(-50%) translateZ(0px);
        }

        .mosaic-widget .mosaic-title,
        .mosaic-widget .mosaic-description,
        .mosaic-list:nth-child(odd) .mosaic-item,
        .mosaic-list:nth-child(even) .mosaic-item {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    .mosaic-description {
        font-size: rem(14);
        line-height: 1.2;
    }
}

.mosaic-perspective {
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
}

.mosaic-framer {
    margin: 0 auto;
    max-width: 960px;
    position: relative;
    width: calc(100% - 180px);

    @include mq-mobile {
        width: 90%;
    }
}

.mosaic-list:nth-child(odd),
.mosaic-list:nth-child(even) {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(center);

    flex-wrap: wrap;

    .mosaic-item {
        display: block;
        position: relative;

        &::before {
            left: 0;
            position: absolute;
            top: 0;
            background-color: rgba($color-primary3, .2);
            content: "";
            display: block;
            height: 100%;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms $easeSmooth;
            width: 100%;
        }

        .component-image {
            display: block;
            height: auto;
            width: 100%;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }
}

.mosaic-list:nth-child(odd) {
    .mosaic-item {
        &:nth-child(1),
        &:nth-child(2) {
            @include align-self(flex-end);

            width: 20%;
        }

        &:nth-child(1) {
            opacity: 0;
            transform: translateY(40px);
            transition: transform 800ms $easeOutQuad, opacity 800ms $easeOutQuad;

            @include mq-mobile {
                width: 55%;
            }
        }

        &:nth-child(2) {
            margin: 0 20px;
            opacity: 0;
            transform: translateY(80px);
            transition: transform 800ms $easeOutQuad, opacity 800ms $easeOutQuad;
            transition-delay: 50ms;

            @include mq-mobile {
                margin: 0 0 0 20px;
                width: 36%;
            }
        }

        &:nth-child(3) {
            opacity: 0;
            transition: transform 700ms $easeOutQuad, opacity 700ms $easeOutQuad;
            transform: translateY(80px);
            width: 30%;

            @include mq-mobile {
                display: none;
            }
        }
    }
}

.mosaic-list:nth-child(even) {
    margin: 20px 0 0;

    .mosaic-item {
        &:nth-child(1),
        &:nth-child(4) {
            opacity: 0;
            transition: transform 600ms $easeOutQuad, opacity 600ms $easeOutQuad;
            transform: translateY(200px);
            width: 15%;

            @include mq-mobile {
                display: none;
            }
        }

        &:nth-child(2),
        &:nth-child(3) {
            opacity: 0;
            transition: transform 800ms $easeOutQuad, opacity 800ms $easeOutQuad;
            width: 30%;
        }

        &:nth-child(2) {
            margin: 0 20px;
            transform: translateY(150px);
            transition-delay: 50ms;

            @include mq-mobile {
                margin: 0;
                width: 43%;
            }
        }

        &:nth-child(3) {
            margin: 0 20px 0 0;
            transform: translateY(200px);
            transition-delay: 200ms;

            @include mq-mobile {
                margin: 0 0 0 20px;
                width: 50%;
            }

            @include respond-to(mobile_stronger) {
                margin: 0 18px 0 0;
            }
        }
    }
}

.mosaic-widget {
    left: 50%;
    max-width: 380px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-35%) translateX(-50%);
    transition: transform 1000ms $easeOutQuad, opacity 1000ms $easeOutQuad;
    width: 30%;
    z-index: 23;

    @include mq-mobile {
        width: 60%;
    }

    img {
        padding: 10px;
    }

    .mosaic-content {
        @include border-tape($border-color: rgba($color-primary3, .5));

        padding: 10px;
        text-align: center;

        &::before,
        &::after {
            background-color: currentColor;
        }

        .border-type-inner {
            &::before,
            &::after {
                background-color: currentColor;
            }
        }

        .border-type-inner {
            padding: 40px;

            @include respond-to(mobile) {
                padding: 20px;
            }
        }

        .text-color-light {
            color: $color-primary3;
        }

        .text-color-dark {
            color: $black01;
        }

        .mosaic-title {
            font-family: $font-main;
            color: inherit;
            opacity: 0;
            transform: translateY(20px);
            transition: transform 1000ms $easeOutQuad, opacity 1000ms $easeOutQuad;
            transition-delay: 100ms;

            @include respond-to(mobile) {
                font-size: rem(22);
            }

            @include respond-to(tablet_plus) {
                font-size: rem(32);
            }
        }

        .mosaic-description {
            margin: 20px 0;
            opacity: 0;
            transform: translateY(20px);
            transition: transform 1000ms $easeOutQuad, opacity 1000ms $easeOutQuad;
            transition-delay: 200ms;

            @include mq-mobile {
                margin: 10px 0;
            }

            @include respond-to(mobile_min) {
                font-size: rem(14);
            }
        }

        .mosaic-btn-cta {
            @include respond-to(mobile) {
                min-width: 120px;
            }
        }
    }
}
