// This Sass loop is supposed to generate clipped borders and descending
// z-indexes. If you need to add new block on homepage for slot or any other
// block that should have clip at the bottom corners - please use class prefix
// "clip-row-" and choose next number after the last one (e.g., "clip-row-5").
// Currently the number of autogenerated classes are 10 - if you need more,
// just change the value of $row-quantity
$class-prefix: "clip-row";
$start-z-index: 100;
$row-quantity: 10;

@for $i from 1 through $row-quantity {
    .#{$class-prefix}-#{$i} {
        @include clip-decorator($z-index: $start-z-index - $i * 10);

        margin-top: -15px;
    }
}

.menu-category {
    .slot-panels-container {
        .asset {
            padding: 30px 30px 0;

            a {
                line-height: 1;
                text-align: left;
            }

            img {
                border-radius: 5px;
                width: 80%;
                height: 100%;
            }

            p {
                @include link_underline($medium-blue);

                font-size: rem(14);
                display: inline-block;
            }
        }
    }
}

.slot-assets-list {
    @extend %clearfix;

    margin: 0;

    .slick-dots {
        text-align: center;
    }
}

.slot-assets-list-item {
    float: left;
    overflow: hidden;
    position: relative;
    width: 50%;

    @include respond-to(mobile) {
        float: none;
        width: 100%;
    }
}

.slot-asset-item {
    position: relative;
}

.js-product-grid {
    @include shared-jcarousel-nav;
}

.category-descriptions,
.two-columns-header-slot-wrapper,
.brand-banner-slot-wrapper {
    .component-image {
        max-width: 100%;
        display: block;
        width: 100%;
    }
}
.hp-best-sellers {
    .main-title {
        margin-top: 30px;
    }
}
