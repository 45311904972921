.cat-wall-link {
    @include flex-basis(250px);
    @include button-type;
    @include ripple-effect;

    margin-bottom: 30px;

    @include respond-to(mobile) {
        @include flex-basis(46%);

        font-size: rem(11);
        margin-bottom: 20px;
    }
}

.cat-wall-link-sale {
    color: $color-primary3;
    background: $red01;
    border-color: $red01;

    &:hover {
        background: transparent;
        color: $red01;
    }
}

.cat-wall-links-title {
    font-family: $font-main;
    text-align: center;
    margin: 50px 0 80px;

    @include respond-to(mobile) {
        font-size: rem(22);
        margin-bottom: 25px;
    }

    @include respond-to(tablet_plus) {
        font-size: rem(32);
    }
}

.cat-wall-links-wrapper {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-start);
    @include flex-wrap();

    margin: 50px auto;
    max-width: 1000px;
    padding-left: 30px;
    padding-right: 30px;

    @include respond-to(mobile) {
        margin: 20px auto 50px;
    }
}