// Promo slot
.hp-promo-slot {
    margin-top: 0;
}

.promo-left {
    padding-left: 60%;
    padding-right: 7%;
}

.promo-right {
    padding-left: 7%;
    padding-right: 60%;
}


.two-columns-header-slot-wrapper {
    @include clip-decorator;

    .promo-left {
        @include respond-to(mobile_to_desktop) {
            padding-left: 45%;
        }
    }

    .promo-right {
        @include respond-to(mobile_to_desktop) {
            padding-right: 45%;
        }
    }

    .promo-content-wrapper {
        bottom: 0;
        color: $color-primary3;
        left: 0;
        padding-top: 25%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;

        @include respond-to(desktop) {
            padding-top: 25%;
        }
        @include respond-to(tablet) {
            padding-top: 20%;
        }
        @include respond-to(mobile) {
            color: $black01;
            padding: 25px;
            position: static;
        }
    }

    img {
        @include respond-to(mobile) {
            @include clip-decorator;
        }
    }
}

.promo-asset-title {
    font-family: $font-main;
    color: $color-primary3;
    text-transform: uppercase;
    margin-bottom: 10px;


    @include respond-to(mobile) {
        color: $black01;
        margin-bottom: 18px;
    }
}

.promo-asset-description {
    min-height: 78px;
    font-size: rem(14);
    line-height: 1.1;
    letter-spacing: -0.02em;
    margin: 0 0 30px;

    @include respond-to(mobile) {
        margin-bottom: 18px;
        min-height: 0;
    }

    @include respond-to(mobile_to_desktop) {
        overflow: hidden;
        max-height: 78px;
    }
}

.promo-asset-link {
    @include respond-to(mobile) {
        color: $black01;
    }
}
