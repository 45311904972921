#header-wrapper {
    min-height: 117px;

    @include respond-to(desktop_plus) {
        min-height: 165px;
        transition: min-height 0.3s;

        &.is-sticky {
            min-height: 123px;
        }
    }
}

.container-searchbox {
    order: 3;
    display: flex;
    align-items: center;
    width: 17%;

    @include respond-to(tablet_regular) {
        width: 25%;
    }

    @include respond-to(mobile_strong) {
        width: 100%;
        order: 4;
        position: relative;

        &::before,
        &::after {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: -10px;
            bottom: auto;
            background-color: rgba(0, 0, 0, 0.6);
        }

        &::before {
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
            left: 0;
        }

        &::after {
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            right: 0;
        }
    }

    @include respond-to(mobile_strong_over) {
        z-index: $header-inner-start-z-index + 2;
        transition: background-color .3s;

        .searchbox-focused & {
            background: $color-primary2;

            button[type="submit"],
            button[type="reset"] {
                i {
                    color: $color-primary3;
                }
            }
        }
    }

    .is-sticky & {
        .header-search-icon {
            display: none;
        }
    }
}

.containter-cart-wish {
    display: flex;
    align-items: center;
    order: 4;
    width: 13%;

    @include respond-to(mobile_strong_over) {
        justify-content: center;
    }

    @include respond-to(tablet_regular) {
        width: 15%;
    }

    @include respond-to(mobile_strong) {
        order: 3;
        width: 30%;
        padding-right: 20px;
        justify-content: flex-end;
    }

    .header-wishlist {
        margin: 0;
        order: 1;

        @include respond-to(mobile_strong_over) {
            display: block;
            text-align: center;
            position: relative;
        }

        @include respond-to(mobile_stronger) {
            width: $header-inner-wrapper-height-tablet;
        }

        @include respond-to(mobile_strong) {
            margin-right: 10px;
        }

        .aricons-wishlist {
            margin-left: 25px;

            .path2 {
                color: $black01;

                &::before {
                    color: $black01;
                }
            }
            .path3 {
                color: $black01;

                &::before {
                    color: inherit;
                }
            }
        }

        &.wishlist-empty a {
            .aricons-wishlist {
                .path3 {
                    color: transparent;
                }
                .path2 {
                    color: $header-links-color;

                    @include respond-to(mobile_strong) {
                        color: $black01;
                    }
                }
            }
        }

        a:hover {
            color: $black01;

            .aricons-wishlist {
                .path2,
                .path3 {
                    color: $black01;
                }
            }
        }

        i span {
            font-size: inherit;
            letter-spacing: 0;
        }

        > a {
            i {
                font-size: rem(20);

                @include respond-to(mobile_strong_over) {
                    vertical-align: text-top;
                }
            }

            span {
                letter-spacing: 0.1rem;
            }
        }

        a {
            @include respond-to(mobile_strong) {
                white-space: nowrap;
            }
        }
    }

    .is-sticky & {
        .wishlist-items-count {
            color: $black01;
        }
    }
}

.header-wishlist,
.mini-cart-total {
    -webkit-tap-highlight-color:rgba(0,0,0,0);

    @include respond-to(mobile_strong) {
        a {
            @include flexbox;
            @include align-items(center);
            @include justify-content(flex-end);

            .wishlist-items-count {
                padding: 0 2px 0 0;
            }
        }
    }
}

.header-wishlist .wishlist-items-count,
.mini-cart-link .minicart-quantity {
    font-size: rem(12);
}

.header-banner {
    @include respond-to(mobile_strong_over) {
        position: relative;
        z-index: $top-banner-z-index;
    }
}

.top-banner .header-search,
.is-sticky .top-banner .header-search {
    border: none;

    @include respond-to(mobile_strong) {
        padding: 20px 10px 10px 10px;
        top: 0;
        background-color: $header-search-bg;
    }

    @include respond-to(mobile_strong_over) {
        display: block;
        position: relative;
        float: none;
        margin: 0 auto;
        padding: 0;
        top: auto;
        width: 85%;
        background-color: transparent;

        &:hover {
            input[type="text"] {
                border-bottom-color: $header-search-field-border-hover-color;
            }
        }
    }

    input[type="text"] {
        border-width: 0;
        border-radius: 0;
        font-size: rem(14);
        font-weight: normal;

        &:focus {
            text-transform: uppercase;
        }

        @include respond-to(mobile) {
            font-size: rem(18);
        }

        @include respond-to(mobile_strong) {
            line-height: 30px;
            height: 30px;
            padding: 0 70px 0 20px;
        }

        @include respond-to(mobile_strong_over) {
            border-bottom: 1px solid $header-search-field-border-color;
            color: $black01;

            &:focus {
                @include placeholder {
                    color: transparent;
                }

                background-color: transparent;
                border-bottom-color: $color-primary3;
                color: $color-primary3;
            }
        }

        &:not(:focus) {
            @include custom-respond-to($break_mobile_strong) {
                color: $black01;
            }
        }
    }

    button[type="submit"] {
        padding: 5px;
        top: 0;
        right: 0;

        @include respond-to(mobile_strong_over) {
            padding: 0;
            top: 3px;
        }

        i {
            font-size: rem(18);

            &.bticons-search {
                display: block;

                @include respond-to(mobile_strong_over) {
                    padding: 3px 3px 0 0;
                }
            }
        }
    }

    button[type="reset"] {
        @include respond-to(mobile_strong) {
            width: 30px;
            height: 30px;
            display: none;
            top: 0;
            right: 31px;
            padding: 0;
        }

        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    form {
        margin-bottom: 0;
        width: 100%;
    }
}

.header-search {
    button i {
        @include respond-to(mobile_strong) {
            font-size: rem(12);
        }
    }

    input[type="text"]:focus ~ .clear-button {
        @include respond-to(mobile_strong) {
            display: block;
        }
    }

    .simple.clear-button {
        @include hidden;
    }
}

.header-banner {
    .top-banner-asset {
        background-color: $black01;
        padding: 3px 0;

        @include respond-to(mobile_strong_over) {
            padding: 3px 0 8px;
        }
    }

    .asset {
        font-family: $font-main-black;

        @include respond-to(mobile_strong_over) {
            margin-bottom: 0;
        }
    }
}

.sticky-wrapper.is-sticky .header-search.toggle-content { // reset
    display: block;
}

.header-inner-wrapper {
    position: relative;
    z-index: $header-inner-start-z-index + 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $color-primary3;

    .is-sticky & {
        @include respond-to(mobile_strong_over) {
            height: 62px;
            border-bottom: 1px solid $color-secondary3;
        }
    }
}

.top-banner {
    background-color: transparent;
    position: relative;
    z-index: $top-banner-z-index;

    &::before {
        @include respond-to(mobile_strong_over) {
            position: fixed;
            z-index: $header-inner-start-z-index + 1;
            top: $header-inner-wrapper-sticky-height + $header-sticky-border-top-width;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($black01, 0.65);
            opacity: 0;
            transition: opacity .3s;
            pointer-events: none;
            content: '';
        }
    }

    &.searchbox-focused {
        &::before {
            @include respond-to(mobile_strong_over) {
                opacity: 1;
                pointer-events: auto;
            }
        }

        .menu-utility-user {
            .user-info {
                &.active {
                    .user-panel {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }

        .no-mobile & .mini-cart-content {
            display: none !important;
        }
    }

    #wrapper & a,
    #wrapper & .header-inner-wrapper a span {
        transition: color .3s, background-color .3s; //  override (core_ext) transition: none
    }

    .primary-logo {
        line-height: 1;
        padding: 36px 0;
        margin: 0;
        background-color: $color-primary3;
        font-size: rem(21);
        order: 2;
        width: 40%;
        text-align: center;
        position: relative;
        z-index: 1;

        @include respond-to(tablet_regular) {
            width: 20%;
        }

        @include respond-to(desktop_plus) {
            font-size: rem(29);
        }

        @include respond-to(mobile_stronger) {
            padding: 18px 0;
        }

        @include respond-to(mobile_strong) {
            padding: 23px 0;
            width: 40%;

            .sticky-wrapper.is-sticky & {
                font-size: rem(21);
            }
        }

        @include respond-to(mobile_min) {
            width: 15rem;
            font-size: rem(17);
        }

        a {
            position: relative;
            width: 100%;
            text-align: center;
            color: $header-logo-color;

            &:hover {
                color: $header-logo-hover-color;
            }
        }

        #wrapper & a {
            -webkit-transition: none;
            transition: none;
        }

        .aricons-arnotts-logo {
            &::before {
                display: none;
            }
        }

        &-image {
            width: 180px;

            @include respond-to(desktop_plus) {
                transition: width 0.3s ease-in-out;

                @include browsersIeAndEdge {
                    transition: none;
                }
            }

            @include respond-to(mobile_stronger) {
                width: 130px;
            }

            @include respond-to(mobile_strong) {
                width: 94px;
            }
        }

        .sticky-wrapper.is-sticky & {
            @include respond-to(mobile_strong_over) {
                padding: 18px 0; // need 62px height for buttons and correct cart flyout top position

                &-image {
                    width: 130px;
                }
            }

            @include respond-to(mobile_strong) {
                &-image {
                    width: 94px;
                }
            }

            @include respond-to(mobile_stronger) {
                font-size: rem(21);
            }

            a {
                color: $black01;

                @include custom-respond-to($break_mobile_strong, $break_desktop) {
                    color: $header-logo-color;
                }
            }
        }
    }

    .sticky-wrapper.is-sticky & {
        background-color: transparent;
        border-bottom: 0;

        &.searchbox-focused {
            @include respond-to(mobile_strong_over) {
                background-color: $color-primary3;
            }
        }
    }
}

#header-wrapper .sticky-wrapper .containter-cart-wish.expanded,
#header-wrapper.sticky-wrapper .containter-cart-wish.expanded  {
    width: 100%;
}

.sticky-wrapper {
    &.is-sticky {
        @include respond-to(mobile_strong_over) {
            height: auto !important;
        }

        @include respond-to(mobile_strong_over) {
            .menu-category {
                &.level-1 {
                    border-top: 0;
                }
            }
        }
    }
}

.header-cookies {
    background-color: $color-auxiliary38; // Old browsers
    background: linear-gradient($color-auxiliary38, $color-primary3);

    .button {
        @extend button.coloured;

        text-transform: capitalize;
    }
}

.ios8.no-desktop,
.ios7.no-desktop {
    #wrapper {
        &.mini-cart-active {
            .containter-cart-wish { // copy from core_ext due to class rename
                z-index: 0;
            }
        }
    }
}

.no-desktop {
    .mini-cart-active {
        .close-minicart {
            &::before {
                content: "\e95b";
                font-size: rem(26);
            }
        }

        .mini-cart-content {
            right: 0;
        }
    }

    &:not(.ios) {
        .mini-cart-content {
            @include respond-to(mobile_strong){
                min-height: 100vh;
            }
        }
    }

    #wrapper {
        &.mini-cart-active {
            .header-search {
                display: block;
            }

            #main,
            .top-banner,
            .footer,
            .header-banner {
                transform: none;
            }

            .js-close-minicart {
                &::before {
                    height: $header-height-mobile;
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: $header-height-mobile;
                }
            }
        }
    }

    .mini-cart-content {
        top: 0 !important;
        right: -$minicart-touch-width;
        left: auto;
        transition: right 500ms ease;

        @include respond-to(mobile_strong_over){
            margin-top: 0;
        }
    }
}

[data-slot-id="header-slider-panel"] {
    .asset {
        font-size: rem(12);
        font-family: $font-main-black;
        padding: 15px 20px;
        text-align: center;
    }

    a {
        color: inherit;
    }

    // Fix for slow js loading (when selectors load after content)
    & > div {
        display: none;
    }

    .slick-slider {
        display: block;
    }
}

.header-slider-panel {
    position: relative;
    font-size: rem(10);
    z-index: 92;

    .timer-container {
        display: inline;
    }

    .timer-hours {
        width: 60px;
        display: inline-block;
    }

    .content-asset {
        font-family: $font-main-black;
        padding: 0 20px;
        text-align: center;
        height: 48px;
        line-height: 48px;
    }

    .header-slide {
        display: inline-block;
        position: relative;
        max-width: 100%;
    }

    .header-slide-inner {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 70ch;
        margin: 0 auto;
    }

    .overflow-handler-tooltip {
        display: none;
        line-height: 1.5;
        position: absolute;
        top: 110%;
        color: $black01;

        @include respond-to(touch) {
            transform: translateX(-50%);
            width: 90%;
            left: 50%;
            overflow: hidden;
            background: $white;
            border-radius: 15px;
            padding: 0 10px;
            word-break: break-all;
            white-space: normal;
            padding: 10px;
            border: 1px solid $black01;
        }

        @include respond-to(touch_over) {
            width: 60%;
            left: 40%;
            padding: 5px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            background-color: #E8E8E8;
            box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
        }
    }

    .overflow-handler {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        opacity: 0;
        cursor: pointer;

        &:hover {
            ~ .overflow-handler-tooltip {
                display: block;
            }
        }
    }

    .no-truncate { // Custom CSS class to hide ellipsis
        .header-slide-inner {
            text-overflow: initial;
            max-width: none;
            overflow: visible;
        }

        .overflow-handler {
            display: none !important;
        }
    }

    svg {
        display: inline-block;
        vertical-align: middle;
    }

    a {
        color: inherit;
    }

    // Fix for slow js loading (when selectors load after content)
    & > div {
        display: none;
    }

    .slick-list {
        overflow: visible; // show tooltip
    }

    .slick-track {
        max-height: 48px;

        @include respond-to(mobile_strong_over) {
            width: auto !important;
            display: flex;
            flex-wrap: nowrap;
        }
    }

    .slick-slide {
        max-height: 48px;

        @include respond-to(mobile_strong_over) {
            border-left: 2px solid rgba($color: #ffffff, $alpha: 0.3);
            flex: 1 1 auto;

            &:first-child {
                border-left: none;
            }
        }

        & > div {
            display: flex;
            justify-content: center;
            height: 100%;
            max-height: 48px;
        }
    }

    .slick-slider {
        display: block;
        max-width: var(--mx-width); // value from Config JSON in BM
        margin: 0 auto;
    }

    .slick-arrow {
        transform: translateY(-50%);
        margin: 0;
        padding: 8px 0;
        background-color: transparent;
        border: 0;

        &:hover {
            opacity: 1;
        }

        &::before {
            font-size: rem(18);
            color: rgba($color: #ffffff, $alpha: 0.5);
        }
    }

    .slick-prev {
        @include icon(simple-arrow-left);

        left: 5px;

        &::after {
            display: none;
        }
    }

    .slick-next {
        @include icon(simple-arrow-right);

        &::after {
            display: none;
        }
    }

    &.removable {
        @include respond-to(m_mobile_down) {
            padding-right: 15px;
        }

        .content-asset {
            @include respond-to(m_mobile_down) {
                padding-right: 25px
            }
        }

        .slick-next {
            @include respond-to(m_mobile_down) {
                right: 7px;
            }
        }
    }

    .remove {
        font-size: rem(45);
        line-height: 24px;
        position: absolute;
        right: 2px;
        top: 0;
        z-index: 10;
        padding: 0 0 20px 0;
        cursor: pointer;
        opacity: 0.5;
    }
}