// Catogory descriptions slot
.category-descriptions {
    padding-top: 3em !important;

    @include respond-to(mobile_strong_over) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1170px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .badge {
        @include shared-badge;

        font-size: rem(16);
        left: -52px;
        top: 60px;
        width: 260px;
    }

    .category-asset-description {
        font-size: rem(14);
        line-height: 1.4;

        @include respond-to(mobile_strong) {
            line-height: 1;
            letter-spacing: -0.02em;
        }
    }
}

.slot-panels-four-box {
    .slot-assets-list {
        @include flexbox;
        @include justify-content(space-between);
        @include flex-wrap;
        @include align-items(flex-start);

        @include respond-to(mobile_strong) {
            display: block;
        }

        &::after {
            display: none;
        }
    }

    a:hover {
        color: $black01;
    }

    .slot-assets-list-item {
        margin-bottom: 65px;
        width: 44%;

        @include respond-to(mobile_strong) {
            margin-bottom: 50px;
            padding: 0 30px;
            width: 100%;
        }

        @include respond-to(mobile_strong_over) {
            float: left;
            max-width: 500px;
        }
    }
}

.slot-panels-full-width {
    margin-bottom: 50px;
    max-width: 1440px;

    @include respond-to(mobile_strong_over) {
        padding-left: 0;
        padding-right: 0;
    }

    a:hover {
        color: $black01;
    }

    .slot-assets-list-item {
        float: left;
        overflow: hidden;
        position: relative;
        width: 50%;

        @include respond-to(mobile_strong) {
            float: none;
            width: 100%;
        }

        + .slot-assets-list-item {
            @include respond-to(mobile_strong) {
                margin-top: 45px;
            }
        }

        &:nth-child(n + 3) {
            margin-top: 45px;
        }
    }
}

.category-content-wrapper {
    text-align: center;
}

.category-asset-title-wrapper {
    @include border-tape($border-color: $color-primary3, $bgcolor: $color-primary3);

    margin: -60px auto 24px;
    width: 56%;

    @include respond-to(touch) {
        margin: -40px auto 5px;
        width: 80%;
    }

    @include respond-to(touch_over) {
        margin-bottom: 0;
    }

    .category-asset-title {
        font-size: rem(18);
        line-height: 1;
        text-transform: uppercase;
        padding: 32px 10px 0;
        word-break: break-word;

        @include respond-to(touch) {
            padding-top: 16px;
        }

        @include respond-to(touch_over) {
            height: 78px;
        }
    }
}

.category-asset-description {
    margin: 0 0 30px;
    padding: 0 25px;

    .slot-panels-full-width & {
        margin: 0 auto 30px;
        width: 56%;

        @include respond-to(touch) {
            padding: 0 10px;
            width: 80%;
        }
    }

    @include respond-to(mobile_strong) {
        margin-bottom: 20px;
        padding: 0;
    }
}
