@include respond-to(touch) {
    img {
        max-width: 100%;
    }

    div[id*="holder_tc"],
    div[id*="holder_tc"] div,
    div[id*="holder_tc"] img {
        max-width: initial;
    }

    .tooltip {
        font-size: .9em;
    }

    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }

    .col-1,
    .col-2 {
        float: none;
        width: 100%;
    }

    .scrollable {
        height: auto;

        li {
            float: left;

            a {
                border: 1px solid $sections-body-border;
                border-radius: 6px;
                display: block;
                padding: .2em .8em;
            }
        }
    }

    #header {
        height: auto;
        padding: 1% 1% 30px;

        .primary-logo {
            float: left;
            position: relative;
            top: auto;
            width: 65%;

            img {
                max-width: 100%;
            }
        }
    }

    #quickviewbutton {
        display: none !important;
    }

    .product-listing-1x4 ul.search-result-items .grid-tile {
        margin: 2%;
        width: 92%;
    }

    .compareitems {
        display: none !important;
    }

    .pt_product-details {
        .primary-content {
            padding-bottom: 68px;
        }
        
        .breadcrumb .last {
            display: none;
        }
    }

    #product-nav-container {
        bottom: 0;
        width: 100%;

        div {
            width: 60px;

            &.product-next {
                float: right;
            }
        }

        .divided span {
            border: 0;
        }
    }

    .sizinginformation {
        padding: 0;

        th {
            padding: 0 0 0 2px;
        }

        td {
            background: $white;
            padding: 5px;
            text-align: center;
        }

        .sizechart {
            padding: 0 !important;
        }
    }

    .pt_cart #secondary {
        display: none;
    }

    .order-summary-footer h2 {
        width: 50%;
    }

    .place-order-totals .order-totals-table {
        width: 100%;
    }
}

@include respond-to(mobile_top) {
    .product-tile .pr_stars {
        left: 40px;
    }

    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;

        .fb_iframe_widget {
            position: relative;
            width: 100% !important;

            span {
                position: relative;
                width: 100% !important;

                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }

    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}

@include respond-to(tablet) {
    .html-slot-container img,
    .bottom-banner-cell img {
        max-width: 100%;
    }

    .ui-dialog {
        max-width: 98%;

        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }

    .scrollable {
        height: auto;
    }

    .mini-cart-total-label {
        display: none;
    }

    .mini-cart-products {
        max-height: 100%;
    }

    table .section-header {
        font-size: 1em !important;
    }

    .pt_gift-registry .add-gift-cert p {
        width: auto;
    }

    #cart-table {
        .item-user-actions {
            width: 72px;
        }
    }

    .home-bottom-right .product-tile {
        min-height: 0;
    }

    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;

        .fb_iframe_widget {
            position: relative;
            width: 100% !important;

            span {
                position: relative;
                width: 100% !important;

                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    
    .mini-cart-item-word{
        span{
            display:none;
        }
    }
}

@include respond-to(mobile_min) {
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}
