/*
*
* Video JS Skin CSS
*
*/

.video-js {
    font-size: 10px;
    overflow: hidden;
    outline: none;
}
.video-js.vjs-fluid {
    padding-top: 100%;
}
.ratio-21-9 .video-js.vjs-fluid {
    padding-top: 42.8%;
}
.ratio-16-9 .video-js.vjs-fluid {
    padding-top: 56.25%;
}
.ratio-4-3 .video-js.vjs-fluid {
    padding-top: 75%;
}
.video-js .vjs-tech {
    background-color: #fff;
}
.amp-video-panel .video-js {
    font-size: 10px;
    margin: 0 auto;
    outline: none;
    overflow: hidden;
}
.amp-video-panel .video-js .vjs-tech {
    background-color: #fff;
}
.vjs-default-skin .vjs-big-play-button {
    font-size: 3em;
    line-height: 3em;
    height: 3em;
    width: 3em;
    border: 0;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    margin-left: -1.5em;
    margin-top: -1.5em;
}
.video-js:hover .vjs-big-play-button {
    background-color: #EEEEEE;
    color: #222222;
}
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
    background-color: #222222;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
}
.video-js .vjs-control-bar {
    background-color: rgba(0,0,0,0.6);
}
.video-js .vjs-slider {
    background-color: #ffffff !important;
}
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
    background: #ffffff;
    height: 3px !important;
}
.video-js .vjs-progress-control:hover .vjs-progress-holder {
     font-size: 1em; 
}
.video-js .vjs-progress-control:hover .vjs-mouse-display:after, 
.video-js .vjs-progress-control:hover .vjs-play-progress:after {
    display: none;
}
.video-js .vjs-play-progress:before {
    font-size: 1.2em;
}

.video-js .vjs-progress-control .vjs-mouse-display {
    width: 4px;
    height: 100%;
    background-color: rgb(255,0,0);
}
.vjs-progress-holder.vjs-slider.vjs-slider-horizontal {
    background-color: #5D5D5D !important;
}
.video-js .vjs-load-progress {
    background-color: #B1B1B1 !important;
}
.video-js .vjs-load-progress div {
    background: ligthen(#656565, 50%);
    background: rgba(101, 101, 101, 0.75);
}
.video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before, .video-js .vjs-control:focus {
    text-shadow: none;
}
.vjs-paused button.vjs-big-play-button {
    display: block;
}
.video-js .vjs-volume-level, {
    background-color: #fff !important;
}
.vjs-volume-bar.vjs-slider-bar.vjs-slider.vjs-slider-horizontal {
    background-color: #5D5D5D !important;
}
.vjs-remaining-time-display {
    font-family: inherit;
    font-size: 12px;
}
.video-js.vjs-default-skin.vjs-ended .vjs-poster {
    display:block !important;
}
.video-js.vjs-default-skin.vjs-ended .vjs-control-bar {
    display: none !important;
}
.vjs-poster {
    background-size: 100% 100%;
    width: 101%;
    height: 101%;
    background-size: contain;
}