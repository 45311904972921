.b-orderhistory-tabs .b-orderhistory-store {
    &.ui-tabs-panel {
        padding: 50px 0 0;
    }
}

.b-store_order {
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-size: rem(16);

    @include respond-to(m_desktop) {
        width: 48%;

        &:nth-child(odd) { // Fix for gutter as "isotope" plugin doesn't work with percentage
            margin-right: 2%;
        }
    
        &:nth-child(even) {
            margin-left: 2%;
        }
    }

    @include respond-to(m_tablet_down) {
        width: 100%;
    }

    &-row {
        display: flex;
        justify-content: space-between;
        line-height: 26px;
    }

    &-cell {
        width: 50%;
    }

    .m-right {
        text-align: right;
    }

    &-purchase {
        background-color: #000;
        padding: 1px 15px;
    }

    &-address {
        font-weight: 500;
        font-size: rem(20);
        line-height: 26px;
        padding-top: 10px;
    }

    &-info {
        line-height: 26px;
    }

    &-products {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px 0;
        margin: 20px 0;
    }

    &-tender,
    &-total_items {
        color: rgba(0, 0, 0, 0.6);
    }

    &-total,
    &-amount {
        font-weight: 500;
        font-size: rem(20);
        margin-top: 8px;
    }
}

.b-orderhistory-store {
    .button {
        width: 250px;
        display: block;
        margin: 0 auto;
        font-size: rem(14);
    }
}