$icomoon-font-path: "fonts" !default;

$aricons-remove-bin: "\e974";
$aricons-arnotts-logo: "\e900";
$aricons-social-twitter: "\e909";
$aricons-social-pinterest: "\e910";
$aricons-social-instagram: "\e911";
$aricons-social-facebook: "\e912";
$aricons-email-fill: "\e951";
$aricons-social-youtube: "\e96a";
$aricons-email: "\e952";
$aricons-burger-menu: "\e908";
$aricons-bag-empty: "\e918";
$aricons-bag-full-path1: "\e918";
$aricons-bag-full-path2: "\e918";
$aricons-close: "\e904";
$aricons-home: "\e905";
$aricons-minus: "\e906";
$aricons-plus: "\e907";
$aricons-search: "\e90a";
$aricons-search-plus: "\e968";
$aricons-share: "\e90b";
$aricons-small-arrow-down: "\e90c";
$aricons-small-arrow-left: "\e90d";
$aricons-small-arrow-right: "\e90e";
$aricons-small-arrow-up: "\e90f";
$aricons-wishlist-empty: "\e914";
$aricons-wishlist-full: "\e915";
$aricons-bag-path1: "\e918";
$aricons-bag-path2: "\e918";
$aricons-bag-path3: "\e918";
$aricons-wishlist-path1: "\e914";
$aricons-wishlist-path2: "\e914";
$aricons-wishlist-path3: "\e915";
$aricons-user-path1: "\e91b";
$aricons-user-path2: "\e91c";
$aricons-video-pause-path1: "\e91d";
$aricons-video-pause-path2: "\e91e";
$aricons-video-play-path1: "\e91f";
$aricons-video-play-path2: "\e920";
$aricons-video-play-path3: "\e921";
$aricons-thumb-size: "\e922";
$aricons-tag-path1: "\e923";
$aricons-tag-path2: "\e924";
$aricons-lock: "\e925";
$aricons-check: "\e926";
$aricons-social-twitter-fill-path1: "\e927";
$aricons-social-twitter-fill-path2: "\e928";
$aricons-social-pinterest-fill-path1: "\e929";
$aricons-social-pinterest-fill-path2: "\e92a";
$aricons-social-instagram-fill-path1: "\e92b";
$aricons-social-instagram-fill-path2: "\e92c";
$aricons-social-facebook-fill-path1: "\e92d";
$aricons-social-facebook-fill-path2: "\e92e";
$aricons-social-email-fill-path1: "\e953";
$aricons-social-email-fill-path2: "\e954";
$aricons-share-circle-path1: "\e92f";
$aricons-share-circle-path2: "\e930";
$aricons-spinner-minus-path1: "\e934";
$aricons-spinner-minus-path2: "\e935";
$aricons-spinner-minus-path3: "\e936";
$aricons-spinner-plus-path1: "\e931";
$aricons-spinner-plus-path2: "\e932";
$aricons-spinner-plus-path3: "\e933";
$aricons-number-1-path1: "\e937";
$aricons-number-1-path2: "\e938";
$aricons-number-2-path1: "\e939";
$aricons-number-2-path2: "\e93a";
$aricons-number-3-path1: "\e93b";
$aricons-number-3-path2: "\e93c";
$aricons-number-4-path1: "\e93d";
$aricons-number-4-path2: "\e93e";
$aricons-number-5-path1: "\e93f";
$aricons-number-5-path2: "\e940";
$aricons-number-6-path1: "\e941";
$aricons-number-6-path2: "\e942";
$aricons-number-7-path1: "\e943";
$aricons-number-7-path2: "\e944";
$aricons-number-8-path1: "\e945";
$aricons-number-8-path2: "\e946";
$aricons-number-9-path1: "\e947";
$aricons-number-9-path2: "\e948";
$aricons-apply: "\e949";
$aricons-dots-path1: "\e94a";
$aricons-dots-path2: "\e94b";
$aricons-dots-path3: "\e94c";
$aricons-checkbox-path1: "\e961";
$aricons-checkbox-path2: "\e962";
$aricons-checkbox2-path1: "\e963";
$aricons-checkbox2-path2: "\e964";
$aricons-radiobutton-path1: "\e965";
$aricons-radiobutton-path2: "\e966";
$aricons-close-small: "\e94d";
$aricons-close-big-path1: "\e94e";
$aricons-close-big-path2: "\e969";
$aricons-arrow-big-path1: "\e94f";
$aricons-arrow-big-path2: "\e950";
$aricons-phone: "\e955";
$aricons-location: "\e956";
$aricons-printer: "\e957";
$aricons-information: "\e958";
$aricons-screen-balloon-path1: "\e959";
$aricons-screen-balloon-path2: "\e95a";
$aricons-cellphone-balloon: "\e95d";
$aricons-balloon: "\e95e";
$aricons-bag-arnotts: "\e95f";
$aricons-close-fine: "\e95b";
$aricons-tiny-arrow-left: "\e95c";
$aricons-tiny-arrow-right: "\e960";
$aricons-up-button-path1: "\e967";
$aricons-up-button-path2: "\e973";
$aricons-spinner-minus-small-path1: "\e96b";
$aricons-spinner-minus-small-path2: "\e96c";
$aricons-spinner-minus-small-path3: "\e96d";
$aricons-spinner-plus-small-path1: "\e96e";
$aricons-spinner-plus-small-path2: "\e96f";
$aricons-spinner-plus-small-path3: "\e970";
$aricons-close-2-path1: "\e971";
$aricons-close-2-path2: "\e972";
$aricons-plane: "\e975";
