// responsive breakpoints
$break_mobmin: 319px;
$break_mobmid: 480px;
$break_mobile_strong: 768px;
$break_mobile: 801px;
$break_tablet: 992px;
$break_touch: 1024px;
$break_desktop: 1260px;
$break_widedesktop: 1380px;
$break_furniture_pdp: 1440px;

// Magazine breakpoints
$m_breakpoint_mobile_small : 416px;  //       small 26em == m_mobile_landscape
$m_breakpoint_mobile       : 768px;  //      medium 40em == m_tablet
$m_breakpoint_tablet       : 1024px; //       large 64em == m_desktop
$m_breakpoint_desktop      : 1400px; //    xlarge 87.5em == m_desktop_large
$m_breakpoint_desktop_large: 1900px; // xxlarge 118.75em == m_desktop_xlarge


@mixin respond-to($media) {
    // <= 374px
    @if $media == mobile_min {
        @media screen and (max-width: $break_mobmin) { @content; }
    }

    // <= 480px
    @if $media == mobile_mid {
        @media screen and (max-width: $break_mobmid) { @content; }
    }

    // <= 767px
    @if $media == mobile_strong {
        @media screen and (max-width: $break_mobile_strong - 1) { @content; }
    }

    // <= 800px
    @if $media == mobile {
        @media screen and (max-width: $break_mobile - 1) { @content; }
    }

    // 375px - 800px
    @if $media == mobile_top {
        @media screen and (min-width: $break_mobmin + 1) and (max-width: $break_mobile - 1) { @content; }
    }

    // >= 375px
    @if $media == not_mobile_min {
        @media screen and (min-width: $break_mobmin + 1) { @content; }
    }

    // <= 992px
    @if $media == mobile_tablet {
        @media screen and (max-width: $break_tablet) { @content; }
    }

    // 768px - 992px
    @if $media == mobile_stronger {
        @media screen and (min-width: $break_mobile_strong) and (max-width: $break_tablet) { @content; }
    }

    // >= 768px
    @if $media == mobile_strong_over {
        @media screen and (min-width: $break_mobile_strong) { @content; }
    }

    // 801px - 992px
    @if $media == tablet {
        @media screen and (min-width: $break_mobile) and (max-width: $break_tablet) { @content; }
    }

    // <= 1024px
    @if $media == touch {
        @media screen and (max-width: $break_touch) { @content; }
    }

    // 801px - 1024px
    @if $media == tablet_ext {
        @media screen and (min-width: $break_mobile) and (max-width: $break_touch) { @content; }
    }

    // 768px - 1024px
    @if $media == tablet_regular {
        @media screen and (min-width: $m_breakpoint_mobile) and (max-width: $break_touch) { @content; }
    }

    // 801px - 1260px
    @if $media == mobile_to_desktop {
        @media screen and (min-width: $break_mobile) and (max-width: $break_desktop) { @content; }
    }

    // >= 801px
    @if $media == tablet_plus {
        @media screen and (min-width: $break_mobile) { @content; }
    }

    // 993px - 1260px
    @if $media == desktop {
        @media screen and (min-width: $break_tablet + 1) and (max-width: $break_desktop) { @content; }
    }

    // <= 1260px
    @if $media == desktop_below {
        @media screen and (max-width: $break_desktop) { @content; }
    }

    // >= 993px
    @if $media == desktop_plus {
        @media screen and (min-width: $break_tablet + 1) { @content; }
    }

    // >= 1025px
    @if $media == touch_over {
        @media screen and (min-width: $break_touch + 1) { @content; }
    }

    // >= 1261px
    @if $media == desktop_over {
        @media screen and (min-width: $break_desktop + 1) { @content; }
    }

    // >= 1381px
    @if $media == widedesktop_over {
        @media screen and (min-width: $break_widedesktop + 1) { @content; }
    }

    @if $media == print {
        @media print { @content; }
    }

    // Magazine Media Queries

    // < 416px
    @if $media == m_mobile_portrait {
        @media screen and (max-width: $m_breakpoint_mobile_small - 1) { @content; }
    }

    // >= 416px
    @if $media == m_mobile_landscape {
        @media screen and (min-width: $m_breakpoint_mobile_small) { @content; }
    }

    // < 768px
    @if $media == m_mobile_down {
        @media screen and (max-width: $m_breakpoint_mobile - 1) { @content; }
    }

    // < 1440px
    @if $media == furniture_down {
        @media screen and (max-width: $break_furniture_pdp) { @content; }
    }

    // >= 768px
    @if $media == m_tablet {
        @media screen and (min-width: $m_breakpoint_mobile) { @content; }
    }

    // < 1024px
    @if $media == m_tablet_down {
        @media screen and (max-width: $m_breakpoint_tablet - 1) { @content; }
    }

    // >= 1024px
    @if $media == m_desktop {
        @media screen and (min-width: $m_breakpoint_tablet) { @content; }
    }

    // >= 1400px
    @if $media == m_desktop_large {
        @media screen and (min-width: $m_breakpoint_desktop) { @content; }
    }

    // >= 1900px
    @if $media == m_desktop_xlarge {
        @media screen and (min-width: $m_breakpoint_desktop_large) { @content; }
    }
}
