.product-tile {
    .product-swatches {
        .selected img,
        img:hover {
            border: 1px solid $color-auxiliary20;
        }

        .swatch-list li:last-child a {
            margin-right: 0;
        }
    }

    .product-swatches-all {
        &:hover {
            color: $color-auxiliary19;
        }
    }

.bticons-zoom::before {
        content: $aricons-search-plus;
    }

    .bticons-zoom,
    .product-actions-wishlist-link {
        display: block;
        padding: 0 7px;
    }

    .product-actions-wishlist-link {
        @include shared-wishlist;
    }

    .product-actions {
        margin: 0 0 10px 0;
        font-size: 0;
        text-align: left;

        &-item {
            display: inline-block;
            line-height: 1;
            font-size: rem(16);
        }

        &-quickview {
            border-right: 1px solid $very-light-gray;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }
    }

    .product-brand {
        line-height: 1;
        letter-spacing: .03em;
    }

    .product-description-link:hover {
        color: $color-auxiliary19;

        .product-name,
        .product-standard-price {
            color: $color-auxiliary19; // fix unsynced color animation ("color: inherit" won't work properly)
        }
    }

    .product-name {
        color: $black01;
        transition: color .3s ease-in-out; // fix unsynced color animation of parent anchor:hover

        @include respond-to(mobile) {
            color: $black01;
        }

        .ie & {
            max-width: 95%;
        }

        .wide-tiles & {
            @include respond-to(mobile) {
                color: $black01;
                padding-bottom: 7px;
            }

            .product-name-brand {
                @include respond-to(mobile) {
                    color: inherit;
                    line-height: 1;
                    font-size: inherit;
                    letter-spacing: .03em;
                    margin-bottom: 10px;
                }
            }

            .product-name-title {
                @include respond-to(mobile) {
                    color: inherit;
                    line-height: 1;
                    letter-spacing: 0;
                    font-size: rem(13);
                }
            }
        }
    }

    .product-pricing,
    .wide-tiles & .product-price {
        margin-top: 30px;
        line-height: 1.5;
    }

    .product-pricing {
        @include respond-to(mobile) {
            margin-top: 3px;
            text-transform: uppercase;
            line-height: 1.5;
        }

        .product-sales-price,
        .product-standard-price {
            display: block;
        }

        .now-sell-price {
            color: $red01;
        }

        .product-standard-price {
            color: $color-auxiliary16;
            transition: color .3s ease-in-out;
        }
    }

    @include shared-promo-flag();

    .promo-flag {
        display: inline-block;
        margin: 0 auto 10px;

        span {
            padding: 5px 8px;
        }
    }

    .promo-flag-below,
    .promo-flag-img-below {
        @include hidden;
    }

    .shop_now-quickview & {
        position: static;
    }

    .shop_now-button,
    .button-dual_functional {
        display: none;
    }

    .shop_now-button {
        .shop_now-quickview & {
            @extend button.coloured;
            @extend .button-type3;

            display: block;
            width: 100%;
            min-width: 0;
        }
    }

    .button-dual_functional {
        .shop_now-quickview & {
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            border: 0;
            width: 90%;

            @include respond-to(m_tablet_down) {
                min-width: auto;
            }
        }
    }

    .shop_now-button-inner {
        &::before {
            content: attr(data-alt-title);
        }
    }

    .column-left-sidebar & {
        .shop_now-button {
            min-width: auto;
        }
    }

    .wide-tiles & {
        .product-price {
            @include shared-product-price;

            color: $red01;

            @include respond-to(mobile) { // All prices
                margin-top: 3px;
                font-size: rem(13);
            }

            @include respond-to(mobile_strong_over) {
                margin: 0 0 15px;
            }

            & > span:only-of-type {
                color: $black01;
            }

            & > span:first-child {
                display: block;
                font-size: rem(13);
            }


            > span:last-child { // Standard Price
                @include respond-to(mobile) {
                    text-decoration: line-through;
                    color: $color-auxiliary16;
                    display: block;
                    font-size: inherit;
                    font-size: rem(11);;
                }

                span { // was
                    @include respond-to(mobile) {
                        margin-right: 0;
                    }
                }
            }

            > span:only-of-type {
                @include respond-to(mobile) { // Sale Price ONLY
                    color: $black01;
                    text-decoration: none;
                }
            }
        }

        .product-name,
        .product-variations,
        .product-add-to-cart {
            @include respond-to(desktop_plus) {
                max-width: 78%;
            }
        }

        .product-name {
            @include respond-to(mobile_strong_over) {
                padding-bottom: 4px;
            }
        }

        .product-variations {
            .label {
                @include respond-to(mobile_strong_over) {
                    font-size: rem(14);
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }
            }

            .attribute {
                @include respond-to(mobile_strong_over) {
                    margin: 0 0 25px;
                }

                .toggle {
                    .value {
                        @include respond-to(desktop_plus) {
                            max-width: 300px;
                            width: auto;
                        }
                    }

                    .selected-value {
                        @include icomoon-icon($aricons-small-arrow-down, $font-size: rem(10), $line-height: 24px, $pseudo: after) {
                            color: $black01;
                        }

                        &:hover:after {
                            color: $color-primary2;
                        }
                    }
                }
            }
        }
    }

    .product-compare {
        .add-bonus-chk {
            @include check(checkbox);

            &:checked {
                + label {
                    &::before,
                    &::after {
                        content: '' !important;
                    }
                }
            }

            ~ label {
                display: inline-block;
            }
        }
    }
}

.wide-tiles {
    &.template_2 {
        .product-tile {
            .product-actions {
                @include respond-to(mobile) {
                    text-align: left;
                }
            }
        }
    }

    .search-result-items {
        @include respond-to(mobile_strong_over) {
            border-top: 1px solid $color-secondary3;
        }

        .grid-tile {
            border-bottom: 1px solid $color-secondary3;
            padding: 25px 0;
        }
    }

    .product-tile {
        .product-variations {
            .attribute {
                .value {
                    width: 100%;
                }

                .toggle {
                    &.expanded {
                        .swatches {
                            top: calc(100% - 1px);

                            li {
                                .swatchanchor {
                                    span {
                                        line-height: 44px;
                                    }
                                }
                            }
                        }
                    }

                    .selected-value {
                        &::after {
                            line-height: 32px;
                        }

                        span {
                            line-height: 44px;
                        }
                    }
                }
            }
        }
    }
}
