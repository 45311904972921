.primary-content { //This nesting is necessary to avoid applying styles for QV
    .product-wrapper {
        @include full-page-border;

        @include respond-to(mobile_strong_over) {
            margin-bottom: 96px;
        }
    
        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
        }
    }
}

.pdp-main {
    @include shared-promo-flag();

    .promo-flag {
        margin-bottom: 26px;
        text-align: left;

        @at-root {
            .pdp-main .pdp-recommendations .promo-flag,
            .pdp-main .product-tile .promo-flag {
                width: auto;
                margin: 0 0 20px;
                padding: 0;
                text-align: left;
            }
        }

        @include respond-to(mobile) {
            display: block;
            text-align: center;
            width: 100%;

            span {
                display: inline-block;
                height: auto;
                vertical-align: baseline;
            }
        }
    }

    .product-detail {
        .product_actions-instore_link,
        .product_actions-wishlist_link {
            @include product_actions-button;

            &:hover {
                @include respond-to(desktop_below) {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }

        .product_actions-wishlist_link {
            @include shared-wishlist;
        }
    }

    .product-col-2 {
        @include respond-to(mobile) {
            width: 100%;
        }

        .qty-title {
            width: 30%;
            line-height: 30px;
            font-size: rem(14);
            float: right;
            text-align: center;
            text-transform: uppercase;

            @include respond-to(tablet) {
                width: 35%
            }
        }
    }

    .prf-charge {
        &-wrapper {
            &.hidden-mobile {
                margin-bottom: 20px;
            }

            &.visible-mobile {
                margin-top: 20px;
            }
        }
    }

    .product-outofstock,
    .product-variations,
    .pdpForm {
        @include respond-to(mobile) {
            width: 100%;
        }
    }

    .pdpForm {
        .whishlist_instore-actions {
            display: none;
        }
    }

    .primary-content & { //This nesting is necessary to avoid applying styles for QV
        &:not(.product-detail-chanel) {
            &.delivery-not-available .add-to-cart-wrapper button { // Hide all buttons when delivery isn't available
                @include respond-to(mobile) {
                    display: none;
                }
            }
        }
    }

    &.is-product-set {
        .product-name-title {
            @include respond-to(mobile_strong_over) {
                margin: 0 0 6px;
            }
        }
    }

    .product-name {
        @include shared-product-name;
    }

    .price_review-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        flex-direction: column;
        align-items: start;

        @include respond-to (mobile) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

    .product-price {
        @include shared-product-price;

        .product-standard-price {
            color: $color-auxiliary15;
            text-decoration: line-through;
            font-size: rem(16);
            &.rrp-price {
                text-decoration: none;
            }
        }        

        &:first-letter {
            text-transform: uppercase;
        }

        .price-sales ~ .price-standard {
            text-decoration: none;
        }
    }

    .label.va-size {
        font-size: rem(14);
        margin-bottom: 4px;
        text-transform: capitalize;

        @include respond-to(mobile) {
            display: block;
            text-align: left;
            margin: 0 auto 4px;
            max-width: 80%;
        }
    }

    .variation-select {
        height: 46px;
    }

    .product-actions-wishlist {
        @include shared-wishlist;
    }

    .add-to-cart-sticky-wrapper {
        @include respond-to(mobile) { // this is to enable sticky-kit for mobile layout only
            &::before {
                content: "js-sticky-enabled";
                display: none;
            }

            &.sticky {
                z-index: 10;
                top: auto !important;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $white;
                padding: 10px 15px;
                width: 100% !important;
            }
        }
    }

    .product-info {
        margin-bottom: 95px;

        b {
            font-weight: 600;
        }

        @include respond-to(mobile) {
            margin-bottom: 50px;
        }
        
        ul {
            font-size: rem(16);
            list-style: square;
            padding: 0 2em;
        }

        iframe {
            max-width: 100%;
        }
    }

    .available-delivery-msg {
        border: 1px solid $very-light-gray;
        padding: 15px 20px;
        margin-bottom: 25px;

        &-title {
            font-family: $font-main-bold;
            font-size: rem(14);
            margin-bottom: 5px;
        }

        &-text {
            font-size: rem(12);
        }

        a {
            text-decoration: underline;
        }
    }

    //Same day delivery
    .delivery-wrapper {
        background-color: $light-gray;
        display: flex;
        align-items: center;
        padding: 15px 25px;

        @include respond-to(mobile) {
            margin: 0 -15px;
            text-align: left;
        }

        @include respond-to(touch) {
            padding: 15px 19px;
        }
        
        @include respond-to(mobile_strong) {
            padding: 15px 15px 15px 20px;
        }

        &::before {
            content: " ";
            background: url("../images/delivery_car.png") center / contain no-repeat;
            width: 100px;
            height: 80px;
            display: inline-block;

            @include respond-to(tablet_ext) {
                height: 70px;
            }

            @include respond-to(mobile) {
                height: 60px;
            }
       } 

        &-info {
            padding-left: 25px;

            @include respond-to(touch) {
                padding-left: 20px;
            }

            @include respond-to(mobile_strong) {
                padding-left: 16px;
            }
        }

        &-title {
            font-size: rem(15);
            line-height: 22px;
            font-family: $font-main-bold; 
            margin-bottom: 8px;

           &-red {
                color: $medium-blue;
           }
        }

        &-body {
            font-size: rem(14);
            line-height: 20px;
        }

        &-timer {
            font-family: $font-main-bold;
            color: $medium-blue;
        }
    }

    // ------ CHANEL SECTION ONLY -------- //

    &.product-detail-chanel {
        .chanel-banner {
            @include respond-to(tablet_plus) {
                margin: -25px 0 55px;
            }
        }

        .product-col-1,
        .product-col-2,
        .product-price,
        button,
        h1 {
            font-family: $sans-serif;
        }

        .quickview-prev,
        .quickview-next,
        .quick-view-description-link,
        .product-delivery-details-message-icon,
        .aricons-check {
            color: $black01;
        }

        .product-col-1,
        .product-col-2 {
            a:hover {
                color: $black01;
            }
        }

        .chanel-collection,
        .product-name-title {
            text-transform: uppercase;
            line-height: 1.2;
            font-family: $sans-serif;
        }

        .product-chanel-title {
            font-size: rem(22);
            font-weight: 700;

            @include respond-to(mobile) {
                text-align: center;
            }
        }

        .chanel-collection {
            font-size: rem(16);
            font-weight: 700;
        }

        .product-name-title {
            font-size: rem(18);
        }

        .product-price,
        .product-price .price-sales {
            font-size: rem(16);
        }

        .product-variations .attribute .label,
        .product-delivery-details-message,
        .prod-feedback-txt {
            font-size: rem(16);
        }

        .product-variations .toggle .selected-value {
            text-transform: uppercase;
            font-size: rem(16);
        }

        .product-price,
        .add-to-cart {
            font-weight: 700;
        }

        .add-to-cart {
            background: $black01;
            font-size: rem(16);

            &:hover {
                background: $color-secondary4;
            }
        }

        .product-detail {
            a:hover {
                @include respond-to(desktop_over) {
                    color: $color-secondary4;
                }
            }
        }

        .product-detail {
            @include respond-to(mobile) {
                text-align: center;
            }
        }

        .product-options, 
        .product-variations {
            .toggle .selected-value {
                &:hover {
                    &::after {
                        color: $color-secondary4;
                    }
                }
            }
        }

        button {
            &.add-to-cart-disabled,
            &[disabled] {
                background: $color-secondary3;
            }

            &.coloured {
                &:hover {
                    background: $color-secondary4;
                }
            }
        }
    
        .socialsharing {
            .aricons-share-circle {
                &:hover {
                    .path1::before {
                        color: $color-secondary4;
                    }
                }
            }
            .aricons-close {
                &:hover {
                    &::before {
                        color: $color-secondary4;
                    }
                }
            }
            .share-icon {
                &:hover {
                    .aricons-social-facebook-fill {
                        .path1 {
                            &::before {
                                color: $color-secondary4;
                            }
                        }
                        .path2 {
                            &::before {
                                color: $black01;
                            }
                        }
                    }
                    .aricons-social-twitter-fill,
                    .aricons-social-email-fill {
                        .path1 {
                            &::before {
                                color: $black01;
                            }
                        }
                        .path2 {
                            &::before {
                                color: $color-secondary4;
                            }
                        }
                    }
                }
            }
        }

        .main-title {
            font-family: $sans-serif;
            font-weight: 700;

            @include respond-to(mobile) {
                font-size: rem(14);
            }
        }

        .tab .tab-label {
            font-family: $sans-serif;
            font-weight: 700;
            font-size: rem(14);

            &::after {
                color: $black01;
            }
        }

        .tab-content {
            font-size: rem(15);
        }

        .product-outofstock {
            border-color: $black01;
        }

        .oos-label,
        .select-error {
            color: $black01;
        }

        select {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGhJREFUeNpiZGBgMABiZiD+CMR3GBBAEYiFYJxeIP4PxJ+BWA4qJgrEb6Hic0ACPEB8GyqwBapoKZT/BNk0J6ggCM9HYgcyoAFkSRBex4AFgIx9DlUAco80Aw4QAlWUxkAAVKALAAQYAFloG0xRpMIfAAAAAElFTkSuQmCC');

            &:hover {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAAAAAAOpoppAAAATklEQVQI12N4eP/l////X9+/z7A0MvHN/4+pkbMYvhdGdv+fGpn9meH/1cjIGZGRp/8z/P8PpCP7/4NYnzMjU9+BWf9PRu77D2H93wwiADMSNssekRS+AAAAAElFTkSuQmCC');
            }
        }

        .pdp-recommendations {
            .carousel {
                .jcarousel-nav.jcarousel-next,
                .jcarousel-nav.jcarousel-prev {
                    &::after,
                    &::before {
                        color: $black01;
                    }

                    &:hover {
                        &::after,
                        &::before {
                            color: $color-secondary4;
                        }
                    }
                }
            }
        }
    }

    .brand-chanel {
        .product-outofstock {
            border-color: $black01;
        }

        .oos-label,
        .select-error {
            color: $black01;
        }
    } 

    .ie & {
        .add-to-cart-disabled:active {
            span {
                position: relative;
                margin-top: -1px;
            }
        }
    }
    
    .keyingredient {
        margin-right: 10px;
        display: inline-block;
        line-height: 2;
    
        &:last-child {
            margin-right: 0;
        }
    }
    
    .keyingredient-link {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            border: 0.5px solid $medium-blue;
            visibility: visible;
            transition: all .3s ease-in-out 0s;
            transform-origin: 0%;
        }

        @include respond-to(m_desktop) {
            &:hover:after {
                visibility: hidden;
                transform: scaleX(0);
                transform-origin: 0% 50%;
            }
        }

        @include respond-to(m_tablet_down) {
            &:active:after {
                visibility: hidden;
                transform: scaleX(0);
                transform-origin: 0% 50%;
            }
        }
    }

    .product-offers .inventory {
        margin-bottom: 20px;
    }
}

.product-detail-chanel ~ .product-shop-all {
    .button {
        background: $black01;
        color: $white;

        &:hover {
            background: $color-secondary4;
            color: $black01;
        }
    }
}

.chanel-section {
    .column-wrapper {
        .secondary-content,
        .primary-clp,
        h3 {
            font-family: $sans-serif;
        }
    }
    .wide-tiles {
        .product-actions-wishlist-link i {
            color: $black01;
        }
    }

    .ui-dialog .ui-dialog-titlebar-close {
        &:hover::before {
            color: $color-secondary4;
        }
    }

    .breadcrumb {
        text-transform: uppercase;
    }

    .searchresult-phrase + .breadcrumb {
        position: static;
        margin: 0;
        padding: 25px 0;
        text-align: left;
        width: 100%;
    }

    .breadcrumb-element-wrapper:nth-of-type(-n+2) { // Fix for first two elements in breadcrumbs CHANEL
        text-transform: lowercase;
    }
}

// -------- CHANEL SECTION END -------- //

.product-variations,
.product-options {
    .swatches {
        li {
            width: 30px;

            @include respond-to(tablet_plus) {
                margin-right: 9px;
            }
        }

        .swatchanchor {
            height: 30px;
            width: 30px;
        }

        .selectable {
            a {
                height: 30px;
                width: 30px;
            }

            &.selected img,
            &:hover img {
                border: 1px solid $color-auxiliary20;
            }
        }
    }

    .toggle {
        &.expanded {
            > ul {
                border: 1px solid $color-auxiliary15;
                border-top: 0;
                top: 100%;
                width: 100%;

                li {
                    height: auto;
                    min-height: $select-item-height;
                    line-height: normal;
                    margin-bottom: 0;
                    padding: 0;

                    &:hover {
                        background: $color-secondary3;
                    }

                    img {
                        margin-top: 0;
                        max-width: 30px;
                    }

                    a.swatchanchor {
                        @include flexbox();
                        @include align-items(center);

                        font-size: rem(16);
                        line-height: normal;
                        height: auto;
                        min-height: $select-item-height;
                        padding: 3px 9px;
                        width: auto;

                        &:hover {
                            color: $black01;
                        }
                    }

                    span {
                        line-height: normal;
                        word-break: break-word;
                    }

                    .swatchanchor-image {
                        padding-left: 0;
                        min-width: 35px;
                    }

                    &.unselectable {
                        a.swatchanchor {
                            &.with-image {
                                &:before {
                                    display: none;
                                }
                            }
                        }

                        .swatchanchor-image {
                            position: relative;
                            overflow: hidden;
                            line-height: normal;

                            img {
                                max-width: 30px;
                            }

                            &:before {
                                @include position(absolute, 7px, auto, auto, 5px);

                                content: '';
                                border-bottom: 2px solid rgba(219, 219, 219, 0.75);
                                -webkit-transform: rotate(-45deg);
                                -ms-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                                -webkit-transform-origin: left bottom;
                                -ms-transform-origin: left bottom;
                                transform-origin: left bottom;
                                width: 28px;
                                z-index: 1;
                            }
                        }
                    }
                }
            }

            .selected-value {
                &::after {
                    content: $aricons-small-arrow-up;
                }
            }
        }

        .selected-value {
            border: 1px solid $color-auxiliary15;
            height: 46px;
            line-height: 42px;
            padding: 0 50px 0 9px;
            text-transform: capitalize;
            width: 100%;

            &::after {
                color: $black01;
                content: $aricons-small-arrow-down;
                font-size: rem(9);
                right: 2px;
                top: 13%;
                width: 30px;
                height: 70%;
                text-align: left;
                line-height: 24px;
                padding: 5px;
                transform: none;
            }

            &:hover {
                &::after {
                    color: $color-primary2;
                }
            }

            img {
                margin: 7px 5px 0 0;
                max-width: 30px;
                max-height: 30px;
                vertical-align: top;
            }

            span {
                @include text-truncate;

                display: inline-block;
                line-height: 42px;
                width: 100%;
            }

            img + span {
                width: calc(100% - 40px)
            }
        }

        .color {
            text-transform: uppercase;
        }
    }

    .error-text {
        color: $red01;
        font-size: rem(12);
        margin-top: 3px;

        @include respond-to(mobile) {
            text-align: left;
        }
    }
}

// Out of stock message
.product-outofstock {
    border: 1px solid $red01;
    display: inline-block;
    padding: 11px 17px;

    .oos-label {
        border: none;
        display: block;
        font-size: rem(16);
        margin: 0;
        padding: 0;
        font-family: $font-main-bold;
    }

    .oos-link {
        color: $black01;
        transition: color .3s;
        font-size: rem(16);
    }

    .product-actions-notify-me {
        display: block;
    }
}

.notify-container {
    button {
        @extend button.coloured;

        font-size: rem(16);
        padding: 13px 23px;
    }
}

.bonus-product-tile {
    .choice-of-bonus-products-grid & .product-tile {
        padding-bottom: 115px;
    }

    .product-options,
    .product-variations {
        .toggle {
            .selected-value {
                width: 93%;
                padding-left: 10px;

                @include respond-to(mobile) {
                    padding-right: 30px;
                    width: 97%;
                    padding-left: 10px;
                }

                @include custom-respond-to(320px, 355px) {
                    font-size: rem(15);
                    padding-left: 5px;
                    padding-right: 27px;
                    width: 99%;
                }

                .swatch-image {
                    margin: 7px 6px 7px 0;
                }
            }

            &.expanded > ul {
                left: 3.5%;
                width: 93%;
                top: 100%;

                li a.swatchanchor {
                    margin: 0;
                    padding: 3px 10px;
                }


                @include respond-to(mobile) {
                    left: 1.5%;
                    width: 97%;
                }

                @include custom-respond-to(320px, 355px) {
                    left: 0.5%;
                    width: 99%;
                }

                &.swatches.color {
                    li {
                        a.swatchanchor {
                            @include custom-respond-to(320px, 355px) {
                                padding-left: 5px;
                            }

                            span {
                                padding-left: 14px;

                                @include respond-to(mobile) {
                                    padding-left: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .product-variations {
        .toggle {
            &.expanded {
                .swatches.size,
                > ul {
                    &.size {
                        border: 1px solid $color-auxiliary15;
                        border-top: 0;
                        left: 3.5%;
                        width: 93%;
                        top: 100%;

                        @include respond-to(mobile) {
                            left: 1.5%;
                            width: 97%;
                        }

                        @include custom-respond-to(320px, 355px) {
                            left: 0.5%;
                            width: 99%
                        }
                    }
                }
            }
        }

        .attribute.size {
            .toggle {
                .selected-value {
                    @include respond-to(tablet_plus) {
                        text-align: left;
                    }

                    span:first-child {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

.product-delivery-details-message {
    position: relative;
    margin: -30px 0 40px;
    padding-left: 25px;
    font-size: rem(14);

    @include respond-to(mobile) {
        display: flex;
        justify-content: center;
    }

    @include respond-to(m_mobile_portrait) {
        display: block;
    }

    &-icon {
        color: $color-primary2;
        font-size: rem(20);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @include respond-to(mobile) {
            position: static;
            transform: none;
            margin-right: 10px;
        }

        @include respond-to(m_mobile_portrait) {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    .under-wo-hover {
        display: inline-block;
        margin-left: 2px;
    }
}

.ui-dialog .product-delivery-details-message {
    display: block;
    
    @include respond-to(mobile) {
        padding-left: 0;
    }
}

.product-delivery-details,
.click-and-collect-availability {
    list-style: none !important;
    padding: 0 !important;

    &-icon {
        @include position(absolute, 10px);
        line-height: inherit;

        &.aricons-check {
            color: $color-primary2;
            font-size: rem(12);
        }

        &.aricons-plus {
            transform: rotate(45deg);
            color: $red01;
        }
    }

    &-item {
        position: relative;
        margin-bottom: 10px;
        padding-left: 50px;

        &-enabled {
            .aricons-plus,
            .aricons-information {
                display: none;
            }
        }

        &-disabled {
            .aricons-check,
            .aricons-information {
                display: none;
            }
        }

        &-info {
            .aricons-check,
            .aricons-plus {
                display: none;
            }
        }
    }

    &-title {
        display: block;
    }

    &-link {
        padding-left: 5px;

        &-with-arrow {
            &::after {
                content: $aricons-tiny-arrow-right;
                font-family: $font-icons;
                font-size: rem(9);
                padding-left: 5px;
            }
        }
    }
}

.click-and-collect-availability-item-disabled {
    color: $color-secondary1;
}

// Shop all links
.product-shop-all {
    margin: 35px 0 60px;
    text-align: center;

    .button {
        background-color: $light-gray02;
        color: $black01;
        min-width: 135px;
        width: auto;
        font-size: rem(12);
        border-radius: 20px;
        text-transform: lowercase;

        &::after {
            display: none;
        }
    }
}

// How it wear Carousel
.product-recommendations {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 95px;

    @include respond-to(mobile) {
        margin-bottom: 70px;
    }

    @include respond-to(mobile_mid) {
        margin: 0 -15px 70px;
    }

    .main-title {
        width: 100%;
        margin-bottom: 30px;
        letter-spacing: 0.48px;
    }

    .recommendations-image-wrapper {
        @include respond-to(tablet_plus) {
            width: 40.7%;
            margin-right: 20px;
            max-height: 588px;
        }

        @include respond-to(mobile) {
            width: 100%;
            margin-bottom: 35px;
        }

        @include respond-to(mobile_mid) {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 100%;
        }

        img {
            height: 100%;
            width: 100%;

            @include respond-to(mobile) {
                width: 100%;
            }

            @include respond-to(mobile_mid) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: contain;
            }
        }
    }

    .recommendations-list-wrapper {
        @include respond-to(desktop_plus) {
            width: 57.1%;

            &.with-overflow {
                border-bottom: 1px solid $very-light-grey;
                height: 590px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        @include respond-to(mobile_mid) {
            padding: 0 15px;
        }
    }

    .recommendations-list {
        display: flex;
        flex-direction: column;

        .recommendations-item {
            border-top: 1px solid $very-light-grey;

            &:last-child {
                border-bottom: 1px solid $very-light-grey;;
            }
        }

        .product-tile {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 0 15px;
            max-width: none;

            @include respond-to(mobile_mid) {
                flex-wrap: wrap;
                padding-bottom: 40px;;
            }

            .product-image {
                width: 19%;
                order: 1;
                margin-bottom: 0;
                margin-right: 10px;

                @include respond-to(mobile_mid) {
                    width: 45%;
                    padding-top: 20px;
                }
            }

            .product-brand {
                font-size: rem(16);
                line-height: 18px;
                margin-bottom: 12px;
                padding-top: 0;

                @include respond-to(mobile_mid) {
                    font-size: rem(12);
                    line-height: 15px;
                    margin-bottom: 10px;
                    padding-top: 0;
                }
            }

            .product-name {
                font-size: rem(12);
                line-height: 22px;
                margin-bottom: 17px;

                @include respond-to(mobile) {
                    padding: 0;
                }

                @include respond-to(mobile_mid) {
                    font-size: rem(12);
                    line-height: 20px;
                }
            }

            .product-pricing {
                font-size: 14px;
                line-height: 22px;

                @include respond-to(mobile_mid) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .product-description-link {
                order: 2;
                width: 50%;
                text-align: left;

                @include respond-to(mobile_mid) {
                    padding: 0;
                }
            }

            .product-actions {
                display: none;
            }

            .button-dual_functional {
                display: block;
                order: 3;
                margin: 21px 0 0 30px;
                width: 35%;

                @include respond-to(mobile_mid) {
                    width: 40%;
                    align-items: right;
                    margin-left: 46%;
                    margin-top: -20px;
                }

                .bticons-zoom,
                .shop_now-button {
                    padding: 10px 26px;
                    line-height: 21px;
                    display: block;
                    width: 100%;
                    text-transform: capitalize;

                    @include respond-to(mobile_mid) {
                        padding: 7px 0;
                        margin-left: 0;
                        font-size: 12px;
                        line-height: 15px;
                    }

                    &::before {
                        content: attr(data-alt-title);
                        font-family: $font-main-bold;
                    }
        
                    @extend .button;
                    @extend .coloured;
                }
            }

            .try-on-badge,
            .promo-flag {
                display: none;
            }

            &.brand-chanel {
                outline: none;
            }
        }
    }
}

//Also in this Range & Recently Viewed on PDP
.pdp-main {
    .aitr-product-recommendations {
        @include full-page-border;

        margin-bottom: 95px;

        @include respond-to(mobile) {
            margin-bottom: 50px;
        }
    }
    
    .aitr-product-recommendations,
    .pdp-last-visited,
    .pdp-full-width-carousel-slot {
        @include full-page-content;

        clear: both;
        margin-top: 60px;

        .product-tile-wrapper {
            @include respond-to(m_desktop) {
                padding: 0 17%;
            }

            @include respond-to(m_tablet_down) {
                padding: 0 15px;
            }
        }

        .product-description-link {
            text-align: center;

            @include respond-to(m_tablet_down) {
                &:hover {
                    color: $black01;
                }
            }
        }

        .product-actions-quickview {
            border-right: 0;
        }

        // Button withdual functional - QV or ADD TO BAG
        .button-dual_functional {
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 45px;

            .bticons-zoom,
            .shop_now-button {
                @extend .button;
                @extend .icon-bag;

                padding: 15px 10px;
                line-height: 1;
                display: block;
                width: 100%;
                text-transform: capitalize;
                letter-spacing: normal;

                &:hover {
                    background: $color-primary2;
                }

                &::before {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                    font-size: rem(18);
                }
    
                &::after {
                    content: attr(data-alt-title);
                    position: static;
                    width: auto;
                    height: auto;
                    font-family: $font-main-bold;
                    font-size: rem(14);
                    display: inline-block;
                    vertical-align: middle;
                    background-image: none;
                    background-position: inherit;
                    transform: none;
                    opacity: initial;
                    transition: none;
                }
            }
        }

        .product-tile {
            padding: 0 0 100px;
            position: relative;
            max-width: none;
            width: 100%;
    
            .product-actions {
                position: absolute;
                bottom: 1px;
                width: 100%;
                margin-bottom: 0;

                @include respond-to(m_tablet) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                @include respond-to(m_mobile_down) {
                    text-align: center;
                }
        
                // Bitton Icon Zoom
                .product-actions-quickview .bticons-zoom {
                    @include respond-to(m_tablet) {
                        display: flex;
                        align-items: center;
                        padding: 0 12px;
                        border-radius: 50px;
                        border: 1px solid $black01;
                        height: 100%;
                        min-height: 32px;
        
                        &::before {
                            content: $aricons-search-plus;
                            font-size: rem(14);
                            color: $black01;
                        }
        
                        &:hover {
                            background-color: $black01;
        
                            &::before {
                                color: $white;
                            }
                        }
                    }
        
                    @include respond-to(desktop_below) {
                        &::before {
                            font-size: rem(12);
                        }
                    }
                }
            }
    
            .product-actions-item {
                &.product-actions-wishlist {
                    @include respond-to(m_desktop) {
                        max-width: 80%;
                    }

                    @include respond-to(desktop_over) {
                        flex-grow: 1;
                    }
                }
            }
    
            .product-actions-wishlist {
                @include respond-to(mobile_strong) {
                    width: 100%;
                }
            }

            .product-actions-wishlist-link {
                @include shared-wishlist;
                @include product_actions-button;

                &::before {
                    content: "Add to wishlist";
                    display: inline-block;
                    letter-spacing: 0.5px;
                    line-height: 1.2;

                    @include respond-to(tablet_regular) {
                        font-size: rem(11);
                    }
                }

                &.wishlist-checked .bticons-heart-filled,
                &.wishlist-checked:hover .bticons-heart-filled,
                .bticons-heart-outline {
                    @include respond-to(desktop_below) {
                        display: none;
                    }

                    @include respond-to(mobile_strong) {
                        display: inline;
                    }

                    @include respond-to(mobile_min) {
                        display: none;
                    }
                }
            }

            .wishlist-checked {
                @include respond-to(m_mobile_down) {
                    background-color: $black01;

                    &::before,
                    .bticons-heart-filled::before {
                        color: $white;
                    }
                }
            }
        }
    
        .try-on-badge,
        .promo-flag {
            display: none;
        }

        .slick-arrow {
            position: static;
            margin: 0;
            padding: 5px;
            border: 0;
            overflow: hidden;

            &::before {
                display: block;
                
                @include respond-to(mobile_strong_over) {
                    font-size: rem(50);
                    width: 50px;
                }

                @include respond-to(m_mobile_down) {
                    font-size: rem(24);
                }
            }

            &::after {
                display: none;
            }
        }

        .slick-prev {
            @include icon(arrow-circle-left-large);

            order: 2;

            @include respond-to(mobile_strong_over) {
                margin-right: 14px;
            }
        
            @include respond-to(mobile_strong) {
                margin-right: 60px;
            }
        }

        .slick-next {
            @include icon(arrow-circle-right-large);

            transform: none;
            order: 3;
        }

        .slick-list {
            width: 100%;
            margin-bottom: 25px;
        }

        .slick-slider {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: auto;
            align-self: stretch;

            > div {
                height: 100%;

                .product-carousel-item {
                    height: 100%;
                }
            }
        }
    }
}

.wide-tiles {
    .product-image {
        @include respond-to(tablet_plus) {
            position: relative;
        }
    }

    .product-tile {
        .product-name {
            @include shared-product-name;
        }

        .product-price {
            @include shared-product-price;
        }

        .product-details {
            border: 1px solid $color-auxiliary17;

            &:hover {
                border-color: $color-secondary3;
            }
        }

        .product-details-toggle {
            border: none;

            @include respond-to(mobile_strong_over) {
                padding: 10px 15px;
            }

            i {
                @include respond-to(mobile_strong_over) {
                    line-height: 18px;
                }

                @include respond-to(mobile_strong) {
                    line-height: 23px;
                }
            }

            &.expanded {
                background-color: $color-auxiliary10;
            }

            &:hover {
                background-color: $color-auxiliary11;
            }

            .bticons-minus,
            .bticons-plus {
                &::before {
                    color: $color-primary2;
                    font-size: rem(10);
                    line-height: 16px;
                }
            }

            .bticons-minus {
                &::before {
                    content: $aricons-minus;
                }
            }

            .bticons-plus {
                &::before {
                    content: $aricons-plus;
                }
            }
        }
    }
}

.price-sales__now-text {
    display: inline-block;
}

// Gift card
.product-add-giftcard {
    margin-top: 0;

    .button-label {
        background: $color-primary3;
        border-color: $color-secondary2;
        color: $black01;
        font-size: rem(14);
        margin: 0;
        transition: all .3s;

        &:hover {
            background: $black01;
            border-color: $black01;
            color: $color-primary3;
        }

        @include respond-to(mobile) {
            padding: 10px 0;
        }
    }

    .form-row {
        label {
            font-size: rem(16);
            margin-bottom: 15px;

            @include respond-to(mobile) {
                display: block;
                font-size: rem(14);
                margin-bottom: 10px;
            }
        }
    }

    .dialog-quickview & {
        .error {
            color: $red01;
            font-size: rem(18);
            padding: 14px 20px;

            &::after {
                display: none;
            }
        }
    }

    .add-to-cart {
        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.pdp-gift-card {
    &.pdp-main.pdp-alternative {
        .product-col-1 {
            @include respond-to(mobile) {
                margin: 0 0 38px;
                width: 100%;
            }
        }

        .product-col-2 {
            @include respond-to(mobile) {
                float: none;
                margin: 0;
                width: 100%;
            }
        }

        .product-name {
            display: block;
            margin-bottom: 14px;

            @include respond-to(mobile) {
                display: none;
            }

            .product-name-title {
                font-size: rem(20);
                text-transform: capitalize;
                font-family: $font-main-bold;
            }
        }

        .product-info {
            padding-top: 0;
        }

        .product-alt-mobile {
            display: none;

            @include respond-to(mobile) {
                display: block;

                .product-name {
                    @include respond-to(mobile) {
                        display: block;
                        text-align: center;
                    }
                }
            }
        }
    }

    .product-gift-subtitle {
        display: block;
        font-size: rem(16);
        margin-bottom: 20px;

        @include respond-to(mobile) {
            margin: 0 0 30px;
        }
    }

    .pdpForm {
        font-size: rem(18);
        margin-bottom: 50px;
        width: 100%;

        @include respond-to(mobile) {
            margin-bottom: 30px;
        }
    }

    .field-wrapper {
        margin: 0;
    }

    .form-row {
        margin-bottom: 25px;

        @include respond-to(mobile) {
            margin-bottom: 30px;
        }
    }

    .row-element-type-input {
        label {
            display: block;
        }
    }

    .form-row-field-giftprice .row-element-type-input,
    .giftmessage {
        border-color: $color-secondary1;
        font-size: rem(16);

        @include respond-to(mobile) {
            border-color: $color-secondary2;
        }
    }

    .form-row-field-giftprice .row-element-type-input {
        @include respond-to(mobile) {
            padding: 7px 20px;
        }
    }

    .gift-card-prices {
        margin-bottom: 6px;
        text-align: justify;

        &::after {
            content: '';
            display: inline-block;
            width: 100%;
        }

        @include respond-to(mobile) {
            margin-bottom: 13px;
        }
    }
}


// COLOR PICKER
.color-picker {
    overflow-y: auto;

    @include respond-to(tablet_plus) {
        margin: 25px 0 20px;
        max-height: 150px;
        display: flex;
        flex-wrap: wrap;
    }

    @include respond-to(mobile) {
        display: none;

        .vto-opened & {
            display: block;
            white-space: nowrap;
            text-align: left;
            font-size: 0;
        }
    }

    .ui-dialog & {
        display: none;
    }

    &-wrapper {
        position: relative;
        max-width: 415px; // To keep 8 items in one row when Windows scroll appears

        @include respond-to(tablet_ext) {
            max-width: 100%;
        }

        @include respond-to(mobile) {
            max-width: none;

            .vto-opened & { // Fix jumping variations
                height: 36px;
                overflow: hidden;
            }
        }
    }

    &-item {
        display: inline-block;
        vertical-align: top;
        width: 32px;
        height: 32px;
        margin: 0 15px 12px 0;
        overflow: hidden;
        border-radius: 50%;

        @include respond-to(mobile) {
            width: 35px;
            height: 35px;
            margin: 0 13px 12px 0;
        }

        @include respond-to(m_desktop) {
            &:nth-child(25) { // Add border to .color-picker-wrapper if it contains more than 24 elements
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: $black01;
                    height: 1px;
                }
            }
        }
    }

    .selected {
        border: 1px solid $black01;
    }

    .unselectable {
        position: relative;
        border: none;
        
        &::before {
            content: " ";
            position: absolute;
            border-bottom: 2px solid $very-light-grey;
            transform: rotate(-45deg);
            transform-origin: left bottom;
            width: 28px;
            bottom: 5px;
            left: 7px;
            right: auto;
            top: auto;
            z-index: 1;

            @include respond-to(mobile) {
                width: 32px;
            }
        }

        .swatchanchor {
            cursor: default;

            &-image-src {
                border: 2px solid $very-light-grey;
                border-radius: 50%;
            }
        }
    }

    .swatchanchor,
    .swatchanchor-image {
        display: block;
        width: 100%;
        height: 100%;
    }

    .swatchanchor-image-src {
        width: 100%;
    }
}

// --- Hide usual variation and show only color picker when VTO is opened --- //
.color-picker-wrapper {
    .vto-opened & {
        @include respond-to(mobile) {
            display: block !important; // Overwrite utility class .hidden
        }
    }
}

.variation-container .swatches {
    .vto-opened & {
        @include respond-to(mobile) {
            display: none;
        }
    }
}
// END //

.brand_bio {
    &-wrapper {
        @include out-of-wrapper;

        clear: both;
        background-color: $light-gray02;
        margin-bottom: 90px;
        text-align: center;
    }

    &-inner {
        @include respond-to(mobile_strong_over) {
            padding: 95px 20px;
        }
    
        @include respond-to(mobile_strong) {
            padding: 30px 25px;
        }
    }

    &-title {
        margin-bottom: 30px;
        font-size: rem(20);
    }

    &-description {
        font-size: rem(14);
        line-height: 18px;
        
        @include respond-to(m_desktop) {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}

.positive-change {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $light-gray02;
    padding: 25px;
    margin-top: 25px;

    @include respond-to(m_mobile_down) {
        padding: 15px;
    }

    &-icon {
        min-width: 80px;
        height: 90px;
        margin-right: 30px;
        background-image: url("https://cdn.media.amplience.net/i/bta/positive-change-logo-1911");
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 0;

        @include respond-to(m_mobile_down) {
           margin-right: 15px;
        }
    }

    &-info {
        line-height: 1.4;
        text-align: left;
        font-size: rem(14);
    }

    &-title {
        font-size: rem(14);
        font-family: $font-main-bold;
        text-transform: none;
        margin-bottom: 5px;
    }

    &-text {
       margin-bottom: 20px;

       @include respond-to(m_mobile_down) {
            margin-bottom: 5px;
        }
    }

    &-link {
        @include link_underline($medium-blue);
    }
}

.more_category {
    text-align: center;

    @include respond-to(mobile_strong_over) {
        margin-bottom: 90px;
    }

    @include respond-to(mobile_strong) {
        margin-bottom: 30px;
    }

    &::before {
        content: "";
        display: block;
        border: 1px solid $lighter-gray;
        margin-left: -100%;
        margin-right: -100%;
        clear: both;

        @include respond-to(mobile_strong_over) {
            margin-bottom: 96px;
        }
    
        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
        }
    }

    &-title {
        font-size: rem(24);
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 32px;

        @include respond-to(mobile_strong) {
            font-size: rem(20);
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include respond-to(mobile_strong) {
            align-items: center;
            flex-direction: column;
        }
    }

    &-item {
        @include respond-to(mobile_strong_over) {
            margin: 0 25px 10px 0;

            &:last-child {
                margin-right: 0;
            }
        }

        @include respond-to(mobile_strong) {
            margin-bottom: 15px;
            min-width: 220px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-link {
        background-color: $light-gray02;
        border-radius: 50px;
        display: block;
        font-size: rem(12);
        font-weight: 600;
        letter-spacing: 1px;
        padding: 10px 20px;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background-color: $lighter-gray;
            color: inherit;
        }
    }
}

// How it wear with hotspots map
.howtowear_map {
    &-static { // Styles for static content on page - NOT DIALOG
        @include respond-to(mobile_strong_over) {
            margin-bottom: 95px;
        }
    
        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
        }

        @include full-page-border;

        .main-title {
            font-size: rem(24);

            @include respond-to(mobile_strong) {
                text-align: left;
                font-size: rem(20);
            }
        }

       .howtowear_map-content {
            position: relative;
    
            @include respond-to(mobile_strong) {
                margin-bottom: 30px;
            }
        }

        .howtowear_map-image {
            max-width: 100%;
        }

        .howtowear_map-hotspot {
            width: 37px;
            height: 37px;
            background: $white;
            border: 2px solid $black01;
            border-radius: 50%;
            font-size: rem(24);
            text-align: center;
    
            @include respond-to(mobile_strong) {
                display: none;
            }
        }
    } //End styles for static content on page - NOT DIALOG

    &-dialog { // Styles only for DIALOG
        &.ui-dialog {
            background-color: $light-gray02;

            @include respond-to(m_desktop) {
                width: 50% !important;
            }

            @include respond-to(m_tablet_down) {
                width: 70% !important;
            }

            .ui-widget-header {
                background-color: $light-gray02;
            }

            .ui-dialog-content {
                padding: 15px;
            }

            .ui-dialog-titlebar {
                width: 0;
                height: 0;
                margin: 0;
                padding: 0;
                position: static;
            }

            .ui-dialog-title {
                display: none;
            }

            .ui-dialog-titlebar-close {
                @include icon(close-icon);

                background: $white;
                border-radius: 50px;
                width: 40px;
                height: 40px;
                padding: 20px 17px 20px 23px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                    font-size: rem(10);
                    font-weight: 900;
                }
            }
        }

        .slick-arrow {
            border: 0;
            padding: 5px;
            background-color: transparent;

            &::before {
                font-size: rem(24);
            }
        }

        .slick-prev {
            @include icon(arrow-circle-left-large);

        }

        .slick-next {
            @include icon(arrow-circle-right-large);

            transform: none;
        }

        &-image,
        .product-tile-wrapper {
            @include respond-to(mobile_strong_over) {
                width: 49%;
            }
        }

        &-image {
            border-radius: 4px;
            object-fit: none;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        img {
            @include respond-to(touch) {
                max-width: none;
            }
        }

        .product-tile-wrapper {
            @include respond-to(desktop_below) {
                padding: 40px 0 0;
            }

            @include respond-to(desktop_over) {
                padding-left: 6%;
                padding-right: 6%;
            }
        }

        .product-tile {
            @include respond-to(mobile_strong_over) {
                flex-direction: column;
            }

            .product-image {
                width: 230px;
                margin: 0 auto 20px;
            }
        }

        .product-description-link {
            text-align: center;
            margin-bottom: 15px;
        }
    } // End styles only for DIALOG

    &-desktop {
        @include respond-to(mobile_strong_over) {
            display: none;
        }
    }

    &-item {
        display: flex !important;
        justify-content: space-between;

        @include respond-to(mobile_strong) {
            margin-bottom: 15px;
        }
    }

    .product-tile-wrapper {
        @include respond-to(mobile_strong) {
            width: 100%;
        }
    }

    .product-tile {
        display: flex;
        margin: 0;
        padding: 0;
        width: auto;
        max-width: none;

        @include respond-to(mobile_strong) {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-end;
        }

        .product-image {
            @include respond-to(mobile_strong_over) {
                margin-bottom: 25px;
            }

            @include respond-to(mobile_strong) {
                width: 39%;
                margin: 0;
            }
        }

        .thumb-link {
            border-radius: 4px;
        }

        .product-actions {
            order: 3;

            @include respond-to(mobile_strong) {
                display: none;
            }
        }
    
        .button-dual_functional,
        .product-description-link {
            @include respond-to(mobile_strong) {
                width: 55%;
                margin-left: 6%;
            }
        }

        .product-description-link {
            @include respond-to(mobile_strong) {
                margin-bottom: 0;
                text-align: left;
                padding: 0;
            }

            @include respond-to(m_tablet_down) {
                &:hover {
                    color: $black01;
                }
            }
        }

        .product-actions-item {
            width: 100%;
            display: block;

            &.product-actions-quickview {
                display: none;
            }
        }

        .button-dual_functional {
            display: block;
            margin-bottom: 10px;
            text-align: center;
        }

        .shop_now-button,
        .bticons-zoom {
            @extend .button;
            @extend .icon-bag;
            
            line-height: 1;
            width: 100%;
            text-transform: capitalize;
            letter-spacing: normal;

            @include respond-to(mobile_strong_over) {
                max-width: 310px;
                padding: 13px 10px 15px;
            }

            @include respond-to(mobile_strong) {
                padding: 10px;
            }

            &:hover {
                background: $color-primary2;
            }

            &::before {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }

            &::after {
                content: "Add to bag";
                position: static;
                width: auto;
                height: auto;
                font-family: $font-main-bold;
                font-size: rem(14);
                display: inline-block;
                vertical-align: middle;
                background-image: none;
                background-position: inherit;
                transform: none;
                opacity: initial;
                transition: none;
            }
        }

        .product-actions-wishlist-link {
            @include shared-wishlist;
            @include product_actions-button;

            max-width: 220px;
            margin: 0 auto;

            @include respond-to(mobile_strong) {
                display: none;
            }

            &::before {
                content: "Add to wishlist";
                display: inline-block;
                font-size: rem(12);
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 1.2;
            }
        }

        .try-on-badge,
        .promo-flag {
            display: none;
        }

        .product-brand {
            font-family: $font-main-bold;

            @include respond-to(mobile_strong_over) {
                font-size: rem(24);
            }
    
            @include respond-to(mobile_strong) {
               font-size: rem(14);
            }
        }

        .product-name {
            font-size: rem(16);
            padding: 0;
        }

        .product-pricing {
            font-size: rem(14);
            font-weight: 700;

            .product-sales-price, 
            .product-standard-price {
                display: inline-block;
                font-size: rem(14);
            }

            .product-sales-price {
                margin-right: 10px;
            }
        }
    }

    .loyalty-points {
        font-size: rem(10);
        color: $hel-gray;
        font-weight: 500;
        display: block;
        margin: 5px 0 10px;
    }
}

.klarna_placement #credit_strip_badge::part(osm-container) {
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    background:white;
    border: 0;
    padding: 0; 
}

.klarna_placement #top_strip_badge::part(osm-container){
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-top: 12px;
    background:white;
    border: 0;
    padding: 0; 
    justify-content: left;
}

.klarna_placement #top_strip_badge::part(osm-badge) , #credit_strip_badge::part(osm-badge) {
    height: 24px;
}

.klarna_placement #top_strip_badge::part(osm-message),#credit_strip_badge::part(osm-message) {
    flex-direction: column;
    align-items: start;
    font-size: 14px;
}

.klarna_placement #top_strip_badge::part(osm-cta),#credit_strip_badge::part(osm-cta) {
    display: block;
    font-size: 14px;
}

@media screen and (max-width: 800px) {
    .klarna_placement #credit_strip_badge::part(osm-cta) {
        margin: 4px auto;
    }
    }