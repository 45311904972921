// Shared components for PDP, PLP (wide tiles, collections), Quickview
@mixin shared-product-name {
    .product-name-brand {
        font-size: rem(20);
        letter-spacing: 0.02em;
        margin: 0;
        text-transform: uppercase;
    }

    .product-name-title {
        font-size: rem(16);
        letter-spacing: 0;
        font-family: $font-main;

        @include respond-to(mobile) {
            line-height: 1;
            margin: 15px 0 22px;
            font-size: rem(14);
        }
    }
}

@mixin shared-product-price {
    line-height: 1;
    text-transform: capitalize;
    font-family: $font-main-bold;

    & > span:only-of-type,
    .product-pricing .product-sales-price {
        color: $black01;
        font-size: rem(20);

        @include respond-to(mobile) {
            font-size: rem(18);
        }
    }

    .price-sales,
    .salesprice,
    .now-sell-price,
    .product-pricing .product-sales-price.now-sell-price {
        color: #B22E2E;
        font-size: rem(16);

        &.has-rrp-price {
            color: #000;
        }

        @include respond-to(mobile) {
            font-size: rem(14);
            display: block;
        }

        ~ .price-standard {
            color: #666666;
            font-size: rem(16);
            position: relative;
            text-transform: capitalize;

            @include respond-to(mobile) {
                font-size: rem(12);
            }

            &::after {
                border-bottom: 1px solid currentColor;
                content: '';
                left: 0;
                position: absolute;
                top: 50%;
                width: 100%;
            }

            &.rrp-price {
                &::after {
                    border-bottom: none;
                }
            }
        }
    }
}

@mixin shared-quantity {
    font-size: 0;
    min-width: 140px;

    @include respond-to(mobile_strong_over) {
        margin-bottom: 30px;
    }

    label {
        display: none;
    }

    .button {
        background: transparent;
        display: inline-block;
        float: none;
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: rem(20);
        border: 8px solid;
        color: $black01;
        line-height: 25px;
        margin-bottom: 30px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        height: 40px;
        width: 44px;
        vertical-align: top;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @include respond-to(mobile) {
            margin-bottom: 25px;
        }

        &.plus {
            border-image: $border-qty-right 8 fill repeat;
            border-radius: 0 10px 10px 0;
        }

        &.minus {
            border-image: $border-qty-left 8 fill repeat;
            border-radius: 10px 0 0 10px;
            line-height: 20px;
        }

        &:hover {
            @include respond-to(touch_over) {
                background: $color-secondary3;
            }
        }

        &:active {
            @include respond-to(touch) {
                background: $color-secondary3;
            }
        }
    }

    .input-text {
        @include appearance($moz_type: textfield);

        border-image: $border-grey-url 8 fill repeat;
        border-width: 8px 0 8px 0;
        box-shadow: none;
        display: inline-block;
        float: none;
        height: 40px;
        padding: 0 5px;
        vertical-align: top;
        width: 50px;

        &:focus {
            background: transparent;
        }
    }

    input.error {
        border-image: $border-grey-url 8 fill repeat;
        border-width: 8px 0 8px 0;
    }
}

@mixin shared-wishlist {
    .bticons-heart-outline::before {
        content: $aricons-wishlist-empty;
    }

    .bticons-heart-filled::before {
        content: $aricons-wishlist-full;
    }

    .bticons-heart-filled {
        display: none;
    }

    &.wishlist-checked {
        .bticons-heart-outline {
            display: none;
        }

        .bticons-heart-filled {
            display: inline;
        }
    }
}

@mixin product_actions-button {
    border: 1px solid $black01;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-main-bold;
    color: $black01;
    letter-spacing: 1px;
    padding: 8px 15px 8px;
    font-size: rem(12);
    line-height: 1.1;
    text-transform: capitalize;

    .bticons-heart-outline,
    .bticons-heart-filled {
        &:before {
            font-weight: 700;
            font-size: rem(14);
        }
    }

    &:hover {
        background-color: $black01;
        color: $white;
    }
}

// Mixin for creating badge / promo flag
// Parameters :
// -------------------------------------------------------------------------
//  $direction        - direction of the type [undefined / left / right] (default = undefined)
// -------------------------------------------------------------------------
@mixin shared-promo-flag($direction: undefined) {
    .promo-flag {
        text-align: center;
        display: block;
        margin: 0 auto;
        position: relative;
        text-transform: uppercase;
        font-size: rem(12);
        line-height: 1;
        letter-spacing: 0.5px;
        color: $black01;
        font-family: $font-main-bold;

        @include respond-to(mobile_mid) {
            display: table;
            padding: 0 25px;
        }

        span {
            background-color: $lighter-gray;
            display: inline-block;
            padding: 8px 10px;
            border-radius: 4px;
        }

        &.promo-flag-standard {
            @include respond-to(mobile_mid) {
                padding: 0 20px; // long word fix
            }
        }

        .product-recommendation-mobile & {
            @include respond-to(mobile_mid) {
                width: 100%;
                left: 0;
            }
        }

        &::after {
            display: none;
        }
    }
}

@mixin shared-jcarousel-nav {
    .jcarousel-nav {
        font-size: rem(18);

        &.jcarousel-prev,
        &.jcarousel-next {
            &::before {
                content: $aricons-arrow-big-path1;
            }

            &::after {
                content: $aricons-arrow-big-path2;
                margin-left: -0.619140625em;
            }

            &::before,
            &::after {
                color: $color-auxiliary13;
                opacity: .3;
                transition: opacity .1s;

                .chanel-section & {
                    color: $black01;
                }
            }

            &:active {
                &::before,
                &::after {
                    opacity: .65;
                }
            }
        }

        &.jcarousel-prev {
            margin-left: 0;
            transform: scaleX(-1);
        }
    }
}

// Shared components for CHECKOUT


// $applyBorderTapeToClass  - inner wrapper's classname to which border-tape will be applied (otherwise will be applied to current selector)
@mixin shared-checkout-sidebar-decoration($applyBorderTapeToClass: undefined) {
    padding: 0;
    border: 0;

    @if $applyBorderTapeToClass == undefined {
        @include border-tape($border-color: $color-auxiliary24, $opacity: .2 .4, $bgcolor: $color-auxiliary23);
    }

    @include respond-to(mobile_min) {
        @include full-page-content;

        float: none;
        position: relative !important; // override _base.scss
        border-left: 5px solid transparent; // can't use paddings\margins here
        border-right: 5px solid transparent;
    }

    @if $applyBorderTapeToClass != undefined {
        > #{$applyBorderTapeToClass} { // inner wrapper to which border-tape will be applied
            @include border-tape($border-color: $color-auxiliary24, $opacity: .2 .4, $bgcolor: $color-auxiliary23);
        }
    }

    h3,
    .section-header {
        font-size: rem(20);
        text-align: center;
    }

    h4,
    .section-sub-header {
        font-family: $font-main;
        font-size: rem(14);
        text-align: center;
        text-transform: none;
        color: $black01;
        margin-bottom: 25px;

        @include respond-to(mobile_mid) {
            margin-bottom: 30px;
        }

        .section-sub-header-edit {
            margin-left: 20px;

            a {
                @extend .cta-type7;
            }
        }
    }

    .border-type-inner {
        padding: 25px 35px 35px;

        @include respond-to(mobile_mid) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include respond-to(mobile_to_desktop) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .cart-footer {
            padding: 0 10px;
            margin-top: 15px;
        }

        .login-box-content {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
    }

    .my-account-subtitle {
        margin: 0 auto 45px;
        text-align: center;
        width: 83.3333333333%;

        @include respond-to(mobile_strong) {
            margin-bottom: 30px;
            width: 100%;
        }

        .dialog-required {
            display: none;
        }

        @include icomoon-icon($aricons-user-path1, $font-size: rem(18)) {
            padding-right: 10px;

            @include respond-to(mobile_strong) {
                display: block;
                font-size: rem(30);
                margin-bottom: 20px;
                padding-right: 0;
            }
        }
    }

    .login-box-content {
        padding: 0 $co-space1;
        border-top: none;

        @include respond-to(mobile_strong_over) {
            margin: 0 0 0 8.3333333333%;
            width: 83.3333333333%;
        }
    }

    .form-row {
        margin: 0;
    }

    .field-wrapper {
        margin-bottom: 55px;

        @include respond-to(mobile_strong) {
            margin-bottom: 32px;

        }
    }
    .js-field-wrapper-captcha{
        @include respond-to(mobile_strong) {
            margin-bottom: 32px;
            max-width: 200px;
        }
    }

    .form-login-submit {
        @include icomoon-icon($aricons-lock, $font-size: rem(13)) {
            padding-right: 12px;
        }

        font-size: rem(16);
        margin-bottom: 15px;
        padding: 17px 22px;

        @include respond-to(mobile_strong) {
            margin-bottom: 27px;
            padding: 12px 22px;
        }
    }

    #password-reset {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

@mixin shared-login-page-layout {
    .primary-content,
    .my-account-title-inner-wrapper {
        float: none;
        margin-left: auto;
        margin-right: auto;
        max-width: 980px;
        width: 100%;
    }

    .col-1,
    .col-2 {
        margin: 0;
        text-align: center;
        width: 50%;
        padding: 0 $co-space1*2;

        @include respond-to(touch) {
            float: left;
        }

        @include respond-to(mobile_strong) {
            float: none;
            padding: 0 10px;
            width: 100%;
        }
    }

    .col-1 {
        @include respond-to(mobile_strong_over) {
            border-right: 0;
        }

        @include respond-to(mobile_strong) {
            &::after {
                display: none;
            }
        }
    }

    .col-2 {
        padding-top: 53px;

        @include respond-to(mobile_strong) {
            margin: 37px 0;
            padding-top: 0;
        }
    }

    .checkoutlogin-col-wrapper {
        .login-box.login-account {
            .border-type-inner {
                background-color: transparent;
                padding: 43px 0 38px;

                @include respond-to(mobile_strong) {
                    padding: 18px 0 27px;
                }
            }
        }
    }

    .login-box {
        &.login-account {
            .field-wrapper {
                .input-text {
                    + .error {
                        position: absolute;
                        padding: 14px 20px;

                        @include respond-to(mobile_strong) {
                            padding: 5px 15px;
                        }
                    }
                }
            }
        }

        h2.my-account-subtitle {
            margin: 0 auto 26px;
            padding: 0 12px;

            @include respond-to(mobile_strong) {
                margin-bottom: 30px;
                width: 100%;
            }
        }

        .login-box-content {
            margin: 0 0 0 8.3333333333%;
            padding: 0 12px;
            width: 83.3333333333%;

        }
        @media only screen and (max-width: 450px) {
            iframe[title="reCAPTCHA"] {
                transform: scale(0.8);
                transform-origin: left;
            }
        }

        .checkout-create-account {
            &-message {
                font-size: rem(18);
                margin: 0 0 30px;
                text-align: center;
            }
        }

        .form-row-button {
            margin: 0;
        }
    }
}


// pass specific $icon or 'undefined' if icon is not needed
@mixin shared-colored-action-button($icon: $aricons-lock) {
    font-size: rem(16);
    padding-bottom: 16px;
    padding-top: 16px;

    @include respond-to(mobile_mid) {
        font-size: rem(14);
        padding: 13px 5px;
    }

    @include respond-to(tablet_ext) {
        padding-left: 5px;
        padding-right: 5px;
        font-size: rem(14);
    }

    @if $icon != undefined {
        @include icomoon-icon($icon, $font-size: rem(13)) {
            padding-right: 12px;
        }
    }
}

@mixin shared-mini-cart-product {
    margin-right: 20px;
    border-bottom-color: rgba($color-secondary3, .7);

    @include respond-to(touch_over) {
        min-height: 120px;
    }

    .mini-cart-subtitle {
        font-size: rem(12);
        color: $body-base-color;
        padding: 0;
        font-family: $font-main-bold;
    }

    .mini-cart-name {
        @include text-truncate;

        text-transform: none;
        font-size: rem(12);
        color: $black01;
        margin-bottom: 5px;
        padding: 0;
    }

    .mini-cart-image {
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 10px;
    }

    .mini-cart-attributes,
    .mini-cart-qty {
        color: $color-auxiliary19;
        font-size: rem(12);
        text-transform: uppercase;
    }

    .mini-cart-attributes {
        padding: 0;

        .attribute {
            margin-bottom: 3px;
            padding: 0;
        }

        .label {
            display: none;
        }
    }

    .mini-cart-col-1 {
        padding-top: 5px;

        .desktop & {
            max-width: 65%;

            @include browsersIeAndEdge {
                flex-basis: 65%;
            }
        }
    }

    .mini-cart-col-2 {
        text-align: right;
        padding-top: rem(36);

        .desktop & {
            word-break: break-all;
            max-width: 35%;
        }
    }

    .mini-cart-pricing {
        padding: 0;
    }

    .mini-cart-price {
        color: $body-base-color;
    }

    .cart-price-adjusted {
        @include flexbox;
        @include flex-direction(column-reverse);

        text-align: right;
        line-height: 1.3;

        @include respond-to(mobile_mid) {
            text-align: left;
        }

        .no-desktop &.mini-cart-pricing {
            @include respond-to(touch) {
                text-align: left;
            }
        }

        .mini-cart-price  {
            color: $red01;
            font-family: $font-main-bold;
            font-size: rem(14);

            @include respond-to(mobile) {
                font-size: rem(12);
            }
        }

        .mini-cart-price-standard {
            color: rgba($black01, .6);
            text-decoration: line-through;
            font-family: $font-main-bold;
            font-size: rem(12);

            @include respond-to(mobile) {
                font-size: rem(10);
            }

            &.rrp-price {
                text-decoration: none;
            }
        }
    }

    .mini-cart-promos {
        padding: 0;
    }
}

@mixin shared-mini-cart-totals {
    margin: 35px 40px 0;
    font-size: rem(14);
    color: $body-base-color;
    text-transform: capitalize;
    text-align: left;

    .mini-cart-loyalty,
    .mini-cart-totals,
    .mini-cart-discount {
        overflow: hidden;

        .label {
            float: left;
            text-align: left;
        }

        .value {
            float: right;
            white-space: nowrap;
            text-align: right;
        }
    }

    .mini-cart-discount {
        color: $red01;

        span {
            @include respond-to(mobile_strong_over) {
                padding-bottom: 5px;
            }
        }
    }

    .mini-cart-loyalty {
        margin-bottom: 35px;
    }

    .mini-cart-loyalty,
    .mini-cart-discount {
        @include respond-to(mobile) {
            font-size: rem(12);
        }
    }

    .mini-cart-totals {
        font-size: rem(18);
        font-family: $font-main-bold;
    }
}

@mixin shared-three-border-tape {
    .login-create-account-title-desktop,
    .collections-component-title {
        position: relative;
        font-family: $font-main;
        text-align: center;
        transform: translateY(50%);
        clear: both;
        z-index: $top-banner-z-index;

        @include respond-to(mobile_strong) {
            display: none;
        }

        @include respond-to(mobile) {
            font-size: rem(22);
        }

        @include respond-to(tablet_plus) {
            font-size: rem(32);
        }

        span {
            background-color: $color-primary3;
            padding: 0 20px;
            position: relative;

            &::before,
            &::after {
                border-style: solid;
                border-width: 15px;
                content: '';
                position: absolute;
            }

            &::before {
                border-color: transparent $color-primary3 transparent transparent;
                right: 100%;
            }

            &::after {
                border-color: transparent transparent transparent $color-primary3;
                left: 100%;
            }
        }
    }

    .login-create-account,
    .collections-components-list-wrapper {
        @include border-tape($border-color: $color-auxiliary24, $opacity: .2 .4);

        font-size: rem(18);
        line-height: 1.33;
        padding-bottom: 0;

        @include respond-to(mobile) {
            margin-top: 0;
        }

        @include respond-to(mobile_strong) {
            margin-left: -10px;
            margin-right: -10px;
        }

        &:before,
        &:after {
            @include respond-to(mobile_strong_over) {
                height: calc(100% - 250px);
            }
        }

        &:before {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 calc(100% - 10px));
        }

        &:after {
            clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), 0 100%);
        }

        .border-type-inner {
            &:after {
                display: none;
            }
        }
    }
}

@mixin shared-badge {
    background-color: $color-auxiliary9; // default badge bg, if was not set in asset JSON config
    color: $color-primary3; // default badge color, if was not set in asset JSON config
    padding: 5px 0;
    position: absolute;
    text-align: center;
    transform: rotate(-45deg);

    @include respond-to(mobile_tablet) {
        font-size: rem(14);
    }
}
