// Brand index page
.pt_brand-index {
    .breadcrumb {
        @include respond-to(desktop_plus) {
            padding-top: 20px;
        }
    }
}

.breadcrumb + .brands-content {
    @include respond-to(desktop_plus) {
        margin-top: -50px;
    }

    @include respond-to(mobile_stronger) {
        margin-top: -40px;
    }
}

//secondary navigation on Customer Service pages expanded initial state
.page-static {
    #secondary {
        ul {
            display: block;
        }
    }
}
.slot-whitespace-medium {
    @include respond-to(desktop_plus) {
        margin-bottom: 100px !important;
    }
}

.slot-whitespace-large {
    @include respond-to(desktop_plus) {
        margin-bottom: 150px !important;
    }
}

.customer-store-info__form {
    margin: 0 auto;
    max-width: 922px;
    padding: 60px 10px;
}
