.flix-media-wrapper {
    .flix-media-header {
        position: relative;
        text-align: left;

        @include respond-to(mobile){
            text-align: center;
        }

        &:before {
            @include position(absolute, 0, 0, 50%);

            content:'';
            border-top: 1px solid $color-auxiliary17;
            z-index: 1;
            width: 100%;
        }

        h2 {
            color: $night-rider;
            cursor: pointer;
            font-size: 1.4rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: inline-block;
            padding-right: 10px;
            background: $color-primary3;
            position: relative;
            border: none;
            z-index: 2;

            @include respond-to(mobile) {
                padding-left: 10px;
            }
        }
    }
}