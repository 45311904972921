#main.furniture-page-main,
.main.furniture-page-main {
    padding: 0;
    max-width: 1440px;
    margin: 0 auto;

    .breadcrumb {
        @include respond-to(furniture_down) {
            padding-left: 20px;
        }
    }

    .product-shop-all {
        padding: 0 15px;
    }

    .this-range {
        margin-bottom: 0;

        & + .pdp-recommendations {
            margin-top: 0;
        }
    }

    .product-tile img {
        margin-top: 0;
    }

    .this-range .product-tile {
        padding-top: 0;
    }

    .main-title {
        margin-bottom: 15px;
    }

    .pdp-recommendations.ui-tabs .ui-tabs-panel .product-image .thumb-link {
        margin-top: 0;
        padding-top: 0;
    }

    .main-title span {
        font-size: rem(28);
        text-transform: none;
    }
}

.pdp-main.pdp-furniture {
    .swatches.color {
        float: none;
    }

    //swatchesstyle
    .swatches-order {
        margin-bottom: 10px;

        &__icon-swatch {
            display: inline-block;
            width: 18px;
            height: 20px;
            vertical-align: text-top;
            margin-right: 3px;
            background-image: url("../images/color-sampler-hover.svg");
        }

        &__link {
            vertical-align: middle;
            font-size: rem(18);
            color: $color-primary2;

            &:hover {
                border-bottom: 1px solid $color-primary2;
            }
        }

        &__edit-link {
            font-size: rem(18);
            display: inline-block;
            vertical-align: middle;
            color: $black01;

            a {
                color: $color-primary2;

                &:hover{
                    border-bottom: 1px solid $color-primary2;
                }
            }
        }
    }

    .swatches-howwork {
        margin-bottom: 15px;

        a {
            text-decoration: underline;
            font-size: rem(16);
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $color-primary2;
            }
        }
    }

    // col-1
    .product-col-1 {
        width: 75%;
        position: static;

        @include respond-to(m_tablet_down) {
            width: 100%;
        }
    }

    .furniture-slider {
         // this is to enable sticky-kit for non-mobile layouts
        @include respond-to(m_desktop) {
            &::before {
                content: "js-sticky-enabled";
                display: none;
            }
        }

        text-align: center;

        a {
            display: block;
        }

        &__img-inner {
            @include lazyload-animation;

            max-width: 100%;
            max-height: 100%;
            height: 100%;
            flex-basis: 100%;
            position: relative;
            padding-bottom: 66.5%;
        }

        &__image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
        }

        &__main-img {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__nav-slider {
            width: 60%;
            position: relative;
            z-index: 20;
            margin: -60px auto 0 auto;

            @include respond-to(m_tablet_down) {
                width: 100%;
                margin-top: 5px;
            }

            .slick-track {
                border: 3px solid $color-primary3;
                background: $color-primary3;
            }

            .slick-list {
                padding: 0 10% 0 0;

                @include respond-to(mobile_strong) {
                    display: none;
                }
            }

            &--less-count {
                .slick-list {
                    padding: 0!important;
                    overflow: visible;
                }

                .slick-track {
                    margin: 0 auto;
                }
            }
        }

        .furniture-nav-arrow {
            height: 100%;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-50%, -25%);
            width: 45px;
            height: 45px;
            cursor: pointer;
            background-color: $black01;

            @include respond-to(mobile_strong) {
                display: none !important;
            }

            &::after {
                display: none;
            }

            span {
                position: absolute;
                left: 75%;
                top: 50%;
                transform: rotate(-45deg);

                &::before,
                &::after {
                    content:'';
                    display: block;
                    position: absolute;
                    height: 4px;
                    width: 20px;
                    background: $white;
                    bottom: 0;
                    right: 0;
                }

                &::after {
                   width: 4px;
                   height: 20px;
                   right: 0;
                   bottom: 0;
                }
            }
        }

        .slick-dotted {
            @include respond-to(m_tablet) {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: auto;
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%);
                    width: 160px;
                }
            }
        }

        &__nav-img {
            border-right: 3px solid $color-primary3;
            background: $color-primary3;
            display: inline-block;
            cursor: pointer;

            a {
                @include lazyload-animation;

                display: block;
                position: relative;
                padding-bottom: 66.5%;

                @include respond-to(m_tablet_down) {
                    width: 105px;
                    padding-bottom: 70px;
                    margin: 0 auto;
                }
            }

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;

                @include respond-to(m_tablet_down) {
                    height: 70px;
                }
            }

            &.slick-current {
                a {
                    outline: 1px solid $color-secondary4;
                }
            }
        }

        .slick-dots {
            margin: 0;
            
            @include respond-to(m_tablet_down) {
                position: static;
            }

            li {
                width: 50px;
                height: 6px;
                padding: 0;
                vertical-align: middle;

                + li {
                    margin-left: 5px;
                }

                @include respond-to(m_mobile_down) {
                    width: 45px;
                }
            }

            button {
                background-color: $tutu;
                width: 100%;
                height: 100%;
                border-radius: 0;
                display: block;
            }

            .slick-active button {
                background-color: $medium-blue;
            }
        }
    }

    .product-description {
        text-align: center;
        padding-top: 30px;
        max-width: 60%;
        margin: 0 auto;

        @include respond-to(furniture_down) {
            padding: 30px 15px 0;
        }

        &-title {
            font-size: rem(30);
            line-height: 1.2;
            text-transform: none;
        }

        &-text {
            font-size: rem(18);
            text-transform: none;

            p {
                font-size: inherit;
                text-transform: none;
                margin: 0 0 5px;
                line-height: 1.4;
            }
        }
    }

    .tabs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1200px;
        margin: 45px auto 0;

        @include respond-to(touch) {
            padding: 0 15px;
        }
    }

    .column {
        width: 48%;
        
        @include respond-to(m_tablet_down) {
            width: 100%;
        }
    }

    //col-2 (Product informations)
    //1rem = 16px
    #product-content-wrapper {
        width: 25%;
        float: right;

        @include respond-to(m_tablet_down) {
            width: 100%;
        }
    }

    .product-col-2 {
        width: 100%;
        float: none;
        padding: 15px 30px 0 30px;

        @include respond-to(mobile_strong) {
            padding: 15px 15px 0;
        }
    }

    .product-name {
        width: 100%;
        max-width: 100%;

        .product-name-brand {
            text-transform: uppercase;
            line-height: 1.2;
            letter-spacing: normal;

            @include respond-to(m_tablet_down) {
                font-size: rem(20);
            }
        }

        .product-name-title {
            font-size: rem(18);
            line-height: 1.2;
        }
    }

    .furniture-help-buttons {
        text-align: left;
        width: 100%;
        padding: 0px 30px 0 30px;
        float: none;

        @include respond-to(mobile_strong) {
            padding: 0 15px;
        }

        &__btn {
            margin-bottom: 16px;

            .icon-printer {
                display: inline-block;
                font-size: 20px;
                margin-right: 10px;
                vertical-align: middle;
            }

            span {
                font-size: rem(14);
            }

            a {
                margin-bottom: 16px;
                line-height: rem(20);
                color: $black01;
                font-size: rem(14);
    
                span {
                    border-bottom: 1px solid currentColor;
                    transition: all 0.3s ease-in-out;
                }
    
                &:hover {
                    color: $color-primary2;
    
                    span {
                        color: $color-primary2;
                    }
                }
            }
        }

        .product-actions-wishlist-link {
            @include shared-wishlist;
            @include product_actions-button;

            &:hover {
                @include respond-to(desktop_below) {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }
    }

    .product-actions {
        margin-bottom: 25px;
    }

    .socialsharing {
        margin-top: 0;
        padding-top: 0;
        text-align: left;
        margin-bottom: 16px;

        .product-share-block {
            .aricons-close {
                color: $black01;
                display: none;
            }

            .aricons-share-circle,
            .aricons-close {
                float: left;
                font-size: rem(20);
                margin-right: 10px;
                line-height: rem(18) !important;
            }

            .path1,
            .path2 {
                &:before {
                    display: inline-block;
                }
            }

            .toggle {
                line-height: rem(20);
                font-size: rem(14);

                &__text {
                    transition: all 0.3s ease-in-out;
                    border-bottom:1px solid currentColor;
                    margin-left: 2px;

                    &:hover {
                        color: $color-primary2;
                    }
                }

                &.expanded {
                    .aricons-share-circle {
                        display: none;
                    }
    
                    .aricons-close {
                        display: block;
                    }
                }
            }

            .toggle-content {
                padding: 0 20px;
                margin-top: 10px;

                a {
                    border: none;
                    display: inline-block;
                    margin: 0 0 0 10px;
                    font-size: rem(26);

                    &.share-icon {
                        border: none;

                        span {
                            border: none;
                        }
                    }

                    .path1,
                    .path2 {
                        &:before {
                            color: $black01;
                        }
                    }

                    &:hover {
                        .path1,
                        .path2 {
                            &:before{
                                color: $color-primary2;
                            }
                        }
                    }
                }
            }
        }

    }

    .furniture-view-in-store {
        text-align: center;
        background: $color-auxiliary38;
        padding: 15px 18px;
        font-size: rem(18);
        line-height: rem(23);
        margin-bottom: 36px;

        &__icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            vertical-align: bottom;
            background-image: url(../images/map-marker-check.svg);
        }
        a {
            font-size: rem(18);
            line-height: rem(23);
            letter-spacing: 1px;
            border-bottom: 1px solid $black01;
            transition: 300ms ease-out, opacity 300ms, color 300ms ease-out, border-color 300ms ease-out;

            &:hover {
                border-bottom: 1px solid transparent;
                color: $black01;
            }
        }
    }

    .btn-furniture-buy-online {
        font-size: rem(16);
        padding-top: 17px;
        padding-bottom: 17px;
        margin-bottom: 16px;
        transition: background-color 300ms ease-out,opacity 300ms,color 300ms ease-out, border-color 300ms ease-out;
    }

    .btn-order-by-phone {
        border: 1px solid $color-primary2;
        padding-top: 12px;
        padding-bottom: 12px;
        color: $color-primary2;
        font-size: rem(16);
        margin-bottom: 32px;
        letter-spacing: 1px;
        width: 100%;
        background: $color-primary3;
        transition: background-color 300ms ease-out, opacity 300ms, color 300ms ease-out, border-color 300ms ease-out;

        &__icon {
            display: inline-block;
            width: 20px;
            vertical-align: bottom;
            height: 20px;
            background-image: url(../images/phone.svg);
            transition: background-color 300ms ease-out, opacity 300ms, color 300ms ease-out, border-color 300ms ease-out;
        }

        &:hover {
            border-color: $orient;
            color: $orient;
            opacity: 0.5;

            .btn-order-by-phone__icon {
                background-image: url(../images/phone-hover.svg);
            }
        }
    }

    .price-switcher {
        max-width: 100%;
        width: 100%;

        @include respond-to(mobile) {
            display: block;
            margin-bottom: 15px;
        }

        .price-sales,
        .now-sell-price,
        .product-sales-price.now-sell-price {
            line-height: rem(32);
            font-size: rem(18);
        }

        .price-standard {
            font-size: rem(16);

            @include respond-to(m_tablet_down) {
                font-size: rem(14);
            }
        }

        .product-price {
            margin-bottom: 15px;

            span:first-of-type:last-of-type { // Override styles for regular price with the same class as Now Price
                font-size: rem(20);

                &.price-sales__now-text {
                    font-size: rem(18);
                }

                &.price-standard-qq-coment {
                    font-size: rem(16);

                    @include respond-to(mobile) {
                        font-size: rem(14);
                    }
                }
            }
        }

        &__credit-price {
            color: $malachite-green;
            display: block;
            margin-bottom: 11px;
            font-size: rem(18);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .product-variations {
        max-width: 100%;
        width: 100%;

        .variant-dropdown .value {
            width: 100%;
        }
    }

    .product-variations .attribute,
    .product-options .attribute {
        @include respond-to(m_tablet_down) {
            .value {
                width: 100%;
            }
        }
    }

    .pdpForm {
        max-width: 100%;
        width: 100%;
    }

    .add-to-cart-wrapper,
    .add-to-cart { // for case if applepay is present
        margin: 20px 0;
    }

    //details & dimensions
    .product-attributes__item {
        text-align: left;
        margin-bottom: 10px;
        font-size: rem(16);

        .lavel,
        .value {
            font-size: rem(14);
            line-height: rem(24);

            @include respond-to(m_tablet) {
                flex-basis: 50%;
            }
        }

        .label {
            font-family: $font-main-bold;
        }
    }

    .product-large-atribute {
        h5 {
            font-size: rem(24);
            margin: 0;
            line-height: rem(30);
            text-transform: none;
        }

        p {
            font-size: rem(16);
            line-height: rem(24);
            margin-bottom: 16px;
            margin-top: 0;
        }
    }

    //Brand Asset
    .brand-asset {
        max-width: 1140px;
        margin: 0px auto 30px;
        padding: 0 30px;

        &__image-col {
            width: 66.6666%;
            float: left;
            position: relative;

            img {
                width: 100%;
                max-width: 100%;
            }

            @include respond-to(m_tablet_down) {
                width: auto;
                float: none;
            }
        }

        &__info-col {
            width: 33.3333%;
            float: right;

            @include respond-to(m_tablet_down) {
                width: auto;
                float: none;
            }
        }

        &__info {
            margin-top: 60px;
            padding: 60px 0 60px 40px;
            margin-left: -80px;
            position: relative;
            background: $color-primary3;

            @include respond-to(m_tablet_down) {
                padding: 30px 30px 0 30px;
                margin: -30px 0 0 30px;
            }

            h2 {
                font-size: rem(40);
                line-height: 1.2;
                margin-bottom: 12px;
                text-transform: none;
            }

            h3 {
                text-transform: none;
                font-size: rem(31);
                line-height: rem(37);
                margin-bottom: 12px;
            }

            &_link,
            p {
                font-size: rem(18);
                line-height: rem(24);
                margin-bottom: 16px;
            }

            &_link {
                letter-spacing: 1px;
                text-transform: uppercase;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: $black01;
                }
            }
        }
    }

    //USP content assets
    .usp-content-furniture {
        margin-top: 30px;

        @include respond-to(mobile_strong) {
            display: none;
        }

        .main-title {
            @include respond-to(mobile_strong) {
                display: none;
            }
        }

        .asset-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 15px;
            padding: 0 30px;
    
            @include respond-to(m_tablet_down) {
                align-items: center;
            }
        }
    
        .asset-image {
            margin-right: 5%;
            width: 23%;
            
            img {
                max-width: 100%;
            }
        }
    
        .asset-info {
            text-align: left;
            width: 72%;
        }
    
        .asset-text,
        .asset-title {
            margin: 0;
            font-size: rem(14);
            line-height: 1.5;
        }
    }

    //furniture delivery-financing assets
    .delivery-financing-assets {
        margin: 30px auto 0;
        max-width: rem(720);

        &::after {
            content: "";
            display: block;
            border: 1px solid $lighter-gray;
            margin-left: -100%;
            margin-right: -100%;

            @include respond-to(mobile_strong_over) {
                margin-top: 95px;
                margin-bottom: 95px;
            }
        
            @include respond-to(mobile_strong) {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }

        .tabs {
            justify-content: center;
            padding: 0;
        }

        .tabs > input[type="radio"] {
            display: none;
        }

        #tab-btn-1:checked ~ .delivery-asset,
        #tab-btn-2:checked ~ .financing-asset {
            display: block;
        }

        #tab-btn-1:checked ~ .delivery-title,
        #tab-btn-2:checked ~ .financing-title {
            border-color: $black01;
            font-family: $font-main-bold;
            border-width: 2px;
        }
    }

    .delivery-financing-title-wrapper {
        border-bottom: 1px solid $sections-header-border;
        width: rem(360);

        @include respond-to(m_tablet_down) {
            width: 50%;
        }

        .delivery-financing-title {
            margin: 0 0 10px;
            text-align: center;
            font-size: rem(14);
        }
    }

    .delivery-asset,
    .financing-asset {
        display: none;
        width: 100%;

        .financing-asset-inner,
        .delivery-asset-inner {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;

            @include respond-to(m_mobile_down) {
                padding: 30px 15px;
                flex-direction: column;
            }
        }

        .asset-title {
            font-family: $font-main-bold;
            text-transform: none;
        }

        .asset-title,
        .asset-text {
            margin-top: 0;
            font-size: rem(14)
        }

        .asset-link {
            @include link_underline($medium-blue);

            margin: 25px auto 0;
            font-size: rem(14);
        }
    }

    .delivery-asset-text-block {
        @include respond-to(m_tablet) {
            padding: 25px 10px 0;
        }
    }

    .financing-asset-option-block,
    .delivery-asset-text-block {
        @include respond-to(mobile_strong_over) {
            width: 48%;
        }

        @include respond-to(m_mobile_down) {
            width: 100%;
        }
    }

    .financing-asset-text-block {
        padding-top: 20px;
        margin-bottom: 10px;
    }

    .financing-asset-options {
        display: flex;
        justify-content: space-between;

        @include respond-to(m_mobile_down) {
            margin: 0 auto;
            flex-direction: column;
        }
    }

    .financing-asset {
        &-option-block {
            background-color: $light-gray02;

            @include respond-to(m_desktop) {
                padding: 30px 25px;
            }

            @include respond-to(m_tablet_down) {
                margin-bottom: 20px;
                padding: 30px 45px;
            }
        }

        &-option-title,
        &-option-text {
            font-family: $font-main-bold;
        }

        &-option-text {
            font-size: rem(12);
            margin-bottom: 0;
        }

        &-option-title {
            font-size: rem(32);
            margin-top: 0;
            text-transform: uppercase;
            color: $medium-blue;
        }
    }

    //furniture buying guides
    .pdp-buying-guides {
        position: relative;
        max-width: 1200px;
        margin: 0 auto 60px;

        &__bg {
            position: absolute;
            width: 100vw;
            background: $color-auxiliary38;
            height: 100%;
            z-index: -1;
            top: 0;
            left: 50%;
            transform: translate(-50%,0);
        }

        .slot {
            padding: 0 0 60px;
        }
    }

    .buying-guide {
        &__item {
            width: calc(50% - 60px);
            margin: 0 30px;
            float: left;

            @include respond-to(mobile_strong) {
                width: auto;
                float: none;
            }

            a {
                display: block;
                color: $black01;

                &:hover {
                    color: $black01;
                }
            }

            .category-content-wrapper {
                text-align: left;
                position: relative;
            }

            .category-asset-title-wrapper {
                margin: -60px 0 0 -9.5px;
                text-align: center;
                background: transparent;
                position: absolute;
                width: auto;

                h4 {
                    text-transform: none;
                    height: 60px;
                    padding: 24px 40px 0 24px;
                    color: $color-primary2;
                    background: $color-auxiliary38;

                    &:before,
                    &:after {
                        background-color: $color-auxiliary38;
                    }
                }

                &:before,
                &:after {
                    background-color: $color-auxiliary38;
                }
            }

            &_text {
                width: 100%;
                padding: 0 24px;
                font-size: rem(18);
                margin: 40px 0 0;
                line-height: 1.3;
                text-rendering: optimizeLegibility;
            }
        }
    }

    .pdp-about-brand-text {
        .cta-type1 {
            color: $black01;
        }
    }
}

//Swatches modal
.ui-dialog.furniture-ui-dialog {
    max-width: 610px;

    @include respond-to(mobile) {
        max-width:calc(100% - 30px);
    }

    & > .ui-widget-header {
        padding: 0;

        .ui-dialog-title {
            margin: 0;
            height: 33px;
        }
    }

    & > .ui-dialog-content {
        padding: 0 15px 31px;
    }

    .swatches-modal {
        &__title {
            border-left: 3px solid $color-primary2;
            font-size: rem(24);
            padding-left: 20px;
            line-height: rem(34);
            color: $nero;
            text-transform: none;
            margin-bottom: 15px;
            margin-left: 4px;
        }

        &__text {
            font-size: rem(16);
            color: $nero;
            margin-bottom: 20px;
        }

        &__instruction {
            background-color: $color-auxiliary11;
            padding: 24px;

            li {
                font-size: rem(16);
                color: $black01;
                margin-bottom: 10px;
            }
        }

        &__return-to-product {
            color: $color-secondary3;
            text-decoration: underline;
            font-size: rem(16);
            cursor: pointer;
            transition: .3s ease;
            display: block;

            @include respond-to(mobile) {
                text-align: center;
            }

            &:hover {
                color: $black01;
            }
        }
    }
}
